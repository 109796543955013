import {Component, OnDestroy} from '@angular/core';
import {AuthService} from '../global-services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: 'churn-unsubscribe.component.html',
})
export class ChurnUnsubscribeComponent implements OnDestroy{

    token = '';
    uidb36: '';
    source_cta = '';
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private authService: AuthService, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            const token = params['token'] || '';
            this.uidb36 = token.split('-')[0] || '';
            this.token = token.substr(this.uidb36.length + 1) || '';
            this.source_cta = params['source'] || '';
        });
    }

    ngOnInit(): void {
        this.submit();
    }

    submit() {
        this.authService.unsubscribeChurnEmail(this.token, this.uidb36, this.source_cta)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
