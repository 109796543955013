export class GeoLocationModel {
    location_content = { US: { url: '', country: 'United States' } };
    default: string = 'US';
    restrict_default_redirect: boolean = false;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.default = json['default'] || this.default;
        this.restrict_default_redirect = json['restrict_default_redirect'] || this.restrict_default_redirect;
        if (json['location_content']) {
            this.location_content = json['location_content'] || this.location_content;
        }
    }
}
