import {Injectable} from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import {environment} from '../../environments/environment';
import { AuthService } from './auth.service';
@Injectable()
export class LoggerService {
    private initialized = false;
    private user: any = undefined;
    constructor( private authService: AuthService) {

        datadogLogs.init({
            clientToken: environment.datadog.clientToken,
            site: environment.datadog.site,
            service: `${environment.datadog.service}-${environment.envName}`,
            forwardErrorsToLogs: environment.datadog.forwardErrorsToLogs,
            sessionSampleRate: environment.datadog.sessionSampleRate,
        });
        this.initialized = true;
        this.user = this.authService.getUser();
    }

    public debug(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.debug(message, context);
        }
    }

    public info(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.info(message, context);
        }
    }

    public warn(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.warn(message, context);
        }
    }

    public error(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.error(message, context);
        }
    }

    public log(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            this.addUserIdToLog(context)
            datadogLogs.logger.log(message, context);
        }
    }

    private addUserIdToLog(context){
        if ( this.user ) {
            context.userid =   this.user.id
        }
    }
}
