import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperComponent } from './image-cropper.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BeaconstacModule } from 'kaizen-design-system';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        BeaconstacModule
    ],
    declarations: [
        ImageCropperComponent,
    ],
    exports: [
        ImageCropperComponent,
    ]
})
export class ImageCropperModule { }
