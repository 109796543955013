<ngx-file-drop class="drop-zone-container d-flex align-content-center justify-content-center"
               [ngStyle]="{'font-size': fileDropSize === 'small' ? 'smaller' : ''}"
           [ngClass]="small ? !disabled && isFileOverDropZone ? 'small-box drop-zone-file-over' : 'small-box':
            !disabled && isFileOverDropZone ? 'drop-zone-file-over' : ''"
           (onFileOver)="isFileOverDropZone = true" (onFileLeave)="isFileOverDropZone = false" [disabled]="disabled"
           (onFileDrop)="onFileDropped($event)"
           (click)="overrideClick ? onClicked() : browse.nativeElement.click()" [style.cursor]="(disabled ? 'default' : 'pointer')" 
           [style.height]="(containerHeight)">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div class="drop-zone-message d-flex my-auto">
      <div  *ngIf="!isUploading && !file && !preview && !disabled && !fileURL">
        <div class="text-main-dark">
          <div class="w-100"><i class="fas fa-cloud-upload-alt fa-2x" aria-hidden="true"></i></div>
          <span class="d-none d-md-block">{{inputText}}</span>
          <span class="text-primary">&nbsp;browse</span>
          <div [ngStyle]="{'font-size': fileDropSize === 'small' ? 'x-small' : 'smaller'}" class="text-center text-slate" *ngIf="dimension && [maxSize]">{{dimension}} pixels. {{[maxSize]/1000000}}MB max file size.
            <span *ngIf="custom"> {{custom}}</span>
          </div>
          <div *ngIf="!dimension && [maxSize]" [ngStyle]="{'font-size': fileDropSize === 'small' ? 'x-small' : 'smaller'}" style="text-align: center">{{[maxSize]/1000000}}MB max file size.
            <span *ngIf="custom"> {{custom}}</span></div>
        </div>
      </div>
      <img *ngIf="!isUploading && !!preview" style="height: 70px;" [src]="preview" />
      <span *ngIf="!isUploading && file && !(fileURL || fileName) && !preview" style="color: #63c2de">{{ file.name
        }}</span>
      <span *ngIf="!isUploading && (fileURL || fileName) && !preview" style="color: #63c2de">{{ fileName || fileURL
        }}</span>
      <spinner *ngIf="isUploading && !disabled" [color]="'#36B1DA'" [size]="30" [tickness]="3"></spinner>
    </div>
    <input class="drop-zone-input-hidden" type="file" #browse [accept]="acceptTypes.join(',')"
           (change)="$event.target.files && onBrowse($event.target.files[0])" [disabled]="isUploading || disabled">
  </ng-template>
</ngx-file-drop>
<div class="alert alert-danger mt-2" *ngIf="_error">{{ _error }}</div>

<div [ngClass]="(!isUploading && (!!preview || fileURL) && !disabled && showRemoveButton) ? '' : 'opacity-0' " class="text-center" (click)="onFileRemoved()">
  <i class="fas fa-trash-alt place-delete bold-on-hover cursor-pointer"> REMOVE</i>
</div>
