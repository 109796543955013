<div class="app flex-row align-items-center">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-md-6">
        <img class="mx-auto d-block mb-5" [src]="logo$ | async" style="max-width: 200px;">
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-5">
        <div class="card-group mb-0">
          <div class="card p-4">
            <div class="card-block">
              <form #resetForm="ngForm" (ngSubmit)="submit()">

                <h4 class="mb-4 text-center">Reset your password</h4>
                <p class="text-muted mb-4">Please set a password for
                  <span class="font-weight-bold" *ngIf="resetModel.email"> {{resetModel.email}}</span>
                </p>

                <div class="alert alert-danger" *ngIf="resetModel.error">{{ resetModel.error }}</div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Enter new password" name="new_password1"
                         #pass1="ngModel" [(ngModel)]="resetModel.pass1" required minlength="6">
                </div>
                <div class="alert alert-warning mt-1" *ngIf="pass1.invalid && (pass1.dirty || pass1.touched)">
                  Password must be at least 6 characters
                </div>

                <div class="input-group mt-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-action-redo"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Re-enter new password" name="new_password2"
                         #pass2="ngModel" [(ngModel)]="resetModel.pass2" required minlength="6">
                </div>
                <div class="alert alert-warning mt-1"
                     *ngIf="(pass2.invalid || resetModel.pass1 !== resetModel.pass2) && (pass2.dirty || pass2.touched)">
                  Passwords do not match
                </div>

                <div class="row mt-4">
                  <div class="col-12">
                    <button type="submit" class="btn btn-primary px-4 action-button-mobile w-100"
                            [disabled]="!resetForm.form.valid || resetModel.inProgress">Submit</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div bsModal #successModal="bs-modal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Success</h4>
      </div>
      <div class="modal-body">
        <p>Your password has been reset. Please login with your new password.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary action-button-mobile" [routerLink]="['/login']">Login</button>
      </div>
    </div>
  </div>
</div>
