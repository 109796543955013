import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {QR} from '../qr/qr.model';
import {AuthService} from './auth.service';

@Injectable()
export class QrSavedInstanceService {
    qrDataSubject: BehaviorSubject<QR> = new BehaviorSubject(null);

    constructor(private authService: AuthService) {
    }

    saveData(qr: QR) {
        this.qrDataSubject.next(qr);
    }

    get savedData() {
        if (this.qrDataSubject.getValue() && this.authService.getCurrentOrganization().id !== this.qrDataSubject.getValue().id) {
            this.clear();
            return null;
        }
        return this.qrDataSubject.getValue();
    }

    clear() {
        this.qrDataSubject.next(null);
    }
}
