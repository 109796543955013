<div class="app flex-row align-items-center">
  <div class="container">

    <div class="d-flex align-items-center justify-content-center" style="min-height: calc(100vh - 57px)">
      <div *ngIf="isRequestingEmailChange">
        <div class="sso-loader">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
               y="0px" viewBox="0 0 100 100" xml:space="preserve">
           <rect fill="none" stroke="#2595ff" stroke-width="4" x="25" y="25" width="50" height="50">
              <animateTransform
                attributeName="transform"
                dur="0.5s"
                from="0 50 50"
                to="180 50 50"
                type="rotate"
                id="strokeBox"
                attributeType="XML"
                begin="rectBox.end"/>
          </rect>
            <rect x="26" y="26" fill="#2595ff" width="50" height="50">
              <animate
                attributeName="height"
                dur="1.3s"
                attributeType="XML"
                from="50"
                to="0"
                id="rectBox"
                fill="freeze"
                begin="0;strokeBox.end"/>
          </rect>
        </svg>


          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
               y="0px" viewBox="0 0 100 100" xml:space="preserve">
            <path fill="#2595ff"
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="0.9s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"/>
          </path>
        </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
               y="0px" viewBox="0 0 100 100" xml:space="preserve">
         <defs>
          <mask id="loader-mask">
              <path d="M 25 25 L 75 25 L 50 75 z" stroke-width="3"
                    stroke="#fff"/>
          </mask>
        </defs>
            <path d="M 25 25 L 75 25 L 50 75 z" class="triangle-path"
                  fill="none" stroke="#2595ff" stroke-width="3">
        </path>
    </svg>
        </div>
      </div>

    </div>

    <div *ngIf="!isRequestingEmailChange && changeEmailAddressModel.success"
         class="success-animation d-flex align-items-center justify-content-center"
         style="flex-direction: column; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto">
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
      <h4 class="mt-4 px-4 text-center">{{changeEmailAddressModel.success}}<br> Please <a [href]="'/login'">click here</a> to login.</h4>
    </div>

    <span *ngIf="!isRequestingEmailChange && changeEmailAddressModel.error"
          class="failure-animation d-flex align-items-center justify-content-center"
          style="flex-direction: column; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto">
      <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="crossmark__cross" fill="none" d="M16 16 36 36 M36 16 16 36"/>
      </svg>
      <h4 class="mt-4 px-4 text-center">{{changeEmailAddressModel.error}} <br>
        Resend the request to change email address from <a [href]="'/account'">account settings</a> in dashboard</h4>
    </span>

  </div>
</div>
