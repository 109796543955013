import {BaseBackendService} from '../global-services/base-backend.service';
import {DigitalBusinessCard, DigitalBusinessCardTemplateModel} from './digital-business-card.model';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../global-services/auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {Injectable} from '@angular/core';
import {CustomSlugValidation} from '../link-page/linkpage.model';
import {DBCCardTypes} from '../shared/utils';
import {QR_CANVAS_TYPES, QR_TEMPLATE, QR_TEMPLATE_SIZE} from '../qr/qr.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {DigitalBusinessCardSetting} from './digital-business-card-setting/digital-business-card-setting.model';
import {CardsOrganizationRestrictedFields} from '../user-account/user-organization.model';

export enum LEAD_COLLECT_TYPE {
    COLLECT_FIRST = 1,
    SHARE_FIRST = 2
}

class BulkDelete {
    result = '';
    detail = '';

    constructor(json) {
        if (!json) {
            return;
        }
        this.result = json['result'];
        this.detail = json['detail'];
    }
}

class ShareWalletPass {
    count: number;
    detail: string = '';

    constructor(json) {
        if (!json) {
            return;
        }

        this.count = json['count'];
        this.detail = json['detail'];
    }
}

class QRDownload {
    urls = {
        svg: '',
        png: '',
        jpeg: '',
        pdf: '',
        eps: ''
    };

    constructor(json) {
        if (!json) {
            return;
        }
        this.urls.pdf = json['urls'].pdf;
        this.urls.jpeg = json['urls'].jpeg;
        this.urls.png = json['urls'].png;
        this.urls.svg = json['urls'].svg;
        this.urls.eps = json['urls'].eps;
    }

}

class QRBulkDownload {
    url = '';
    type = 1;

    constructor(json) {
        if (!json) {
            return;
        }
        this.url = json['url'];
        this.type = json['type'];
    }
}

@Injectable()
export class DigitalBusinessCardsService extends BaseBackendService<DigitalBusinessCard>{
    private selectedDigitalBusinessCard: BehaviorSubject<DigitalBusinessCard> = new BehaviorSubject(null);
    private selectedOperation: BehaviorSubject<String> = new BehaviorSubject('');

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, DigitalBusinessCard, 'dbc', messageModal);
    }

    post(body: object, custom?: string): Observable<any> {
        if (body instanceof DigitalBusinessCard) {
            body.clearModelForPost()
        }
        return super.post(body, custom);
    }

    put(body: object, id: number | string, custom?: string): Observable<any> {
        if (body instanceof DigitalBusinessCard) {
            body.clearModelForPut()
        }
        return super.put(body, id, custom);
    }

    setDBCCreated(digitalBusinessCard: DigitalBusinessCard, operation: String) {
        this.selectedDigitalBusinessCard.next(digitalBusinessCard)
        this.selectedOperation.next(operation)
    }

    getDBCCreated() {
        return { digitalBusinessCard: this.selectedDigitalBusinessCard.getValue(), operation: this.selectedOperation.getValue() };
    }
}


@Injectable()
export class DigitalBusinessCardTemplateService extends BaseBackendService<DigitalBusinessCardTemplateModel> {

    private digitalBusinessCardTemplate: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, DigitalBusinessCardTemplateModel, 'dbc/templates', messageModal);
    }

    setDBCTemplate(digitalBusinessCard: DigitalBusinessCardTemplateModel) {
        this.digitalBusinessCardTemplate.next(digitalBusinessCard);
    }

    getDBCTemplate() {
        return this.digitalBusinessCardTemplate.getValue();
    }

    getDBCTemplateAsBulk() {
        const template = this.digitalBusinessCardTemplate.getValue();
        if (!template) {
            return;
        }
        const templateAsBulk = {};
        templateAsBulk['background_color'] = template['customizations']['background_color'];
        templateAsBulk['user_info_color'] = template['customizations']['user_info_color'];
        templateAsBulk['secondary_color'] = template['customizations']['secondary_color'];
        templateAsBulk['font_style'] = template['customizations']['font_style'];
        templateAsBulk['title_font_size'] = template['customizations']['title_font_size'];
        templateAsBulk['layout'] = template['layout'];
        templateAsBulk['autodownload_v2'] = template['autodownload_v2'];
        templateAsBulk['location_enabled'] = template['location_enabled'];
        templateAsBulk['auto_send_wallet_pass'] = template['auto_send_wallet_pass'];
        templateAsBulk['logo_url'] = template['logo_url'];
        templateAsBulk['user_image_url'] = template['user_image_url'];
        templateAsBulk['customizations'] = template['customizations'];
        templateAsBulk['lead_collection'] = template['lead_collection'];
        templateAsBulk['lead_attribute'] = template['lead_attribute'];
        templateAsBulk['lead_user_agreement'] = template['lead_user_agreement'];
        templateAsBulk['lead_user_agreement_attribute'] = template['lead_user_agreement_attribute'];
        return templateAsBulk;
    }

}

@Injectable()
export class DigitalBusinessCardSettingService extends BaseBackendService<DigitalBusinessCardSetting> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, DigitalBusinessCardSetting, 'dbc/restrictedfields', messageModal);
    }
}

@Injectable()
export class CardsOrgSettingService extends BaseBackendService<CardsOrganizationRestrictedFields> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, CardsOrganizationRestrictedFields, 'dbc/restrictedfields', messageModal);
    }
}


@Injectable()
export class CustomSlugValidationService extends BaseBackendService<CustomSlugValidation> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, CustomSlugValidation, 'dbc/validate', messageModal);
    }
}

@Injectable()
export class ShareWalletPassService extends BaseBackendService<ShareWalletPass> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, ShareWalletPass, 'dbc/bulkwalletpass/list', messageModal);
    }

    shareWithArray(ids: Array<number>, organization: string | number) {
        const data = {
            ids: ids,
            organization: organization
        };
        return this.post(data);
    }
}

@Injectable()
export class ShareWalletPassFilterService extends BaseBackendService<ShareWalletPass> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, ShareWalletPass, 'dbc/bulkwalletpass/filter', messageModal);
    }

    shareWithFilter(filters: Object, organization: string | number,  cardType: DBCCardTypes, card_owner: number) {

        const data = {
            filters: filters,
            organization: organization,
            card_owner: card_owner,
            card_type: cardType
        };
        return this.post(data);
    }
}

@Injectable()
export class DbcBulkFilterSoftDeleteService extends BaseBackendService<BulkDelete> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, BulkDelete, 'dbc/bulkdeactivate/filter', messageModal);
    }

    deleteWithFilter( organization: string | number, filters: Object, cardType: DBCCardTypes, card_owner: number) {
        // filters['organization_id'] = organization;

        const data = {
            filters: filters,
            organization: organization,
            card_owner: card_owner,
            card_type: cardType
        };
        return this.post(data);
    }
}

@Injectable()
export class DbcBulkSoftDeleteService extends BaseBackendService<BulkDelete> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, BulkDelete, 'dbc/bulkdeactivate/list', messageModal);
    }

    deleteWithArray(organization, ids: Array<number>) {
        const data = {
            ids: ids,
            organization: organization
        };
        return this.post(data);
    }
}

@Injectable()
export class DbcBulkFilterHardDeleteService extends BaseBackendService<BulkDelete> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, BulkDelete, 'dbc/bulkdelete/filter', messageModal);
    }

    deleteWithFilter( organization: string | number, filters: string, cardType: DBCCardTypes, card_owner: number) {

        const data = {
            filters: filters,
            organization: organization,
            card_owner: card_owner,
            card_type: cardType
        };
        return this.post(data);
    }
}

@Injectable()
export class DbcBulkHardDeleteService extends BaseBackendService<BulkDelete> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, BulkDelete, 'dbc/bulkdelete/list', messageModal);
    }

    deleteWithArray(organization, ids: Array<number>) {
        const data = {
            ids: ids,
            organization: organization
        };
        return this.post(data);
    }
}

@Injectable()
export class OwnerTransferService extends BaseBackendService<any> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, ShareWalletPass, 'dbc', messageModal);
    }

    changeProductOwnerr(forceMigrate: boolean, dbc: DigitalBusinessCard, maintainer: number) {
        let migrate = '';
        if (forceMigrate) {
            migrate = '?force_migrate=true';
        }
        return this.postToId({ 'new_card_owner': maintainer }, dbc.id + '/transfer/owner/' + migrate)
    }
}

@Injectable()
export class DbcQRDownloadService extends BaseBackendService<QRDownload> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, QRDownload, 'dbc', messageModal);
    }

    download(id: number, canvasType: QR_CANVAS_TYPES, organization: string | number, size = 1024, errorCorrectionLevel = 0, qr_template?: QR_TEMPLATE, templateSize?: QR_TEMPLATE_SIZE, logoUrl?: string): Observable<any> {
        const url = '?canvas_type=' + canvasType + '&size=' + size + '&error_correction_level=' + errorCorrectionLevel + '&organization=' + organization;
        return this.getDetail(id + '/download', url).pipe(
            map(response => {
                response['urls'][canvasType] += '?t=' + Date.now()
                return response
            })
        );
    }

}

@Injectable()
export class DbcQRBulkDownloadService extends BaseBackendService<QRBulkDownload> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, QRBulkDownload, 'dbc/qrcode/list', messageModal);
    }

    downloadWithArray(organization: string|number, ids: Array<number>, canvasType: QR_CANVAS_TYPES, size = 1024, errorCorrectionLevel = 0): Observable<any> {

        const data = {
            name: 'Bulk download',
            error_correction_level: errorCorrectionLevel,
            size: size,
            ids: ids
        };

        if (canvasType.trim()) {
            data['canvas_type'] = canvasType;
        }

        return this.post(data);

    }

}

@Injectable()
export class DbcQRBulkFilterDownloadService extends BaseBackendService<QRDownload> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, QRDownload, 'dbc/qrcode/filter', messageModal);
    }

    downloadQRWithFilter
    (organization: number, filters: string, canvasType: QR_CANVAS_TYPES, size = 1024, errorCorrectionLevel = 0): Observable<any> {
        const data = {
            name: 'Bulk download',
            error_correction_level: errorCorrectionLevel,
            size: size,
            filters: filters,
            organization: organization

        };

        if (canvasType.trim()) {
            data['canvas_type'] = canvasType;
        }


        return this.post(data);
    }

}
