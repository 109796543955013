import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscardChangeComponent } from './discard-change.component';
import {BeaconstacModule} from 'kaizen-design-system';
import {ModalModule} from 'ngx-bootstrap/modal';
import {DiscardChangesService} from '../../global-services/discard-changes.service';

@NgModule({
    declarations: [DiscardChangeComponent],
    exports: [
        DiscardChangeComponent
    ],
    imports: [
        CommonModule,
        BeaconstacModule,
        ModalModule
    ],
    providers: [
        DiscardChangesService
    ]
})
export class DiscardChangeModule { }
