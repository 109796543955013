<div class="p-3 gwp-preview" [ngStyle]="{ 'background-color': backgroundColor, 'color': textColor , 'border-radius': heroImage ? '16px 16px 0px 0px' : '16px'}">
    <div class="d-flex align-items-center flex-row mb-2">
        <div class="gwp-company-logo-cont">
            <img *ngIf="logo; else showIcon" class="h-100 w-100" [src]="logo"
                style="border-radius: 50%" />
            <ng-template #showIcon>
                <div class="d-flex justify-content-center align-items-center w-100 h-100 gwp-empty-company-logo bg-white">
                    {{ company?.substring(0, 1) | uppercase }}
                </div>
            </ng-template>
        </div>

        <div class="gwp-company-name my-1 mx-3" [ngClass]="company ? '' : 'd-none'">{{company}}</div>
    </div>

    <hr style="border-color: #FFFFFF; width: 268.22px;opacity: 0.1; margin-bottom: 0rem;">

        <div *ngIf = "designation" class="mt-3">
          <span class="profile-designation-gwp">{{designation}}</span>  
        </div>

        <div  style="height: 30px">
            <span class="profile-fname-gwp">{{firstName}}&nbsp;</span>
            <span class="profile-lname-gwp">{{lastName}}</span>
        </div>

    <div *ngIf="email">
        <div class="mt-3">
            <div class="font-fs-12">Email</div>
            <div class="gwp-profile-email text-break font-fs-18">{{ email }}</div>
        </div>

        <hr style="border-color: #FFFFFF; width: 268.22px;opacity: 0.1;margin-top: 0.5rem; margin-bottom: 0rem;" class="mt-0; mb-0">
    </div>

    <div *ngIf="phone" style="height: 45px">
        <div class="mt-3">
            <div class="font-fs-12">Phone number</div>
            <div class="font-fs-14">{{ phone }}</div>
        </div>

        <hr style="border-color: #FFFFFF; width: 268.22px;opacity: 0.1;margin-top: 0.5rem; margin-bottom: 0rem;" class="mt-0; mb-0">
    </div>

    <div class="gwp-vcard-qrcode-cont">
        <div class="m-auto gwp-vcard-qrcode">
            <img class="gwp-vcard-qrcode-img" [src]="'../../assets/img/dummy-qrcode.png'" />
        </div>
    </div>

</div>

<div class="gwp-hero-img" [ngStyle]="!heroImage ? {'border': '2px dashed #979797'} : '' ">
    <img *ngIf="heroImage;" class="h-100 w-100 hero-image" [src]="heroImage"/>

    <div *ngIf="heroImage === undefined || heroImage === ''">
        <div class="gwp-hero-img-text">
            HERO IMAGE
        </div>
    </div>
</div>
