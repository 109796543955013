import { NgModule } from '@angular/core';
import {
    GetOrganizationName,
    GetTimeZone,
    GetPreviewUrl,
    IsLinkValid,
    IsDeepLinkValid,
    TextHasURL,
    StringSubstr,
    SliceValues,
    UtilsObjectKeys,
    GetTagNames,
    TrimValues,
    ToLowerCase,
    GetLanguageCodePipe,
    IsErrorCSVField,
    IsErrorCSVRow,
    ValidatePhoneNumber,
    IsOnHigherPlan,
    IsOnHigherDBCPlan,
    DecimalPrice,
    IsSAN,
    GetReadableGroup,
    IsInvitePending,
    IsOwner,
    GetTypeString,
    FormatDate,
    GetReadableGroupFromType,
    GetSanitizedHtml,
    DateFormatStringPipe,
    LatLongRangePipe,
    OperandIsValid,
    GetObjectNameFromTypePipe,
    GetReadableDatePipe,
    GetURLWithoutHttpsPipe,
    GanerateRandomId,
    DisableFieldCheckPipeV2,
    InputIconPipeV2
} from './pipes.component';

@NgModule({
    imports: [],
    declarations: [
        GetOrganizationName,
        GetTimeZone,
        IsLinkValid,
        DateFormatStringPipe,
        IsDeepLinkValid,
        TextHasURL,
        GetPreviewUrl,
        UtilsObjectKeys,
        StringSubstr,
        SliceValues,
        GetTagNames,
        TrimValues,
        ToLowerCase,
        GetLanguageCodePipe,
        IsErrorCSVField,
        IsErrorCSVRow,
        ValidatePhoneNumber,
        IsOnHigherPlan,
        IsOnHigherDBCPlan,
        DecimalPrice,
        IsSAN,
        GetReadableGroup,
        GetReadableGroupFromType,
        IsOwner,
        IsInvitePending,
        GetTypeString,
        FormatDate,
        GetSanitizedHtml,
        LatLongRangePipe,
        OperandIsValid,
        GetObjectNameFromTypePipe,
        GetReadableDatePipe,
        GetURLWithoutHttpsPipe,
        GanerateRandomId,
        DisableFieldCheckPipeV2,
        InputIconPipeV2
    ],
    exports: [
        GetOrganizationName,
        GetPreviewUrl,
        GetTimeZone,
        IsLinkValid,
        IsDeepLinkValid,
        TextHasURL,
        UtilsObjectKeys,
        StringSubstr,
        SliceValues,
        GetTagNames,
        TrimValues,
        ToLowerCase,
        GetLanguageCodePipe,
        IsErrorCSVField,
        IsErrorCSVRow,
        ValidatePhoneNumber,
        IsOnHigherPlan,
        IsOnHigherDBCPlan,
        DecimalPrice,
        IsSAN,
        GetReadableGroup,
        GetReadableGroupFromType,
        IsOwner,
        IsInvitePending,
        GetTypeString,
        FormatDate,
        GetSanitizedHtml,
        LatLongRangePipe,
        OperandIsValid,
        DateFormatStringPipe,
        GetObjectNameFromTypePipe,
        GetReadableDatePipe,
        GetURLWithoutHttpsPipe,
        GanerateRandomId,
        DisableFieldCheckPipeV2,
        InputIconPipeV2
    ],
})
export class PipesModule {}
