import { Component, ViewChild, OnInit } from '@angular/core';
import { AMPLITUDE_EVENT_CATEGORIES, AmplitudeService } from 'app/global-services/amplitude.service';
import { AuthService } from 'app/global-services/auth.service';
import { OverlayService } from 'app/global-services/overlay.service';
import { ResponseAddonService } from 'app/global-services/response-addon-service';
import { MessageModalService } from 'app/shared/message-modal/message-modal.service';
import { UserService } from 'app/user-account/user.service';
import { BUTTON_STYLES, BUTTON_TYPES } from 'kaizen-design-system';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-response-addon-modal',
    templateUrl: './response-addon-modal.component.html',
    styleUrls: ['./response-addon-modal.component.scss']
})
export class ResponseAddonModalComponent implements OnInit {

    BUTTON_TYPES = BUTTON_TYPES;
    BUTTON_STYLES = BUTTON_STYLES;

    responseAddonOptions = [
        {name: ' responses for $120 per year', selected: true, disabled: false, value: 1},
        {name: ' responses for $200 per year', selected: false, disabled: false, value: 2},
        {name: ' responses for $500 per year', selected: false, disabled: false, value: 10},
        {name: ' responses for $800 per year', selected: false, disabled: false, value: 20}
    ];
    responseAddonCount = ['+5,000', '+10,000', '+50,000', '+100,000'];
    formAddonPrice: number;
    additionalFormResponses: number = 1;
    selectedResponseAddonCount: string = '+5,000';
    fetchingUpcomingPrice: boolean = false;
    nextBillingDate: string;
    lastBillingDate: string;
    clickedFormFrom: string;
    checkResponseLimit: boolean = false;

    @ViewChild('responseAddOnModal', {static: true}) responseAddOnModal: ModalDirective;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private authService:  AuthService, private userService: UserService,
        private overlay: OverlayService, private messageModal: MessageModalService, private service: ResponseAddonService,
        private amplitude: AmplitudeService, private router: Router,
    ) {
        this.checkResponseLimit = this.authService.getCurrentOrganization().check_response_limits;
    }


    ngOnInit(): void {
        this.service.visible$.pipe().subscribe(visible => {
            if (visible) {
                this.setUpResponseAddonModal({value: 1}, 0);
                this.userService.getSubscription().pipe().subscribe(subscriptionResult => {
                    if (subscriptionResult !== null) {
                        for (const subscription of subscriptionResult) {
                            this.nextBillingDate = moment(subscription.expires).format('MM/DD/YY');
                            this.lastBillingDate = moment(subscription.expires).subtract(1, 'years').format('MM/DD/YY');
                        }
                    }
                }, error => {
                    // TODO error handling
                });
                this.responseAddOnModal.show();
                this.clickedFormFrom = this.router.url.startsWith('/forms') ? 'Formspage' : this.router.url.startsWith('/account') ? 'Billingpage' : 'QRpage';
            }
        });
    }

    setUpResponseAddonModal($event, index) {
        this.fetchingUpcomingPrice = true;
        this.responseAddonOptions.forEach((item, i) => {
            item.selected = (i === index);
        });
        this.additionalFormResponses = $event.value;
        this.selectedResponseAddonCount = this.responseAddonCount[index];
        const data = {
            plan : this.authService.getUser().qrCustomerPlan,
            yearly: true,
            additionalFormResponses: this.additionalFormResponses
        }
        this.userService.getUpcomingInvoicePrice(data).subscribe(res => {
            this.formAddonPrice = res.upcomingPrice
            this.fetchingUpcomingPrice = false;
        })
    }

    updateAddonResponses() {
        this.responseAddOnModal.hide();
        this.overlay.isLoading(true);
        const body = {
            plan : this.authService.getUser().qrCustomerPlan,
            yearly: true,
            additionalFormResponses: this.additionalFormResponses
        }
        this.userService.updateAddOns(body).subscribe(
            () => {
                this.overlay.isLoading(false);
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, `BuyResponses_${this.clickedFormFrom}_paid`, {
                    value: (this.additionalFormResponses * 5000)
                });
                this.messageModal.show('Congrats! Your response limit has now increased by ' + (this.selectedResponseAddonCount.substring(1)) + '.', 'success');
                setTimeout(function(){
                    location.reload();
                }, 3000);
            }, (error) => {
                this.overlay.isLoading(false);
                this.messageModal.show(error.message, 'danger');
            }
        );
    }

}
