<div bsModal #firebaseNotificationModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div *ngIf="currentNotification" class="modal-dialog modal-dialog-centered px-3" role="document">
        <div class="modal-content" style="max-width: 598px;">
            <div class="modal-body p-4">

                <h4 class="notification-heading mb-0" [innerHTML]="currentNotification.title">
                </h4>

                <div class="notification-description" [innerHTML]="currentNotification.message">
                </div>

                <div class="notification-description" *ngFor="let message of currentNotification.messages" [innerHTML]="message">
                </div>
            </div>
            <div class="modal-body px-4 pb-4 pt-3" *ngIf="notificationsCount > 1">
                <div class="d-flex justify-content-between">
                    <i [ngStyle]="(notifications.indexOf(this.currentNotification) > 0) ? {color: '#5E6980'} : {color: '#E2E2E3'}" class="fas fa-chevron-left cursor-pointer" (click)="navigateNotifications(-1)"></i>
                    <div class="d-flex pt-2 w-100 justify-content-center circles-container" *ngIf="notificationsCount < 10">
                        <i *ngFor="let notification of notifications; index as i" [id]="'scroll-item-' + i" class="fas fa-circle circles" [ngClass]="(notification.id === currentNotification.id) ? 'bg-color-active' : 'bg-color-inactive'">
                        </i>
                    </div>
                    <i [ngStyle]="(notifications.indexOf(this.currentNotification) < (notificationsCount-1)) ? {color: '#5E6980'} : {color: '#E2E2E3'}" class="fas fa-chevron-right cursor-pointer" (click)="navigateNotifications(1)"></i>
                </div>
            </div>
            <div class="modal-footer px-4 py-3" style="border-top: none;">
                <div>
                    <span style="float: right; display: flex; flex-direction: row">
                        <span style="padding-left: 16px">
                          <beaconstac-button (onClick)="markAsRead()" [label]="primaryCta"></beaconstac-button>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
