import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {AuthService} from '../global-services/auth.service';
import {Router} from '@angular/router';
import {BEACONSTAC_HOSTS} from '../app.constants';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Utils} from '../shared/utils';
import {IntercomService} from '../global-services/intercom.service';

@Component({
    templateUrl: 'sso-login.component.html',
})
export class SsoLoginComponent implements AfterViewInit, OnDestroy {

    isLoading: boolean = true;
    errorMessage: string = '';

    logoSource = new BehaviorSubject<string>('');
    logo$ = this.logoSource.asObservable();
    ngUnsubscribe: Subject<any> = new Subject();

    authSubscription: Observable<any>;
    errorSubscription: Observable<any>;

    constructor(private router: Router, private authService: AuthService, private intercom: IntercomService) {
        this.setupWhiteLabelConfig();
    }

    ngAfterViewInit(): void {
        this.observeAuthentication();
    }

    observeAuthentication() {
        this.authSubscription = this.authService.isAuthenticated$;
        this.authSubscription.subscribe(isAuthenticated => {
            if (isAuthenticated) {
                this.authService.postAuthtargetRoute$.subscribe(route => {
                    this.router.navigateByUrl(route || 'overview');
                });
            }
        });

        this.authService.authError$.subscribe(err => {
            this.handleSSOError(err);
        })
    }

    handleSSOError(error) {
        if (!error) {
            return;
        }
        this.isLoading = false;
        this.errorMessage = error.message || error.error_description;
        this.intercom.bootAnonymousUser(true);
        this.intercom.startNewConversation();
    }

    logout() {
        this.authService.logout();
    }

    async setupWhiteLabelConfig() {
        const host = window.location.hostname.toLowerCase();
        if (BEACONSTAC_HOSTS.indexOf(host) !== -1) {
            this.logoSource.next('assets/img/uniqode-header-logo-dark.png');
        } else {
            try {
                const config: any = await Utils.getJSON(`https://beaconstac-cname-content.s3.amazonaws.com/${host}.json`);
                this.logoSource.next(config.logo_url);
            } catch (e) {
                this.logoSource.next('https://s3.amazonaws.com/beaconstac-cname-content/' + host);
            }

        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
