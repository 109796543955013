import {environment} from '../../environments/environment';
import * as moment from 'moment-timezone';
import {LandingPageTheme} from '../markdown-cards/landing-page-theme.service';

export class CouponCode {
    coupon_code: string;
    header: string;
    message: string;
    logo_url: string;
    description_header: string;
    description_body: string;
    customizations: {
        background_color: string;
        header_color: string;
        body_color: string;
        coupon_color: string;
        button_color: string;
    } = {
            background_color: '#ebd42a',
            header_color: '#e72b2b',
            body_color: '#064a3a',
            coupon_color: '#fff',
            button_color: '#59c65d',
        };
    expires_at: string = moment().startOf('day').add(7, 'days').format();
    website: string;
    location: string;
    company_name: string;
    terms_and_conditions: string;
    timezone: string = moment.tz.guess();
    wallet_pass: {
        barcode_format: string;
    } = {
            barcode_format: 'PKBarcodeFormatQR'
        };
    css_body: string = '';
    theme: string | number;
    theme_data: LandingPageTheme = null;
    custom_theme: number | string;
    custom_theme_data: LandingPageTheme = null;
    version: number = null;

    constructor(json?) {
        if (!json) {
            return;
        }

        this.coupon_code = json['coupon_code'] ? json['coupon_code'] : this.coupon_code;
        this.header = json['header'] ? json['header'] : this.header;
        this.message = json['message'] ? json['message'] : this.message;
        this.logo_url = json['logo_url'] ? json['logo_url'] : this.logo_url;
        this.description_header = json['description_header'] ? json['description_header'] : this.description_header;
        this.description_body = json['description_body'] ? json['description_body'] : this.description_body;
        this.customizations = json['customizations'] ? json['customizations'] : this.customizations;
        this.expires_at = json['expires_at'] ? json['expires_at'] : this.expires_at;
        this.website = json['website'] ? json['website'] : this.website;
        this.location = json['location'] ? json['location'] : this.location;
        this.company_name = json['company_name'] ? json['company_name'] : this.company_name;
        this.terms_and_conditions = json['terms_and_conditions'] ? json['terms_and_conditions'] : this.terms_and_conditions;
        this.timezone = json['timezone'] ? json['timezone'] : this.timezone;
        this.wallet_pass = json['wallet_pass'] ? json['wallet_pass'] : this.wallet_pass;
        this.css_body = json['css_body'] ? json['css_body'] : this.css_body;
        this.theme = json['theme'];
        this.theme_data = json['theme_data'];
        this.custom_theme = json['custom_theme'];
        this.custom_theme_data = json['custom_theme_data'];
        this.version = json['version'];
    }

    setExpiresAt(date?) {
        if (date) {
            this.expires_at = moment.tz(date, this.timezone).format();
        } else {
            this.expires_at = moment.tz(this.expires_at, this.timezone).format();
        }
    }

    getExpiresAt(localeFormat: boolean = false) {
        return localeFormat ? moment(this.expires_at).format('LLLL') : moment(this.expires_at).format('YYYY-MM-DD');
    }

    // tslint:disable-next-line:member-ordering
    static getCouponCodePreviewHTML(coupon_code: CouponCode, isMobile: boolean) {

        // background color rgba values -> rgb value
        let background_color = coupon_code.customizations.background_color;
        if (coupon_code.customizations.background_color.startsWith('rgba')) {
            const rgba = coupon_code.customizations.background_color.substring(5, coupon_code.customizations.background_color.length - 1).split(',');
            let r = parseInt(rgba[0], 10), b = parseInt(rgba[2], 10), g = parseInt(rgba[1], 10);
            const a = parseFloat(rgba[3]);
            r = Math.round((1.0 - a) * 255) + Math.round(a * r);
            b = Math.round((1.0 - a) * 255) + Math.round(a * b);
            g = Math.round((1.0 - a) * 255) + Math.round(a * g);
            background_color = `rgb(${r},${g},${b})`;
        }
        let button_color = coupon_code.customizations.button_color;
        let theme_data: LandingPageTheme;
        if (coupon_code.theme || coupon_code.custom_theme) {
            theme_data = coupon_code.custom_theme ? coupon_code.custom_theme_data : coupon_code.theme_data;
            if (!theme_data) {
                theme_data = coupon_code.theme_data;
            }
        }
        if (!coupon_code.version && (coupon_code.theme || coupon_code.custom_theme)) {
            button_color = theme_data.colors.buttonBackground;
            background_color = theme_data.colors.backgroundColor;
            coupon_code.customizations.button_color = theme_data.colors.buttonBackground;
            coupon_code.customizations.background_color = theme_data.colors.backgroundColor;
            coupon_code.version = 2
        }
        let html = `<html lang="en"><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title></title>
    <link rel="stylesheet" href="${environment.couponCodeCssEndpoint + '?v=2'}">

    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">`

        if (coupon_code.theme || coupon_code.custom_theme) {
            html += `<link href="https://fonts.googleapis.com/css?family=${theme_data.font}&display=swap" rel="stylesheet">`
        }

        html += `<style>
        .content {
            max-width: 500px;
            margin: auto;
        }
        .coupon_container {
            background: ${coupon_code.customizations.coupon_color} !important;
        }
        .circle {
            background-color: ${background_color};
        }
        .code_section {
            border-color: #59c65d;
        }
        .button {
            background-color: #59c65d;
            color: #fff;
            padding: 13px 12px;
        }
        .no-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .no-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
    `;
        if (coupon_code.theme || coupon_code.custom_theme) {
            html += `font-family: ${theme_data.font};`;
        }
        html += `}
        .background_color {
            background-color: ${background_color}
            }
    </style>`;

        if (coupon_code.theme || coupon_code.custom_theme) {
            html += coupon_code.getThemeAsCSS(coupon_code);
        }
        if (coupon_code.css_body) {
            html += '<style>' + coupon_code.css_body + '</style>';
        }

        html += `</head>

  <body class="no-scrollbar background_color" style="margin: 0; overflow-x: hidden; ${isMobile ? '' : 'zoom: 0.60'}">
  <div id="markdown-div">
    <div class="content">
    <div class="logo_image_container" style="margin-left: 10px; padding-top: 15px; padding-bottom: 10px;">`;

        if (coupon_code.logo_url) {
            html += `<img src="${coupon_code.logo_url}">`;
        }

        html += '</div>';

        if (coupon_code.company_name) {
            html += `<p class="company_name" style="margin: 10px 10px 15px 10px;
            color: ${CouponCode.getTextColor(background_color)}">${coupon_code.company_name}</p>`;
        }


        html += `
            <div class="coupon_container center_align" style="margin: 0 10px 10px 10px;">
            <div style="top: -25px; height: 43px; width: 55px;" class="circle center_align background_color"></div>
            <p style="color: ${coupon_code.customizations.header_color}; word-break: break-word" class="header">
            ${coupon_code.header ? coupon_code.header : '30% off'}</p>
            <p style="color: ${coupon_code.customizations.body_color}; word-break: break-word;" class="message">
            ${coupon_code.message ? coupon_code.message : 'Big Sale!'}</p>
            <div class="circles_flex">
                <div style="top: 25px; left: -25px; height: 43px; width: 55px;" class="circle background_color"></div>

                <div class="circle small_circle above background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle above background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle above background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle above background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle above background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle above background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle above background_color" style="margin: 0px 3pt;"></div>

                <div style="top: 25px; right: -25px; height: 43px; width: 55px;" class="circle background_color"></div>
            </div>
        </div>

        <div class="coupon_container center_align" style="margin: 0 10px 10px 10px;">
            <div class="circles_flex">
                <div style="top: -22px; left: -25px; height: 43px; width: 55px;" class="circle background_color"></div>

                <div class="circle small_circle bottom background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle bottom background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle bottom background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle bottom background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle bottom background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle bottom background_color" style="margin: 0px 3pt;"></div>
                <div class="circle small_circle bottom background_color" style="margin: 0px 3pt;"></div>

                <div style="top: -22px; right: -25px; height: 43px; width: 55px;" class="circle background_color"></div>

            </div>

            <div style="padding: 0px; width: 100%;">
                <div class="code_container">
                    <input value="${coupon_code.coupon_code ? coupon_code.coupon_code : 'ABCD1234'}" type="text" id="myInput"
                    class="code_section" readonly="" style="max-width: 150px; border-color: ${button_color};">
                    <button onclick="myFunction()" class="code_section button" style="border-radius: 0px 5px 5px 0px;
                    border-color: ${button_color};
                    background-color: ${button_color};">
                        COPY
                    </button>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">`;

        if (coupon_code.expires_at) {
            html += `<p class="expiry">Valid up to ${new Date(coupon_code.expires_at).toLocaleDateString()}</p>
                <div style="top: 60px; left:-272px; height: 43px; width: 55px;" class="circle background_color"></div>
                    <div style="top: 20px; left:270px; height: 43px; width: 55px;" class="circle background_color"></div>`;
        } else {
            html += `<div style="top: 18px; left:-20px; height: 43px; width: 55px;" class="circle background_color"></div>
                    <div style="top: 18px; left:25px; height: 43px; width: 55px;" class="circle background_color"></div>`;
        }

        html += `</div>
        </div>

        <div class="button_container">`;

        if (coupon_code.website) {
            html += `<a href="${coupon_code.website}">
                <button type="button" class="button base_buttons"
                style="background-color: ${button_color};">VIEW WEBSITE</button>
            </a>`;
        }

        if (coupon_code.location) {
            html += `<a href="${coupon_code.location}">
                <button type="button" style="margin-top: 15px;
                background-color: ${button_color};" class="button base_buttons">LOCATE US</button>
            </a>`;
        }

        html += `</div>

        <div class="description_container" style="padding: 5px 10px;">`;

        if (coupon_code.description_header) {
            html += `<p class="description_header" style="color: ${CouponCode.getTextColor(background_color)}; word-break: break-word">
                ${coupon_code.description_header}</p>`;
        }

        if (coupon_code.description_body) {
            html += `<p class="description_body" style="color: ${CouponCode.getTextColor(background_color)}; word-break: break-word">
                ${coupon_code.description_body}</p>`;
        }

        html += `
        </div>


            <div style="padding: 10px">`;

        if (coupon_code.terms_and_conditions) {
            html += `<p class="t_and_c" style="color: ${CouponCode.getTextColor(background_color)}">
                Terms and Conditions</p>
                <p class="t_and_c" style="color: ${CouponCode.getTextColor(background_color)}">
                   ${coupon_code.terms_and_conditions}</p>`;
        }

        html += `</div>
            </div>
</body></html>`;
        return html;
    }

    // tslint:disable-next-line:member-ordering
    static getTextColor(backgroundColor: string) {
        const redValue = parseInt(backgroundColor.substring(1, 3), 16);
        const greenValue = parseInt(backgroundColor.substring(3, 5), 16);
        const blueValue = parseInt(backgroundColor.substring(5, 7), 16);
        if (Math.sqrt(redValue * redValue + greenValue * greenValue + blueValue * blueValue) > 200) {
            return '#000000';
        } else {
            return '#ffffff';
        }
    }

    private getThemeAsCSS(coupon_code: CouponCode): string {
        let theme_data: LandingPageTheme = coupon_code.custom_theme ? coupon_code.custom_theme_data : coupon_code.theme_data;
        if (!theme_data) {
            theme_data = coupon_code.theme_data
        }
        let css = '<style>';
        let buttonStyle = '';
        if (theme_data.colors.buttonBackground) {
            buttonStyle += `background-color: ${theme_data.colors.buttonBackground};border-color:${theme_data.colors.buttonBackground};`
        }
        if (theme_data.colors.buttonText) {
            buttonStyle += `color: ${theme_data.colors.buttonText};`
        }
        if (buttonStyle) {
            css += `a{${buttonStyle}}`;
        }
        // if ((theme_data.background && theme_data.background.href) || theme_data.colors.backgroundColor) {
        // css += `.container-main{background:none}`;
        // }
        if (theme_data.colors.backgroundColor && (!theme_data.background || !theme_data.background.href)) {
            css += `.container-main {background-color: ${theme_data.colors.backgroundColor}; background-image: none}`;
        }
        if (theme_data.colors.text) {
            css += `body,p,h1,h2,h3,h4,h5,h6,pre{color: ${theme_data.colors.text}}`;
        }
        if (theme_data.background && theme_data.background.href) {
            css += `.container-main {background-image: url(${theme_data.background.href})}`;
        }
        if (theme_data.font) {
            css += `body, a,p,h1,h2,h3,h4,h5,h6 {font-family: ${theme_data.font}}`;
        }
        css += '</style>';
        return css;
    }

}
