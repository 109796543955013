import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaService } from '../../places/media-service';
import { MediaGalleryComponent } from './media-gallery.component';
import { BeaconstacFileDropModule } from '../beaconstac-file-drop/beaconstac-file-drop.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MediaFolderService } from '../../media-folders/media-folder.service';
import { BeaconstacModule } from 'kaizen-design-system';

@NgModule({
    imports: [
        CommonModule,
        BeaconstacFileDropModule,
        InfiniteScrollModule,
        BeaconstacModule
    ],
    declarations: [MediaGalleryComponent],
    exports: [MediaGalleryComponent],
    providers: [MediaService, MediaFolderService]
})
export class MediaGalleryModule { }
