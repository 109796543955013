import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'info-action-popup',
    templateUrl: './info-action-popup.component.html',
    styleUrls: ['./info-action-popup.component.scss']
})
export class InfoActionPopupComponent {

    @Input() mediaUrl: string;
    @Input() showModal: boolean = false;
    @Input() popupId: string;
    @Output() closePopupEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    closeModal() {
        this.closePopupEmitter.emit(false);
    }
}
