import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpsellComponent } from './upsell.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BeaconstacModule } from 'kaizen-design-system';
import { UpsellService } from '../../global-services/upsell.service';
import { TrialSwitchService } from 'app/global-services/trial-switch.service';
import { OverlayService } from '../../global-services/overlay.service';
import { MessageModalService } from '../../shared/message-modal/message-modal.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BeaconstacMobileDrawerModule } from '../beaconstac-mobile-drawer/beaconstac-mobile-drawer.module';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox/checkbox.component';

@NgModule({
    declarations: [UpsellComponent, CheckboxComponent],
    exports: [UpsellComponent],
    imports: [
        CommonModule,
        ModalModule,
        FormsModule,
        BeaconstacModule,
        TooltipModule.forRoot(),
        BeaconstacMobileDrawerModule,
    ],
    providers: [UpsellService, TrialSwitchService, OverlayService, MessageModalService],
})
export class UpsellModule {}
