import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MessageModalService } from './message-modal.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'beaconstac-message-modal',
    template: `
        <div bsModal #messageModal="bs-modal" class="modal fade" role="dialog" (onHide)="onHide()">
            <beaconstac-toast
                [message]="service.message$ | async"
                [type]="service.type$ | async"
                (onClick)="messageModal.hide()"
            >
            </beaconstac-toast>
        </div>
    `,
    styles: [
        `
            .alert {
                margin: 0 !important;
            }
            .modal-dialog {
                position: absolute !important;
                right: 0 !important;
                padding: 0 16px !important;
                max-width: unset !important;
                width: auto !important;
            }
        `,
    ],
})
export class MessageModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild('messageModal', { static: false }) private messageModal: ModalDirective;
    visibilitySubscription: Subscription;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(public service: MessageModalService) {}

    ngAfterViewInit() {
        this.visibilitySubscription = this.service.visible$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((visible) => {
            if (visible) {
                this.messageModal.show();
            } else {
                this.messageModal.hide();
            }
        });
    }

    ngOnDestroy() {
        if (this.visibilitySubscription) {
            this.visibilitySubscription.unsubscribe();
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onHide() {
        this.service.dismiss();
    }
}
