import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeaconstacAddOnComponent } from './beaconstac-add-on.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BeaconstacModule} from 'kaizen-design-system';
import {PipesModule} from '../pipes/pipes.module';



@NgModule({
    declarations: [BeaconstacAddOnComponent],
    exports: [
        BeaconstacAddOnComponent
    ],
    imports: [
        CommonModule,
        ModalModule,
        BeaconstacModule,
        PipesModule
    ]
})
export class BeaconstacAddOnModule { }
