import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {AuthService} from '../global-services/auth.service';
import {filter, map, tap} from 'rxjs/operators';
import {Utils} from '../shared/utils';

@Injectable()
export class LoggedInGuard implements CanActivate {

    // store access log to redirect to last access url
    static lastAccessedPage: string = null;
    static previousPage: string = null;

    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        // Ignore url if redirected to login
        if (state.url.indexOf('login') === -1) {
            LoggedInGuard.lastAccessedPage = state.url;
        }
        const authenticated = this.authService.isAuthenticated$.pipe(
            tap(isAuthenticated => {
                if (!isAuthenticated) {
                    if (this.authService.getTokenType() === AuthService.tokeTypes.token) {
                        return;
                    }
                    if (Utils.isWhiteLabeledDashboard()) {
                        this.router.navigateByUrl('/login');
                        return;
                    }
                    if (!state.url.startsWith('/sso')) {
                        this.authService.auth0Login(LoggedInGuard.lastAccessedPage);
                        return;
                    }
                    return;
                }
            }),
            map(isAuthenticated => {
                if (!isAuthenticated && state.url.startsWith('/sso')) {
                    return true;
                }
                if (this.authService.getTokenType() === AuthService.tokeTypes.token) {
                    return true;
                }
                return isAuthenticated;
            })
        )

        const loggedIn = this.authService.isLoggedIn$.pipe(
            filter(isLoggedIn => isLoggedIn));
        return combineLatest([authenticated, loggedIn]).pipe(map((result) => {
            return result[0] && result[1];
        }));

    }
}
