import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../global-services/auth.service';
import { OverlayService } from '../global-services/overlay.service';
import { MessageModalService } from '../shared/message-modal/message-modal.service';
import { Animations } from 'app/shared/beaconstac-animations';
import { User } from '../user-account/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';


@Component({
    templateUrl: './email-verification-success.component.html',
    animations: [Animations.collapse],
})
export class EmailVerificationSuccessComponent implements OnInit, OnDestroy {

    disabled: boolean = false;
    loading$: any;
    user: User;
    brandLogo: string = '';
    firstName: string;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private authService: AuthService, private overlay: OverlayService,
    // @ts-ignore
        private messageModal: MessageModalService,
        private router: Router, private route: ActivatedRoute) {

        this.loading$ = this.overlay.loading$;
        this.overlay.isLoading(false);
        this.user = this.authService.getUser();
        if (!this.user.email_verified) {
            this.router.navigate(['/email-verification-pending']);
        }
        this.setupLogo();
    }

    setupLogo() {
        if (this.user.organization.whitelabel_logo_small) {
            this.brandLogo = this.user.organization.whitelabel_logo_small;
        } else {
            this.brandLogo = '/assets/img/uniqode-header-logo.svg';
        }
    }

    ngOnInit(): void {
        this.route.fragment.subscribe(fragment => {
            this.overlay.isLoading(false);
        });

        this.handleQueryParams();
        this.firstName = this.user.first_name;
    }

    handleQueryParams(): void {
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => { });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    logout() {
        this.authService.logout();
    }
}
