import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {
    AMPLITUDE_EVENT_CATEGORIES,
    AMPLITUDE_EVENTS,
    AmplitudeService
} from '../../../global-services/amplitude.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'pwa-nudge-mobile',
    templateUrl: './pwa-nudge-mobile.component.html',
    styleUrls: ['./pwa-nudge-mobile.component.scss']
})
export class PwaNudgeMobileComponent {

    @Input() source: string

    constructor(private amplitude: AmplitudeService) {
    }

    routeToUniqodeCards() {
        this.logClickEventToAmplitude()
        window.open(environment.MyCardsPwaUrl)
    }

    logClickEventToAmplitude() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.MYCARDS_NUDGE_CLICKED_MOBILE, {
            opened_from: this.source
        })
    }

}
