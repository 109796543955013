import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject, Subscription} from 'rxjs';
import {ProductOwnerAccessService} from './product-owner-access.service';
import {Location} from '@angular/common';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'beaconstac-product-owner-access',
    template: `
    <div bsModal #ProductOwnerAccessModal="bs-modal" class="modal fade" role="dialog" (onHide)="onHide()" [config]="{'backdrop':'static'}">
      <div class="modal-dialog modal-danger" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h4 class="modal-title">Are you sure you want to proceed?</h4>
            <button type="button" class="close" (click)="ProductOwnerAccessModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            {{service.message$ | async}}
          </div>

          <div class="modal-footer">
            <button class="btn btn-danger" (click)="ProductOwnerAccessModal.hide()">Continue anyway</button>
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
          </div>

        </div>
      </div>
    </div>
  `,
    styles: [`
    .alert {
      margin: 0 !important;
    }
  `]
})
export class ProductOwnerAccessComponent implements AfterViewInit, OnDestroy {

    @ViewChild('ProductOwnerAccessModal', {static: false}) private ProductOwnerAccessModal: ModalDirective;
    visibilitySubscription: Subscription;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(public service: ProductOwnerAccessService, private _location: Location) {
    }

    ngAfterViewInit() {
        this.visibilitySubscription = this.service.visibility$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(visible => {
            if (visible) {
                this.ProductOwnerAccessModal.show();
            } else {
                this.ProductOwnerAccessModal.hide();
            }
        });
    }

    ngOnDestroy() {
        if (this.visibilitySubscription) {
            this.visibilitySubscription.unsubscribe();
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onHide() {
        this.service.dismiss();
    }

    onCancel() {
        this._location.back();
        this.service.dismiss();
    }

}
