/**
 * Created by aakash on 14/09/17.
 */
import {environment} from '../../environments/environment';

export class Media {

    id: number;
    url: string;
    status: string;
    name: string;
    source: string;
    md5_hash: string;
    content_type: string;
    created: string;
    modified: string;
    organization: number;
    tags: any;
    public: boolean;
    typeform_image_id: number;
    typeform_url: string;
    typeform_compatible: boolean;
    __preview_url: string;

    constructor(json: any) {
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.url = json['url'];
        this.status = json['status'];
        this.name = json['name'];
        this.source = json['source'];
        this.md5_hash = json['md5_hash'];
        this.content_type = json['content_type'];
        this.created = json['created'];
        this.modified = json['modified'];
        this.organization = json['organization'];
        this.tags = json['tags'];
        this.public = json['public'];
        this.typeform_image_id = json['typeform_image_id'];
        this.typeform_url = json['typeform_url'];
        this.typeform_compatible = json['typeform_compatible'];
        if (this.content_type === 'application/pdf') {
            this.__preview_url = environment.eddyProPDFUrl + this.id;
        }
    }

    toJSON(): object {
        return {
            id: this.id,
            url: this.url,
            status: this.status,
            name: this.name,
            source: this.source,
            md5_hash: this.md5_hash,
            content_type: this.content_type,
            created: this.created,
            modified: this.modified,
            organization: this.organization,
            tags: this.tags,
            public: this.public,
            typeform_image_id: this.typeform_image_id,
            typeform_url: this.typeform_url,
            typeform_compatible: this.typeform_compatible
        };
    }

    toString(): string {
        return JSON.stringify(this.toJSON());
    }
}
