import {Injectable} from '@angular/core';

declare let fbq: Function;

@Injectable({
    providedIn: 'root'
})

export class FacebookPixelService {

    constructor() {
    }

    public eventEmitter(eventName: string, content_type: object = {}) {
        try {
            fbq('track', eventName, content_type);
        } catch (e) {
            console.error(e);
        }
    }
}
