import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../../global-services/amplitude.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'mycards-popup',
    templateUrl: './mycards-popup.component.html',
    styleUrls: ['./mycards-popup.component.scss']
})
export class MycardsPopupComponent {
    redirectToUniqodeCards: string = environment.MyCardsPwaUrl;
    myCardsGif: string = '../../assets/gif/uniqodeCardsGif.gif';
    @Input() showModal: boolean;
    @Output() closeMycardsPopupEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private amplitude: AmplitudeService) {
    }

    closePopupEmitter(value: boolean) {
        this.showModal = false;
        this.closeMycardsPopupEmitter.emit(false);
    }

    handleLinkToPwaLandingPage(event: any) {
        event.stopPropagation();
        this.logEventForClickOnPWALink()
    }

    logEventForClickOnPWALink() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.MYCARDS_LANDING_PAGE_OPENED_DESKTOP_POPUP)
    }
}
