import {BaseModel} from '../../global-services/base.model';

export class AgreementLinksModal extends BaseModel {
    label: string;
    url: string;

    __valid_url__: boolean = true;
    constructor(json?) {
        super();

        if (!json) {
            return;
        }

        this.label = json['label'] ? json['label'] : '';
        this.url = json['url'] ? json['url'] : '';
    }
}
