<div bsModal #discardChangeModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog  modal-dialog-centered justify-content-center" role="document">
        <div class="modal-content" style="max-width: 380px;">
            <div class="modal-body modal-container p-0">
                <div class="modal-description">

                    <h4 class="discard-modal-heading" [innerHTML]="service.title$ | async">
                    </h4>

                    <div class="discard-modal-description mt-4" [innerHTML]="service.message$ | async">
                    </div>
                </div>

                <div style="padding-top: 32px;">
                    <div class="modal-buttons">
                        <span class="button-1 d-flex align-items-center">
                          <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [style]="BUTTON_STYLES.TEXT" (onClick)="onHide()" [label]="'Cancel'"></beaconstac-button>
                        </span>
                        <span class="button-2">
                          <beaconstac-button
                              [type]="BUTTON_TYPES.DANGER"
                              (onClick)="confirmDiscardChanges()"
                              [label]="service.primaryCta$ | async"
                              [width]="'100%'"
                          ></beaconstac-button>
                        </span>
                  </div>
                </div>

            </div>
        </div>
    </div>
</div>
