<div class="app align-items-center login-background">
    <div class="login-header">
        <img class="login-page-logo mx-4" [src]="logo$ | async">
    </div>

    <beaconstac-wait-loader *ngIf="isLoading" class="beaconstac-overlay full-layout"></beaconstac-wait-loader>

    <div class="d-flex align-items-center justify-content-center" style="min-height: calc(100vh - 57px)">
        <div *ngIf="errorMessage">
            <div class="d-flex justify-content-center" style="max-width: 800px;">
                <div class="mb-1 p-3 text-center">
                    <div style="background-color: #fff0c8; border: 1px solid #fcbf49; border-radius: 2px; padding: 16px">
                        <i class="fa fa-exclamation-circle mr-2 text-warning"></i>
                        <span [innerText]="migrationMessage"></span></div>
                </div>
            </div>
            <div *ngIf="errorMessage">
                <div class="mb-1 p-3 text-center" [innerHTML]="errorMessageDescription"></div>
            </div>
            <div class="text-center">
                <a class="btn btn-primary" (click)="login()">Login</a>
            </div>
        </div>
    </div>
</div>
