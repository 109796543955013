import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../shared/utils';

@Pipe({
    name: 'highlight',
})
export class HighlightSearch implements PipeTransform {
    transform(value: any, args: any): any {
        if (!args) {
            return value;
        }
        args = Utils.escapeRegExp(args);
        for (const arg of args.trim().split(' ')) {
            if (!arg) {
                continue;
            }
            const re = new RegExp(arg, 'gi'); // 'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
            value = value.replace(re, '<mark>$&</mark>');
        }
        return value;
    }
}
