import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalletPassTemplateRoutingModule } from './wallet-pass-template-routing.module';
import { WalletPassTemplateComponent } from './wallet-pass-template.component';
import { BeaconstacModule } from 'kaizen-design-system';
import { GoogleWalletPassPreviewModule } from 'app/global-components/google-wallet-pass-preview/google-wallet-pass-preview.module';
import { AppleWalletPassPreviewModule } from 'app/global-components/apple-wallet-pass-preview/apple-wallet-pass-preview.module';
import { WalletpassTemplateService } from './wallet-pass-template.service';
import {ModalModule} from 'ngx-bootstrap/modal';
import {MediaGalleryModule} from 'app/global-components/media-gallery/media-gallery.module';
import {ImageCropperModule} from 'app/global-components/image-cropper/image-cropper.module';
import { BeaconstacMobileDrawerModule } from 'app/global-components/beaconstac-mobile-drawer/beaconstac-mobile-drawer.module';

@NgModule({
    declarations: [WalletPassTemplateComponent],
    imports: [
        CommonModule,
        WalletPassTemplateRoutingModule,
        BeaconstacModule,
        GoogleWalletPassPreviewModule,
        AppleWalletPassPreviewModule,
        ModalModule,
        MediaGalleryModule,
        ImageCropperModule,
        BeaconstacMobileDrawerModule
    ],
    providers: [WalletpassTemplateService],
})
export class WalletPassTemplateModule {}
