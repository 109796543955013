import {Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {BEACONSTAC_HOSTS} from '../app.constants';

@Component({
    selector: 'app-sso-logout',
    templateUrl: './sso-logout.component.html',
    styles: [`
    .triangle-path {
      fill-rule: nonzero;
      stroke-dasharray: 170;
      stroke-dashoffset: 170;
      stroke-linejoin: round;
      stroke-linecap: round;
      mask: url(#loader-mask);
      animation: dash 3.6s linear;
      animation-iteration-count: infinite;
    }

    @keyframes dash {
      100% {
        stroke-dashoffset: -170;
      }
    }

    .sso-loader {
      height: 200px;
      width: 600px;
      display: flex;
    }

    @media (max-width: 772px) {
      .sso-loader {
        height: 200px;
        width: 80vw;
      }
    }
  `]
})
export class SsoLogoutComponent implements OnDestroy {

    isLoading: boolean = true;

    logoSource = new BehaviorSubject<string>('');
    logo$ = this.logoSource.asObservable();
    ngUnsubscribe: Subject<any> = new Subject();

    constructor() {
        this.setupLogo();
    }

    setupLogo() {
        const host = window.location.hostname.toLowerCase();
        if (BEACONSTAC_HOSTS.indexOf(host) !== -1) {
            this.logoSource.next('assets/img/uniqode-header-logo.svg');
        } else {
            this.logoSource.next('https://s3.amazonaws.com/beaconstac-cname-content/' + host);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
