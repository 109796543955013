import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../global-services/auth.service';

@Injectable()
export class MultiUserGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.authService.getUser();
        const canAccess = user && user.hasMultiUserAccess();
        if (!canAccess) {
            this.router.navigate(['/account']);
        }
        return user && user.hasMultiUserAccess();
    }
}
