import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSearchComponent } from './global-search.component';
import { FormsModule } from '@angular/forms';
import { HelpDocsService, SearchService } from '../global-services/search.service';
import { RouterModule } from '@angular/router';
import { HighlightSearch } from './highlight-text';
import { PipesModule } from '../global-components/pipes/pipes.module';
import { BeaconstacModule } from 'kaizen-design-system';
import { AmplitudeService } from 'app/global-services/amplitude.service';

@NgModule({
    declarations: [GlobalSearchComponent, HighlightSearch],
    exports: [GlobalSearchComponent],
    imports: [CommonModule, FormsModule, PipesModule, RouterModule, BeaconstacModule],
    providers: [SearchService, HighlightSearch, HelpDocsService, AmplitudeService],
})
export class GlobalSearchModule {}
