import {BaseModel} from '../global-services/base.model';

export enum SCOPE_TYPES {
    ACCOUNT = 1,
    RESTRICTED = 2,
    ORGANIZATION = 3
}

export enum FOLDER_TYPES {
    UNCATEGORIZED= 0,
    MEDIA = 1,
    QR_CODE_TEMPLATE= 2,
    LANDING_PAGE_TEMPLATE= 3
}

export class Folder extends BaseModel {
    id: number;
    name: string;
    folder_type: FOLDER_TYPES = FOLDER_TYPES.MEDIA;
    scope: SCOPE_TYPES = SCOPE_TYPES.RESTRICTED;
    uncategorized: boolean;
    logos: boolean;
    meta: object;
    created: string;
    updated: string;
    maintainer: number;
    organization: number;

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.name = json['name'];
        this.folder_type = json['folder_type'];
        this.scope = json['scope'];
        this.uncategorized = json['uncategorized'];
        this.logos = json['logos'];
        this.meta = json['meta'];
        this.created = json['created'];
        this.updated = json['updated'];
        this.maintainer = json['maintainer'];
        this.organization = json['organization'];
    }

}
