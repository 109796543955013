import {Injectable} from '@angular/core';
import {BaseBackendService} from './base-backend.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {BaseModel} from './base.model';
import {PLAN_TYPES} from '../user-account/user.model';


export class TrialSubscribeModel extends BaseModel {
    plan: PLAN_TYPES;
    user_seat_addons: number;

    constructor(json?) {
        super();
        this.plan = json['plan'] || null;
        this.user_seat_addons = json['user_seat_addons'] || null
    }
}

@Injectable()
export class ProductSegmentationService extends BaseBackendService<TrialSubscribeModel> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, TrialSubscribeModel, 'subscribe_trial', messageModal);
    }
}
