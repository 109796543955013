import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {FirebaseService} from '../../global-services/firebase.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {UserNotification} from '../../global-services/user-notification.model';

@Component({
    selector: 'app-firebase-popup-notification',
    templateUrl: './firebase-popup-notification.component.html',
    styleUrls: ['./firebase-popup-notification.component.scss']
})

export class FirebasePopupNotificationComponent implements AfterViewInit {

    @ViewChild('firebaseNotificationModal', {static: true}) firebaseNotificationModal: ModalDirective;

    notifications: UserNotification[];
    notificationsCount: number;
    currentNotification: UserNotification;
    primaryCta: string = 'Okay';

    constructor(private firebaseService: FirebaseService) {
    }

    ngAfterViewInit() {
        this.firebaseService.userNotificationPopup$.subscribe((notifications: UserNotification[]) => {
            this.notifications = notifications;
            this.notificationsCount = notifications?.length || 0;
            this.primaryCta = this.notificationsCount > 1 ? 'Mark all as read' : 'Okay';
            if (this.notificationsCount) {
                this.currentNotification = notifications[0];
                this.showNotificationPopupModal();
            } else {
                this.hideNotificationPopupModal();
            }
        });
    }

    showNotificationPopupModal() {
        this.firebaseNotificationModal.show();
    }

    hideNotificationPopupModal() {
        this.firebaseNotificationModal.hide();
    }



    markAsRead() {
        this.hideNotificationPopupModal();
        this.notifications.forEach(notification => {
            notification.markAsRead();
        })
    }
    navigateNotifications(num) {
        const index = this.notifications.indexOf(this.currentNotification);
        if ((index + num >= 0) && (index + num < this.notifications.length)) {
            this.currentNotification = this.notifications[index + num];
        }
    }

}
