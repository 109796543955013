import {BaseModel} from './base.model';

export class FirebaseEmailVerification extends BaseModel {

    email_verified: number = 0;

    constructor(json: object) {
        super();
        if (!json) {
            return;
        }
        this.email_verified = json['email_verified'] || false;
    }

}
