import {environment} from '../../environments/environment';

export const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const TIME = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30',
    '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
    '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30',
    '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];

class BusinessTimingModel {
    opens = {hours: '08', minutes: '00'};
    closes = {hours: '20', minutes: '00'};
    is_closed: boolean = false;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.opens = json['opens'];
        this.closes = json['closes'];
        this.is_closed = json['is_closed'];
    }
}

export class BusinessCard {
    business_name: string = '';
    open_hours = {
        monday: new BusinessTimingModel(),
        tuesday: new BusinessTimingModel(),
        wednesday: new BusinessTimingModel(),
        saturday: new BusinessTimingModel(),
        thursday: new BusinessTimingModel(),
        friday: new BusinessTimingModel(),
        sunday: new BusinessTimingModel()
    };
    button_url: string;
    button_title: string;
    contact: {
        name: string;
        phone: string;
        email: string;
    } = {
            name: '',
            phone: '',
            email: ''
        };

    logo_url: string;
    brand_image: string;
    customizations: {
        background_color: string;
        opacity: string;
        button_color: string;
    } = {
            background_color: '#000000',
            opacity: '0.25',
            button_color: '#FFA500'
        };
    address_url: string;
    address: string;
    headline: string;
    message: string;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.business_name = json['business_name'] ? json['business_name'] : this.business_name;
        this.button_url = json['button_url'] ? json['button_url'] : this.button_url;
        this.button_title = json['button_title'] ? json['button_title'] : this.button_title;
        this.contact = json['contact'] ? json['contact'] : this.contact;
        this.logo_url = json['logo_url'] ? json['logo_url'] : this.logo_url;
        this.brand_image = json['brand_image'] ? json['brand_image'] : this.brand_image;
        this.customizations = json['customizations'] ? json['customizations'] : this.customizations;
        this.address_url = json['address_url'] ? json['address_url'] : this.address_url;
        this.address = json['address'] ? json['address'] : this.address;
        this.headline = json['headline'] ? json['headline'] : this.headline;
        this.message = json['message'] ? json['message'] : this.message;

        // Create BusinessTimingModels
        for (const key in json['open_hours']) {
            this.open_hours[key] = new BusinessTimingModel(json['open_hours'][key]);
        }

    }

    // tslint:disable-next-line:member-ordering
    static hexToRGB(hex, opacity) {
        const r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (opacity) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }

    // tslint:disable-next-line:member-ordering
    static getBusinessCardPreviewHTML(business_card: BusinessCard, isMobile: boolean) {
        const cafeImage = environment.imagesEndpoint + 'business_card/cafe.jpg';
        const background = BusinessCard.hexToRGB(business_card.customizations.background_color, business_card.customizations.opacity);

        let html = `<html lang="en"><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>Business</title>
    <link rel="stylesheet" href="${environment.businessCardCssEndpoint}">

        <link rel="icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" id="fav_icon">
        <link rel="shortcut icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />

    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">

    <style>
        .content {
            max-width: 500px;
            margin: auto;
        }
        .header_container {
            background-image: url(${business_card.brand_image ? business_card.brand_image : cafeImage});
        }
        .blurring {
            background-color: ${background};
            width: 100%;
        }
        .no-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .no-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
    </style>
</head>

<body class="no-scrollbar" style="margin: 0; padding-bottom: 10px; ${isMobile ? '' : 'zoom: 0.70'}">
<div id="markdown-div">
    <div class="content">


            <div class="header_container">

            <div class="blurring">
                `;
        if (business_card.logo_url) {
            html += `<div class="logo_image_container">
<img src="${business_card.logo_url}"></div>`;
        }
        html += `
                <p class="title_section">${business_card.business_name ? business_card.business_name : 'John\'s Cafe'}</p>

                    <a>
                        <button type="button" class="button_container"
                        style="background-color: ${business_card.customizations.button_color}; outline: none;">
                            ${business_card.button_title ? business_card.button_title : 'VIEW MENU'}
                        </button>
                    </a>

            </div>
        </div>

        <div style="padding: 10px 20px;">
        <div style="box-shadow: 0 2px 0 #f3f1f1;">`;

        if (business_card.headline) {
            html += `<p class="summary_header">${business_card.headline}</p>`;
        }
        if (business_card.message) {
            html += `<p class="summary">${business_card.message}</p>`;
        } else {
            html += '<p class="summary"></p>'
        }

        html += `
            </div><p class="heading">
            <i class="far fa-clock" aria-hidden="true"></i>
            Opening hours
        </p>
            <p style="color: #1bb513; margin: 10px 0 0 0">Open Now</p>
            <!-- <p style="color: #e62b2b; margin: 10px 0 0 0">Closed</p> -->`;
        if (business_card.open_hours.monday.opens.hours) {
            html += `<p class="subject">
        Monday ${business_card.open_hours.monday.opens.hours}:${business_card.open_hours.monday.opens.minutes} - ${business_card.open_hours.monday.closes.hours}:${business_card.open_hours.monday.closes.minutes}
        <i class="fas fa-chevron-down" aria-hidden="true" style="cursor: pointer;"></i>
        </p>`;
        } else {
            html += '<p>Monday 08:00 - 18:00<i class="fas fa-chevron-down" aria-hidden="true" style="cursor: pointer;"></i></p>';
        }

        if (business_card.address) {
            html += `<p class="heading">
                <i class="fas fa-map-marker-alt" aria-hidden="true"></i>
                Location
            </p>
                <p class="subject">${business_card.address}</p>`;
        }
        if (business_card.address_url) {
            html += `<a style="text-decoration: none; padding: 10px 10px 10px 0;
                    cursor: pointer; color: ${business_card.customizations.button_color}">
                        Show on map
                    </a>`;
        }

        html += `<p class="heading">
            <i class="far fa-address-book" aria-hidden="true"></i>
            Contact
        </p>
            <p class="sub_section">Name</p>
            <p class="subject">${business_card.contact.name ? business_card.contact.name : 'John Doe'}</p>
            <p class="sub_section" style="margin-bottom: 3px">Phone</p>
            <a style="text-decoration: none" class="subject">${business_card.contact.phone ? business_card.contact.phone : '111111'}</a>
            <p style="margin-bottom: 3px" class="sub_section">Email</p>
            <a style="text-decoration: none" class="subject">
                ${business_card.contact.email ? business_card.contact.email : 'john@email.com'}</a>


        </div>

    </div>
</div>

</body></html>`;
        return html;
    }
}
