<div bsModal #imageCropModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}"
  (onHide)="modalHide()">
  <div class="modal-dialog modal-info modal-lg" role="document">
    <div class="modal-content modal-content-kz">

      <div class="modal-header-kz">
        <h4 class="modal-title">Upload image</h4>
      </div>

      <div class="modal-body p-0">
        <div class="p-3" style="padding-left:0px !important;">
          <div #imgContainer class="img-container"
            [ngClass]="shouldLoadCropper == false ? 'd-flex justify-content-center' : '' ">
            <div *ngIf="loading" class="loader" [style.height.px]="imgContainer.offsetHeight"
              [style.width.px]="imgContainer.offsetWidth" [ngClass]="imageUploading ? 'transparent-background': ''">
              <spinner class="custom-spinner-analytics" [size]="40" [tickness]="2">
              </spinner>
            </div>
            <img #image [src]="imageSafeUrl" (load)="cropperLoad()">
          </div>
        </div>

        <div *ngIf="shouldLoadCropper" class="d-flex justify-content-center bg-light mb-2 mr-3 mt-2 py-3 px-5">
          <div class="d-flex px-1 px-md-3">
            <i class="px-2 icon-color cursor-pointer fas fa-arrows-alt"
              [ngClass]="_cropper?.options?.dragMode === 'move' && 'text-primary'" (click)="setDragMode()"
              aria-hidden="true"></i>
          </div>
          <div class="d-flex px-1 px-md-3">
            <i class="fas px-2 icon-color cursor-pointer fa-search-minus" (click)="zoomOut()" aria-hidden="true"></i>
            <i class="fas px-2 icon-color cursor-pointer fa-search-plus" (click)="zoomIn()" aria-hidden="true"></i>
          </div>
          <div class="d-flex px-1 px-md-3">
            <i class="px-2 icon-color cursor-pointer fas fa-arrows-alt-v" (click)="flipHorizontal()"></i>
            <i class="px-2 icon-color cursor-pointer fas fa-arrows-alt-h" (click)="flipVertical()"></i>
          </div>
          <div class="d-flex px-1 px-md-3">
            <i class="px-2 icon-color cursor-pointer fas fa-undo" aria-hidden="true" (click)="rotateLeft()"></i>
            <i class="px-2 icon-color cursor-pointer fas fa-redo" aria-hidden="true" (click)="rotateRight()"></i>
          </div>
          <div class="d-flex px-1 px-md-3">
            <i class="px-2 icon-color cursor-pointer fas fa-sync" aria-hidden="true" (click)="reset()"></i>
          </div>
        </div>
      </div>

      <div class="modal-footer modal-footer-kz modal-negative-margins">
        <div class="w-100">
          <div class="d-flex justify-content-between justify-content-md-end">

            <beaconstac-button class="ml-2 d-none d-md-block" (onClick)="imageCropModal.hide()"
              [type]="BUTTON_TYPES.SECONDARY" [style]="BUTTON_STYLES.FILLED" [label]="'Cancel'"></beaconstac-button>

            <beaconstac-button *ngIf="shouldLoadCropper && showUploadOriginal" class="ml-md-2" (onClick)="uploadImage(false)"
              [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.STROKE" [label]=" 'Upload Original' ">
            </beaconstac-button>

            <beaconstac-button *ngIf="shouldLoadCropper" class="ml-md-2" (onClick)="uploadImage(true)"
              [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.FILLED" [label]="'Save'">
            </beaconstac-button>

            <beaconstac-button *ngIf="!shouldLoadCropper" class="ml-2" (onClick)="uploadImage(false)"
              [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.FILLED" [label]="'Upload'"></beaconstac-button>
          </div>
          <div class="mt-3 d-md-none cropper-cancel-button">
            <beaconstac-button class="" (onClick)="imageCropModal.hide()" [type]="BUTTON_TYPES.SECONDARY"
              [style]="BUTTON_STYLES.FILLED" [label]="'Cancel'"></beaconstac-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>