<div bsModal #responseAddOnModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered custom-modal-border" role="document">
      <div class="modal-content">
  
        <div class="modal-body p-4">
  
          <h4 class="response-addon-heading mb-0">
            Increase your Forms response limit
          </h4>
  
          <div class="mt-3 mb-4 response-addon-subheading">
            Continue to collect more responses on your forms by increasing your response limit from the options below.
          </div>
  
          <form class="pt-1 mb-4">
            <div *ngFor="let option of responseAddonOptions; let i = index">
              <beaconstac-stroke-radio-button
                [button]="option"
                (onClick)="setUpResponseAddonModal($event, i)"
              ><span class="bold-title">{{ responseAddonCount[i] }}</span></beaconstac-stroke-radio-button>
            </div>
          </form>
  
          <div *ngIf="fetchingUpcomingPrice" class="d-flex justify-content-center">
            <spinner class="custom-spinner-analytics" [size]="35" [tickness]="2"></spinner>
          </div> 
          <div *ngIf="!fetchingUpcomingPrice" class="response-addon-note">
            NOTE: You pay only <b class="bold">${{ (formAddonPrice | decimalPrice | number) || 0 }}</b> for <b class="bold">{{ selectedResponseAddonCount }} responses</b> on a pro-rated basis for the remaining duration of your plan from {{ lastBillingDate }} to {{ nextBillingDate }}.
          </div>
  
        </div>
        <div class="modal-footer px-4 pb-4 pt-0 border-0">
            <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                (onClick)="responseAddOnModal.hide();"
                                [label]="'Cancel'"
            ></beaconstac-button>
            <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Pay & Proceed'"
                                (onClick)="updateAddonResponses()"
                                [style]="BUTTON_STYLES.FILLED"
            ></beaconstac-button>
        </div>
  
      </div>
    </div>
  </div>
  
