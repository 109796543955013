import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'beaconstac-highlighted-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
    @Input() checked: boolean;
    @Input() title: string;
    @Input() disabled: boolean;
    @Input() subtitle: string;

    @Output() checkedChange = new EventEmitter<boolean>();
}
