import {AfterViewInit, Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {DiscardChangesService} from '../../global-services/discard-changes.service';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';

@Component({
    selector: 'beaconstac-discard-change',
    templateUrl: './discard-change.component.html',
    styleUrls: ['./discard-change.component.scss']
})
export class DiscardChangeComponent implements OnInit, AfterViewInit, OnDestroy {
    BUTTON_TYPES = BUTTON_TYPES;
    BUTTON_STYLES = BUTTON_STYLES;
    visibilitySubscription: Subscription;

    @ViewChild('discardChangeModal', {static: false}) discardChangeModal: ModalDirective;
    constructor(public service: DiscardChangesService) { }

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.visibilitySubscription = this.service.visible$.pipe().subscribe(visible => {
            if (visible) {
                this.discardChangeModal.show()
            } else {
                this.discardChangeModal.hide();
            }
        });
    }

    onHide() {
        this.service.hide();
    }

    confirmDiscardChanges() {
        this.service.confirmLeave();
    }

    ngOnDestroy() {
        this.visibilitySubscription.unsubscribe()
    }

}
