import {Component, Input} from '@angular/core';

@Component({
    selector: 'beaconstac-wait-loader',
    templateUrl: './wait-loader.html',
    styleUrls: ['./wait-loader.scss']
})
export class WaitLoaderComponent {

    @Input() loadingTextArray: Array<string> = [];
    @Input() loadingText: string = '';


    constructor() {
    }
}
