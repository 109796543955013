import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleWalletPassPreviewComponent } from './google-wallet-pass-preview.component';

@NgModule({
    declarations: [GoogleWalletPassPreviewComponent],
    imports: [CommonModule],
    exports: [GoogleWalletPassPreviewComponent]
})
export class GoogleWalletPassPreviewModule { }
