import { environment } from '../../environments/environment';
import { socialMediaNamesArray, socialIconsFontClasses } from 'app/shared/social-media';
import {LanguageService} from '../global-components/notification-language/language.service';


import {getInvertedColor, getCustomColoredSocialIconSvg} from 'app/shared/utils';
import {
    CONTACT_BUTTON_TYPES,
    getAddToContactIconColor,
    getContactButton,
    getLeadFormHtml,
    setTypography,
} from './templateUtils';
import {CONTACT_DETAIL} from '../digital-business-card/digital-business-card-utils';
function isHexColorCode(hex) {
    return /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);
}

function hexToRGBA(hex, opacity) {
    hex = hex.trim();
    if (!isHexColorCode(hex)) {
        return hex;
    }
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16)
    }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
}



function populateDropdown(card, multiLangList, currentLanguage) {

    let dropDownHTML = `<div class="language-dropdown"><div class="beaconstac-dbc-language-dropdown-container" id="language-dropdown-container" style="margin-left: 140px">
    <select class="beaconstac-dbc-language-dropdown"  name="language-dropdown" id="language-selector-1" autocomplete="off" disabled>`

    const languageService = new LanguageService();
    const languages = languageService.getLanguagesByCodes(multiLangList);
    const currentLanguageName = languageService.getLanguageByCode(currentLanguage);
    languages.forEach((language) => {
        const isSelected = language === currentLanguageName ? 'selected' : '';

        dropDownHTML += `<option value="${language}" ${isSelected}>${language}</option>`;
    });
    dropDownHTML += `</select>
    <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M6.41843 8.87328L1.92793 4.42286C1.71136 4.20822 1.71136 3.86023 1.92793 3.64562L2.45168 3.12655C2.66788 2.91228 3.01828 2.91187 3.23499 3.12563L6.81056 6.65267L10.3861 3.12563C10.6028 2.91187 10.9532 2.91228 11.1694 3.12655L11.6932 3.64562C11.9097 3.86026 11.9097 4.20824 11.6932 4.42286L7.20269 8.87328C6.98612 9.0879 6.635 9.0879 6.41843 8.87328Z" fill="white"/>
    </svg>
</div></div>`;
    return dropDownHTML;
}

export function Layout11TemplateHtml(card, isMobile, isMultiLanguageContent = false, multiLangList?, currentLanguage?) {

    let name = card.first_name;
    if (card.last_name) {
        name += ' '
        name += card.last_name;
    }
    if (card.prefix) {
        name = card.prefix + ' ' + name
    }
    if (card.suffix) {
        name += ', ' + card.suffix
    }

    const hasSocialLinks = false;

    let multiLangDropDownHTML;
    if (isMultiLanguageContent) {
        multiLangDropDownHTML = populateDropdown(card, multiLangList, currentLanguage);
    } else {
        multiLangDropDownHTML = '';
    }

    let headHtmlCode =
        `<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>${name}</title>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
  integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
  crossorigin="anonymous" referrerpolicy="no-referrer" />
  <link rel="stylesheet" href="${environment.multiLanguageDropdownCssEndpoint}styles.css?v=3.0" />
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Work+Sans">
  <script src="https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js"></script>`


    if (card.logo_url) {
        headHtmlCode +=
            `<link rel="icon" type="image/x-icon" href="${card.logo_url}" id="fav_icon" />
    <link rel="shortcut icon" type="image/x-icon" href="${card.logo_url}" />`
    } else {
        headHtmlCode +=
            `<link rel="icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" id="fav_icon" />
    <link rel="shortcut icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" />`
    }
    let styleCode = '';

    styleCode += setTypography(card);

    headHtmlCode += styleCode;
    headHtmlCode += `<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    <style>
    html{
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .main-container {
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    html::-webkit-scrollbar {
      width: 0;
      height: 0;
  }
  .main-container::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    #language-selector-1: disabled{
        border-radius: 4px;
        background-color: rgba(0,0,0,0.4);
        border: 0px;
        color: #FFF;
        text-align: center;
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px;
        padding-right: 16px;
        position: relative;
        top: 16px;
    }
    .initials_custom_styles {
        background-color:${hexToRGBA(card.customizations.background_color, 0.2)};
        color:${card.customizations.background_color};
        font-family: ${card.customizations.font_style};
    }
    .pronouns_styles{
        opacity: 0.5;
        font-style: normal;
        line-height: normal;
    }
    .address-box-styles{
        width: 100%;
        min-height: 8vh;
        height: auto;
        background-color: ${card.customizations.background_color};
    }
    .svg-container {
        display: flex;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        flex-shrink: 0;
    }
    .contact-details-container{
        display: flex;
        min-height: 48px;
        background-color: ${card.customizations.background_color};
        margin: 0px 18px 8px 18px;
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .contact-details-main{
        display: flex;
        align-items: center;
        padding: 10px 16px;
        width: 100%;
    }
    .social-logo {
        height: 3rem;
    }
    .grid-item{
        padding: 0px 10px 0px 0px;
    }
    .social-links-text{
        color: #000;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
    }
    @media(min-width: 253px)
    {
        .add-to-contacts-button{
            padding: 12px 8px 12px 8px;
            text-align: center;
        }
        .share-contact-button{
            padding: 12px 48px;
        }
    }
    @media(max-width: 253px){
        .add-to-contacts-button{
            text-align: center;
            padding: 10px;
        }
        .share-contact-button{
            text-align: center;
            padding: 10px;
        }
    }
    .contact_details_container{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .social-icons {
        align-items: center;
        justify-items: center;
        display: flex;
        justify-content: center;
        margin: 20px 0px 20px 0px;
        flex-wrap: wrap
    }
    .icon {
        color: #c21515; /* Red color */
    }
    .contact-details-text{
        word-wrap: break-word;
        word-break: break-word;
        overflow: hidden;
    }

    .secondary_data_label{
        opacity: 0.5;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lead-details {
        height: 23px !important;
    }

    #exchange-contact-icon {
        color: ${getAddToContactIconColor(card.customizations.typography)}
    }
    </style>
  </head>`


    let html = `<!DOCTYPE html>
  <html lang="en" style="height: 100%;" id="content-container">
  ${headHtmlCode}
  <body style="margin: 0; padding-bottom: 5px;zoom:0.85;" id="body">
    <div class="content">`

    html += `<div>  ${multiLangDropDownHTML}`

    html += `<div style="text-align: center; background: rgba(131, 121, 121, 0.1);"
                 class="text-center d-flex justify-content-center align-items-center">`


    html += '</div>'

    html += `<div id="user-info-background" style="padding: 40px 18px 12px 18px;>
            <div style="display: flex; flex-direction: row;">
                <div class="user_name personal_info" style="text-align: unset;word-wrap: break-word;"><div class="personal_info" style="word-wrap: break-word;">${name}</div>
`

    if (card.pronouns_v2) {
        html += `<span class="user_name personal_info pronouns_styles" style="word-wrap: break-word;font-weight: 400">(${card.pronouns_v2})</span>`
    }

    html += '</div></div>'

    if (card.designation) {
        html += `<div class="user_designation company_details" style="padding-left: 18px; line-height: normal; text-align: unset;
        word-wrap: break-word;">${card.designation}</div>`
    }

    if (card.company) {
        if (card.department) {
            html += `<div class="user_designation company_details" style="padding-bottom:24px; padding-left: 18px; line-height: normal ;margin-top: 4px; text-align: unset; word-wrap: break-word;">${card.department}<span> &#x2022; </span>${card.company}</div>`
        } else {
            html += `<div class="user_designation company_details" style="padding-bottom:24px; padding-left: 18px; line-height: normal ;margin-top: 4px; text-align: unset; word-wrap: break-word;">${card.company}</div>`
        }
    } else if (card.department) {
        html += `<div class="user_designation company_details" style="margin-top: 8px; text-align: unset; word-wrap: break-word;">${card.department}</div>`
    }

    html += '</div>'

    html += '<div class="address-box-styles d-flex align-items-center">'

    if (card.address_v2 || card.logo_url){
        html += ' <div style="padding: 16px 18px 16px 18px; display: flex" class="d-flex align-items-center">'
    }
    if (card.logo_url) {
        html += `<img style="${card.logo_size >= 0 ? `width: ${card.logo_size}%` : 'max-width: 120px;'}; margin-right: 12px; flex-shrink: 0; height: -webkit-fill-available; height: 100%;" src="${card.logo_url}">`
    }

    if (card.address_v2) {
        const tempAddress = card.address_v2.replace(/ +\n/g, '\n').replace(/\n +/g, '\n').trim();
        html += `<div class="contact_details " style="word-wrap: break-word;
        word-break: break-word;
        overflow: hidden;
        ">${tempAddress}</div>`
    }
    if (card.address_v2 || card.logo_url){
        html += '</div>'
    }
    html += '</div>'

    let contactBtn = '';
    if (card.__show_new_sticky_btn__ && card.lead_collection) {
        contactBtn = `<div class="btn-exchange add-to-contacts-button" id="btn-exchange" type="submit">
                             <i id="exchange-contact-icon" class="fas fa-exchange-alt"></i>
                             <span class="submitLead" style="padding-left: 20px;">
                             Exchange Contacts
                         </span>
                     </div>`;
    }  else {
        // This extra css style is required in this layout only
        contactBtn = `<style>

                        .add-ctn-icon {
                            width: 16px !important;
                        }

                        .btn-download {
                            display: flex;
                            justify-content: center;
                            padding: 10px;
                            text-decoration: none;
                        }
                    </style>`
        contactBtn += getContactButton(CONTACT_BUTTON_TYPES.ADD_TO_CONTACTS, card);
    }

    html += `<div class="btn-container button flex-center" style="background-color: ${card.customizations.button_color}; margin: 16px 18px 24px 18px; border-radius: 29px;
                     box-shadow: 0px 4px 4px rgba(98, 62, 9, 0.16);">
          ${contactBtn}
        </div>
        <script>
        WebFont.load({
                    google: {
                           families: ['${card.customizations.font_style}']
                     }
         });
   </script>`

    html += '</div>'

    if (card.summary) {
        html += `<div style="text-align: unset; white-space: pre-line ; word-break: break-word;
  padding: unset !important;margin: 0px 18px 24px 18px;"
  class="summary bio">${card.summary}
  </div>`
    }

    card.__contact_info_list__.forEach((contact) => {
        if (contact.value.length > 0) {
            switch (contact.key) {
                case CONTACT_DETAIL.PHONE_V2:
                    contact.value.forEach(phone => {
                        if (phone.value.trim() !== '') {
                            html += `<div class="contact-details-container">
                                         <div class="contact-details-main">
                                             <div style="background-color : white ; " class="svg-container d-flex flex-center">
                                               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M9.67161 3.97758C10.3263 4.10506 10.9279 4.42462 11.3995 4.89535C11.8712 5.36608 12.1913 5.96657 12.3191 6.61996M9.67161 1.30176C11.0317 1.45256 12.3 2.06047 13.2683 3.02566C14.2365 3.99086 14.8472 5.25597 15 6.61327M14.3298 11.9515V13.9584C14.3305 14.1447 14.2923 14.3291 14.2175 14.4998C14.1427 14.6705 14.033 14.8238 13.8955 14.9497C13.758 15.0757 13.5956 15.1716 13.4187 15.2312C13.2419 15.2909 13.0546 15.3131 12.8686 15.2963C10.8062 15.0727 8.8251 14.3693 7.08449 13.2426C5.46509 12.2156 4.09211 10.8452 3.06307 9.22889C1.93035 7.48372 1.22544 5.49678 1.00544 3.42904C0.988691 3.24405 1.01072 3.05761 1.07012 2.88158C1.12952 2.70556 1.22499 2.5438 1.35046 2.40662C1.47592 2.26944 1.62863 2.15983 1.79886 2.08479C1.96909 2.00974 2.15312 1.97089 2.33921 1.97071H4.34993C4.6752 1.96752 4.99053 2.08248 5.23716 2.29418C5.48379 2.50587 5.64488 2.79985 5.6904 3.12132C5.77527 3.76356 5.93266 4.39416 6.15957 5.00109C6.24974 5.24052 6.26926 5.50074 6.2158 5.75091C6.16235 6.00107 6.03816 6.2307 5.85796 6.41259L5.00676 7.26216C5.96088 8.93692 7.35021 10.3236 9.02818 11.2759L9.87939 10.4263C10.0616 10.2465 10.2917 10.1225 10.5423 10.0692C10.793 10.0158 11.0537 10.0353 11.2936 10.1253C11.9017 10.3518 12.5335 10.5089 13.177 10.5936C13.5025 10.6394 13.7999 10.8031 14.0124 11.0535C14.225 11.3039 14.3379 11.6235 14.3298 11.9515Z" stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round"/>
                                               </svg>
                                             </div>
                                         <div class="contact_details_container">
                                             <div class="contact_details contact-details-text secondary_data_label" id="secondary_data_label_phone">${phone.label.charAt(0).toUpperCase() + phone.label.slice(1)}</div>
                                                  <div class="contact_details contact-details-text"> ${phone.value}</div>
                                             </div>
                                         </div>
                                     </div>`
                        }
                    })
                    break;
                case CONTACT_DETAIL.EMAIL_V2:
                    contact.value.forEach(email => {
                        if (email.value.trim() !== '') {
                            html += `<div class="contact-details-container">
                                        <div class="contact-details-main">
                                            <div style="background-color : white ; " class="svg-container d-flex flex-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                                    <path d="M2.8 1.30176H17.2C18.19 1.30176 19 2.08926 19 3.05176V13.5518C19 14.5143 18.19 15.3018 17.2 15.3018H2.8C1.81 15.3018 1 14.5143 1 13.5518V3.05176C1 2.08926 1.81 1.30176 2.8 1.30176Z" stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M19 3.30176L10 9.30176L1 3.30176" stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div class="contact_details_container">
                                                <div class="contact_details contact-details-text secondary_data_label" id="secondary_data_label_email">${email.label.charAt(0).toUpperCase() + email.label.slice(1)}</div>
                                                <div class="contact_details contact-details-text"> ${email.value}</div>
                                            </div>
                                        </div>
                                    </div>`
                        }
                    })
                    break;
                case CONTACT_DETAIL.WEBSITE_V2:
                    contact.value.forEach(website => {
                        if (website.value.trim() !== '') {
                            html += `<div class="contact-details-container">
                                        <div class="contact-details-main">
                                           <div style="background-color : white ; " class="svg-container d-flex flex-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                   <path d="M7.99999 15.3018C11.866 15.3018 15 12.1678 15 8.30178C15 4.43579 11.866 1.30179 7.99999 1.30179C4.134 1.30179 1 4.43579 1 8.30178C1 12.1678 4.134 15.3018 7.99999 15.3018Z" stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round"/>
                                                   <path d="M1 8.30182H15" stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round"/>
                                                   <path d="M8.00007 1.30179C9.75096 3.21863 10.746 5.70621 10.8001 8.30178C10.746 10.8974 9.75096 13.3849 8.00007 15.3018C6.24918 13.3849 5.25415 10.8974 5.20007 8.30178C5.25415 5.70621 6.24918 3.21863 8.00007 1.30179V1.30179Z" stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                           </div>
                                            <div class="contact_details_container">
                                                <div class="contact_details contact-details-text secondary_data_label" id="secondary_data_label_website">${website.label.charAt(0).toUpperCase() + website.label.slice(1)}</div>
                                                <div class="contact_details contact-details-text" > ${website.value}</div>
                                            </div>
                                        </div>
                                     </div>`
                        }
                    });
                    break;
                case CONTACT_DETAIL.CUSTOM_FIELDS:
                    contact.value.forEach(custom_field => {
                        if (custom_field.value.trim() !== '') {
                            html += `<div class="contact-details-container">
                                        <div class="contact-details-main">
                                           <div style="background-color : white ; " class="svg-container d-flex flex-center">
                                               <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.35938 14.6053H7.73438V14.2303V9.99817V9.62317H7.35938H6.77344C6.65693 9.62317 6.5625 9.52874 6.5625 9.41224V8.0127C6.5625 7.89619 6.65693 7.80176 6.77344 7.80176H10.0547C10.1712 7.80176 10.2656 7.89619 10.2656 8.0127V14.2303V14.6053H10.6406H11.2266C11.3431 14.6053 11.4375 14.6998 11.4375 14.8163V16.2158C11.4375 16.3323 11.3431 16.4268 11.2266 16.4268H6.77344C6.65693 16.4268 6.5625 16.3323 6.5625 16.2158V14.8163C6.5625 14.6998 6.65693 14.6053 6.77344 14.6053H7.35938ZM7.26562 3.91113C7.26562 2.95325 8.04212 2.17676 9 2.17676C9.95786 2.17676 10.7344 2.95325 10.7344 3.91113C10.7344 4.86902 9.95788 5.64551 9 5.64551C8.04212 5.64551 7.26562 4.86902 7.26562 3.91113Z" stroke="${card.customizations.icon_color}" stroke-width="0.75"/>
                                               </svg>
                                           </div>
                                           <div class="contact_details_container">
                                                <div  class="contact_details contact-details-text secondary_data_label" id="secondary_data_label_custom">${custom_field.label.charAt(0).toUpperCase() + custom_field.label.slice(1)}</div>
                                                <div class="contact_details contact-details-text" >${custom_field.value}</div>
                                            </div>
                                        </div>
                                     </div>`
                        }
                    })
                    break;
            }
        }
    })

    const socialMediaIcons = ''
    const iconColor = getInvertedColor(card.customizations.icon_color);
    let includesSocialLinks = false;

    for (const name of socialMediaNamesArray) {
        if (card.social_links[name]) {
            includesSocialLinks = true;
            break;
        }
    }


    if (includesSocialLinks) {
        html += '<div class="social-icons">';

        for (const name of socialMediaNamesArray) {
            if (!card.social_links[name]) {
                continue;
            }

            if (socialIconsFontClasses[name]) {
                html += `<div class="svg-container" style="background-color: ${card.customizations.icon_color}; margin: 0px 8px 8px 8px !important;"><i class="${socialIconsFontClasses[name]}" style="color: ${iconColor}"></i></div>`;
            } else {
                const svgIconCode = getCustomColoredSocialIconSvg(name, card.customizations.icon_color, iconColor);
                html += `<div class="svg-container" style="background-color: ${card.customizations.icon_color}; margin: 0px 8px 8px 8px !important;">`;
                html += svgIconCode;
                html += '</div>';

            }
        }
        html += '</div>';
        html += '</div>'
    }


    if (hasSocialLinks) {
        html +=
              `<div class="social-links-text">Connect with me on</div>
              <div style="display: flex; margin: 0px 18px 24px 18px; justify-content: center; flex-wrap: wrap">
                ${socialMediaIcons}
              </div>`
    }

    if (!card.__show_new_sticky_btn__ && card.lead_collection) {
        html += `<div id="leadModalBtn" class="flex-center" style="margin: 0px 18px 20px 18px; border-radius: 29px; text-align: center; color: ${card.customizations.button_color}; border: 2px solid ${card.customizations.button_color};font-family: Atkinson Hyperlegible" [ngStyle]="{'padding-top': hasSocialLinks ? '0px' : '20px'}">
                  <div class="flex-center cursor-pointer share-contact-button button" style="color: ${card.customizations.button_color}">
                      Share your contact
                  </div>
              </div>`
    }

    html += getLeadFormHtml(card);
    html += `
      </div>
      </div>
    </div>`
    html += `<script>
        WebFont.load({
                    google: {
                           families: ['${card.customizations.font_style}']
                     }
         });
   </script>
  </body>
  </html>
  <script>
  var ids = ["secondary_data_label_phone", "secondary_data_label_email", "secondary_data_label_website", "secondary_data_label_custom"];
  (async function() {
    setCardBackground()
    setLabelFontSize(ids)
})();
function setCardBackground() {
    const container = document.getElementById('content-container');
    const backgroundType = '${card.customizations.background.type}';
    const backgroundValue = '${card.customizations.background.value}';

    if (backgroundType === 'color') {
        container.style.backgroundColor = backgroundValue;
    } else {
        container.style.backgroundImage = "url('" + backgroundValue + "')";
    }
}

function setLabelFontSize(ids) {
    document.addEventListener("DOMContentLoaded", function() {
      ids.forEach(function(id) {
        var element = document.getElementById(id);
        if (element) {
          var computedStyle = window.getComputedStyle(element);
          var fontSize = parseFloat(computedStyle.fontSize);
          element.style.fontSize = (fontSize - 2) + "px";
        }
      });
    });
  }
  </script>`

    return html;
}
