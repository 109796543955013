import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { OverlayService } from '../global-services/overlay.service';
import { AuthService } from '../global-services/auth.service';
import { debounceTime } from 'rxjs/operators';
import {BUTTON_TYPES, TOOLTIP_POSITION, CARET_POSITION, BUTTON_STYLES} from 'kaizen-design-system';
import { MessageModalService } from '../shared/message-modal/message-modal.service';
import { Animations } from '../shared/beaconstac-animations';
import { environment } from 'environments/environment';
import { WalletpassTemplateService } from './wallet-pass-template.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {MediaGalleryComponent} from '../global-components/media-gallery/media-gallery.component';
import {Media} from '../places/media.model';
import {ImageCropperComponent} from '../global-components/image-cropper/image-cropper.component';
import {
    Organization,
    WalletPassCustomizations
} from '../user-account/user-organization.model';

enum WALLET_PASS {
    APPLE_WALLET,
    GOOGLE_WALLET,
}

enum WALLET_PASS_CUSTOMIZATION {
    GOOGLE_WALLET_PASS_BACKGROUND,
    APPLE_WALLET_PASS_BACKGROUND,
    APPLE_WALLET_PASS_FOREGROUND,
}

@Component({
    selector: 'app-wallet-pass-template',
    templateUrl: './wallet-pass-template.component.html',
    styleUrls: ['./wallet-pass-template.component.scss'],
    animations: [Animations.collapse],
})
export class WalletPassTemplateComponent implements OnInit {

    @ViewChild('imageSelectModalLogo', {static: false}) imageSelectModalLogo: ModalDirective;
    @ViewChild('imageSelectModalHeroImage', {static: false}) imageSelectModalHeroImage: ModalDirective;
    @ViewChild('imageSelectGalleryLogo', {static: false}) imageSelectGalleryLogo: MediaGalleryComponent;
    @ViewChild('imageSelectGalleryHeroImage', {static: false}) imageSelectGalleryHeroImage: MediaGalleryComponent;
    @ViewChild('imageCropperLogo', {static: false}) private imageCropperLogo: ImageCropperComponent;
    @ViewChild('imageCropperHeroImage', {static: false}) private imageCropperHeroImage: ImageCropperComponent;
    @ViewChild('awpLogoImageSelectModal', {static: false}) private awpLogoImageSelectModal: ModalDirective;
    @ViewChild('awpLogoImageSelectMediaGallery', {static: false}) awpLogoImageSelectMediaGallery: MediaGalleryComponent;
    @ViewChild('imageCropperAWPLogoImg', {static: false}) private imageCropperAWPLogoImg: ImageCropperComponent;

    activeTabColour: string = '#2595FF';
    inactiveTabColour: string = '#979797';
    isAWP: boolean = true; // isAppleWalletPass
    appleWalletPassCustomizations: {
        backgroundColor: string;
        textColor: string;
        logo?: {
            url: string;
            id: string;
        }
    } = {
            backgroundColor: '#16212F',
            textColor: '#FFFFFF',
            logo: {
                url: '',
                id: '',
            }
        };
    googleWalletPassPreviewColor: { backgroundColor: string; textColor: string } = {
        backgroundColor: '#16212F',
        textColor: '#FFFFFF',
    };
    googleWalletPassPreviewLogo: { logo_url: string } = {
        logo_url: ''
    };
    googleWalletPassPreviewHeroImage: { hero_image_url: string } = {
        hero_image_url: ''
    }
    googleWalletPassPreviewLogoId: { logo_id: number } = {
        logo_id: null,
    }
    googleWalletPassPreviewHeroImageId: { hero_image_id: number } = {
        hero_image_id: null,
    }
    walletPassPreviewColorSelect = new Subject<any>();
    profilePictureUrl = `${environment.baseURL}/static/images/dbc/ashley.png`;

    BUTTON_TYPES = BUTTON_TYPES;
    WALLET_PASS_CUSTOMIZATION = WALLET_PASS_CUSTOMIZATION;
    WALLET_PASS = WALLET_PASS;

    imageCropperFile: File;
    imageSelectModel = {
        media: <Media>null,
        link: '',
        alt: '',
        isUploading: false,
    };
    updatedMediaLogo: Media = null;
    updatedMediaHeroImage: Media = null;
    isMobileScreen: boolean = false;
    isPreviewDrawerOpen: boolean = false;
    isGuidelinesModalOpen: boolean = false;
    isHeroImageGuidelinesModalOpen: boolean = false;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;

    uploadToFolder = null;
    protected readonly BUTTON_STYLES = BUTTON_STYLES;

    awpLogoImgSelectModel = {
        media: <Media>null,
        link: '',
        alt: '',
        isUploading: false,
    };
    awpLogoImgCropperFile: File;
    awpLogoImgUploadToFolder = null;
    awpUpdatedMediaLogo: Media = null;


    constructor(
        private overlay: OverlayService,
        public walletpassTemplateService: WalletpassTemplateService,
        private authService: AuthService,
        private messageModal: MessageModalService,
    ) {}

    @HostListener('window:resize', ['$event'])

    onResize() {
        this.isMobileScreen = window.innerWidth <= 768;
    }

    ngOnInit(): void {
        this.onResize();
        const currOrgWalletPass: WalletPassCustomizations = this.authService.getCurrentOrganization().wallet_pass_template;
        // This needs to be updated after save
        this.setAppleWalletPassCustomizations(currOrgWalletPass);
        this.googleWalletPassPreviewColor.backgroundColor = '#' + currOrgWalletPass.google_wallet_pass_background;
        this.googleWalletPassPreviewLogo.logo_url = currOrgWalletPass.google_wallet_pass_logo;
        this.googleWalletPassPreviewHeroImage.hero_image_url = currOrgWalletPass.google_wallet_pass_hero_image;
        this.googleWalletPassPreviewLogoId.logo_id = currOrgWalletPass.google_wallet_pass_logo_id;
        this.googleWalletPassPreviewHeroImageId.hero_image_id = currOrgWalletPass.google_wallet_pass_hero_image_id;
        this.getGoogleWalletPassTextColor(this.googleWalletPassPreviewColor.backgroundColor);
        this.overlay.isLoading(false);
        this.walletPassPreviewColorSelect.pipe(debounceTime(200)).subscribe((event) => {
            this.saveWalletPassChanges(event);
        });
    }

    setAppleWalletPassCustomizations(currOrgWalletPass: WalletPassCustomizations) {
        const { apple_wallet_pass_background,
            apple_wallet_pass_foreground,
            apple_wallet_pass_logo,
            apple_wallet_pass_logo_id } = currOrgWalletPass;
        this.appleWalletPassCustomizations.backgroundColor = '#' + apple_wallet_pass_background;
        this.appleWalletPassCustomizations.textColor = '#' + apple_wallet_pass_foreground;
        this.appleWalletPassCustomizations.logo.url = apple_wallet_pass_logo;
        this.appleWalletPassCustomizations.logo.id = apple_wallet_pass_logo_id?.toString();
    }

    updateAppleWalletPassPreview() {
        const data = {
            walletPassType: WALLET_PASS.APPLE_WALLET,
            backgroundColor: this.appleWalletPassCustomizations.backgroundColor.slice(1),
            textColor: this.appleWalletPassCustomizations.textColor.slice(1),
            logoURL: this.appleWalletPassCustomizations.logo?.url,
            logoId: this.appleWalletPassCustomizations.logo?.id.toString()
        };
        this.walletPassPreviewColorSelect.next(data);
    }

    updateGoogleWalletPassPreview() {
        const data = {
            walletPassType: WALLET_PASS.GOOGLE_WALLET,
            backgroundColor: this.googleWalletPassPreviewColor.backgroundColor.slice(1),
            logo_url: this.googleWalletPassPreviewLogo.logo_url,
            hero_image: this.googleWalletPassPreviewHeroImage.hero_image_url,
            logo_id: this.googleWalletPassPreviewLogoId.logo_id,
            hero_image_id: this.googleWalletPassPreviewHeroImageId.hero_image_id,
        };
        this.getGoogleWalletPassTextColor(this.googleWalletPassPreviewColor.backgroundColor);
        this.walletPassPreviewColorSelect.next(data);
    }

    getGoogleWalletPassTextColor(bgColor) {
        const color = bgColor.substring(1, 7);
        const r = parseInt(color.substring(0, 2), 16); // hexToR
        const g = parseInt(color.substring(2, 4), 16); // hexToG
        const b = parseInt(color.substring(4, 6), 16); // hexToB
        return (this.googleWalletPassPreviewColor.textColor =
            r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#ffffff');
    }

    saveWalletPassChanges(event) {
        this.overlay.isLoading(true);
        const currOrg = this.authService.getCurrentOrganization();

        const reqBody = this.getWalletPassCustomizations(currOrg, event)
        const currentOrgId = this.authService.getCurrentOrgId();

        this.walletpassTemplateService.put(reqBody, currentOrgId, `?organization=${currentOrgId}`).subscribe(
            () => {
                this.overlay.isLoading(false);
                this.messageModal.show('Changes saved.', 'success');
            },
            (error) => {
                this.messageModal.show('Failed to save changes. Please contact support.', 'danger');
                this.overlay.isLoading(false);
                console.error(error);
            },
        );
    }

    getWalletPassCustomizations(currOrg: Organization, event) {
        const body = {
            name: currOrg.name,
            wallet_pass_template: currOrg.wallet_pass_template,
        }

        if (event['walletPassType'] === WALLET_PASS.GOOGLE_WALLET) {
            const {backgroundColor, logo_url, hero_image, logo_id, hero_image_id} = event;
            body.wallet_pass_template.google_wallet_pass_background = backgroundColor;
            body.wallet_pass_template.google_wallet_pass_logo = logo_url;
            body.wallet_pass_template.google_wallet_pass_hero_image = hero_image;
            body.wallet_pass_template.google_wallet_pass_logo_id = logo_id;
            body.wallet_pass_template.google_wallet_pass_hero_image_id = hero_image_id;
        } else {
            const {backgroundColor, textColor, logoURL, logoId} = event;
            body.wallet_pass_template.apple_wallet_pass_background = backgroundColor;
            body.wallet_pass_template.apple_wallet_pass_foreground = textColor;
            body.wallet_pass_template.apple_wallet_pass_logo_id = logoId;
            body.wallet_pass_template.apple_wallet_pass_logo = logoURL;
        }
        return body;
    }

    onColorPickerChange(event, attribute) {
        switch (attribute) {
            case WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_BACKGROUND:
                this.appleWalletPassCustomizations.backgroundColor = event;
                break;
            case WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_FOREGROUND:
                this.appleWalletPassCustomizations.textColor = event;
                break;
            case WALLET_PASS_CUSTOMIZATION.GOOGLE_WALLET_PASS_BACKGROUND:
                this.googleWalletPassPreviewColor.backgroundColor = event;
                this.getGoogleWalletPassTextColor(event);
                break;
        }
    }

    setupImageSelectModal(uploadType: string) {
        if (!this.imageCropperFile) {
            this.imageSelectModel = {
                media: null,
                link: '',
                alt: '',
                isUploading: false,
            };

            if (uploadType === 'logo'){
                this.updatedMediaLogo = null;
                this.imageSelectGalleryLogo.fetchFolders();
            } else if (uploadType === 'heroImage'){
                this.updatedMediaHeroImage = null;
                this.imageSelectGalleryHeroImage.fetchFolders();
            }
        }
    }

    loadCropper(file, uploadType: string) {
        this.imageCropperFile = file;
        if (this.imageCropperFile.type !== 'image/gif') {
            if (uploadType === 'logo'){
                this.imageSelectModalLogo.hide();
                this.showImageCropper('logo');
            } else if (uploadType === 'heroImage'){
                this.imageSelectModalHeroImage.hide();
                this.showImageCropper('heroImage');
            }
        }
    }

    showImageCropper(uploadType: string) {
        if (uploadType === 'logo'){
            if (!this.imageCropperLogo) {
                setTimeout(() => {
                    this.showImageCropper('logo');
                }, 100);
                return;
            }
            this.imageCropperLogo.show();
        } else if (uploadType === 'heroImage'){
            if (!this.imageCropperHeroImage) {
                setTimeout(() => {
                    this.showImageCropper('heroImage');
                }, 100);
                return;
            }
            this.imageCropperHeroImage.show();

        }
    }

    updateMedia(media, uploadType: string) {
        if (uploadType === 'logo'){
            if (this.imageCropperLogo) {
                this.imageCropperLogo.hide();
                this.setUpImageCropperModal()
            }
            this.updatedMediaLogo = media;
        } else if (uploadType === 'heroImage'){
            if (this.imageCropperHeroImage) {
                this.imageCropperHeroImage.hide();
                this.setUpImageCropperModal()
            }
            this.updatedMediaHeroImage = media;
        }
    }

    closeImageCropperModal() {
        this.setUpImageCropperModal();
    }

    setUpImageCropperModal() {
        this.imageCropperFile = null;
    }

    selectImage(uploadType: string) {
        if (uploadType === 'logo') {
            this.imageSelectModalLogo.hide();
            this.updateMedia(this.imageSelectModel.media, 'logo')
            this.googleWalletPassPreviewLogo.logo_url = this.updatedMediaLogo.url
            this.googleWalletPassPreviewLogoId.logo_id = this.updatedMediaLogo.id;
        } else if (uploadType === 'heroImage') {
            this.imageSelectModalHeroImage.hide()
            this.updateMedia(this.imageSelectModel.media, 'heroImage')
            this.googleWalletPassPreviewHeroImage.hero_image_url = this.updatedMediaHeroImage.url;
            this.googleWalletPassPreviewHeroImageId.hero_image_id = this.updatedMediaHeroImage.id;
        }
        this.messageModal.show('Image updated', 'success');
    }

    imageSelectedInGallery({media, clicked}, uploadType: string) {
        if (media) {
            this.imageSelectModel.media = media;
            if (!clicked) {
                if (uploadType === 'logo') {
                    this.imageSelectModalLogo.hide();
                    this.selectImage('logo');
                } else if (uploadType === 'heroImage') {
                    this.imageSelectModalHeroImage.hide();
                    this.selectImage('heroImage');
                }
            }
        } else {
            this.imageSelectModel.media = null;
        }
    }

    setUploadToFolder(folder) {
        this.uploadToFolder = folder
    }

    onLogoRemoved(uploadType: string) {
        if (uploadType === 'logo') {
            this.updateMedia(null, 'logo');
            this.googleWalletPassPreviewLogo.logo_url = '';
            this.googleWalletPassPreviewLogoId.logo_id = null;
        }

        if (uploadType === 'heroImage') {
            this.updateMedia(null, 'heroImage');
            this.googleWalletPassPreviewHeroImage.hero_image_url = '';
            this.googleWalletPassPreviewHeroImageId.hero_image_id = null;
        }
    }

    updateWalletPassPreview(){
        if (this.isAWP) {
            this.updateAppleWalletPassPreview();
        } else {
            this.updateGoogleWalletPassPreview();
        }
    }


    onAWPLogoRemoved() {
        this.updateAWPLogoImg();
    }

    /* app media gallery functions */
    onAWPLogoImgSelect({media, clicked}: {media: Media | null, clicked?: boolean}): void {
        if (media) {
            this.awpLogoImgSelectModel.media = media;
            if (!clicked) {
                this.awpLogoImageSelectModal.hide();
            }
            this.updateAWPLogoImg(media)
        } else {
            this.awpLogoImgSelectModel.media = null;
        }
    }

    updateAWPLogoImg({url, id} = {url: '', id: null}): void {
        const mediaID = id ? id.toString() : '';
        this.appleWalletPassCustomizations.logo.url = url;
        this.appleWalletPassCustomizations.logo.id = mediaID;
    }

    /* check how to upload this */
    setupAWPLogoUploadPath(folder: number) {
        /* Uppload to folder useful for image cropper */
        this.awpLogoImgUploadToFolder = folder;
    }

    setupAWPLogoImgCropper(file: File) {
        this.awpLogoImgCropperFile = file;
        if (this.awpLogoImgCropperFile.type !== 'image/gif') {
            this.awpLogoImageSelectModal.hide();
            this.showAWPLogoImageCropper();
        }
    }

    showAWPLogoImageCropper() {
        if (!this.imageCropperAWPLogoImg) {
            setTimeout(() => {
                this.showAWPLogoImageCropper();
            }, 100);
            return;
        }
        this.imageCropperAWPLogoImg.show();
    }


    selectAWPLogoImg() {
        this.awpLogoImageSelectModal.hide();
        // What to update here? Don't know
        // This.update media
        this.updateAWPLogoMedia(this.awpLogoImgSelectModel.media)
        this.updateAWPLogoImg(this.awpLogoImgSelectModel.media)

    }

    updateAWPLogoMedia(media: Media) {
        if (this.imageCropperAWPLogoImg) {
            this.imageCropperAWPLogoImg.hide();
            this.resetAWPLogoImgCropper()
        }
        this.awpUpdatedMediaLogo = media
        this.messageModal.show('Image updated', 'success');
    }

    resetAWPLogoImgCropper() {
        this.awpLogoImgCropperFile = null;
    }

    closeAWPLogoImgCropperModal() {
        this.resetAWPLogoImgCropper();
    }

    setupAWPLogoImgSelectModal() {
        /* awpLogoImgCropperFile: Used for awp cropper */
        if (!this.awpLogoImgCropperFile) {
            this.imageSelectModel = {
                media: null,
                link: '',
                alt: '',
                isUploading: false,
            };

            this.awpUpdatedMediaLogo = null;
            this.awpLogoImageSelectMediaGallery.fetchFolders();
        }
    }
}
