import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {BEACONSTAC_HOSTS} from '../app.constants';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Utils} from '../shared/utils';
import {IntercomService} from '../global-services/intercom.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
    templateUrl: 'auth-migration.component.html',
})
export class AuthMigrationComponent implements AfterViewInit, OnDestroy {

    isLoading: boolean = true;
    errorMessage: string = '';
    errorMessageDescription: string = '';
    migrationMessage: string = 'As a part of the rebranding Uniqode, we are upgrading our authentication processes as well. One step in this is moving our authentication endpoints from auth.beaconstac.com to auth.uniqode.com. If you are seeing this page, it is likely that you (or your organisation’s admin) has yet not updated configuration in your Identity Service Provider (IDP) to reflect this.\n\n' +
        'You (or your admin) should immediately update this to ensure a seamless SSO experience for your users. You can click on the links below or reach out to your Uniqode account manager for help.\n\n' +
        'In the interim you can continue using the Uniqode platform via the previous authentication scheme by clicking on the button below (or wait for the page to automatically redirect you) . You’ll be requested to authenticate with your credentials again.\n' +
        'We apologise for this temporary inconvenience as we migrate and further strengthen our security.';

    logoSource = new BehaviorSubject<string>('');
    logo$ = this.logoSource.asObservable();
    ngUnsubscribe: Subject<any> = new Subject();

    authSubscription: Observable<any>;
    errorSubscription: Observable<any>;

    constructor(private intercom: IntercomService, private cookieService: CookieService) {
        this.setupWhiteLabelConfig();
    }

    ngAfterViewInit(): void {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const error = {
            message: urlSearchParams.get('error'),
            error_description: urlSearchParams.get('error_description'),
        };
        this.handleSSOError(error);
    }

    handleSSOError(error: any) {
        if (!error) {
            return;
        }
        this.isLoading = false;
        this.errorMessage = error.message;
        this.errorMessageDescription = error.error_description;
        this.intercom.bootAnonymousUser(true);
        if (this.cookieService.get('domain') !== 'beaconstac') {
            document.cookie = 'domain=beaconstac; path=/; domain=.uniqode.com;';
        } else {
            this.cookieService.delete('domain', '/', '.uniqode.com');
        }

    }

    login() {
        window.location.href = window.origin;
    }

    async setupWhiteLabelConfig() {
        const host = window.location.hostname.toLowerCase();
        if (BEACONSTAC_HOSTS.indexOf(host) !== -1) {
            this.logoSource.next('assets/img/uniqode-header-logo-dark.png');
        } else {
            try {
                const config: any = await Utils.getJSON(`https://beaconstac-cname-content.s3.amazonaws.com/${host}.json`);
                this.logoSource.next(config.logo_url);
            } catch (e) {
                this.logoSource.next('https://s3.amazonaws.com/beaconstac-cname-content/' + host);
            }

        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
