import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'beaconstac-apple-wallet-pass-preview',
    templateUrl: './apple-wallet-pass-preview.component.html',
    styleUrls: ['./apple-wallet-pass-preview.component.scss']
})
export class AppleWalletPassPreviewComponent implements OnInit {

    @Input() backgroundColor: string;
    @Input() textColor: string;
    @Input() designation: string;
    @Input() companyName: string;
    @Input() logo: string;
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() email: string;
    @Input() profilePhoto: string;

    constructor() { }

    ngOnInit(): void { }
}
