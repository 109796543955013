import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
    templateUrl: './change-email-verification.component.html',
    styleUrls: ['./change-email-verification.component.scss']
})
export class ChangeEmailVerificationComponent implements OnInit {

    changeEmailAddressModel = {
        email: '',
        error: '',
        success: '',
    };

    token = '';
    uidb36: '';
    isRequestingEmailChange: boolean;

    constructor(private http: HttpClient, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            const token = params['token'] || '';
            this.uidb36 = token.split('-')[0] || '';
            this.token = token.substr(this.uidb36.length + 1) || '';
        });
    }

    ngOnInit() {
        this.confirmEmailChange()
    }

    confirmEmailChange() {
        this.isRequestingEmailChange = true
        this.changeEmailAddressModel.error = '';
        this.changeEmailAddressModel.success = '';
        this.confirmChangeEmailAddress(this.token, this.uidb36)
            .pipe(finalize(() => {
                setTimeout(() => {
                    this.isRequestingEmailChange = false
                }, 1500)
            }))
            .subscribe(successful => {
                if (successful) {
                    this.changeEmailAddressModel.success = 'Email address changed successfully.';
                }
            }, error => {
                this.changeEmailAddressModel.error = 'Email address change failed, your verification link may have expired.';
            });
    }

    confirmChangeEmailAddress(token: string, uidb36: string): Observable<boolean> {
        return this.http
            .get(environment.baseURL + environment.apiEndpoint + environment.apiVersion
        + '/users/email_change_verify/' + uidb36 + '-' + token + '/').pipe(
                map((response: any): boolean => {
                    return true;
                }),
                catchError(error => {
                    console.error(error);
                    return observableThrowError(error);
                }));
    }

}
