import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { DASHBOARD_MENU } from '../shared/utils';

@Injectable()
export class DashboardService {

    private currentDashboardSource = new BehaviorSubject<DASHBOARD_MENU>(DASHBOARD_MENU.QRCODES);
    public currentDashboard$ = this.currentDashboardSource.asObservable();

    setCurrentDashboard(value: DASHBOARD_MENU) {
        setTimeout(() => {
            this.currentDashboardSource.next(value);
        }, 0);
    }

    get currentDashboardSourceValue() {
        return this.currentDashboardSource.value;
    }
}
