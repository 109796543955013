<div bsModal #addOnModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content modal-content--width">
      <div *ngIf="addOnModel.addOnStep === ADD_ON_STEPS.SELECT_QUANTITY" class="modal-body p-4">
        <h4 class="mb-3" style="color: #16212F; font-size: 20px;">{{title}}</h4>
        <div
          class="text-secondary text-left addon-description"
          [ngStyle]="showWarning ? {'margin-bottom': '4px'} :  {'margin-bottom': '36px'}">
         <span *ngIf="!addOnModel.useInnerHtmlForDescription; else innerHtmlDescriptionBlock" [innerHtml]="message | getSanitizedHtml">
         </span>
         <ng-template #innerHtmlDescriptionBlock>
           <div [innerHTML]="message"></div>
         </ng-template>
        </div>
          <div *ngIf="showWarning && !this.isOnEnterprisePlan" class="bx--row px-3 pt-3 mb-3 warning-box" style="min-height: 70px;">
              <div class="bx--col d-flex" style="background: #FFF0C8; flex-direction: row;">
                  <i class="fas fa-info-circle"></i>
                  <span class="pt-3 pb-2" style="display:inline-block;">{{warningMessage}}&nbsp;</span>
                  <beaconstac-button style="padding-top: 14px"
                      [style]="BUTTON_STYLES.TEXT"
                      [type]="BUTTON_TYPES.PRIMARY"
                      [label]="'Upgrade to add more'"
                      (onClick)="routeToChangePlan()"
                  ></beaconstac-button>
              </div>
          </div>
        <div class="row">
          <div class="col-3 subtitle-1" style="font-size: 16px; color: #1B3045; opacity: 0.3;">ITEM</div>
          <div class="col-6 subtitle-1" style="font-size: 16px; color: #1B3045; opacity: 0.3;">QUANTITY</div>
          <div class="col-3 subtitle-1" style="font-size: 16px; color: #1B3045; opacity: 0.3;">PRICE</div>
        </div>
      </div>

      <div *ngIf="addOnModel.addOnStep === ADD_ON_STEPS.SELECT_QUANTITY" class="modal-body p-4"
        style="border-top: 1px solid rgba(94, 105, 128, .2)">

        <div class="row">
          <div class="col-3" *ngIf="addOnModel.product !== ADD_ON_PRODUCT_TYPE.USER_SEATS"> {{ (tableTitle[addOnModel.product] === 'User'? 'User Seat': tableTitle[addOnModel.product])  + (addOnModel.quantity > 1 ? 's' : '') }}</div>
          <div class="col-3" *ngIf="addOnModel.product === ADD_ON_PRODUCT_TYPE.USER_SEATS"> {{ tableTitle[addOnModel.product] }}</div>
          <div class="col-6">
            <span [ngStyle]="{ 'border': !addOnModel?.hideQuantityControls ? '1px solid #E4E5E7' :'' }" style="height: 32px" class="counter bx--row ml-1">
              <span *ngIf="!addOnModel?.hideQuantityControls" class="bx--col add-on-bar">
                <beaconstac-button [icon]="'fas fa-minus'" [iconSize]="ICON_SIZE.SMALL" [type]="BUTTON_TYPES.PRIMARY"
                  [style]="BUTTON_STYLES.TEXT" [label]="''"
                  [disabled]="(addOnModel.product === ADD_ON_PRODUCT_TYPE.USERS || addOnModel.product === ADD_ON_PRODUCT_TYPE.USERS) ? addOnModel.quantity === 1 : addOnModel.quantity === 50"
                  (onClick)="subtractQuantity()"></beaconstac-button>
              </span>
              <span class="bx--col add-on-bar">
                {{addOnModel.quantity * addOnModel.pack | number}}
              </span>
              <span *ngIf="!addOnModel?.hideQuantityControls" class="bx--col add-on-bar">
                <beaconstac-button *ngIf="!addOnModel?.hideQuantityControls" [icon]="'fas fa-plus'" [iconSize]="ICON_SIZE.SMALL" [style]="BUTTON_STYLES.TEXT"
                  [type]="BUTTON_TYPES.PRIMARY"
                  [disabled]="addOnModel.product === ADD_ON_PRODUCT_TYPE.QR_CODES && addOnModel.quantity === 5000"
                  [label]="''" (onClick)="addQuantity()"></beaconstac-button>
              </span>
            </span>
          </div>
          <div class="col-3">${{addOnModel.pricePerQuantityInCents * addOnModel.quantity * addOnModel.pack |
            decimalPrice | number}}/yr</div>
        </div>

        <div class="row mt-4" *ngIf="addOnModel.product === ADD_ON_PRODUCT_TYPE.QR_CODES">
          <div class="col-3">SCANS</div>
          <div class="col-6">
            <span style="width: 180px; height: 32px" class="bx--row ml-1">
              <span class="bx--col add-on-bar">
                {{addOnModel.quantity * addOnModel.pack * ADDITIONAL_SCANS_PER_QR | number}}
              </span>
            </span>
          </div>
          <div class="col-3">NA</div>
        </div>

        <div *ngIf="!isOnDbcTrialPlan" class="bx--row" style="margin-top: 56px">
          <div class="bx--col">
            <span *ngIf="!fetchingUpcomingPrice" class="text-secondary text-left"
              style="font-size: 15px; font-style: normal; font-weight: 400; line-height: 24px; letter-spacing: 0px; text-align: left; color: #16212F;">
              NOTE: You pay only
              <b class="text-dark">${{upcomingPrice | decimalPrice | number}}</b>
              for
              <b class="text-dark">{{addOnModel.quantity * addOnModel.pack | number}} {{descriptionText[addOnModel.product]}}</b>
              on a pro-rated basis, for the remaining duration of your plan from
              {{lastBillingDate}} to {{nextBillingDate}}.
            </span>
            <div *ngIf="fetchingUpcomingPrice" class="text-center" style="margin-bottom: 11px">
              <spinner [size]="30"></spinner>
            </div>
          </div>
        </div>
          <!-- TODO NEED to check comparison data -->
          <div class="bx--row" *ngIf="false && !isOnEnterprisePlan">
              <div class="bx--col">
                  <div class="mt-4">
                      <div class="mt-3 mb-4 d-flex flex-column bulk-dbc-container">
                            <span class="bulk-message d-flex flex-row my-3">
                                <i class="fas fa-info-circle pt-1"></i>
                                <span class="pl-2">You can save <b>$134</b> by upgrading to Business plan for the additional <b>{{addOnModel.quantity * addOnModel.pack | number}} seats</b> instead.
                                    <span (click)="routeToChangePlan()">Upgrade now</span>.
                                </span>
                            </span>
                      </div>
                  </div>
              </div>
          </div>

        <div class="pt-4">
          <span class="float-left d-flex flex-row mt-3" *ngIf="!isOnEnterprisePlan && !inviteUser">
            <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" (onClick)="routeToChangePlan()"
              [style]="BUTTON_STYLES.TEXT" [label]="'Upgrade Plan'"></beaconstac-button>
          </span>
          <span class="float-right d-flex flex-row">
            <span>
              <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" (onClick)="addOnModal.hide();" [label]="'Close'">
              </beaconstac-button>
            </span>
            <span class="pl-3">
              <beaconstac-button [label]="buttonLabel[addOnModel.product.toLowerCase()]"
                (onClick)="isOnDbcTrialPlan ? updateAddOns() : addOnModel.addOnStep = ADD_ON_STEPS.PAYMENT"></beaconstac-button>
            </span>
          </span>
        </div>
      </div>

      <div *ngIf="addOnModel.addOnStep === ADD_ON_STEPS.PAYMENT" class="modal-body p-4">
        <h4 class="mb-3" style="color: #16212F; font-size: 20px;"> Payment confirmation </h4>
        <div class="text-secondary text-left" style="font-size: 15px; font-style: normal; font-weight: 400; line-height: 24px;
        letter-spacing: 0px; margin-bottom: 36px">
          Your purchase will be charged against your current payment method.</div>

        <div class="bx--row" *ngIf="!isOnEnterprisePlan">
          <div class="bx--col" style="height: 40px;">
            <div class="bg-white p-2" style="border: 1px solid #E4E5E7; border-radius: 4px;">
              <span>
                <img src="../../assets/img/user-upgrade/{{selectedCard?.brand | lowercase}}.png" style="height: 20px;">
              </span>
              <span style="margin-left: 18px">
                **** **** **** {{selectedCard?.last4}}
              </span>
              <span class="float-right" style="color: #16212F;">
                {{selectedCard?.funding | uppercase}} CARD | Expires
                {{selectedCard?.exp_month}}/{{selectedCard?.exp_year}}
              </span>
            </div>
          </div>
        </div>

        <div class="" style="padding-top: 32px">
          <span class="d-flex flex-row float-left" style="margin-top: 14px;">
            <beaconstac-button [type]="BUTTON_TYPES.PRIMARY"
              (onClick)="addOnModel.addOnStep = ADD_ON_STEPS.SELECT_QUANTITY" [style]="BUTTON_STYLES.TEXT"
              [label]="'Go Back'"></beaconstac-button>
          </span>
          <span class="d-flex flex-row float-right">
            <span>
              <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" (onClick)="addOnModal.hide();" [label]="'Cancel'">
              </beaconstac-button>
            </span>
            <span class="pl-3">
              <beaconstac-button [label]="'Confirm'" (onClick)="updateAddOns()"></beaconstac-button>
            </span>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>
