import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class OverlayService {

    private loadingSource = new BehaviorSubject<boolean>(false);
    private loadingTextSource = new BehaviorSubject<string>('');
    loadingText$ = this.loadingTextSource.asObservable();
    loading$ = this.loadingSource.asObservable();

    isLoading(loading: boolean, loadingText: string = '') {
        setTimeout(() => {
            this.loadingSource.next(loading);
            this.loadingTextSource.next(loadingText);
        }, 0);
    }
}
