export class PDF {

    files: Array<{ url: string, name: string }> = [];
    content_table: boolean = false;
    name: string = '';
    uploaded: boolean = null;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.files = json['files'];
        this.content_table = json['content_table'];
        this.name = json['name'];
        this.uploaded = json['uploaded'];
    }
}
