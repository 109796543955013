<div *ngIf="loading$ | async" class="beaconstac-overlay" style="width: 100%;">
  <spinner class="custom-spinner-overlay" style="" [size]="60" [tickness]="3">
  </spinner>
</div>
<div>
  <beaconstac-message-modal></beaconstac-message-modal>
</div>
<div class="d-flex align-items-center justify-content-between">
  <img class="login-page-logo mx-4" [src]="brandLogo">
  <div class="mx-4">
    Hi {{firstName}} |
    <a href="Javascript:void(0)" (click)="logout()">Logout</a>
  </div>
</div>
<div class="d-flex vh-100 align-items-center justify-content-center">
  <div style="text-align: center">
    <h3>Hi <span style="color: #2595ff">{{firstName}}</span>, please verify your email address</h3>
    <div class="m-5">
      <img width="40%" src="/assets/img/email-verification/email_verification_image.png">
    </div>
    <p>We have sent an email to {{user.email}} with instructions</p>
    <div>
      <button class="btn btn-primary mr-4" style="min-width: 30%;" (click)="resendVerificationEmail()">Resend Email
      </button>
      <a class="btn btn-outline-primary" style="min-width: 30%; text-transform: uppercase" href="mailto:support@uniqode.com">Contact Support</a>
    </div>
  </div>

</div>
