import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../global-services/auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {Organization} from './user-organization.model';
import {BaseBackendService} from '../global-services/base-backend.service';
@Injectable()
export class MyOrganizationsService  extends BaseBackendService<Organization> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, Organization, 'myorganizations', messageModal);
    }

}
