<div class="app align-items-center login-background">
  <div class="login-header">
    <img class="login-page-logo mx-4" [src]="logo$ | async">
  </div>

  <beaconstac-wait-loader *ngIf="isLoading" class="beaconstac-overlay full-layout"></beaconstac-wait-loader>

  <div class="d-flex align-items-center justify-content-center" style="min-height: calc(100vh - 57px)">
    <div *ngIf="errorMessage">
      <div class="mb-1 p-3 text-center" [innerHTML]="errorMessage"></div>
      <div class="text-center">
        <a class="btn btn-primary" (click)="logout()">Try signing in again</a>
      </div>
    </div>
  </div>
</div>
