import { timer as observableTimer, BehaviorSubject, Observable, Subscription } from 'rxjs';

import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TOAST_TYPES } from 'kaizen-design-system';

@Injectable()
export class MessageModalService {
    private visibleSource = new BehaviorSubject<boolean>(false);
    visible$: Observable<boolean> = this.visibleSource.pipe(distinctUntilChanged());

    private messageSource = new BehaviorSubject<string>('');
    message$: Observable<string> = this.messageSource.asObservable();

    private typeSource = new BehaviorSubject<string>(TOAST_TYPES.SUCCESS);
    type$: Observable<string> = this.typeSource.asObservable();

    private dismissSubscription: Subscription = new Subscription();

    constructor() {}

    /**
     * @param {string} message
     * @param {"success" | "danger" | "warning"} type: changes color of the alert
     * @param {number} autoDismiss: millis to wait before dismissing, 0 to prevent autoDismiss
     */
    show(message: string, type: 'success' | 'danger' | 'warning' = 'success', autoDismiss: number = 3000) {
        this.messageSource.next(message);
        this.typeSource.next(type);
        this.visibleSource.next(true);

        if (!this.dismissSubscription.closed) {
            this.dismissSubscription.unsubscribe();
        }

        if (autoDismiss > 0) {
            this.dismissSubscription = observableTimer(autoDismiss).subscribe(() => this.dismiss());
        }
    }

    dismiss() {
        this.visibleSource.next(false);
        this.dismissSubscription.unsubscribe();
    }
}
