import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: 'input[beaconstacLowerCase]',
})
export class LowerCaseTextInputDirective {

    constructor(private el: ElementRef) {
    }

    @HostListener('change') onChange() {
        this.el.nativeElement.value = this.el.nativeElement.value.toLowerCase();
    }

}
