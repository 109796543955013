import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../global-services/auth.service';
import { UserService } from '../user-account/user.service';
import { OverlayService } from '../global-services/overlay.service';
import { MessageModalService } from '../shared/message-modal/message-modal.service';
import { Animations } from 'app/shared/beaconstac-animations';
import { User } from '../user-account/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FirebaseService } from '../global-services/firebase.service';

@Component({
    templateUrl: './email-verification-pending.component.html',
    styleUrls: ['../user-account/user.scss'],
    animations: [Animations.collapse],
})
export class EmailVerificationPendingComponent implements OnInit, OnDestroy {

    disabled: boolean = false;
    loading$: any;
    user: User;
    brandLogo: string = '';
    firstName: string;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private authService: AuthService, private overlay: OverlayService,
        private messageModal: MessageModalService,
        private userService: UserService,
        private firebaseService: FirebaseService,
        private router: Router, private route: ActivatedRoute) {
        this.loading$ = this.overlay.loading$;
        this.overlay.isLoading(false);
        this.user = this.authService.getUser();
        this.setupLogo();

        this.firebaseService.emailVerification$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            if (res && res.email_verified) {
                this.userService.getDetail().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                    this.router.navigate(['/email-verification-success']);
                })
            } else if (this.user.email_verified) {
                this.router.navigate(['/overview']);
            }
        })
    }

    setupLogo() {
        if (this.user.organization.whitelabel_logo_small) {
            this.brandLogo = this.user.organization.whitelabel_logo_small;
        } else {
            this.brandLogo = '/assets/img/uniqode-header-logo.svg';
        }
    }

    ngOnInit(): void {
        this.route.fragment.subscribe(fragment => {
            this.overlay.isLoading(false);
        });

        this.handleQueryParams();
        this.firstName = this.user.first_name;

    }

    handleQueryParams(): void {
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            if (params['trigger'] === 'auto') {
                this.resendVerificationEmail(true);
            }
        })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    resendVerificationEmail(auto: boolean = false) {
        this.authService.resendVerificationEmail().subscribe(res => {
            if (!auto) {
                this.messageModal.show('Verification email sent - please check your inbox.');
            }
        }, e => {
            this.messageModal.show('Failed to send verification email. Please contact support@uniqode.com', 'danger');
        });
    }

    logout() {
        this.authService.logout();
    }
}
