import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../global-services/auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {BaseBackendService} from '../global-services/base-backend.service';
import {Folder} from './media-folder.model';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class MediaFolderService extends BaseBackendService<Folder> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, Folder, 'folders', messageModal);
    }

    validateFolderName(folderName, organization) {
        const folderUrl = this.apiURL + `name/validate?name=${folderName}&organization=${organization}`;
        return this.http.get(folderUrl, this.buildHeaders({})).pipe(
            map((response) => {
                return response
            }), catchError(this.handleError));
    }

}
