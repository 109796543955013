import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {PRODUCT_TYPES} from '../shared/utils';

export enum ON_LEAVE_ACTIONS {
    REDIRECT,
    OPEN_TRIAL_SWITCH,
    ORGANIZATION_CHANGE,
    LOGOUT
}

@Injectable()
export class DiscardChangesService {
    private visibleSource = new BehaviorSubject<boolean>(false);
    visible$: Observable<boolean> = this.visibleSource.pipe(distinctUntilChanged());
    private titleSource: BehaviorSubject<string> = new BehaviorSubject('Leave this page?');
    title$: Observable<string> = this.titleSource.pipe(distinctUntilChanged());
    private messageSource: BehaviorSubject<string> = new BehaviorSubject(
        'Leaving will result in losing the recent changes you have made.',
    );
    message$: Observable<string> = this.messageSource.pipe(distinctUntilChanged());
    private primaryCtaSource: BehaviorSubject<string> = new BehaviorSubject('Leave');
    primaryCta$: Observable<string> = this.primaryCtaSource.pipe(distinctUntilChanged());
    private onLeaveActionSource: BehaviorSubject<ON_LEAVE_ACTIONS> = new BehaviorSubject(ON_LEAVE_ACTIONS.REDIRECT);
    onLeaveAction$: Observable<ON_LEAVE_ACTIONS> = this.onLeaveActionSource.pipe(distinctUntilChanged());
    private changeOrgSource: BehaviorSubject<any> = new BehaviorSubject(null);
    changeOrg$: Observable<any> = this.changeOrgSource.pipe(distinctUntilChanged());
    private logoutActionSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    logoutAction$: Observable<boolean> = this.logoutActionSource.pipe(distinctUntilChanged());

    private showTrialSwitchModalSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showTrialSwitchModal$: Observable<boolean> = this.showTrialSwitchModalSource.pipe();

    // Variables
    onLeaveAction = ON_LEAVE_ACTIONS.REDIRECT;
    nextOrg: any = null;

    confirmLeaveChangeSubject: Subject<boolean> = new Subject<boolean>();

    constructor(private router: Router, private authService: AuthService) {
        this.onLeaveAction$.subscribe((res) => {
            this.onLeaveAction = res;
        });
    }

    show(title: string, message: string, primaryCta?: string, onLeaveAction?: ON_LEAVE_ACTIONS, organization?) {
        this.visibleSource.next(true);
        this.titleSource.next(title);
        this.messageSource.next(message);
        if (!primaryCta) {
            primaryCta = 'Leave';
        }
        this.primaryCtaSource.next(primaryCta);
        if (onLeaveAction) {
            this.onLeaveActionSource.next(onLeaveAction);
            this.onLeaveAction = onLeaveAction;
        }
        this.authService.allowRedirectForcefully.next(false);
        if (organization) {
            this.nextOrg = organization;
        }
    }

    hide() {
        this.visibleSource.next(false);
        this.confirmLeaveChangeSubject.next(false);
        if (this.router.url.includes('digital-business-card')) {
            this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC);
        } else {
            this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR);
        }
    }

    confirmLeave() {
        this.visibleSource.next(false);
        switch (this.onLeaveAction) {
            case ON_LEAVE_ACTIONS.REDIRECT:
                this.confirmLeaveChangeSubject.next(true);
                this.authService.hasUnsavedChangesSource.next(false);
                console.log('REDIRECT')
                break;
            case ON_LEAVE_ACTIONS.OPEN_TRIAL_SWITCH:
                this.confirmLeaveChangeSubject.next(false);
                this.showTrialSwitchModalSource.next(true);
                this.onLeaveActionSource.next(ON_LEAVE_ACTIONS.REDIRECT);
                console.log('OPEN_TRIAL_SWITCH')
                break;
            case ON_LEAVE_ACTIONS.ORGANIZATION_CHANGE:
                this.authService.hasUnsavedChangesSource.next(false);
                this.changeOrgSource.next(this.nextOrg);
                console.log('ORGANIZATION_CHANGE')
                break;
            case ON_LEAVE_ACTIONS.LOGOUT:
                this.authService.allowRedirectForcefully.next(true);
                this.authService.hasUnsavedChangesSource.next(false);
                console.log('LOGOUT')
                this.logoutActionSource.next(true);
        }

        // Reseting values
        this.showTrialSwitchModalSource.next(false);
        this.logoutActionSource.next(false);
    }
}
