import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseAddonModalComponent } from './response-addon-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StrokeRadioButtonModule } from '../stroke-radio-button/stroke-radio-button.module';
import { PipesModule } from '../pipes/pipes.module';
import { BeaconstacModule } from 'kaizen-design-system';

@NgModule({
    declarations: [ResponseAddonModalComponent],
    exports: [ResponseAddonModalComponent],
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        StrokeRadioButtonModule,
        PipesModule,
        BeaconstacModule,
    ]
})
export class ResponseAddonModalModule { }
