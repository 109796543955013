import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageModalService } from './message-modal.service';
import { MessageModalComponent } from './message-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BeaconstacModule } from 'kaizen-design-system';

@NgModule({
    imports: [CommonModule, ModalModule, BeaconstacModule],
    declarations: [MessageModalComponent],
    exports: [MessageModalComponent],
    providers: [MessageModalService],
})
export class MessageModalModule {}
