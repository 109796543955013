import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'beaconstac-help-menu-display',
    templateUrl: './help-menu-display.component.html',
    styleUrls: ['./help-menu-display.component.scss']
})
export class HelpMenuDisplayComponent implements OnInit, OnDestroy {

    @ViewChild('helpModal', {static: true}) private modal: ModalDirective;
    @ViewChild('helpFrame', {static: true}) private frame: ElementRef;
    private url: string;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor() {
    }

    ngOnInit() {
        this.modal.onHide
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                this.clearModal();
                this.hide();
            });
    }

    show(url: string) {
        this.url = url;
        const html = `<iframe src="${this.url}?autoplay=1" frameborder="0" height="100%" allow="encrypted-media" allowfullscreen></iframe>`;
        this.frame.nativeElement.innerHTML = html;
        this.modal.show();
    }

    hide() {
        this.url = null;
        this.frame.nativeElement.innerHTML = '';
    }

    private clearModal() {
        this.frame.nativeElement.src = 'about:blank';
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
