import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {ModalModule} from 'ngx-bootstrap/modal';
import {LowerCaseTextInputModule} from '../shared/lower-case-text-input/lower-case-text-input.module';
import {MessageModalModule} from '../shared/message-modal/message-modal.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {EmailVerificationSuccessComponent} from './email-verification-success.component';
import {EmailVerificationPendingComponent} from './email-verification-pending.component';
import {RouterModule} from '@angular/router';
import { ChangeEmailVerificationComponent } from './change-email/change-email-verification/change-email-verification.component';
import {BeaconstacModule} from 'kaizen-design-system';


@NgModule({
    declarations: [
        EmailVerificationSuccessComponent,
        EmailVerificationPendingComponent,
        ChangeEmailVerificationComponent
    ],
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        FormsModule,
        TooltipModule.forRoot(),
        LowerCaseTextInputModule,
        MessageModalModule,
        RouterModule,
        BeaconstacModule
    ]
})
export class EmailVerificationModule {
}
