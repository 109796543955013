import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../global-services/auth.service';
import {PRODUCT_TYPES} from '../shared/utils';
import {SUBSCRIPTION_STATE} from '../user-account/user.model';

@Injectable()
export class CurrentProductState implements CanActivateChild {

    constructor(private authService: AuthService, private router: Router) {
    }


    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        switch (this.authService.getCurrentProduct()) {
            case 'QrCodes':
                if (this.authService.getUser().getProductSubscriptionState(PRODUCT_TYPES.QR) !== SUBSCRIPTION_STATE.Expired) {
                    return true
                }
                this.redirectToExpiryPage(PRODUCT_TYPES.QR);
                break
            case 'Cards':
                if (this.authService.getUser().getProductSubscriptionState(PRODUCT_TYPES.DBC) !== SUBSCRIPTION_STATE.Expired) {
                    return true
                }
                this.redirectToExpiryPage(PRODUCT_TYPES.DBC);

        }
    }

    redirectToExpiryPage(product: PRODUCT_TYPES) {
        switch (product) {
            case PRODUCT_TYPES.QR:
                this.router.navigate(['/renew-plan']);
                break
            case PRODUCT_TYPES.DBC:
                this.router.navigate(['/renew-plan']);
                break
        }
    }
}
