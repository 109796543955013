import { Injectable } from '@angular/core';
import { BaseBackendService } from './base-backend.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { MessageModalService } from 'app/shared/message-modal/message-modal.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
export class ProductUsageCountModel {
    count: number;
    limit: number;

    constructor(json: any) {
        if (json) {
            this.count = json['count'];
            this.limit = json['limit'];
        }
    }
}

@Injectable()
export class ProductUsageCountService extends BaseBackendService<ProductUsageCountModel> {

    linkpageCount: number;
    linkpageLimit: number;

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, ProductUsageCountModel, 'organizations', messageModal);
    }

    getProductUsage(): Observable<ProductUsageCountModel> {
        const orgId = this.authService.getCurrentOrgId();
        const custom = `usage/linkpage/?organization=${orgId}&child_orgs=true`;
        return this.getDetail(orgId, custom).pipe(
            tap((data: ProductUsageCountModel) => {
                this.linkpageCount = data.count;
                this.linkpageLimit = data.limit;
            })
        );
    }

    getLinkpageCount() {
        return this.linkpageCount;
    }
}
