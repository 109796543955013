import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class RouterReloadService {

    private loadingSource = new BehaviorSubject<null>(null);
    reload$ = this.loadingSource.asObservable();

    reload() {
        setTimeout(() => {
            this.loadingSource.next(null);
        }, 0);
    }
}
