import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '../auth.service';
import {FEATURE_FLAGS} from '../../shared/feature-flags';

@Injectable()
export class DbcLDFlagService {
    public isDbcActivationExperiment1: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isUserInFirstFlow: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(private readonly authService: AuthService) {
        this.getRequiredFlags();
    }

    getRequiredFlags() {
        this.authService.getFeatureFlag(FEATURE_FLAGS.DBC_ACTIVATION_EXPERIMENT_1, false).then(res => {
            this.isDbcActivationExperiment1.next(res);
        });
    }
}
