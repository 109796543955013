import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../global-services/auth.service';
import {DASHBOARD_MENU} from '../shared/utils';
import {DashboardService} from '../global-services/dashboard.service';

const QR_ROUTE_URLS = [
    'qr-codes',
    'qr-templates',
    'bulk-qr-codes',
    'forms',
    'linkpage'
]

const DBC_ROUTES = [
    'digital-business-cards'
]

@Injectable()
export class ProductGuard implements  CanActivateChild {

    constructor(private router: Router, private authService: AuthService,
        private  dashboardService: DashboardService) {
    }


    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.authService.getUser();
        const canAccess = user && (this.authService.getCurrentOrganization().feature_permissions.dbc);
        if (!canAccess) {
            this.authService.setCurrentProduct(DASHBOARD_MENU.QRCODES);
            this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.QRCODES);
            this.router.navigate(['/overview']);
            return;
        }
        this.authService.setCurrentProduct(DASHBOARD_MENU.CARDS);
        this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.CARDS);
        return canAccess;
    }
}

@Injectable()
export class ProductRouteGuard implements  CanActivateChild {

    constructor(private authService: AuthService,  private  dashboardService: DashboardService, private router: Router) {
    }


    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return true;

        const url = state.url;
        const isRouteForQR = QR_ROUTE_URLS.filter(val => url.includes(val));
        const isRouteForDbc = DBC_ROUTES.filter(val => url.includes(val));
        const currentOrgId = this.authService.getCurrentOrgId();
        const qrAccess = this.authService.getUser().hasQRAccess(currentOrgId);
        const dbcAccess = this.authService.getUser().hasDBCAccess(currentOrgId);

        if (isRouteForQR.length && qrAccess) {
            this.authService.setCurrentProduct(DASHBOARD_MENU.QRCODES);
            this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.QRCODES);
        } else if (isRouteForDbc.length && dbcAccess) {
            this.authService.setCurrentProduct(DASHBOARD_MENU.CARDS);
            this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.CARDS);
        } else if ((isRouteForQR.length && !qrAccess) || (isRouteForDbc.length && !dbcAccess)) {
            this.router.navigate(['/overview'], {queryParams: {orgID: this.authService.getCurrentOrgId()}});
        } else {
            const product = this.authService.getCurrentProduct() || 'QrCodes'
            const currentDashboard = product === DASHBOARD_MENU.QRCODES ? DASHBOARD_MENU.QRCODES : DASHBOARD_MENU.CARDS
            this.dashboardService.setCurrentDashboard(currentDashboard);
        }
        return true;
    }
}
