import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../global-services/auth.service';
import {PRODUCT_TYPES} from '../shared/utils';

@Injectable()
export class DBCProductGuard implements  CanActivateChild {

    constructor(private router: Router, private authService: AuthService) {
    }


    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.authService.isSafeSuperUser()) {
            return true
        }

        const user = this.authService.getUser();
        const currentOrgId = this.authService.getCurrentOrgId();

        if (user.hasDBCAccess(currentOrgId)) {

            this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC)

            return true
        } else if (user.hadDBCAccess()) {
            this.router.navigate(['/renew-plan']);
            return false
        } else {
            return false
        }
    }
}
