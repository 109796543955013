import {Injectable, OnDestroy} from '@angular/core';
import {PLAN_TYPES, User} from '../../user-account/user.model';
import {
    AnalyticsPermissions,
    AvatarItemPermissions,
    DBCFreemiumStrategy,
    DEFAULT_ANALYTICS_PERMISSIONS,
    DEFAULT_AVATAR_PERMISSIONS,
    DEFAULT_FEATURE_VISIBILITY_PERMISSIONS,
    DEFAULT_LAYOUT_PERMISSIONS,
    DEFAULT_NAV_PERMISSIONS,
    DEFAULT_UPSELL_TOUCHPOINT_PERMISSIONS,
    FeatureVisibilityPermisions,
    LayoutPermissions,
    NavBarItemPermissions,
    DbcFormUpsellTouchpointsPermission
} from './dbc.rbac.model';
import {AuthService} from '../auth.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DbcRbacService implements OnDestroy {

    ngUnsubscribe: Subject<any> = new Subject<any>();

    private readonly navBarPermissionSubject: BehaviorSubject<NavBarItemPermissions> = new BehaviorSubject<NavBarItemPermissions>(null);
    public navBarPermission$ = this.navBarPermissionSubject.pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe));

    private readonly avatarPermissionSubject: BehaviorSubject<AvatarItemPermissions> = new BehaviorSubject<AvatarItemPermissions>(null);
    public avatarPermission$ = this.avatarPermissionSubject.pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe));

    private readonly analyticsPermissionSubject: BehaviorSubject<AnalyticsPermissions> = new BehaviorSubject<AnalyticsPermissions>(null);
    public analyticsPermission$ = this.analyticsPermissionSubject.pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe));

    private readonly layoutsPermissionSubject: BehaviorSubject<LayoutPermissions> = new BehaviorSubject<LayoutPermissions>(null);
    public layoutsPermission$ = this.layoutsPermissionSubject.pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe));

    private readonly dbcFormUpsellTouchpointsPermissionSubject: BehaviorSubject<DbcFormUpsellTouchpointsPermission> = new BehaviorSubject<DbcFormUpsellTouchpointsPermission>(null);
    public dbcFormUpsellTouchpointsPermission$ = this.dbcFormUpsellTouchpointsPermissionSubject.pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe));

    private readonly featuresVisibilitySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public featuresVisibility$ = this.featuresVisibilitySubject.pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe));

    constructor(private readonly authService: AuthService) {
        this.authService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
            if (user) {
                this.navBarPermissionSubject.next(this.getDbcNavBarItems(user));
                this.avatarPermissionSubject.next(this.getDbcAvatarItems(user));
                this.analyticsPermissionSubject.next(this.getDbcAnalyticsItems(user));
                this.layoutsPermissionSubject.next(this.getDbcLayoutsItems(user));
                this.dbcFormUpsellTouchpointsPermissionSubject.next(this.getDbcUpsellTouchpointItems(user));
                this.featuresVisibilitySubject.next(this.getFeaturesVisibilityItems(user));
            }
        })
    }

    /**
     * @description
     * This function will return the navigation menu item permissions based on the plan and other properties
     * @param user: {User}
     * @returns NavBarItemPermissions
     * **/
    private getDbcNavBarItems(user: User): NavBarItemPermissions {
        let navItems: NavBarItemPermissions = DEFAULT_NAV_PERMISSIONS;
        if (user.dbcCustomerPlan === PLAN_TYPES.DBCFreemium) {
            navItems = new DBCFreemiumStrategy().getNavbarOptions();
        }

        // Set role based permissions for nav links
        navItems.settings = navItems.settings && user.isAdmin(this.authService.getCurrentOrgId());
        navItems.orgCards = navItems.orgCards && user.isAdmin(this.authService.getCurrentOrgId());
        return navItems;
    }

    /**
     * @description
     * This function will return the avatar menu item permission based on the plan and other properties
     * @param user: {User}
     * **/
    private getDbcAvatarItems(user: User): AvatarItemPermissions {
        let avatarItems: AvatarItemPermissions = DEFAULT_AVATAR_PERMISSIONS;
        if (user.dbcCustomerPlan === PLAN_TYPES.DBCFreemium) {
            avatarItems = new DBCFreemiumStrategy().getAvatarOptions();
        }
        return avatarItems;
    }

    /**
     * @description
     * This function will return the analytics permission based on the plan and other properties
     * @param user: {User}
     * **/
    private getDbcAnalyticsItems(user: User): AnalyticsPermissions {
        let analyticsItems: AnalyticsPermissions = DEFAULT_ANALYTICS_PERMISSIONS;
        if (user.dbcCustomerPlan === PLAN_TYPES.DBCFreemium) {
            analyticsItems = new DBCFreemiumStrategy().getAnalyticsPermissions();
        }
        return analyticsItems;
    }

    /**
     * @description
     * This function will return the layouts permission based on the plan and other properties
     * @param user: {User}
     * **/
    private getDbcLayoutsItems(user: User): LayoutPermissions {
        let layoutsItems: LayoutPermissions = DEFAULT_LAYOUT_PERMISSIONS;
        if (user.dbcCustomerPlan === PLAN_TYPES.DBCFreemium) {
            layoutsItems = new DBCFreemiumStrategy().getLayoutsPermissions();
        }
        return layoutsItems;
    }

    /**
     * @description
     * This function will return the upsell touchpoint permission based on the plan and other properties
     */
    private getDbcUpsellTouchpointItems(user: User): DbcFormUpsellTouchpointsPermission {
        let upsellTouchpointItems: DbcFormUpsellTouchpointsPermission = DEFAULT_UPSELL_TOUCHPOINT_PERMISSIONS;
        if (user.dbcCustomerPlan === PLAN_TYPES.DBCFreemium) {
            upsellTouchpointItems = new DBCFreemiumStrategy().getFirstFlowUpsellTouchpointsPermission();
        }
        return upsellTouchpointItems;
    }

    /**
     * @description
     * This function will return the features visibility based on the plan and other properties
     */
    private getFeaturesVisibilityItems(user: User) {
        let featureVisibilityPermissions: FeatureVisibilityPermisions = DEFAULT_FEATURE_VISIBILITY_PERMISSIONS;

        if (user.dbcCustomerPlan === PLAN_TYPES.DBCFreemium) {
            featureVisibilityPermissions = new DBCFreemiumStrategy().getFeaturesVisibilityPermissions();
        }

        return featureVisibilityPermissions;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
