import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FirebasePopupNotificationComponent} from './firebase-popup-notification.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BeaconstacModule} from 'kaizen-design-system';



@NgModule({
    declarations: [
        FirebasePopupNotificationComponent
    ],
    imports: [
        CommonModule,
        ModalModule,
        BeaconstacModule,
    ],
    exports: [
        FirebasePopupNotificationComponent
    ]
})
export class FirebasePopupNotificationModule {
}
