import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {
    constructor() {}

    // Set an item in session storage
    setItem(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    // Get an item from session storage
    getItem(key: string): any {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    // Remove an item from session storage
    removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }

    // Clear all items from session storage
    clear(): void {
        sessionStorage.clear();
    }
}
