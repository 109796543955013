import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Animations } from '../../shared/beaconstac-animations';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Utils } from '../../shared/utils';
import { AMPLITUDE_EVENT_CATEGORIES, AmplitudeService } from '../../global-services/amplitude.service';

enum FileRejectionReasons {
    Size,
    Type,
    Unknown
}

@Component({
    selector: 'beaconstac-file-drop',
    templateUrl: './beaconstac-file-drop.component.html',
    styleUrls: ['./beaconstac-file-drop.scss'],
    animations: [Animations.collapse]
})
export class BeaconstacFileDropComponent {

    @Input() file: File = null;
    @Input() acceptTypes: Array<string> = Utils.contentTypes;
    @Input() maxSize: number = 5e+6;
    @Input() isUploading: boolean = false;
    @Input() preview: string;
    @Input() disabled: boolean = false;
    @Input() overrideClick: boolean = false;
    @Input() showRemoveButton: boolean = false;

    @Input() inputText = 'Choose a file';
    @Input() hint: string = '';
    @Input() fileURL: string = '';
    @Input() fileName: string = '';
    @Input() dimension: string = '';
    @Input() custom: string = '';
    @Input() small: boolean = false;
    @Input() fileDropSize: string = 'small';
    @Output() fileChange: EventEmitter<File> = new EventEmitter();
    @Output() onClick: EventEmitter<null> = new EventEmitter();
    @Output() onFileRemove: EventEmitter<null> = new EventEmitter();
    @Output() rejectionError: EventEmitter<null> = new EventEmitter();
    @ViewChild('browse', { static: false }) browse: ElementRef;

    isFileOverDropZone: boolean = false;
    isMobileDevice: boolean = (window.innerWidth < 768);
    isIpad: boolean = (window.innerWidth > 767 && window.innerWidth <= 1024);
    _error: string = '';
    _errorTimeout = null;
    hintGoogleDriveUpload: boolean = false;
    containerHeight: string = '100%';

    constructor(private amplitude: AmplitudeService) { }

    clear() {
        this.isFileOverDropZone = false;
        this.error = '';
        this.hintGoogleDriveUpload = false;
        this.file = null;
        this.browse.nativeElement.value = '';
    }

    set error(error: string) {
        this._error = error;
        this.setContainerHeight()
        if (this._errorTimeout) {
            clearTimeout(this._errorTimeout);
            this._errorTimeout = null;
        }
    }


    onClicked() {
        this.onClick.emit();
    }

    setContainerHeight() {
        this.containerHeight = this.isMobileDevice ? '77%' : this.isIpad ? '91%' : '86%';
    }

    onAccept(file: File) {
        if (!file || this.disabled) {
            return;
        }
        this.error = '';
        if (file instanceof File) {
            this.file = file;
        }

        this.fileChange.emit(this.file);
    }

    onReject(rejectionReason) {
        if (this.disabled) {
            return;
        }
        switch (rejectionReason) {
            case FileRejectionReasons.Type:
                let allowedFileTypes = '.';
                let index = 0;
                for (let fileType of this.acceptTypes) {
                    // Special if case for xlsx files, as the file type was not looking good in the frontend.
                    if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        fileType = 'xlsx';
                    }
                    allowedFileTypes += fileType.replace(/.*\//g, '');
                    if (index !== this.acceptTypes.length - 1) {
                        allowedFileTypes += ' | .';
                    }
                    index++;
                }
                this.error = `Please upload file with correct extension ( ${allowedFileTypes.trim()} ).`;
                break;
            case FileRejectionReasons.Size:
                this.error = `File too large: exceeds ${this.maxSize / 1000000}MB limit. ${this.hint}`;
                this.rejectionError.emit();
                this.hintGoogleDriveUpload = true;
                break;
            default:
                this.error = 'Unable to upload this file';
        }
    }

    onBrowse(file: File) {
        if (this.disabled) {
            return;
        }
        if (!file) {
            this.error = 'Unable to upload this file';
            this.hintGoogleDriveUpload = false;
            return;
        }
        if (file.size > this.maxSize) {
            this.onReject(FileRejectionReasons.Size);
            return;
        }
        this.onAccept(file);
    }


    onFileDropped(files: NgxFileDropEntry[]) {
    // const files = event.files;
        for (const droppedFile of files) {

            // Check if it is a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    const fileNameSplit = file.name.split('.');
                    const extension = fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1] : 'unknown';
                    if (this.acceptTypes.indexOf(file.type) !== -1 && file.size <= this.maxSize) {
                        this.onAccept(file);
                    } else {
                        if (this.acceptTypes.indexOf(file.type) === -1) {
                            this.onReject(FileRejectionReasons.Type);
                            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Error, 'mime type error', {
                                allowedFileTypes: this.acceptTypes.join(','),
                                uploadedFileType: file.type || 'unknown',
                                fileExtension: extension
                            })
                        } else {
                            this.onReject(FileRejectionReasons.Size)
                        }
                    }
                });
            } else {
                this.onReject(FileRejectionReasons.Unknown)
            }
        }
        this.isFileOverDropZone = false;
    }

    onFileRemoved() {
        this.onFileRemove.emit();
    }
}
