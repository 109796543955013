import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ProductOwnerAccessComponent} from './product-owner-access.component';
import {ProductOwnerAccessService} from './product-owner-access.service';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
    ],
    declarations: [
        ProductOwnerAccessComponent,
    ],
    exports: [
        ProductOwnerAccessComponent,
    ],
    providers: [
        ProductOwnerAccessService,
    ]
})
export class ProductOwnerAccessModule {
}
