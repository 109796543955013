import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PwaNudgeMobileComponent} from './pwa-nudge-mobile.component'

@NgModule({
    declarations: [PwaNudgeMobileComponent],
    imports: [CommonModule],
    exports: [PwaNudgeMobileComponent],
})
export class PwaNudgeMobileModule {}
