import {languages, Utils} from '../../shared/utils';
import {SMART_RULES_TYPE} from '../models/smart-rules.model';

export enum DROPDOWN_TYPE {
    RULE_TYPE,
    CONDITION_TYPE,
    VALUE_TYPE,
}

export enum CONDITION_DROPDOWN_TYPE {
    LOGIC_BOOLEAN_CONDITION = 'LogicBooleanConditions',
    LOGIC_NUMBER_CONDITION = 'LogicalNumberConditions',
    LOGIC_TIME_CONDITION = 'LogicalTimeConditions',
    LOGIC_DATE_CONDITION = 'LogicalDateConditions'

}

export enum OPERAND_VALUE_TYPE {
    TEXT,
    OBJECT
}

export const SmartRulesTypeList = [
    { icon: 'fas fa-clock',          name: 'Time', value: SMART_RULES_TYPE.TIME },
    { icon: 'fas fa-calendar-alt',   name: 'Date', value: SMART_RULES_TYPE.DATE },
    { icon: 'fas fa-calendar-day',   name: 'Day', value: SMART_RULES_TYPE.DAY },
    { icon: 'fas fa-globe-americas', name: 'Country', value: SMART_RULES_TYPE.COUNTRY },
    { icon: 'fas fa-qrcode  ',       name: 'Total user scans', value: SMART_RULES_TYPE.TOTAL_USER_SCANS },
    { icon: 'fas fa-users  ',        name: 'Unique user scans', value: SMART_RULES_TYPE.UNIQUE_USER_SCANS },
    { icon: 'fas fa-language  ',     name: 'Device language', value: SMART_RULES_TYPE.DEVICE_LANGUAGE },
    { icon: 'fas fa-mobile-alt',     name: 'Device OS', value: SMART_RULES_TYPE.DEVICE },
    { icon: 'fas fa-map-marker-alt', name: 'Location', value: SMART_RULES_TYPE.LOCATION },
    { icon: 'fas fa-key ',           name: 'Passcode', value: SMART_RULES_TYPE.PASSCODE },
    { icon: 'fas fa-child ',         name: 'User age', value: SMART_RULES_TYPE.USER_AGE },
];

export const LogicBooleanConditions = [
    {name: 'is', value: 'is'},
    {name: 'is not', value: 'is_not'}];

export const LogicalTimeConditions = [
    ...LogicBooleanConditions,
    {name: 'is between', value: 'is_between'},
    {name: 'is not between', value: 'is_not_between'}
]

export const LogicalDateConditions = [
    ...LogicalTimeConditions,
    {name: 'is after', value: 'is_after'},
    {name: 'is before', value: 'is_before'},
]

export const LogicalNumberConditions = [
    ...LogicalTimeConditions,
    {name: 'is greater than', value: 'is_greater_than'},
    {name: 'is less than', value: 'is_less_than'}
]


export const AllConditoinsType = [
    ...LogicalDateConditions,
    {name: 'is greater than', value: 'is_greater_than'},
    {name: 'is less than', value: 'is_less_than'}
]

export const logicNormalConditions = [
    {name: 'equal', value: 'is equal to'},
    {name: 'not_equal', value: 'is not equal to'},
    {name: 'begins_with', value: 'begins with'},
    {name: 'ends_with', value: 'ends with'},
    {name: 'contains', value: 'contains'},
    {name: 'not_contains', value: 'does not contain'}];

export const LogicalOperatorList = [
    {name: 'And', value: 'and'},
    {name: 'Or', value: 'or'}
]

export const SmartRulesDropDownOptions2 = {
    [CONDITION_DROPDOWN_TYPE.LOGIC_BOOLEAN_CONDITION]: LogicBooleanConditions,
    [CONDITION_DROPDOWN_TYPE.LOGIC_NUMBER_CONDITION]: LogicalNumberConditions,
    [CONDITION_DROPDOWN_TYPE.LOGIC_TIME_CONDITION]: LogicalTimeConditions,
    [CONDITION_DROPDOWN_TYPE.LOGIC_DATE_CONDITION]: LogicalDateConditions,
}

export const SmartRulesDropDownOptions3 = {
    [SMART_RULES_TYPE.USER_TYPE]: [
        {name: 'New Scanner', value: 'new scanner'},
        {name: 'Returning Scanner', value: 'returning scanner'}
    ],

    [SMART_RULES_TYPE.DEVICE]: [
        {name: 'iOS', value: 'ios'},
        {name: 'Android', value: 'android'},
        {name: 'Mac OS', value: 'mac os x'},
        {name: 'Any other OS', value: 'other'},
    ],

    [SMART_RULES_TYPE.COUNTRY]: Utils.countries,

    [SMART_RULES_TYPE.DEVICE_LANGUAGE]: languages,
}

export const DaysList = [
    {name: 'Sun', value: 'Sunday'},
    {name: 'Mon', value: 'Monday' },
    {name: 'Tue', value: 'Tuesday' },
    {name: 'Wed', value: 'Wednesday' },
    {name: 'Thu', value: 'Thursday' },
    {name: 'Fri', value: 'Friday' },
    {name: 'Sat', value: 'Saturday' }
]

