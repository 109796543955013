import { share, tap } from 'rxjs/operators';
import { BaseBackendService } from '../global-services/base-backend.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../global-services/auth.service';
import { Observable } from 'rxjs';
import { Organization } from './user-organization.model';
import { HttpClient } from '@angular/common/http';
import { MessageModalService } from '../shared/message-modal/message-modal.service';

@Injectable()
export class UserOrgService extends BaseBackendService<Organization> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, Organization, 'organizations', messageModal);
    }

    put(body: object, id: number | string, custom?: string): Observable<any> {
        const sub = super.put(body, id, custom).pipe(
            tap((response) => {
                const org = this.authService.getOrganization();
                this.authService.setOrganization(org);
                this.authService.updateOrgInMyOrgList(org);
                this.authService.setCurrentOrgObjInLocalStorage(org);
            }),
            share(),
        );
        sub.subscribe(
            (_) => {},
            (_) => {},
        );
        return sub;
    }

    post(body: object): Observable<any> {
        const sub = super.post(body).pipe(
            tap((response) => {
                const org = this.authService.getOrganization();
                this.authService.setOrganization(org);
                this.authService.setCurrentOrgObjInLocalStorage(org);
            }),
            share(),
        );
        sub.subscribe(
            (_) => {},
            (_) => {},
        );
        return sub;
    }

    delete(id: number | string, custom?: string): Observable<any> {
        const sub = super.delete(id, custom).pipe(
            tap((response) => {
                const org = this.authService.getOrganization();
                this.authService.setOrganization(org);
                this.authService.setCurrentOrgObjInLocalStorage(org);
            }),
            share(),
        );
        sub.subscribe(
            (_) => {},
            (_) => {},
        );
        return sub;
    }

    requestSSOSetup(body: any) {
        return super.post(body, 'sso_setup/');
    }

    transferOwnership(body) {
        return super.post(
            {
                user_id: body.userId,
                password: body.password,
            },
            'transfer_ownership/',
        );
    }

    saveCampaignAccessConfigurations(body) {
        return super.post(
            {
                campaign_access: body.campaign,
                restrict_static_qr: body.restrict_static_qr,
            },
            'campaign_access/' + body.id + '/',
        );
    }

    updateGACode(body, orgId: number) {
        return super.put(body, orgId + '/ga_code/');
    }

    getLinkpageUsage(orgId: number, custom?: string) {
        return this.getDetail(`${orgId}/usage/linkpage`, custom);
    }
}
