import { Injectable } from '@angular/core';
import { SubscriptionDetail, UserService } from '../user-account/user.service';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

export enum PLAN_INTERVALS {
    Monthly = 'monthly',
    Yearly = 'yearly'
}

@Injectable()
export class UserSubscriptionService {

    private subscriptionDetails: Array<SubscriptionDetail>;
    starterTrial: boolean = false;

    subscriptionSubject = new BehaviorSubject<object>(null);
    subscription$ = this.subscriptionSubject.asObservable();
    planSubject = new BehaviorSubject<object>(null);
    previousPlan$ = this.planSubject.asObservable();

    constructor(private userService: UserService) {
    }

    getSubscriptionDetails() {
        this.userService.getSubscription().subscribe(res => {
            this.subscriptionDetails = res;

            const subscriptionObject = {
                plan: null,
                isStarterTrial: false,
                expires: null,
                status: 'active',
                trial: false,
                dbcTrial: false,
                dbcExpires: null,
                qrTrialEnd: null
            };

            if (this.subscriptionDetails) {

                for (const subscription of this.subscriptionDetails) {
                    if (!subscription) {
                        return;
                    }

                    if (subscription.productType === 'qr') {
                        if (subscription.id.indexOf('-STARTER-TRIAL') !== -1) {
                            subscriptionObject.isStarterTrial = true;
                            subscriptionObject.qrTrialEnd = subscription.trial_end;
                        }
                        if (subscription.id.indexOf('-PB') === -1 && subscription.id.indexOf('-PQ') === -1) {
                            subscriptionObject.plan = subscription;
                            subscriptionObject.status = subscription.status;
                            subscriptionObject.trial = subscription.status === 'trialing';
                            subscriptionObject.expires = subscription.trial_end ? moment(subscription.trial_end * 1000).diff(moment(), 'days') : null;
                        }
                    }

                    if (subscription.productType === 'dbc') {
                        if (subscription.status === 'trialing') {
                            subscriptionObject.dbcTrial = true
                        }
                        subscriptionObject.plan = subscription;
                        subscriptionObject.status = subscription.status;
                        subscriptionObject.trial = subscription.status === 'trialing';
                        subscriptionObject.dbcExpires = subscription.trial_end ? moment(subscription.trial_end * 1000).diff(moment(), 'days') : null;
                    }
                }
            }
            this.subscriptionSubject.next(subscriptionObject);
        }, error => {
        });

    }

    upgradePlan(plan, yearly: boolean) {
        return this.userService.updateSubscription(plan, yearly);
    }

    getLastInvoice() {
        this.userService.getLastInvoice().subscribe(res => {
            this.planSubject.next(res);
        }, error => {
        });

    }
}
