<div bsModal #creditCardModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">

  <div class="modal-dialog" style="width: 400px" role="document">
    <div class="modal-content">

      <form #cardForm="ngForm" method="post" id="payment-form">

        <div class="modal-body" style="padding: 24px">

          <h4 style="color: #16212F; font-size: 20px; margin-bottom: 24px">{{headerText}}</h4>

          <div *ngIf="staterUpgrade" class="mb-3">
            <div class="row">
              <div class="col-12">
                Unlock all premium QR Code features - dynamic QR codes, unlimited scans, download in all formats and
                campaign
                analytics.
              </div>
              <h6 class="col-12 my-4 text-center">Select plan</h6>
              <div class="col-12 col-md-6 cursor-pointer">
                <div class="w-100 text-center py-3 stater-plan-button" [ngClass]="staterPlanYearly ? '' : 'active'"
                     (click)="onPlanTypeClicked(false)">
                  <div><span class="stater-plan-price"><input class="mr-2" type="radio" [checked]="!staterPlanYearly">15$</span>/month(billed
                    monthly)
                  </div>
                  <div class="stater-plan-savings-text invisible">Cancel*</div>
                </div>
              </div>
              <div class="col-12 col-md-6 cursor-pointer">
                <div class="w-100 text-center py-3 stater-plan-button" [ngClass]="staterPlanYearly ? 'active' : ''"
                     (click)="onPlanTypeClicked(true)">
                  <div>
                      <span class="stater-plan-price"><input class="mr-2" type="radio"
                                                             [checked]="staterPlanYearly">99$</span>/year(billed
                    annually)
                  </div>
                  <div class="stater-plan-savings-text ">Save 45% vs paying monthly</div>
                </div>
              </div>
            </div>
            <h6 class="mt-4 text-center">Enter card details</h6>
          </div>

          <div class="form-row stripe-container">
            <div id="card-element" class="stripe-card-container">
              <!-- a Stripe Element will be inserted here. -->
            </div>
            <!-- Used to display Element errors -->
            <div id="card-errors" class="stripe-card-error" role="alert"></div>

          </div>

          <div style="padding-top: 17px; margin-bottom: 40px">
            <span style="float: right; display: flex; flex-direction: row">
              <span>
                <beaconstac-button [style]="'filled'" [type]="'secondary'" [label]="'Cancel'"
                                   (onClick)="creditCardModal.hide();"></beaconstac-button>

              </span>
              <span style="padding-left: 16px">
                <beaconstac-button [disabled]="disabled || cardForm.invalid" [style]="'filled'" [type]="'primary'"
                                   [label]="'Confirm'" (onClick)="confirmCard()"></beaconstac-button>

              </span>
              <button class="d-none" id="upgrade-submit-button" value="submit" type="submit"></button>
            </span>
          </div>

        </div>

      </form>

    </div>

  </div>
</div>

<!--  <div class="modal-dialog" style="width: 400px" role="document">-->
<!--    <div class="modal-content">-->

<!--&lt;!&ndash;      <div class="modal-header">&ndash;&gt;-->
<!--&lt;!&ndash;        <h4 class="modal-title">{{headerText}}</h4>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        <button type="button" class="close" (click)="creditCardModal.hide()" aria-label="Close">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          <span aria-hidden="true">&times;</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        </button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->

<!--      <form #cardForm="ngForm" method="post" id="payment-form">-->
<!--        <div class="modal-body" style="padding: 24px">-->
<!--          <div *ngIf="staterUpgrade" class="mb-3">-->
<!--            <div class="row">-->
<!--              <div class="col-12">-->
<!--                Unlock all premium QR Code features - dynamic QR codes, unlimited scans, download in all formats and-->
<!--                campaign-->
<!--                analytics.-->
<!--              </div>-->
<!--              <h6 class="col-12 my-4 text-center">Select plan</h6>-->
<!--              <div class="col-12 col-md-6 cursor-pointer">-->
<!--                <div class="w-100 text-center py-3 stater-plan-button" [ngClass]="staterPlanYearly ? '' : 'active'"-->
<!--                     (click)="onPlanTypeClicked(false)">-->
<!--                  <div><span class="stater-plan-price"><input class="mr-2" type="radio" [checked]="!staterPlanYearly">15$</span>/month(billed-->
<!--                    monthly)-->
<!--                  </div>-->
<!--                  <div class="stater-plan-savings-text invisible">Cancel*</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-12 col-md-6 cursor-pointer">-->
<!--                <div class="w-100 text-center py-3 stater-plan-button" [ngClass]="staterPlanYearly ? 'active' : ''"-->
<!--                     (click)="onPlanTypeClicked(true)">-->
<!--                  <div>-->
<!--                    <span class="stater-plan-price"><input class="mr-2" type="radio"-->
<!--                                                           [checked]="staterPlanYearly">99$</span>/year(billed annually)-->
<!--                  </div>-->
<!--                  <div class="stater-plan-savings-text ">Save 45% vs paying monthly</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <h6 class="mt-4 text-center">Enter card details</h6>-->
<!--          </div>-->
<!--          <div class="form-row stripe-container">-->
<!--            <div id="card-element" class="stripe-card-container">-->
<!--              &lt;!&ndash; a Stripe Element will be inserted here. &ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; Used to display Element errors &ndash;&gt;-->
<!--            <div id="card-errors" class="stripe-card-error" role="alert"></div>-->

<!--            &lt;!&ndash;<div>-->
<!--              <div class="row mt-4 mb-2"><span class="col-4 my-auto">Account password:</span>-->
<!--                <input type="password" name="password" class="form-control col-6" placeholder="Enter password to confirm" (input)="onAccountPasswordEntered()"-->
<!--                       [(ngModel)]="accountPasswordText">-->
<!--              </div>-->
<!--            </div>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->

<!--        <div style="padding-top: 16px;">-->
<!--          <span style="float: right; display: flex; flex-direction: row; margin-bottom: 16px; margin-right: 16px">-->
<!--            <span>-->
<!--            </span>-->
<!--            <span style="padding-left: 16px">-->
<!--              <beaconstac-button [style]="'filled'" [type]="'secondary'" [label]="'CANCEL'" (onClick)="creditCardModal.hide();"></beaconstac-button>-->
<!--            </span>-->
<!--          </span>-->
<!--        </div>-->

<!--      </form>-->
<!--    </div>-->
<!--  </div>-->


<!--<div bsModal #creditCardModal="bs-modal" class="modal fade" role="dialog">-->
<!--  <div class="modal-dialog modal-info" role="document">-->
<!--    <div class="modal-content">-->

<!--      <div class="modal-header">-->
<!--        <h4 class="modal-title">{{headerText}}</h4>-->
<!--        <button type="button" class="close" (click)="creditCardModal.hide()" aria-label="Close">-->
<!--          <span aria-hidden="true">&times;</span>-->
<!--        </button>-->
<!--      </div>-->

<!--      <form #cardForm="ngForm" method="post" id="payment-form">-->
<!--        <div class="modal-body">-->
<!--          <div *ngIf="staterUpgrade" class="mb-3">-->
<!--            <div class="row">-->
<!--              <div class="col-12">-->
<!--                Unlock all premium QR Code features - dynamic QR codes, unlimited scans, download in all formats and-->
<!--                campaign-->
<!--                analytics.-->
<!--              </div>-->
<!--              <h6 class="col-12 my-4 text-center">Select plan</h6>-->
<!--              <div class="col-12 col-md-6 cursor-pointer">-->
<!--                <div class="w-100 text-center py-3 stater-plan-button" [ngClass]="staterPlanYearly ? '' : 'active'"-->
<!--                     (click)="onPlanTypeClicked(false)">-->
<!--                  <div><span class="stater-plan-price"><input class="mr-2" type="radio" [checked]="!staterPlanYearly">15$</span>/month(billed-->
<!--                    monthly)-->
<!--                  </div>-->
<!--                  <div class="stater-plan-savings-text invisible">Cancel*</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-12 col-md-6 cursor-pointer">-->
<!--                <div class="w-100 text-center py-3 stater-plan-button" [ngClass]="staterPlanYearly ? 'active' : ''"-->
<!--                     (click)="onPlanTypeClicked(true)">-->
<!--                  <div>-->
<!--                    <span class="stater-plan-price"><input class="mr-2" type="radio"-->
<!--                                                           [checked]="staterPlanYearly">99$</span>/year(billed annually)-->
<!--                  </div>-->
<!--                  <div class="stater-plan-savings-text ">Save 45% vs paying monthly</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <h6 class="mt-4 text-center">Enter card details</h6>-->
<!--          </div>-->
<!--          <div class="form-row stripe-container">-->
<!--            <div id="card-element" class="stripe-card-container">-->
<!--              &lt;!&ndash; a Stripe Element will be inserted here. &ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; Used to display Element errors &ndash;&gt;-->
<!--            <div id="card-errors" class="stripe-card-error" role="alert"></div>-->

<!--            &lt;!&ndash;<div>-->
<!--              <div class="row mt-4 mb-2"><span class="col-4 my-auto">Account password:</span>-->
<!--                <input type="password" name="password" class="form-control col-6" placeholder="Enter password to confirm" (input)="onAccountPasswordEntered()"-->
<!--                       [(ngModel)]="accountPasswordText">-->
<!--              </div>-->
<!--            </div>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="modal-footer">-->
<!--          <input class="btn btn-primary" type="submit" [disabled]="disabled || cardForm.invalid"-->
<!--                 value="{{primaryButtonText}}">-->
<!--          <button type="button" class="btn btn-secondary" (click)="creditCardModal.hide();">Cancel</button>-->
<!--        </div>-->

<!--      </form>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
