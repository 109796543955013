import * as moment from 'moment-timezone';
import {TEXT_FIELD_TYPES} from 'kaizen-design-system';
import { DEFAULT_MAP_LOCATION } from 'app/shared/utils';
export enum CONDITION_DROPDOWN_TYPE {
    LOGIC_BOOLEAN_CONDITION = 'LogicBooleanConditions',
    LOGIC_NUMBER_CONDITION = 'LogicalNumberConditions',
    LOGIC_TIME_CONDITION = 'LogicalTimeConditions',
    LOGIC_DATE_CONDITION = 'LogicalDateConditions'
}

export enum SMART_RULES_TYPE {
    TIME = 'time',
    DATE = 'date',
    DAY = 'day',
    COUNTRY = 'country',
    USER_TYPE = 'user_type',
    TOTAL_USER_SCANS = 'total_user_scans',
    UNIQUE_USER_SCANS = 'unique_user_scans',
    DEVICE_LANGUAGE = 'language',
    DEVICE = 'device',
    LOCATION = 'gps_location_range',
    PASSCODE = 'passcode',
    USER_AGE = 'age',
    TIME_RANGE = 'time_range',
    DATE_RANGE = 'date_range'
}

export enum VALUE_TYPES {
    TEXT = 'text',
    NUMBER = 'number',
    EPOCH = 'epoch',
    LAT_LONG = 'lat_long',
    DAY_TIMEZONE = 'day_timezone'
}

export class Destination {
    type: string = 'url_redirect'
    target: string = '';

    constructor(json?) {
        if (!json) {
            return;
        }
        this.type = json['type'] ? json['type'] : 'url_redirect';
        this.target = json['target'] ? json['target'] : '';
    }
}

export class Rules {
    name: string = 'New rule'
    rule_operator: string = 'and';
    operands: Array<any> = [];
    destination: Destination = new Destination();
    action_required: Array<string> = [];
    __isValid__: boolean = true;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.name = json['name'] ? json['name'] : [];
        this.rule_operator = json['rule_operator'] ? json['rule_operator'] : '';
        this.destination  = json['destination'] ?  new Destination(json['destination']) : new Destination();
        this.action_required = json['action_required'] ? json['action_required'] : [];
        this.__isValid__ = json['__isValid__'] || true;
        if (json['operands']) {
            for (const operand of json['operands']) {
                switch (operand.field) {
                    case SMART_RULES_TYPE.TIME:
                        this.operands.push( new TimeModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.EPOCH,
                            timezone: operand.timezone
                        }));
                        break;
                    case SMART_RULES_TYPE.DAY:
                        this.operands.push( new DayModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.DAY_TIMEZONE,
                            timezone: operand.timezone
                        }));
                        break;
                    case SMART_RULES_TYPE.COUNTRY:
                        this.operands.push( new CountryModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.TEXT,
                        }));
                        break;
                    case SMART_RULES_TYPE.USER_TYPE:
                        this.operands.push( new UserTypeModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.TEXT,
                        }));
                        break;
                    case SMART_RULES_TYPE.DEVICE_LANGUAGE:
                        this.operands.push( new LanguageModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.TEXT,
                        }));
                        break;
                    case SMART_RULES_TYPE.DEVICE:
                        this.operands.push( new DeviceModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.TEXT,
                        }));
                        break;
                    case SMART_RULES_TYPE.PASSCODE:
                        this.operands.push( new PasscodeModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.TEXT,
                        }));
                        break;
                    case SMART_RULES_TYPE.TOTAL_USER_SCANS:
                        this.operands.push( new TotalUserScans({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.NUMBER
                        }));
                        break;
                    case SMART_RULES_TYPE.UNIQUE_USER_SCANS:
                        this.operands.push( new UniqueUsersScans({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.NUMBER
                        }));
                        break;
                    case SMART_RULES_TYPE.USER_AGE:
                        this.operands.push( new AgeModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.NUMBER
                        }));
                        break;
                    case SMART_RULES_TYPE.DATE:
                        this.operands.push( new DateModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.EPOCH,
                            timezone: operand.timezone
                        }));
                        break;
                    case SMART_RULES_TYPE.LOCATION:
                        this.operands.push( new LocationModel({
                            field: operand.field,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.LAT_LONG,
                            range: operand.range
                        }));
                        break;
                    default:
                        this.operands.push( new TimeModel({
                            field: SMART_RULES_TYPE.TIME,
                            comparison_operator: operand.comparison_operator,
                            value: operand.value,
                            value_type: VALUE_TYPES.EPOCH,
                            timezone: operand.timezone
                        }));
                }
            }
        }
    }
}

export class SmartRulesModel {
    rules: Array<Rules> = [];
    default: Destination = new Destination();
    __defaultUrlValid__: boolean = true;
    __disableNextButton__: boolean = true

    constructor(json?) {
        if (!json) {
            return;
        }

        const rulesArray = json['rules'] ? json['rules'] : [];
        for (const rule of rulesArray) {
            this.rules.push(new Rules(rule));
        }
        this.default = json['default'] ?  new Destination(json['default']) : new Destination();
        this.__disableNextButton__ = json['__disableNextButton__'] || true;
    }
}

export class Operand {
    field: string = '';
    comparison_operator: string = '';
    value: Array<string> = ['', ''];
    value_type: string = '';
    __conditionDropdown__: string = '';
    __conditionValueType__: 'dropdown' | 'modal' | 'text' = 'text';
    __hasMultipleValues__: boolean = false;
    __valid__: boolean = true;
    __errorMessage__1: string = '';
    __errorMessage__2: string = '';

    constructor(json?) {
        if (!json) {
            return;
        }
        this.field = json['field'] ? json['field'] : '';
        this.comparison_operator = json['comparison_operator'] ? json['comparison_operator'] : '';
        this.value = json['value'] ? json['value'] : ['', ''];
        this.value_type = json['value_type'] ? json['value_type'] :  '';
        if (this.comparison_operator ===  'is_between' || this.comparison_operator === 'is_not_between') {
            this.__hasMultipleValues__ = true;
        }

        if (this.value.length === 1 && !(this.field === SMART_RULES_TYPE.DAY)) {
            this.value.push('');
        }
    }
}


export class CountryModel extends Operand {
    constructor(json?) {
        super(json);
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_BOOLEAN_CONDITION
        this.__conditionValueType__ = 'dropdown';
        if (!json) {
            return;
        }
        this.value_type = VALUE_TYPES.TEXT;

        if (!this.value[0]) {
            this.value[0] = 'United States';
        }
    }
}

export class LanguageModel extends Operand {
    constructor(json?) {
        super(json);
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_BOOLEAN_CONDITION
        this.__conditionValueType__ = 'dropdown';
        if (!json) {
            return;
        }
        this.value_type = VALUE_TYPES.TEXT

        if (!this.value[0]) {
            this.value[0] = 'English';
        }
    }
}

export class DeviceModel extends Operand {
    constructor(json?) {
        super(json);
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_BOOLEAN_CONDITION
        this.__conditionValueType__ = 'dropdown';
        if (!json) {
            return;
        }
        this.value_type = VALUE_TYPES.TEXT

        if (!this.value[0]) {
            this.value[0] = 'ios';
        }
    }
}

export class CityModel extends Operand {
    constructor(json?) {
        super(json)
        if (!json) {
            return;
        }
    }
}

export class StateModel extends Operand {
    constructor(json?) {
        super(json)
        if (!json) {
            return;
        }
    }
}

export class AgeModel extends Operand {
    __placeholder__: string;
    __textFieldType__: TEXT_FIELD_TYPES = TEXT_FIELD_TYPES.NUMBER;
    constructor(json?) {
        super(json)
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_NUMBER_CONDITION
        this.__conditionValueType__ = 'text';
        this.__placeholder__ = 'Enter age in numbers';
        this.__hasMultipleValues__ = json.hasOwnProperty('__hasMultipleValues__') ? json['__hasMultipleValues__'] : this.__hasMultipleValues__;
        if (!json) {
            return;
        }
        this.value_type = VALUE_TYPES.NUMBER
    }
}

export class UserTypeModel extends Operand {
    constructor(json?) {
        super(json);
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_BOOLEAN_CONDITION
        this.__conditionValueType__ = 'dropdown';
        if (!json) {
            return;
        }
        this.value_type = VALUE_TYPES.TEXT

        if (!this.value[0]) {
            this.value[0] = 'new scanner';
        }
    }
}

export class PasscodeModel extends Operand {
    __placeholder__: string;
    constructor(json?) {
        super(json);
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_BOOLEAN_CONDITION
        this.__conditionValueType__ = 'text';
        this.__placeholder__ = 'Enter passcode';
        this.__hasMultipleValues__ = json.hasOwnProperty('__hasMultipleValues__') ? json['__hasMultipleValues__'] : this.__hasMultipleValues__;
        if (!json) {
            return;
        }
        this.value_type = VALUE_TYPES.TEXT
    }
}


export class TimeModel extends Operand {
    timezone: string = 'US/Alaska';
    __isValidStartEndTime__: boolean = true;
    __startEndTimeErrorMessage__: string = ''

    constructor(json?) {
        super(json);
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_TIME_CONDITION
        this.__conditionValueType__ = 'modal';
        if (!json) {
            return;
        }
        this.timezone = json['timezone'] ? json['timezone'] : this.timezone;
        this.__hasMultipleValues__ = json.hasOwnProperty('__hasMultipleValues__') ? json['__hasMultipleValues__'] : this.__hasMultipleValues__;
        this.value_type = VALUE_TYPES.EPOCH
        this.__isValidStartEndTime__ = json.hasOwnProperty('__isValidStartEndTime__') ? json['__isValidStartEndTime__'] : this.__isValidStartEndTime__
        this.__startEndTimeErrorMessage__ = json['__startEndTimeErrorMessage__'] ? json['__startEndTimeErrorMessage__'] : this.__startEndTimeErrorMessage__
    }
}


export class DateModel extends Operand {
    timezone: string = 'US/Alaska';
    __startDate__: string;
    __endDate__: string;
    constructor(json?) {
        super(json)
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_DATE_CONDITION
        this.__conditionValueType__ = 'modal';
        if (!json) {
            return;
        }


        this.timezone = json['timezone'] ? json['timezone'] : this.timezone;
        this.__hasMultipleValues__ = json.hasOwnProperty('__hasMultipleValues__') ? json['__hasMultipleValues__'] : this.__hasMultipleValues__;
        this.__startDate__ = json['__startDate__'] ? json['__startDate__'] : '';
        this.__endDate__ = json['__endDate__'] ? json['__endDate__'] : '';
        if (this.value && this.value[0]) {
            this.__startDate__ = moment(Number(this.value[0])).format('MMM DD, YYYY')
        }
        if (this.value && this.value[1]) {
            this.__endDate__ = moment(Number(this.value[1])).format('MMM DD, YYYY')
        }
        this.value_type = VALUE_TYPES.EPOCH
    }
}

export class DayModel extends Operand {
    timezone: string = 'US/Alaska';
    __daysSelected__: string = ''
    __validDaysSelected__: boolean = true;
    constructor(json?) {
        super(json)
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_BOOLEAN_CONDITION
        this.__conditionValueType__ = 'modal';
        if (!json) {
            return;
        }

        this.timezone = json['timezone'] ? json['timezone'] : this.timezone;
        this.__daysSelected__ = json['__daysSelected__'] ? json['__daysSelected__'] : '';
        this.value_type = VALUE_TYPES.DAY_TIMEZONE

        this.value = new Array<string>(7).fill('');

        const daysMap = {
            'Sun': 0,
            'Mon': 1,
            'Tue': 2,
            'Wed': 3,
            'Thu': 4,
            'Fri': 5,
            'Sat': 6
        };
        if (json['value']) {
            json['value'].forEach(day => {
                const index = daysMap[day];
                if (index !== undefined) {
                    this.value[index] = day;
                }
            });
        }
        if (!this.__daysSelected__ && this.value) {
            this.__daysSelected__ = this.value.filter(day => day !== '').join(', ');
        }
        this.__validDaysSelected__ = !!this.value.filter(day => day !== '').length;
    }
}

export class TotalUserScans extends Operand {
    __placeholder__: string;
    __textFieldType__: TEXT_FIELD_TYPES = TEXT_FIELD_TYPES.NUMBER;
    constructor(json?) {
        super(json)
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_NUMBER_CONDITION
        this.__conditionValueType__ = 'text';
        this.__placeholder__ = 'Enter number of scans';
        this.__hasMultipleValues__ = json.hasOwnProperty('__hasMultipleValues__') ? json['__hasMultipleValues__'] : this.__hasMultipleValues__;
        if (!json) {
            return;
        }
        this.value_type = VALUE_TYPES.NUMBER
    }
}

export class UniqueUsersScans extends Operand {
    __placeholder__: string;
    __textFieldType__: TEXT_FIELD_TYPES = TEXT_FIELD_TYPES.NUMBER;
    constructor(json?) {
        super(json)
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_NUMBER_CONDITION
        this.__conditionValueType__ = 'text';
        this.__placeholder__ = 'Enter number of scans';
        this.__hasMultipleValues__ = json.hasOwnProperty('__hasMultipleValues__') ? json['__hasMultipleValues__'] : this.__hasMultipleValues__;
        if (!json) {
            return;
        }
        this.value_type = VALUE_TYPES.NUMBER
    }
}

export class LocationModel extends Operand {
    range: number;
    __searchTerm__: string = '';
    __latLongSearch__: string = '';
    __markerOptions__ = {draggable: true};
    __center__: {lat: number, lng: number} = { lat: DEFAULT_MAP_LOCATION.LATITUDE, lng: DEFAULT_MAP_LOCATION.LONGITUDE };
    __latitude__: number;
    __longitude__: number;
    __isLatLongValid__: boolean = true;
    __latLongErrorMessage__: string = '';
    __rangeIsValid__: boolean = true;
    __rangeErrorMessage__: string = '';
    constructor(json?) {
        super(json);
        this.__conditionDropdown__ = CONDITION_DROPDOWN_TYPE.LOGIC_BOOLEAN_CONDITION
        this.__conditionValueType__ = 'modal';
        if (!json) {
            return;
        }
        this.range = json['range'] ? json['range'] : this.range;

        if (this.value) {
            this.__latitude__ = Number(this.value[0])
            this.__longitude__ = Number(this.value[1])
            if (this.value[0] && this.value[1]) {
                this.__latLongSearch__ = this.value[0].slice(0, 5) + ', ' + this.value[1].slice(0, 5)
            }
        }
        this.value_type = VALUE_TYPES.LAT_LONG
        this.__isLatLongValid__ = json.hasOwnProperty('__isLatLongValid__') ? json['__isLatLongValid__'] : this.__isLatLongValid__
        this.__latLongErrorMessage__ = json['__latLongErrorMessage__'] ? json['__latLongErrorMessage__'] : this.__latLongErrorMessage__
        this.__rangeIsValid__ = json.hasOwnProperty('__rangeIsValid__') ? json['__rangeIsValid__'] : this.__rangeIsValid__
        this.__rangeErrorMessage__ = json['__rangeErrorMessage__'] ? json['__rangeErrorMessage__'] : this.__rangeErrorMessage__
    }
}

