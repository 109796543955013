<info-action-popup [showModal]="showModal" (closePopupEmitter)="closePopupEmitter($event)" [popupId]="'my-cards-id'" [mediaUrl]="myCardsGif">
    <div class="d-flex flex-column">
        <div class="popup-heading">Share your cards on the go</div>
        <div class="popup-text">Go to <a [href]="redirectToUniqodeCards" target="_blank" (click)="handleLinkToPwaLandingPage($event)">mycards.uniqode.com</a> on your phone to quickly view and share your digital business cards.</div>
        <div class="qr-code-container d-flex flex-column align-items-center">
            <div class="qr-code">
                <div class="qr-text pt-3">SCAN THIS QR CODE</div>
                <img src="../../../assets/img/qrToUniqodeCards.svg" class="qr-img" alt="QR code that redirects to myCards">
            </div>
        </div>
        <div class="popup-bottom-text">Scan to get your cards on your phone</div>
    </div>
</info-action-popup>
