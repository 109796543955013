import {finalize, takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {AuthService} from '../global-services/auth.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {BehaviorSubject, Subject} from 'rxjs';
import {BEACONSTAC_HOSTS} from '../app.constants';
import {environment} from '../../environments/environment';
import {IntercomService} from '../global-services/intercom.service';
import {LoggedInGuard} from '../guards/logged-in.guard';
import {Utils} from '../shared/utils';
import { Router } from '@angular/router';


@Component({
    templateUrl: 'login.component.html'
})
export class LoginComponent implements AfterViewInit, OnDestroy {

    @ViewChild('passwordResetModal', {static: true}) passwordResetModal: ModalDirective;

    loginModel = {
        username: '',
        password: '',
        error: {
            message: '',
            timeout: null
        },
        inProgress: false
    };

    resetModel = {
        username: '',
        error: {
            message: '',
            timeout: null
        },
        inProgress: false
    };

    logoSource = new BehaviorSubject<string>('');
    logo$ = this.logoSource.asObservable();
    newUserAccess: boolean = false;
    queryParamSubscription = null;

    storeUrl: string = '';
    subscriptionErrorModel = {
        username: ''
    };

    auth2: any;
    ngUnsubscribe: Subject<any> = new Subject();

    isWhiteLabeledDashboard: boolean = false;

    constructor(private authService: AuthService, private router: Router, intercom: IntercomService) {
        intercom.bootAnonymousUser(false);
        this.setupLogo();

        this.isWhiteLabeledDashboard = Utils.isWhiteLabeledDashboard();

        this.storeUrl = environment.storeUrl;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            this.storeUrl = environment.buyQrUrl + '?forceLogout=true&next=' + encodeURI(environment.buyQrUrl);
        }
    }

    ngAfterViewInit(): void {
    }

    setupLogo() {
        const host = window.location.hostname.toLowerCase();
        if (BEACONSTAC_HOSTS.indexOf(host) !== -1) {
            this.newUserAccess = true;
            this.logoSource.next('assets/img/uniqode-header-logo.svg');
        } else {
            this.newUserAccess = false;
            this.logoSource.next('https://s3.amazonaws.com/beaconstac-cname-content/' + host);
        }
    }

    login(): void {
        this.loginModel.inProgress = true;
        this.clearError(this.loginModel.error);
        this.authService.login(this.loginModel.username, this.loginModel.password, false).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.loginModel.inProgress = false;
            }))
            .subscribe((successful: boolean): void => {
                this.onSuccessfulLogin(successful);
            }, error => {
                this.onUnsuccessfulLogin(error);
            });
    }

    resetPassword(): void {
        this.resetModel.inProgress = true;
        this.clearError(this.resetModel.error);
        this.authService.sendResetPasswordMail(this.resetModel.username).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.resetModel.inProgress = false;
            }))
            .subscribe((successful: boolean): void => {
                if (successful) {
                    this.setError(this.resetModel.error, 'Done, please check your email inbox for reset instructions', 5000);
                } else {
                    this.setError(this.resetModel.error, 'Failed to reset, please make sure your email ID is correct', 5000);
                }
            }, (error: any): void => {
                this.setError(this.resetModel.error, 'Failed to reset, please make sure your email ID is correct', 5000);
            });
    }

    private onSuccessfulLogin(successful) {
        if (successful) {
            // Check if login was triggered by a dashboard url and if so redirect to the requested url.
            if (LoggedInGuard.lastAccessedPage) {
                this.router.navigateByUrl(LoggedInGuard.lastAccessedPage);
            } else {
                this.router.navigateByUrl('/');
            }
        } else {
            this.setError(this.loginModel.error, 'Login failed, please try again', 5000);
        }
    }

    private onUnsuccessfulLogin(error) {
        this.setError(this.loginModel.error, error.message, 5000);
    }

    private setError(model: { message: string, timeout: any }, value: string, lifetime: number): void {
        this.clearError(model);
        model.message = value;
    }

    private clearError(model: { message: string, timeout: any }): void {
        clearTimeout(model.timeout);
        model.message = '';
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
