import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../global-services/auth.service';

@Injectable()
export class IntegrationAccessGuard implements  CanActivateChild {

    constructor(private router: Router, private authService: AuthService) {
    }


    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.authService.isSafeSuperUser()) {
            return true
        }

        const user = this.authService.getUser();

        if (user.isAdmin(this.authService.getCurrentOrgId())) {
            return true
        }
        this.router.navigate(['/overview/'], {queryParams: {orgID: this.authService.getCurrentOrgId()}})
        return false

    }
}
