export enum LINKPAGE_URL_TYPES {
    LINKS = 1,
    SEPARATOR = 2,
    SOCIAL_LINKS = 3,
    LANDING_PAGE = 4,
    FORM = 5,
    LINK_PAGE = 6,
    VIDEO = 7,
    TEXT = 8,
    GALLERY = 9,
    PDF = 10,
    EMAIL = 11,
    CALL = 12,
    SMS = 13,
    LOCATION = 14,
    BUSINESS_HOURS = 15,
}



export enum LINKPAGE_URL_TRACKING_EVENTS {
    SEPARATOR = 'separator',
    SOCIAL_LINKS = 'social-links',
    LANDING_PAGE = 'landing-page',
    FORM = 'form',
    LINK_PAGE = 'linkpage',
    VIDEO = 'embed-video',
    TEXT = 'text',
    GALLERY = 'image-gallery',
    PDF = 'upload-pdf',
    EMAIL = 'email',
    CALL = 'call',
    SMS = 'sms',
    LOCATION = 'location',
    BUSINESS_HOURS = 'business-hours',
}

export enum LINKPAGE_IMAGE_TYPES {
    IMAGE_GALLERY,
    LINKPAGE_THUMBNAIL,
    LINK_IMAGE,
    LINKPAGE_COVER_IMAGE,
    LINKPAGE_BACKGROUND,
    SOCIAL_LINK_CUSTOM_ICON,
}

export enum LINK_IMAGE_TYPES {
    NO_IMAGE = 1,
    ICON = 2,
    THUMBNAIL = 3,
}

export enum LINKPAGE_BUTTON_TYPES {
    SQUARE = 1,
    ROUNDED = 2,
}

export enum BACKGROUND_TYPE {
    SOLID_COLOR = 1,
    IMAGE_URL = 2,
    VIDEO = 3,
}

export const SOCIAL_MEDIA = [
    { name: 'Facebook', added: false, valid: true, placeholder: 'https://www.facebook.com/username' },
    { name: 'Instagram', added: false, valid: true, placeholder: 'https://www.instagram.com/username' },
    { name: 'Twitter', added: false, valid: true, placeholder: 'https://twitter.com/username' },
    { name: 'LinkedIn', added: false, valid: true, placeholder: 'https://www.linkedin.com/in/username' },
    { name: 'TikTok', added: false, valid: true, placeholder: 'https://www.tiktok.com/@username' },
    { name: 'SnapChat', added: false, valid: true, placeholder: 'https://snapchat.com/add/username' },
    { name: 'Whatsapp', added: false, valid: true, placeholder: '' },
    { name: 'Telegram', added: false, valid: true, placeholder: 'https://telegram.me/username' },
    { name: 'Vimeo', added: false, valid: true, placeholder: '' },
    { name: 'Youtube', added: false, valid: true, placeholder: '' },
    { name: 'Wistia', added: false, valid: true, placeholder: '' },
    { name: 'Twitch', added: false, valid: true, placeholder: 'https://twitch.tv/username' },
    { name: 'Discord', added: false, valid: true, placeholder: 'https://discord.gg/username' },
    { name: 'Pinterest', added: false, valid: true, placeholder: 'https://www.pinterest.com/username' },
    { name: 'Yelp', added: false, valid: true, placeholder: 'https://www.yelp.com/biz/username' },
    { name: 'Paypal', added: false, valid: true, placeholder: 'https://paypal.me/username' },
    { name: 'Venmo', added: false, valid: true, placeholder: 'https://venmo.com/username' },
    { name: 'CashApp', added: false, valid: true, placeholder: 'https://cash.app/$username' },
    { name: 'Calendly', added: false, valid: true, placeholder: '' },
    { name: 'Shopify', added: false, valid: true, placeholder: '' },
    { name: 'Github', added: false, valid: true, placeholder: 'https://github.com/username' },
    { name: 'Dribbble', added: false, valid: true, placeholder: 'https://dribbble.com/username' },
    { name: 'Behance', added: false, valid: true, placeholder: 'https://www.behance.net/username' },
    { name: 'Custom_Url', added: false, valid: true, placeholder: '' },
];

export const ADAVANCED_LINK_TYPES = [
    { name: 'Separator', value: LINKPAGE_URL_TYPES.SEPARATOR, hidden: false },
    { name: 'Linkpage', value: LINKPAGE_URL_TYPES.LINK_PAGE, hidden: false },
    { name: 'Landing page', value: LINKPAGE_URL_TYPES.LANDING_PAGE, hidden: false },
    { name: 'Form', value: LINKPAGE_URL_TYPES.FORM, hidden: false },
    { name: 'Social links', value: LINKPAGE_URL_TYPES.SOCIAL_LINKS, hidden: false },
    { name: 'Text', value: LINKPAGE_URL_TYPES.TEXT, hidden: false },
    { name: 'Embed Video', value: LINKPAGE_URL_TYPES.VIDEO, hidden: false },
    { name: 'Image Gallery', value: LINKPAGE_URL_TYPES.GALLERY, hidden: false },
    { name: 'Email', value: LINKPAGE_URL_TYPES.EMAIL, hidden: false },
    { name: 'Call', value: LINKPAGE_URL_TYPES.CALL, hidden: false },
    { name: 'SMS', value: LINKPAGE_URL_TYPES.SMS, hidden: false },
    { name: 'Upload PDF', value: LINKPAGE_URL_TYPES.PDF, hidden: false },
    { name: 'Location', value: LINKPAGE_URL_TYPES.LOCATION, hidden: false },
    { name: 'Business Hours', value: LINKPAGE_URL_TYPES.BUSINESS_HOURS, hidden: false },
];

export const DEFAULT_TEMPLATES = [
    'freelancer',
    'footwear-retail',
    'musician',
    'marketing-agency',
    'gym',
    'school',
    'conferences',
    'music-festival',
    'beauty',
    'restaurant',
    'hotel',

    'realtor',
    'doctor-profile',
    'apparel-retail',
    'small-business',
    'real-estate-agency',
    'restaurant-details',
    'social-media',
    'contact-us',
    'review-site',
    'social-media-II'
];

export enum TemplateTabs {
    FEATURED_TEMPLATE = 'featuredTemplate',
    MY_TEMPLATE = 'myTemplate',
}

export const isMobileDevice = window.innerWidth <= 768;
