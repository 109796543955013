<div class="app flex-row align-items-center">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-md-6">
        <img class="mx-auto d-block mb-5" [src]="logo$ | async" style="max-width: 200px;">
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-5">
        <div class="card-group mb-0">
          <div class="card p-4">
            <div class="card-block">
              <form #resetForm="ngForm" (ngSubmit)="submit()">
                <h4 class="mb-4 text-center">Sign up to join your team</h4>
                <p class="text-muted mb-4">Please set a password for
                  <span class="font-weight-bold" *ngIf="newChildUserModel.email"> {{newChildUserModel.email}}</span>
                </p>
                <div class="input-group">
                  <input type="text" class="form-control mb-4" placeholder="Enter your first name" name="first_name"
                         [(ngModel)]="newChildUserModel.first_name"  (keyup)="checkValidation()" maxlength="30">
                </div>
                <div class="input-group">
                  <input type="text" class="form-control mb-4" placeholder="Enter your last name" name="last_name"
                         [(ngModel)]="newChildUserModel.last_name"  (keyup)="checkValidation()" maxlength="30">
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Create new password" name="new_password1"
                         #pass1="ngModel" [(ngModel)]="newChildUserModel.password"  (keyup)="checkValidation()" >
                </div>

                <div *ngIf=newChildUserModel.password.length [@collapse]>
                  <div class="d-flex mt-2 text-secondary">Password must contain</div>
                  <div class="pb-4 ml-2">
                    <div class="bx--row mt-1">
                      <div class="bx--col">
                        <div [ngStyle]="{'color': newChildUserModel.validation.eightCharValidation ? '#979797' : '#FF445E'}">
                          <i style="font-size: 10px;" [ngStyle]="{'color': newChildUserModel.validation.eightCharValidation ? '#26B969' : '#FF445E'}"
                             class="fa-align-center fas fa-circle">
                          </i> <span style="font-size: 15px; padding-left: 7px">At least 8 characters</span>
                        </div>
                      </div>
                    </div>
                    <div class="bx--row mt-1">
                      <div class="bx--col">
                        <div [ngStyle]="{'color': newChildUserModel.validation.lowerCaseValidation ? '#979797' : '#FF445E'}">
                          <i style="font-size: 10px;" [ngStyle]="{'color': newChildUserModel.validation.lowerCaseValidation ? '#26B969' : '#FF445E'}"
                             class="fa-align-center fas fa-circle">
                          </i> <span style="font-size: 15px; padding-left: 7px">Lower case letters(a-z)</span>
                        </div>
                      </div>
                    </div>
                    <div class="bx--row mt-1">
                      <div class="bx--col">
                        <div [ngStyle]="{'color': newChildUserModel.validation.upperCaseValidation ? '#979797' : '#FF445E'}">
                          <i style="font-size: 10px;" [ngStyle]="{'color': newChildUserModel.validation.upperCaseValidation ? '#26B969' : '#FF445E'}"
                             class="fa-align-center fas fa-circle">
                          </i> <span style="font-size: 15px; padding-left: 7px">Upper case letters(A-Z)</span>
                        </div>
                      </div>
                    </div>
                    <div class="bx--row mt-1">
                      <div class="bx--col">
                        <div [ngStyle]="{'color': newChildUserModel.validation.numberValidation ? '#979797' : '#FF445E'}">
                          <i style="font-size: 10px;" [ngStyle]="{'color': newChildUserModel.validation.numberValidation ? '#26B969' : '#FF445E'}"
                             class="fa-align-center fas fa-circle">
                          </i> <span style="font-size: 15px; padding-left: 7px">Numbers (0-9)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="input-group mt-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-action-redo"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Confirm new password" name="new_password2"
                         #pass2="ngModel" [(ngModel)]="newChildUserModel.confirmPassword" >
                </div>
                <div *ngIf="newChildUserModel.password.length > 0 && newChildUserModel.confirmPassword.length> 0" class="align-danger-message mt-2">
                  <beaconstac-message class="align-message" [ngClass]="newChildUserModel.password == newChildUserModel.confirmPassword ? 'd-none': 'd-block'" type="danger" message="Passwords do not match"></beaconstac-message>
                </div>

                <p class="mb-4 mt-4">By signing up, I accept the Uniqode <a
                  href="https://www.uniqode.com/terms-of-service" target="_blank">Terms of service</a> and
                  acknowledge the <a href="https://www.uniqode.com/privacy" target="_blank">Privacy Policy</a></p>

                <div class="row">
                  <div class="col-12">
                    <button type="submit" class="btn btn-primary px-4 action-button-mobile w-100"
                            [disabled]="!newChildUserModel.passStrengthGood || newChildUserModel.password != newChildUserModel.confirmPassword || !newChildUserModel.first_name || !newChildUserModel.last_name">Sign up
                    </button>
                  </div>
                </div>
                <div class="alert alert-danger mt-2" *ngIf="newChildUserModel.error">{{ newChildUserModel.error }}</div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
