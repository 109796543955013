import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseBackendService, ListResult} from '../global-services/base-backend.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../global-services/auth.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Media} from './media.model';
import {MessageModalService} from '../shared/message-modal/message-modal.service';

@Injectable()
export class MediaService extends BaseBackendService<Media> {

    progressSource = new BehaviorSubject<number>(0);
    progress$ = this.progressSource.asObservable();

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, Media, 'media', messageModal);
    }

    deleteFile(id: number | string, asMasterOrg: boolean = false, folderId?: number): Observable<any> {
        const orgId = asMasterOrg ? this.authService.getOrganization().id : this.authService.getCurrentOrgId();
        let custom = '?organization=' + orgId;
        if (folderId) {
            custom += '&folder=' + folderId
        }
        return this.delete(id, custom);
    }

    uploadFile(file: File, type: string, typeformCompatible: boolean = false, asMasterOrg: boolean = false, folderId?: number): Observable<Media> {
        return new Observable<Media>(observer => {
            if (!file) {
                return observer.error({message: 'No file passed'});
            }

            const orgId = asMasterOrg ? this.authService.getOrganization().id : this.authService.getCurrentOrgId();
            const postBody = {organization: orgId, 'public': true, 'typeform_compatible': typeformCompatible};
            if (folderId) {
                postBody['folder'] = folderId
            }
            this.postToId(postBody, '?organization=' + orgId + '&content_type=' + type)
                .subscribe(mediaPostRes => {
                    if (type !== 'text') {
                        // Todo: Handle text if required
                    }

                    const formData: FormData = new FormData();
                    formData.append('key', mediaPostRes['key']);
                    formData.append('Policy', mediaPostRes['policy']);
                    formData.append('X-Amz-Signature', mediaPostRes['x-amz-signature']);
                    formData.append('X-Amz-Algorithm', mediaPostRes['x-amz-algorithm']);
                    formData.append('X-Amz-Date', mediaPostRes['x-amz-date']);
                    formData.append('X-Amz-Credential', mediaPostRes['x-amz-credential']);
                    formData.append('X-Amz-Security-Token', mediaPostRes['x-amz-security-token']);
                    formData.append('Content-Type', file.type);
                    formData.append('file', file);

                    const xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 204) {
                                let custom = '?organization=' + orgId;
                                if (folderId) {
                                    custom += '&folder=' + folderId
                                }
                                this.getDetail(mediaPostRes['id'], custom).subscribe((mediaGetRes: Media) => {
                                    mediaGetRes.name = file.name;
                                    mediaGetRes.status = 'Active';
                                    mediaGetRes.content_type = file.type;

                                    this.put(mediaGetRes, mediaGetRes.id, '?organization=' + orgId).subscribe(mediaPutRes => {
                                        observer.next(mediaGetRes);
                                        observer.complete();
                                    }, mediaPutError => {
                                        observer.error({message: 'PUT /media error', error: mediaPutError});
                                    });
                                }, mediaGetError => {
                                    observer.error({message: 'GET /media error', error: mediaGetError});
                                });
                            } else {
                                observer.error({message: 'POST ' + mediaPostRes['post_action_url'] + ' error', error: xhr.response});
                            }
                        }
                    };
                    xhr.upload.onprogress = (e) => {
                        if (e.lengthComputable) {
                            const progress = (e.loaded / file.size)
                            const percentComplete = progress * 100 < 100 ? Math.floor(progress * 100) : 100;
                            this.progressSource.next(percentComplete);
                        }
                    };
                    xhr.open('POST', mediaPostRes['post_action_url']);
                    xhr.send(formData);
                }, mediaPostErr => {
                    observer.error({message: 'POST /media error', error: mediaPostErr});
                });
        });
    }

    getSignedURL(file: File, type: string, typeformCompatible: boolean = false, asMasterOrg: boolean = false): Observable<any> {
        const orgId = asMasterOrg ? this.authService.getOrganization().id : this.authService.getCurrentOrgId();
        return this.postToId({organization: orgId, 'public': true, 'typeform_compatible': typeformCompatible},
            '?organization=' + orgId + '&content_type=' + type);
    }

    getImages(): Observable<ListResult<Media>> {
        const mediaUrl = this.apiURL + '?organization=' + this.authService.getCurrentOrgId();
        return this.http.get(mediaUrl, this.buildHeaders({})).pipe(
            map((response) => {
                const jsonResponse = response;
                return {
                    objects: jsonResponse['results'].map(result => {
                        return new this.model(result);
                    }),
                };
            }), catchError(this.handleError));
    }

    get FORALA_URL() {
        return this.apiURL + 'froala/' ;
    }

    transferByList(medias, folderId, asMasterOrg = false) {
        const orgId = asMasterOrg ? this.authService.getOrganization().id : this.authService.getCurrentOrgId();
        const body = {
            organization: orgId,
            folder: folderId,
            media_list: medias
        };
        return this.post(body, 'transfer/list/')
    }

    transferByFilter(filter, folderId, asMasterOrg = false) {
        const orgId = asMasterOrg ? this.authService.getOrganization().id : this.authService.getCurrentOrgId();
        const body = {
            organization: orgId,
            folder: folderId,
            media_filter: filter
        };
        return this.post(body, 'transfer/filter')
    }

    deleteByList(medias, asMasterOrg = false) {
        const orgId = asMasterOrg ? this.authService.getOrganization().id : this.authService.getCurrentOrgId();
        const body = {
            organization: orgId,
            media_list: medias
        };
        return this.post(body, '/delete/list')
    }

    deleteByFilter(filter, asMasterOrg = false) {
        const orgId = asMasterOrg ? this.authService.getOrganization().id : this.authService.getCurrentOrgId();
        const body = {
            organization: orgId,
            media_filter: filter
        };
        return this.post(body, 'delete/filter')
    }

}
