import { Injectable } from '@angular/core';
import {Observable , throwError as observableThrowError , BehaviorSubject} from 'rxjs';
import { map , catchError} from 'rxjs/operators';
import { BaseBackendService } from './base-backend.service';
import { AuthService } from './auth.service';
import { MessageModalService } from 'app/shared/message-modal/message-modal.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { CustomFont } from 'app/global-services/customfont';

export interface ListResult<E> {
    objects: E[];
    pageCount: number;
    totalCount: number;
    next: any;
    previous: any;
}

export interface SearchParams {
    [param: string]: string | number;
}

@Injectable({
    providedIn: 'root'
})
export class CustomFontsService extends BaseBackendService<CustomFont>{

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, CustomFont, 'customfonts', messageModal);
    }

    private shouldScrollToManageCustomFonts = new BehaviorSubject<boolean>(false);
    shouldScrollToManageCustomFonts$ = this.shouldScrollToManageCustomFonts.asObservable();

    setScrollToManageCustomFonts(shouldScroll: boolean) {
        this.shouldScrollToManageCustomFonts.next(shouldScroll);
    }

    fontFile: File;
    validate(file, custom?: string): Observable<any> {
        const formData = new FormData();
        this.fontFile = file;
        formData.append('file', file);
        formData.append('organization', this.authService.getCurrentOrgId().toString());
        let url = this.apiURL;
        if (custom) {
            url += `/${custom}`
        }

        url += '/' + '?organization=' + this.authService.getCurrentOrgId();

        const argsObject = {};
        argsObject['headers'] = new HttpHeaders({
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });

        return this.http.post(url, formData, argsObject).pipe(
            map((response) => {
                return response;
            }), catchError(this.handleError));
    }

    getFontFamilies(custom?: any): Observable<any> {
        let url = this.apiURL;
        if (custom) {
            url += '/' + custom;
        }
        return this.http.get(url, this.buildHeaders({}));
    }

    uploadFile(file: File, type: string, typeformCompatible: boolean = false, asMasterOrg: boolean = false, folderId?: number, result?: any): Observable<CustomFont> {
        return new Observable<CustomFont>(observer => {
            if (!file) {
                return observer.error({ message: 'No file passed' });
            }

            const orgId = asMasterOrg ? this.authService.getOrganization().id : this.authService.getCurrentOrgId();

            const queryParams = new HttpParams()
                .set('organization', orgId.toString())
                .set('content_type', 'font'); // Set public to true in query params

            if (folderId) {
                queryParams.append('folder', folderId.toString());
            }
            const urlWithParams = `?${queryParams.toString()}`;

            const postBody = {
                organization: orgId,
                public: true,
                content_type: 'font'
            };
            this.postToId(postBody, urlWithParams).subscribe(mediaPostRes => {
                const formData: FormData = new FormData();
                formData.append('key', mediaPostRes['key']);
                formData.append('Policy', mediaPostRes['policy']);
                formData.append('X-Amz-Signature', mediaPostRes['x-amz-signature']);
                formData.append('X-Amz-Algorithm', mediaPostRes['x-amz-algorithm']);
                formData.append('X-Amz-Date', mediaPostRes['x-amz-date']);
                formData.append('X-Amz-Credential', mediaPostRes['x-amz-credential']);
                formData.append('X-Amz-Security-Token', mediaPostRes['x-amz-security-token']);
                formData.append('Content-Type', file.type);
                formData.append('file', file);
                const xhr = new XMLHttpRequest();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 204) {
                            this.getDetail(mediaPostRes['id']).subscribe((mediaGetRes: CustomFont) => {
                                mediaGetRes.name = result.name;
                                mediaGetRes.status = 'Active';
                                mediaGetRes.content_type = file.type;
                                mediaGetRes.style = result.style;

                                mediaGetRes.family = result.family;
                                const id = mediaGetRes.id;
                                const params = `?organization=${this.authService.getCurrentOrgId()}`;
                                this.put(mediaGetRes, id , params ).subscribe(mediaPutRes => {
                                    observer.next(mediaPutRes);
                                    observer.complete();
                                }, mediaPutError => {
                                    observer.error({ message: 'PUT /media error', error: mediaPutError });
                                });
                            }, mediaGetError => {
                                observer.error({ message: 'GET /media error', error: mediaGetError });
                            });
                        } else {
                            observer.error({ message: 'POST ' + mediaPostRes['post_action_url'] + ' error', error: xhr.response });
                        }
                    }
                };

                xhr.open('POST', mediaPostRes['post_action_url']);
                xhr.send(formData);
            }, mediaPostErr => {
                observer.error({ message: 'POST /media error', error: mediaPostErr });
            });
        });
    }



    public delete(id: number | string, custom?: string): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null); // Changed to throwError
        }

        let url = this.apiURL; // Start with the base URL

        // Append the ID to the URL
        const queryParams = `?organization=${this.authService.getCurrentOrgId().toString()}`;
        url += id + '/';

        if (custom) {
            url += '/' + custom;
        }
        url += queryParams;

        // Make the DELETE request
        return this.http.delete(url, this.buildHeaders({})).pipe(
            map(response => {
                this.authService.setDataUpdate();
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            })
        );
    }









}


