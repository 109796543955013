import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {StripeCardComponent} from './stripe-card.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BeaconstacModule} from 'kaizen-design-system';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalModule,
        BeaconstacModule
    ],
    declarations: [
        StripeCardComponent,
    ],
    exports: [
        StripeCardComponent,
    ]
})

export class StripeCardModule {

}
