import {BaseModel} from './base.model';
import {BehaviorSubject} from 'rxjs';
import * as moment from 'moment';
import {FirebaseNotificationTypes} from '../shared/utils';

export class UserNotification extends BaseModel {

    private notificationSubject = new BehaviorSubject<boolean>(null);

    id: string;
    title: string;
    message: string;
    link: string;
    read: boolean;
    created: number;
    updated: number;
    // Is a dashboard variable
    _isInternalLink: boolean = false;
    is_popup: boolean;
    type: FirebaseNotificationTypes;
    messages: string[];
    bulk_csv_file_name: string;

    constructor(json: object, id: string) {
        super();
        this.id = id;
        this.title = json['title'];
        this.message = json['message'];
        this.link = json['link'] || '';
        this.read = json['read'];
        this.created = Number.parseInt(json['created']);
        this.updated = Number.parseInt(json['updated']);
        this._isInternalLink = this.link && this.link.indexOf(window.origin) !== -1
        this.is_popup = json.hasOwnProperty('is_popup') ? json['is_popup'] : this.is_popup;
        this.type = json['type'] || this.type;
        this.bulk_csv_file_name = json['bulk_csv_file_name'];
        this.messages = json['messages'];
    }

    onClick(event) {
        if (!this.link) {
            event.preventDefault();
        }
        this.updated = Number.parseInt(moment().format('x'));
        this.markAsRead();
    }

    get readAsObservable() {
        return this.notificationSubject.asObservable();
    }

    get readableCreatedDate() {
        return moment(this.created).format('LLLL')
    }

    markAsRead() {
        this.read = true;
        this.notificationSubject.next(true);
    }

    toObject() {
        return {
            id: this.id,
            title: this.title,
            message: this.message,
            link: this.link,
            read: this.read,
            updated: this.updated,
            created: this.created,
        }
    }


}
