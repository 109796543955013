import {Injectable} from '@angular/core';
import {BaseModel} from '../global-services/base.model';
import {BaseBackendService} from '../global-services/base-backend.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../global-services/auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';


export class FaviconModel extends BaseModel {
    url: string;

    constructor(json: any) {
        super();
        this.url = json['url'];
    }
}


@Injectable()
export class RecommendationService extends BaseBackendService<FaviconModel> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, FaviconModel, 'fetch-logo', messageModal);
    }

    fetchFavicon(url: string) {
        const apiUrl = `${this.apiURL}?website_url=${url}`;
        return this.http
            .get(apiUrl, this.buildHeaders({})).pipe(
                map(response => {
                    return response;
                }),
                catchError(error => {
                    return of(null);
                }));
    }
}
