import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ResponseAddonService {

    private visibleSource = new BehaviorSubject<boolean>(false);
    visible$: Observable<boolean> = this.visibleSource.pipe();

    constructor() { }

    show() {
        this.visibleSource.next(true);
    }

}

