export class PhoneSms {

    phone: number;
    body: string = '';

    constructor(json?) {
        if (!json) {
            return;
        }
        this.phone = json['phone'] ? json['phone'] : '';
        this.body = json['body'] ? json['body'] : '';
    }
}
