import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrokeRadioButtonComponent } from './stroke-radio-button.component';

@NgModule({

    imports: [
        CommonModule,
    ],
    declarations: [StrokeRadioButtonComponent],
    exports: [StrokeRadioButtonComponent]
})
export class StrokeRadioButtonModule { }
