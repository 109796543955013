import {environment} from '../../../environments/environment';

export enum SOCIAL_LINKS_VIEW_TYPE {
    ICON = 1,
    BUTTON = 2
}

export interface SOCIAL_MEDIA_ITEM {
    icon_url?: string,
    id?: string,
    isActive: boolean,
    custom_icon: boolean,
    button_name?: string,
    redirect_url?: string
}

export const SOCIAL_MEDIA_LIST: Array<SOCIAL_MEDIA_ITEM> = [
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/facebook-icon.png`, button_name: 'Facebook', redirect_url: 'https://www.facebook.com/username' , id: 'Facebook', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/instagram-icon.png`, button_name: 'Instagram', redirect_url: 'https://www.instagram.com/username', id: 'Instagram', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/twitter-icon.png`, button_name: 'Twitter', redirect_url: 'https://twitter.com/username', id: 'Twitter', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/linkedin-icon.png`, button_name: 'LinkedIn', redirect_url: 'https://www.linkedin.com/in/username', id: 'LinkedIn', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/tiktok-icon.png`, button_name: 'TikTok', redirect_url: 'https://www.tiktok.com/@username', id: 'TikTok', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/snapchat-icon.png`, button_name: 'SnapChat', redirect_url: 'https://snapchat.com/add/username', id: 'SnapChat', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/whatsapp-icon.png`, button_name: 'Whatsapp', redirect_url: '', id: 'Whatsapp', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/telegram-icon.png`, button_name: 'Telegram', redirect_url: 'https://telegram.me/username', id: 'Telegram', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/vimeo-icon.png`, button_name: 'Vimeo', redirect_url: '', id: 'Vimeo', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/youtube-icon.png`, button_name: 'Youtube', redirect_url: '', id: 'Youtube', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/wistia-icon.png`, button_name: 'Wistia', redirect_url: '', id: 'Wistia', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/twitch-icon.png`, button_name: 'Twitch', redirect_url: 'https://twitch.tv/username', id: 'Twitch', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/discord-icon.png`, button_name: 'Discord', redirect_url: 'https://discord.gg/username', id: 'Discord', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/pinterest-icon.png`, button_name: 'Pinterest', redirect_url: 'https://www.pinterest.com/username', id: 'Pinterest', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/yelp-icon.png`, button_name: 'Yelp', redirect_url: 'https://www.yelp.com/biz/username', id: 'Yelp', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/paypal-icon.png`, button_name: 'Paypal', redirect_url: 'https://paypal.me/username', id: 'Paypal', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/venmo-icon.png`, button_name: 'Venmo', redirect_url: 'https://venmo.com/username', id: 'Venmo', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/cashapp-icon.png`, button_name: 'CashApp', redirect_url: 'https://cash.app/$username', id: 'CashApp', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/calendly-icon.png`, button_name: 'Calendly', redirect_url: '', id: 'Calendly', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/shopify-icon.png`, button_name: 'Shopify', redirect_url: '', id: 'Shopify', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/github-icon.png`, button_name: 'Github', redirect_url: 'https://github.com/username', id: 'Github', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/dribbble-icon.png`, button_name: 'Dribbble', redirect_url: 'https://dribbble.com/username', id: 'Dribbble', isActive: false, custom_icon: false },
    { icon_url: `${environment.imagesEndpoint}linkpage/social_icons/behance-icon.png`, button_name: 'Behance', redirect_url: 'https://www.behance.net/username', id: 'Behance', isActive: false, custom_icon: false },
    { icon_url: '../../../../../assets/img/social-icons/plus-sign-icon.png', button_name: '', redirect_url: '', id: '', isActive: false, custom_icon: true }
];
