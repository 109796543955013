import {Injectable} from '@angular/core';
import {BaseBackendService} from './base-backend.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';

class IncentiveModel {
    id: string;
}

@Injectable()
export class IncentiveService extends BaseBackendService<IncentiveModel> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, IncentiveModel, 'users', messageModal);
    }

    /*
    This function is used to check if user can claim free incentive or not
    * */
    public checkIncentiveClaimValidity(custom?: string) {
        return super.getDetail(this.authService.getUser().id, custom);
    }
}
