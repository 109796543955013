import {first, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {DiscardChangesService} from '../global-services/discard-changes.service';
import {AuthService} from '../global-services/auth.service';

export interface DiscardChangesV2 {
    hasWriteAccess: boolean;
    hasUnsavedChanges: () => Observable<boolean>;
}

@Injectable()
export class DiscardChangesGuardV2 implements CanDeactivate<DiscardChangesV2> {

    private allowForceFullRedirection: boolean = false;
    constructor(private authService: AuthService, private discardChangesService: DiscardChangesService) {
        this.authService.allowRedirectForcefully.subscribe(res => {
            this.allowForceFullRedirection = res;
        })
    }

    canDeactivate(component: DiscardChangesV2, _, __, nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!component?.hasWriteAccess || !nextState || nextState.url === '/login' || nextState.url === '/account/upgrade') {
            return true;
        } else if ( this.allowForceFullRedirection ) {
            return true;
        }


        return component.hasUnsavedChanges().pipe(
            switchMap((hasUnsavedChanges) => {
                if (hasUnsavedChanges) {
                    this.discardChangesService.show(
                        'Leave this page?',
                        'Leaving will result in losing the recent changes you have made.',
                    );

                    return this.discardChangesService.confirmLeaveChangeSubject.pipe(first());
                } else {
                    return of(true);
                }
            })
        );
    }
}
