import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../global-services/auth.service';

@Injectable()
export class UserSessionGuard implements CanActivateChild, CanActivate {

    static currentPage: string = null;

    constructor(private authService: AuthService) {
    }

    canActivateChild(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        UserSessionGuard.currentPage = state.url;
        const user = this.authService.getUser();
        return !!user;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return false;
    }

}
