import {BaseModel} from './base.model';

export class TrackScan extends BaseModel {

    scans: number = 0;

    constructor(json: object) {
        super();
        if (!json) {
            return;
        }
        this.scans = json['qr_scan_count'] ? Number.parseInt(json['qr_scan_count']) : 0;
    }

}
