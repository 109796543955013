import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {AuthService} from '../global-services/auth.service';
import {map} from 'rxjs/operators';
import {Utils} from '../shared/utils';

@Injectable()
export class AdminLoginGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const unAuthenticated = this.authService.isAuthenticated$.pipe(map(isAuthenticated => {
            return !isAuthenticated
        }
        ));

        const loggedIn = this.authService.isLoggedIn$.pipe(
            map(isLoggedIn => !isLoggedIn)
        );

        const beaconstacAdminDomain = of(Utils.isWhiteLabeledDashboard())
        return combineLatest([unAuthenticated, loggedIn, beaconstacAdminDomain]).pipe(map((result) => {
            const combinedResult = result[0] && result[1] && result[2];
            if (!combinedResult) {
                this.router.navigateByUrl('/overview');
            }
            return combinedResult;
        }));

    }
}
