export class Email {

    email: string = '';
    subject: string = '';
    body: string = '';

    constructor(json?) {
        if (!json) {
            return;
        }
        this.email = json['email'] ? json['email'] : '';
        this.subject = json['subject'] ? json['subject'] : '';
        this.body = json['body'] ? json['body'] : '';
    }
}
