import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'pwa-nudge-component',
    templateUrl: './pwa-nudge-component.component.html',
    styleUrls: ['./pwa-nudge-component.component.scss']
})
export class PwaNudgeComponentComponent {

    @Output() triggerPopup: EventEmitter<boolean> = new EventEmitter<boolean>();

    onNudgeClick() {
        this.triggerPopup.emit(true);
    }

}
