import { Injectable } from '@angular/core';
import { BaseModel } from './base.model';
import { BaseBackendService } from './base-backend.service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { MessageModalService } from '../shared/message-modal/message-modal.service';

export class SearchItem extends BaseModel {
    id: number = null;
    name: string = null;
    slug: string = null;
    object_type: string = null;
    serial_number: string = null;
    labels: Array<string> = [];
    organization: number = null;
    created: number = null;
    updated: number = null;
    maintainer: string = null;
    maintainer_name: string = null;
    maintainer_email: string = null;
    notes: string | null = null;

    // Dashboard required variables
    link: string;
    icon: string;
    queryParams: any = {};
    additionalFields = [];
    sort: [] = null;

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'] || null;
        this.name = json['name'] || null;
        this.slug = json['slug'] || null;
        this.object_type = json['object_type'] || null;
        this.labels = json['labels'] || [];
        this.organization = json['organization'] || null;
        this.serial_number = json['serial_number'] || null;
        this.notes = json['qr_notes'] || null;
        this.created = json['created'] || null;
        this.updated = json['updated'] || null;
        this.maintainer = json['maintainer'] || null;
        this.maintainer_name = json['maintainer_name'] || null;
        this.maintainer_email = json['maintainer_email'] || null;
        this.sort = json['sort'] || null;
        this.configureSearchUIVariables();
    }

    private configureSearchUIVariables() {
        switch (this.object_type) {
            case 'qr_code':
                this.link = `/qr-codes/summary/${this.id}`;
                this.icon = 'fas fa-qrcode';
                if (this.labels.length) {
                    this.additionalFields.push({
                        icon: 'fas fa-tag',
                        text: this.labels.join(', '),
                    });
                }
                break;
            case 'landing_page':
                this.link = `/landing-pages/detail/${this.id}`;
                this.icon = 'fas fa-code';
                break;
            case 'linkpage':
                this.link = `/linkpage/${this.id}`;
                this.icon = 'fas fa-bars';
                break;
            case 'form':
                this.link = `/forms/${this.id}`;
                this.icon = 'fas fa-thumbs-up';
                break;
            case 'label':
                this.link = '/qr-codes';
                this.icon = 'fas fa-tag';
                this.queryParams = {
                    tags__name__in: this.name,
                };
                break;
        }
    }
}

export class HelpDoc extends BaseModel {
    id: number = null;
    title: string = null;
    url: string = null;

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'] || null;
        this.title = json['title'] || null;
        this.url = json['url'] || null;
    }
}

@Injectable()
export class SearchService extends BaseBackendService<SearchItem> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, SearchItem, 'search', messageModal);
    }
}

@Injectable()
export class HelpDocsService extends BaseBackendService<HelpDoc> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, HelpDoc, 'articles/search', messageModal);
    }
}
