import {Component, OnDestroy} from '@angular/core';
import {AuthService} from '../global-services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {BEACONSTAC_HOSTS} from '../app.constants';
import {takeUntil} from 'rxjs/operators';
import {Animations} from '../shared/beaconstac-animations';

@Component({
    templateUrl: 'invite.component.html',
    styleUrls: ['./invite.component.scss'],
    animations: [Animations.collapse]
})
export class InviteComponent implements OnDestroy{

    logoSource = new BehaviorSubject<string>('');
    logo$ = this.logoSource.asObservable();

    newChildUserModel = {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        confirmPassword: '',
        error: '',
        inProgress: false,
        validation: {
            eightCharValidation: false,
            lowerCaseValidation: false,
            upperCaseValidation: false,
            numberValidation: false,
        },
        passStrengthGood: false,
    };
    token = '';
    uidb36: '';
    source_cta = '';
    ngUnsubscribe: Subject<any> = new Subject();

    lowercaseRegex = /[a-z]+/;
    uppercaseRegex = /[A-Z]+/;
    numbersRegex = /[0-9]+/;

    constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {
        this.route.params.subscribe(params => {
            const token = params['token'] || '';
            this.uidb36 = token.split('-')[0] || '';
            this.token = token.substr(this.uidb36.length + 1) || '';
        });
        this.route.queryParams.subscribe(params => {
            const identity = params['identity'] || '';
            if (identity) {
                this.newChildUserModel.email = atob(identity);
            }
            this.source_cta = params['source'] || '';
        });
        this.setupLogo();
    }

    setupLogo() {
        const host = window.location.hostname.toLowerCase();
        if (BEACONSTAC_HOSTS.includes(host)) {
            this.logoSource.next('assets/img/uniqode-header-logo-dark.png');
        } else {
            this.logoSource.next('https://s3.amazonaws.com/beaconstac-cname-content/' + host);
        }
    }

    extractNextUrlWithFilters() {
        const parsedUrl = new URL(window.location.href);
        const searchParams = parsedUrl.searchParams;
        const filters = searchParams.get('filters');
        const next = searchParams?.get('next')?.split('?')[0] || '/overview';
        return { next, filters };
    }

    submit() {
        this.newChildUserModel.error = '';
        this.newChildUserModel.inProgress = true;
        this.authService.confirmChildInvitation(this.token, this.uidb36, this.source_cta, this.newChildUserModel.first_name, this.newChildUserModel.last_name, this.newChildUserModel.password, this.newChildUserModel.confirmPassword)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(successful => {
                if (successful) {
                    // Check if login was triggered by a dashboard url and if so redirect to the requested url.
                    setTimeout(() => {
                        const { next, filters } = this.extractNextUrlWithFilters();
                        this.router.navigate([next], { queryParams : { filters } });
                    }, 500);
                }
            }, error => {
                this.newChildUserModel.inProgress = false;
                this.newChildUserModel.error = 'Sign up failed, your sign up link may have expired. Please ask account admin to resend the invitation';
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    checkValidation() {
        const { password } = this.newChildUserModel ;
        this.newChildUserModel.validation.eightCharValidation = password.length > 7;
        this.newChildUserModel.validation.lowerCaseValidation = this.lowercaseRegex.test(password);
        this.newChildUserModel.validation.upperCaseValidation = this.uppercaseRegex.test(password);
        this.newChildUserModel.validation.numberValidation = this.numbersRegex.test(password);

        this.checkValidationCount()
    }

    checkValidationCount() {

        const {validation} = this.newChildUserModel;

        this.newChildUserModel.passStrengthGood = validation.eightCharValidation &&  validation.lowerCaseValidation && validation.upperCaseValidation && validation.numberValidation
    }
}
