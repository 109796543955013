<div class="nudge-container" (click)="onNudgeClick()" (keyup)="onNudgeClick()" (keydown)="onNudgeClick()">
    <i class="fas fa-mobile-alt fa-icon fa-3x pb-3"></i>
    <div class="nudge-text pb-3">
        Get your digital business cards on your phone for easy share access
    </div>

    <div class="nudge-link">
        Go Mobile
        <i class="fa fa-arrow-right fa-icon fa"></i>
    </div>
</div>
