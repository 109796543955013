import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeaconstacFileDropComponent} from './beaconstac-file-drop.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {AmplitudeService} from '../../global-services/amplitude.service';
import {BeaconstacModule} from 'kaizen-design-system';

@NgModule({
    imports: [
        CommonModule,
        NgxFileDropModule,
        BeaconstacModule
    ],
    declarations: [BeaconstacFileDropComponent],
    exports: [BeaconstacFileDropComponent],
    providers: [AmplitudeService
    ]
})
export class BeaconstacFileDropModule {
}
