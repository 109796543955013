import { defaultTemplateHtml } from 'app/preview-templates/layout3-template';
import { layout1TemplateHtml } from '../preview-templates/layout1-template';
import {layout2TemplateHtml} from '../preview-templates/layout2-template';
import {layout4TemplateHtml} from '../preview-templates/layout4-template';
import {layout6TemplateHtml} from '../preview-templates/layout6-template';
import {layout5TemplateHtml} from '../preview-templates/layout5-template';
import {typographyLayouts} from '../digital-business-card/templates-utils';
import {Typography} from './customfont';
import {
    getDefaultForButton,
} from '../shared/utils';
import {
    buildContactInfoList,
    default_contact_info_ordering
} from '../digital-business-card/digital-business-card.model';

/* Populating the fields for the old vcard users for typography */
function checkTypography(customizations) {

    if (customizations) {
        customizations.typography['personal_info']['google_font_style'] = 'Semi Bold';
        customizations.typography['personal_info']['google_font_size'] = 24;
        customizations.typography['personal_info']['google_font_colour'] = customizations.user_info_color;

        customizations.typography['company_details']['google_font_style'] = 'Regular';
        customizations.typography['company_details']['google_font_size'] = 16;
        customizations.typography['company_details']['google_font_colour'] = customizations.user_info_color;

        customizations.typography['contact_details']['google_font_style'] = 'Semi Bold';
        customizations.typography['contact_details']['google_font_size'] = 14;
        customizations.typography['contact_details']['google_font_colour'] = customizations.secondary_color;

        customizations.typography['bio']['google_font_style'] = 'Regular';
        customizations.typography['bio']['google_font_size'] = 14;
        customizations.typography['bio']['google_font_colour'] = customizations.secondary_color;

        customizations.typography['button'] = getDefaultForButton(customizations.layout)
    }

    return customizations;
}


export class VCardPlus {
    first_name: string = 'John';
    last_name: string = 'Doe' ;
    phone: {
        mobile: string,
        work: string,
        home: string
    } = {
            mobile: '',
            work: '',
            home: ''
        };
    email: string;
    website: string;
    address_line1: string;
    address_line2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    summary: string;
    logo_url: string;
    designation: string;
    company: string;
    address_url: string;
    social_links: {
        facebook: string,
        instagram: string,
        twitter: string,
        linkedin: string,
        tiktok: string,
        snapchat: string,
        whatsapp: string,
        telegram: string,
        vimeo: string,
        youtube: string,
        wistia: string,
        twitch: string,
        discord: string,
        pinterest: string,
        yelp: string,
        paypal: string,
        venmo: string,
        cashapp: string,
        calendly: string,
        shopify: string,
        github: string,
        dribbble: string,
        behance: string,
        custom_url: string
    } = {
            facebook: '',
            instagram: '',
            twitter: '',
            linkedin: '',
            tiktok: '',
            snapchat: '',
            whatsapp: '',
            telegram: '',
            vimeo: '',
            youtube: '',
            wistia: '',
            twitch: '',
            discord: '',
            pinterest: '',
            yelp: '',
            paypal: '',
            venmo: '',
            cashapp: '',
            calendly: '',
            shopify: '',
            github: '',
            dribbble: '',
            behance: '',
            custom_url: '',
        }
    customizations: {
        background_color: string;
        user_info_color: string;
        secondary_color: string
        font_style: string;
        title_font_size: number;
        button_color: string;
        icon_color: string;
        background: {
            type: string,
            value: string
        },
        typography: Typography
    } = {
            background_color: '#9b25ff', // Primary Color
            user_info_color: '#FFFFFF', // Profile Text Color
            secondary_color: '#000000', // Secondary Color
            font_style: 'Roboto',
            title_font_size: 30,
            button_color: '#9b25ff',
            icon_color: '#9b25ff',
            background: {
                type: 'color',
                value: '#FFFFFF'
            },
            typography: {
                font_type: 'google',
                font_family: 'Open Sans',
                personal_info: {
                    google_font_style: 'Regular',
                    google_font_colour: '#000000',
                    google_font_size: 24,
                },
                company_details: {
                    google_font_style: 'Regular',
                    google_font_colour: '#000000',
                    google_font_size: 16,
                },
                contact_details: {
                    google_font_style: 'Regular',
                    google_font_colour: '#000000',
                    google_font_size: 14,
                },
                button: {
                    google_font_style: 'Regular',
                    google_font_colour: '#FFFFFF',
                    google_font_size: 16,
                },
                bio : {
                    google_font_style: 'Regular',
                    google_font_colour: '#000000',
                    google_font_size: 16,
                }
            },
        };
    user_image_url: string;
    pronouns_v2: string;
    autodownload_v2: boolean;
    address_v2: string;
    phone_v2: Array<Object> = [];
    email_v2: Array<Object> = [];
    website_v2: Array<Object> = [];
    prefix: string;
    suffix: string;
    department: string;
    custom_fields: Array<Object> = [];
    layout: string = '3';
    jwt_update_token = '';
    contact_info_ordering = default_contact_info_ordering;
    __contact_info_list__ = buildContactInfoList({...this})

    constructor(json?) {
        if (!json) {
            return;
        }

        this.first_name = json['first_name'] ? json['first_name'] : '';
        this.last_name = json['last_name'] ? json['last_name'] : '';
        this.phone = json['phone'] ? json['phone'] : this.phone;
        this.email = json['email'] ? json['email'] : this.email;
        this.website = json['website'] ? json['website'] : this.website;
        this.address_line1 = json['address_line1'] ? json['address_line1'] : this.address_line1;
        this.address_line2 = json['address_line2'] ? json['address_line2'] : this.address_line2;
        this.city = json['city'] ? json['city'] : this.city;
        this.state = json['state'] ? json['state'] : this.state;
        this.country = json['country'] ? json['country'] : this.country;
        this.zip = json['zip'] ? json['zip'] : this.zip;
        this.summary = json['summary'] ? json['summary'] : '';
        this.logo_url = json['logo_url'] ? json['logo_url'] : this.logo_url;
        this.designation = json['designation'] ? json['designation'] : this.designation;
        this.company = json['company'] ? json['company'] : this.company;
        this.address_url = json['address_url'] ? json['address_url'] : this.address_url;
        this.customizations = json['customizations'] ? json['customizations'] : this.customizations;
        this.city = json['city'] ? json['city'] : this.city;
        this.user_image_url = json['user_image_url'] ? json['user_image_url'] : this.user_image_url;
        this.social_links = json['social_links'] ? json['social_links'] : this.social_links;
        this.pronouns_v2 = json['pronouns_v2'] ? json['pronouns_v2'] : this.pronouns_v2;
        this.autodownload_v2 = json['autodownload_v2'] ? json['autodownload_v2'] : this.autodownload_v2;
        this.address_v2 = json['address_v2'] ? json['address_v2'] : this.address_v2;
        this.phone_v2 = json['phone_v2'] ? json['phone_v2'] : this.phone_v2;
        this.email_v2 = json['email_v2'] ? json['email_v2'] : this.email_v2;
        this.website_v2 = json['website_v2'] ? json['website_v2'] : this.website_v2;
        this.custom_fields = json['custom_fields'] ? json['custom_fields'] : this.custom_fields;
        this.prefix = json['prefix'] ? json['prefix'] : this.prefix;
        this.suffix = json['suffix'] ? json['suffix'] : this.suffix;
        this.department = json['department'] ? json['department'] : this.department;
        this.layout = json['layout'] ? json['layout'] : this.layout;
        this.jwt_update_token = json['jwt_update_token'] ? json['jwt_update_token'] : '';
        this.contact_info_ordering = json['contact_info_ordering'] ? json['contact_info_ordering'] : default_contact_info_ordering;
        this.__contact_info_list__ = buildContactInfoList({...json})
    }

    // tslint:disable-next-line:member-ordering
    static getVcardPreviewHTML(vcard_plus, isMobile: boolean) {

        if (!vcard_plus.customizations?.button_color) {
            vcard_plus.customizations.button_color = vcard_plus.customizations.background_color
        }

        if (!vcard_plus.customizations?.icon_color) {
            vcard_plus.customizations.icon_color = vcard_plus.customizations.background_color
        }

        if (!vcard_plus.customizations?.background) {
            vcard_plus.customizations.background = {
                type: 'color',
                value: '#FFFFFF'
            }
        }

        if (!vcard_plus.customizations?.typography) {
            vcard_plus.customizations.typography = typographyLayouts[vcard_plus.layout] ? typographyLayouts[vcard_plus.layout] : typographyLayouts['1']
        }
        checkTypography(vcard_plus.customizations)

        vcard_plus.customizations.typography.font_family = vcard_plus.customizations.font_style;
        vcard_plus.customizations.typography.personal_info.google_font_size = vcard_plus.customizations.title_font_size;

        vcard_plus.contact_info_ordering = default_contact_info_ordering
        vcard_plus.__contact_info_list__ = buildContactInfoList({...vcard_plus})

        switch (vcard_plus.layout) {
            case 'individual':
            case '1':
                return layout1TemplateHtml(vcard_plus , isMobile);
            case 'employee':
            case '2':
                return layout2TemplateHtml(vcard_plus , isMobile);
            case '4':
                return layout4TemplateHtml(vcard_plus , isMobile);
            case '5':
                return layout5TemplateHtml(vcard_plus , isMobile);
            case '6':
                return layout6TemplateHtml(vcard_plus , isMobile);
            default:
                return defaultTemplateHtml(vcard_plus , isMobile);
        }
    }
}

