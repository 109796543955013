<div class="app flex-row align-items-center login-background">
  <div *ngIf="loginModel.inProgress" class="beaconstac-overlay" style="width: 100%">
    <spinner class="custom-spinner-overlay" style="" [size]="60" [tickness]="3">
    </spinner>
  </div>
  <div class="login-container">
    <div class="login-header">
      <img class="login-page-logo mx-4" [src]="logo$ | async">
    </div>

    <div class="row justify-content-center" style="min-height: 90vh;">
      <div class="d-md-down-none col-lg-6">
        <div class="login-form-container">
          <div class="container">
            <div class="row justify-content-center">
              <div class="login-content-container">
                <div class="mb-3">
                  <img src="../../assets/img/login-side-image.png">
                </div>
                <span class="login-page-welcome-text">Sign in to your account</span>
                <hr>
                <span class="login-page-welcome-subtext">To access your dashboard, please sign in with your email and password.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="login-form-container">
          <div class="container">
            <div class="row justify-content-center">
              <form #loginForm="ngForm" class="login-content-container" (ngSubmit)="login()">

                <div class="alert alert-warning" role="alert" *ngIf="loginModel.error.message">
                  <strong>{{ loginModel.error.message }}</strong>
                </div>
                <div class="login-page-text">Email</div>
                <div class="input-group mb-3">
                  <!--<span class="input-group-addon"><i class="icon-user"></i></span>-->
                  <input type="email" class="form-control login-input-box" placeholder="name@example.com"
                         name="username" id="username"
                         #username="ngModel" [(ngModel)]="loginModel.username" beaconstacLowerCase required email
                         minlength="6">
                </div>

                <div class="login-page-text">Password</div>
                <div class="input-group mb-4">
                  <!--<span class="input-group-addon"><i class="icon-lock"></i></span>-->
                  <input type="password" class="form-control login-input-box" placeholder="password" name="password"
                         #password="ngModel" [(ngModel)]="loginModel.password" required minlength="4">
                </div>

                <div class="row">
                  <div class="col-12">
                    <button type="submit" class="btn btn-primary px-4 login-button"
                            [disabled]="!loginForm.form.valid || loginModel.inProgress">Sign in
                    </button>
                  </div>
                  <div class="col-12 mt-1">
                    <button type="button" class="btn btn-link px-0" (click)="passwordResetModal.show()">Forgot password?
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #passwordResetModal="bs-modal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-info" role="document">
    <div class="modal-content">

      <form #resetForm="ngForm" (ngSubmit)="resetPassword()">

        <div class="modal-header">
          <h4 class="modal-title">Reset password</h4>
          <button type="button" class="close" (click)="passwordResetModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <p class="text-muted">Enter your account's email address to receive reset instructions</p>

          <div class="alert alert-warning" role="alert" *ngIf="resetModel.error.message">
            <strong>{{ resetModel.error.message }}</strong>
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span  class="input-group-text"><i class="icon-user"></i></span>
            </div>
            <input type="email" class="form-control" placeholder="Email address" name="username"
                   #username="ngModel" [(ngModel)]="resetModel.username" beaconstacLowerCase required email
                   minlength="6">
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="passwordResetModal.hide()">Close</button>
          <button type="submit" class="btn btn-primary" [disabled]="!resetForm.form.valid || resetModel.inProgress">
            Reset
          </button>
        </div>

      </form>

    </div>
  </div>
</div>
