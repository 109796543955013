import {Component, OnDestroy, ViewChild} from '@angular/core';
import {AuthService} from '../global-services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {BehaviorSubject, Subject} from 'rxjs';
import {BEACONSTAC_HOSTS} from '../app.constants';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: 'password-reset.component.html'
})
export class PasswordResetComponent implements OnDestroy{

    @ViewChild('successModal', {static: true}) successModal: ModalDirective;
    logoSource = new BehaviorSubject<string>('');
    logo$ = this.logoSource.asObservable();

    resetModel = {
        email: '',
        pass1: '',
        pass2: '',
        error: '',
        inProgress: false
    };
    token = '';
    uidb36: '';
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private authService: AuthService, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            const token = params['token'] || '';
            this.uidb36 = token.split('-')[0] || '';
            this.token = token.substr(this.uidb36.length + 1) || '';
        });
        this.route.queryParams.subscribe(params => {
            const identity = params['identity'] || '';
            if (identity) {
                this.resetModel.email = atob(identity);
            }
        });
        this.setupLogo();
    }

    setupLogo() {
        const host = window.location.hostname.toLowerCase();
        if (BEACONSTAC_HOSTS.includes(host)) {
            this.logoSource.next('assets/img/uniqode-header-logo.svg');
        } else {
            this.logoSource.next('https://s3.amazonaws.com/beaconstac-cname-content/' + host);
        }
    }

    submit() {
        this.resetModel.error = '';
        this.resetModel.inProgress = true;
        this.authService.resetPassword(this.token, this.uidb36, this.resetModel.pass1, this.resetModel.pass2)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(success => {
                this.successModal.show();
            }, error => {
                this.resetModel.inProgress = false;
                this.resetModel.error = 'Reset failed, your reset link may have expired';
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
