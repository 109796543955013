import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class HelpMenuDisplayService {

    private messageSource = new BehaviorSubject<string>(null);
    messageSource$ = this.messageSource.asObservable();

    constructor() {
    }

    show(url: string) {
        setTimeout(() => {
            this.messageSource.next(url);
        }, 0);
    }

}
