import {environment} from '../../environments/environment';
import {TERMS_OF_SERVICE_TEXT, Utils} from '../shared/utils';
import {DigitalBusinessCard} from '../digital-business-card/digital-business-card.model';
import {TYPOGRAPHY_CUSTOMIZATIONS} from '../digital-business-card/digital-business-card-utils';
import {AgreementLinksModal} from '../global-components/terms-service/terms-service.modal';

export function getInitials(cardData) {
    const {first_name: firstName, last_name: lastName } = cardData;
    const firstInitial = firstName ? firstName[0].toUpperCase() : '';
    const lastInitial = lastName ? lastName[0].toUpperCase() : '';

    return firstInitial + lastInitial;
}

function getGoogleTypographySpecifics(cardData: DigitalBusinessCard, attribute: string) {

    const typographyCustomization = cardData.customizations.typography;
    let googleFontSize = '16px';
    let googleFontColor = '#000000';
    let googleFontWeight = 200;
    let googleFontFamily = 'sans-serif'

    // console.log('typographyCustomization', typographyCustomization.company_details)

    if (typographyCustomization[attribute]?.google_font_size) {
        googleFontSize = `${typographyCustomization[attribute]?.google_font_size}px`;
    }

    if (typographyCustomization[attribute]?.google_font_colour) {
        googleFontColor = typographyCustomization[attribute]?.google_font_colour;
    }

    if (typographyCustomization[attribute]?.google_font_style) {
        googleFontWeight = Number(`${Utils.GOOGLE_FONT_MAPPING[typographyCustomization[attribute].google_font_style]}`)
    }

    if (typographyCustomization?.font_family) {
        googleFontFamily = typographyCustomization.font_family;
    }

    return `
    .${attribute} {
        font-family: ${googleFontFamily};
        font-size: ${googleFontSize};
        color: ${googleFontColor};
        font-weight: ${googleFontWeight};
    }
    `
}

function getCustomTypographySpecifics(card, attribute: TYPOGRAPHY_CUSTOMIZATIONS) {
    const typographyCustomization = card.customizations.typography;

    let customFontSize = '16px';
    let customFontColor = '#000000';
    let customFontStyle = 200;
    let customFontFamily = 'sans-serif';

    if (typographyCustomization[attribute]?.custom_font_size) {
        customFontSize = `${typographyCustomization[attribute]?.custom_font_size}px`;
    }

    if (typographyCustomization[attribute]?.custom_font_colour) {
        customFontColor = typographyCustomization[attribute]?.custom_font_colour;
    }

    if (typographyCustomization[attribute]?.custom_font_style) {
        customFontStyle = typographyCustomization[attribute]?.custom_font_style;
    }

    if (typographyCustomization?.font_family) {
        customFontFamily = typographyCustomization.font_family;
    }

    return `
        @font-face {
            font-family: '${customFontFamily} - ${customFontStyle}';
            src: url('${typographyCustomization[attribute].custom_font_url}') format('truetype');
        }
        .${attribute} {
            font-family: '${customFontFamily} - ${customFontStyle}', sans-serif;
            font-size: ${customFontSize};
            color : ${customFontColor};
      }`

}

export function setTypography(card: DigitalBusinessCard) {

    let typography = '';

    if (card.customizations.typography.font_type === 'custom') {

        typography += '<style>'
        typography += `${getCustomTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO)}`
        typography += `${getCustomTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS)}`;
        typography += `${getCustomTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS)}`;
        typography += `${getCustomTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON)}`;
        typography += `${getCustomTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.BIO)}`;
        typography += '</style>'

    } else {
        typography += `<link href="https://fonts.googleapis.com/css?family=${card.customizations.typography.font_family}:wght@100;300;400;500;600;700&display=swap" rel="stylesheet" />
                       <style>`

        typography += `${getGoogleTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO)}`;
        typography += `${getGoogleTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS)}`;
        typography += `${getGoogleTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS)}`;
        typography += `${getGoogleTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON)}`;
        typography += `${getGoogleTypographySpecifics(card, TYPOGRAPHY_CUSTOMIZATIONS.BIO)}`;
        typography += '</style>';

    }

    return typography;
}

export function getAddToContactIconColor(cardTypography): string {
    let addToContactIconColor = '#FFFFFF';
    if (cardTypography?.font_type === 'custom') {
        addToContactIconColor = cardTypography?.button?.custom_font_colour;
    }
    if (cardTypography?.font_type === 'google') {
        addToContactIconColor = cardTypography?.button?.google_font_colour;
    }
    return addToContactIconColor;
}

export function getDesignationFontSize(cardTypography): string {
    let designationFontsize = '16px';
    if (cardTypography?.font_type === 'custom') {
        designationFontsize = cardTypography?.company_details?.custom_font_size - 4 + 'px';
    }
    if (cardTypography?.font_type === 'google') {
        designationFontsize = cardTypography?.company_details?.google_font_size - 4 + 'px';
    }
    return designationFontsize;
}




export function getHeaderCode(card) {
    let headHtmlCode = `<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>{{ card.name }}</title>
   <link rel="stylesheet" href="${environment.multiLanguageDropdownCssEndpoint}styles.css?v=3.0" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Work+Sans">
    <script src="https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js"></script>`;

    if (card.logo_url) {
        headHtmlCode += `<link rel="icon" type="image/x-icon" href="${card.logo_url}" id="fav_icon"/>
    <link rel="shortcut icon" type="image/x-icon" href="${card.logo_url}"" id="shortcut_icon"/>`;
    } else {
        headHtmlCode += `<link rel="icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" id="fav_icon" />
                        <link rel="shortcut icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" />`;
    }
    headHtmlCode += `<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

            <style>
                  html {
                      -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none;  /* Firefox */
                    }
                    .main-container {
                      -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none;  /* Firefox */
                    }
                    html::-webkit-scrollbar {
                      width: 0;
                      height: 0;
                  }

                .secondary_data {
                    color: {{ card.secondary_color }};
                }

                .content {
                    font-family: '{{ card.font_style }}', sans-serif;
                }

                .email {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .hidden {
                    display: none;
                }
            </style>
        </head>`;

    return headHtmlCode
}

function isHexColorCode(hex) {
    return /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);
}

export function hexToRGBA(hex, opacity) {
    hex = hex.trim();
    if (!isHexColorCode(hex)) {
        return hex;
    }
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16)
    }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
}

export enum CONTACT_BUTTON_TYPES {
    ADD_TO_CONTACTS = 'Add to Contacts',
    EXCHANGE_CONTACTS = 'Exchange Contacts',
}

export function getContactButton(type: CONTACT_BUTTON_TYPES = CONTACT_BUTTON_TYPES.ADD_TO_CONTACTS, card) {

    switch (type) {
        case CONTACT_BUTTON_TYPES.ADD_TO_CONTACTS:
            return `
                <div class="btn flex-center" style="">
                <svg class="add-ctn-icon" width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_434_678)">
                    <path d="M10.6667 14V12.6667C10.6667 11.9594 10.3857 11.2811 9.88564 10.781C9.38554 10.281 8.70726 10 8.00002 10H3.33335C2.62611 10 1.94783 10.281 1.44774 10.781C0.947639 11.2811 0.666687 11.9594 0.666687 12.6667V14M13.3334 5.33333V9.33333M15.3334 7.33333H11.3334M8.33335 4.66667C8.33335 6.13943 7.13945 7.33333 5.66669 7.33333C4.19393 7.33333 3.00002 6.13943 3.00002 4.66667C3.00002 3.19391 4.19393 2 5.66669 2C7.13945 2 8.33335 3.19391 8.33335 4.66667Z" stroke="${getAddToContactIconColor(card.customizations.typography)}" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_434_678">
                    <rect width="16" height="16" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
                <a id="vcard-download" class="pl-4 btn-download button"
                   href="javascript:void(0)">
                     Add to Contacts
                 </a>
             </div>`
            break;
        default:
            return `
                <div class="btn-exchange" id="btn-exchange" type="submit">
                 <i id="exchange-contact-icon" class="fas fa-exchange-alt"></i>
                 <span class="button exchange-button">
                 Exchange Contacts
             </span>
         </div>
            `;
    }
}

function generateTOSString(links: Array<AgreementLinksModal>, dbc) {
    let tosString = TERMS_OF_SERVICE_TEXT
    const parts = [];

    if (links.length > 0 && links[0].label) {
        parts.push(`<a class="tos-anchor-links" href="${links[0]?.url}" target="_blank" rel="noopener noreferrer">${links[0].label}</a>`);
    }
    if (links.length > 1 && links[1].label) {
        parts.push(`<a class="tos-anchor-links" href="${links[1]?.url}" target="_blank" rel="noopener noreferrer">${links[1].label}</a>`);
    }
    if (links.length > 2 && links[2].label) {
        parts.push(`<a class="tos-anchor-links" href="${links[2]?.url}" target="_blank" rel="nofollow">${links[2].label}</a>`);
    }

    if (parts.length > 0) {
        tosString += ' Read ' + parts.join(', ').replace(/, ([^,]*)$/, ', and $1') + '.';
    }

    return tosString;
}

export function getLeadFormHtml(dbc: DigitalBusinessCard) {
    const { phone, designation, notes, company} = dbc?.lead_attribute || {};
    const showLeadForm = dbc.__show_lead_form_in_preview__;

    if (!showLeadForm) {
        return '';
    }

    const tosDoc = dbc.lead_user_agreement ? generateTOSString(dbc.lead_user_agreement_attribute?.user_agreement_urls, dbc) : '';


    return `
     <style>
                .drawer-modal {
                    display: flex;
                    flex-direction: column;
                    gap: 0.4rem;
                    padding: 24px;
                    position: fixed;
                    bottom: 0%;
                    z-index: 2147483004;
                    background-color: white;
                    border: 1px solid #ddd;
                    transition: transform 0.3s ease-in-out;
                    transform: translateY(100%);
                    font-family: 'Work Sans';

                    width: -webkit-fill-available;
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                    max-height: 85%;
                  }

                  .drawer-modal--open {
                    transform: translateY(0);
                    transition: transform 0.3s ease-in-out;
                    min-height: 100px;
                  }

                  .contact-drawer-modal--open {
                    transform: translateY(0);
                    transition: transform 0.7s ease-in-out;
                    min-height: 100px;
                  }

                  .drawer-overlay, .contact-drawer-overlay {
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgba(0, 0, 0, 0.5);
                    z-index: 2147483003;
                  }

                .modal-content-box {
                    background: white;
                    border-radius: 5px;
                    animation-name: modalopen;
                    animation-duration: 0.25s;
                    width: -webkit-fill-available;
                }

                .owner-info {
                    font-weight: 300;
                    font-size: 20px;
                    font-family: "Work Sans";
                    line-height: 28px;
                    color: #16212F;
                    margin-bottom: 24px;
                    display: flex;
                    flex-wrap: wrap;
                    white-space: nowrap;
                }

                .contact-headline {
                    font-weight: 300;
                    font-size: 20px;
                    font-family: "Work Sans";
                    line-height: 28px;
                    color: #16212F;
                    margin-bottom: 16px;
                    display: flex;
                    justify-content: space-between;
                }

                .owner-name {
                    font-style: italic;
                    font-weight: 600;
                }

                .lead-details {
                    background: #FAFAFA;
                    border: 1px solid #E4E5E7;
                    border-radius: 4px;
                    padding: 8px;
                    gap: 8px;
                    height: 40px;
                    font-size: 14px;
                    width: -webkit-fill-available;
                    font-family: "Work Sans";
                }

                .lead-details:focus {
                    outline: none !important;
                    border: 1px solid #2595FF;
                }

                #lead-first-name-input:focus {
                    outline: none !important;
                    border-right: 1px solid #2595FF !important;
                }

                #lead-last-name-input:focus {
                    outline: none !important;
                    border-left: 1px solid #2595FF !important;
                }

                #submit-lead-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    height: 24px;
                    width: -webkit-fill-available;
                    margin-bottom: 8px;
                    border: 0;
                    cursor: pointer;
                    background: #2595FF;
                    min-height: 40px;
                    font-family: 'Work Sans';

                }

                #submit-lead-btn:disabled {
                        cursor: not-allowed;
                }

                #lead-add-contact-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #2595FF;
                    background: none;
                    border: none;
                    cursor: pointer;
                    width: 100%;
                    margin-top: 8px;
                    font-family: 'Work Sans';
                }

                #create-now-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ffffff !important;
                    background: {{card.background_color}};
                    border: none;
                    cursor: pointer;
                    width: -webkit-fill-available;
                    font-size: 18px !important;
                    min-height: 40px;
                    border-radius: 8px;
                }

                .contact-btn-text {
                    color: #ffffff !important;
                }


                .btn-text {
                    color: #2595FF;
                    font-size: 15px;
                    padding-left: 4px;
                    text-decoration: none;
                }

                .submitLead {
                    color: #fff;
                    font-size: 15px;
                    font-weight: lighter;
                    padding-left: 12px;
                }

                .notes {
                    font-family: 'Work Sans', sans-serif;
                    height: 80px;
                    resize: none;
                    font-size: 15px;
                }

                .disclaimer {
                    width: 100%;
                    font-weight: 400;
                    font-family: "Open Sans";
                    font-size: 12px;
                    color: #979797;
                    margin-top: 16px;
                    text-align: center;
                }

                #lead-invalid-email {
                    display: none;
                    padding: 0;
                    padding-top: 8px;
                    margin: 0;
                    color: #FF445E;
                    font-size: 13px;
                }

                #lead-invalid-fname, #lead-invalid-lname {
                    display: none;
                    padding: 0;
                    padding-top: 8px;
                    margin: 0;
                    color: #FF445E;
                    font-size: 13px;
                }

                #lead-invalid-phone {
                    display: none;
                    padding: 0;
                    padding-top: 8px;
                    margin: 0;
                    color: #FF445E;
                    font-size: 13px;
                }
                .leads-drawer-container {
                    display: flex;
                    justify-content: center;
                }

                .button-seperator {
                    margin-left: 50%;
                    font-size: 13px;
                }

                .loader {
                    border: 3px solid #f3f3f3;
                    border-radius: 50%;
                    border-top: 3px solid #3498db;
                    width: 14px;
                    height: 14px;
                    -webkit-animation: spin 2s linear infinite;
                    animation: spin 2s linear infinite;
                    display: none;
                }

                .contact-info {
                    color:  #5E6980;
                    text-align: center;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.25px;
                    margin-bottom: 16px;
                }

                .confetti-image {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    margin-bottom: 16px;
                }

                .confetti-user-image {
                    width: 82px;
                    height: 82px;
                    border-radius: 50%;
                    object-fit: contain;
                }

                .confetti-check {
                    background: #26B969;
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    color: #FFFFFF;
                    position: absolute;
                    top: 75%;
                    left: 58%;
                    transform: translate(-50%, -50%);
                }

                .confetti-initials {
                    height: 82px;
                    width: 82px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 38.361px !important;
                    background: {{ card.background_color|hex_to_rgba }};
                }

                .tos-text {
                    color: #979797;
                    font-size: 12px;
                    font-style: normal;
                    font-family: "Open Sans";
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.33px;
                }

                .tos-anchor-links {
                    color: #979797;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.33px;
                    text-decoration-line: underline;
                }

                #ontact-name {
                    display: flex;
                    flex-wrap: wrap;
                }

                /* Safari */
                @-webkit-keyframes spin {
                      0% { -webkit-transform: rotate(0deg); }
                      100% { -webkit-transform: rotate(360deg); }
                }

                @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                }

                .form-container {
                    max-height: 150px;
                    overflow: scroll;
                    margin-bottom: 16px;
                    font-family: "Work Sans";
                    padding-right: 8px;
                    row-gap: 16px;
                    display: grid;
                }

                .name-grid {
                    display: flex;
                    flex-direction: row;
                }

                #lead-full-name {
                    border-radius: 5px;
                  }

                #lead-first-name-input {
                    border-right: none;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    width: 50%;
                }

                #lead-last-name-input {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    width: 50%;
                }

                .hidden {
                    display: none;
                }

                #submit-container {
                    margin-top: 16px;
                }

                #lead-exchange-contact-icon {
                    color: #ffffff;
                }

            </style>
        <div class="leads-drawer-container">
            <div
                    class="drawer-modal" id="drawer"
                    >
                    <div class="modal-content-box">
                        <div class="owner-info">
                            Exchange contacts with&thinsp;<br> <span class="owner-name">${dbc.first_name}</span>
                        </div>
                        <form id="lead-form-v2">
                            <div class="form-container">
                                <div>
                                    <div class="name-grid" id="lead-full-name">
                                        <input class="lead-details" type="text" name="lead-first-name-input" id="lead-first-name-input" placeholder="First name"
                                            required>
                                        <input class="lead-details" type="text" name="lead-last-name-input" id="lead-last-name-input" placeholder="Last name"
                                            required>
                                    </div>
                                    <p id="lead-invalid-fname">This is a required field</p>
                                </div>
                                <div>
                                    <input class="lead-details" type="email" name="lead-email-input" id="lead-email-input" placeholder="Email" required>
                                    <p id="lead-invalid-email">Please enter a valid email address</p>
                                </div>
                                <input class="lead-details" type="text" name="lead-company-input" id="lead-company-input" placeholder="Company" required>

                                <div id="lead-phone-container">
                                    <input class="lead-details" type="text" name="lead-phone-input" id="lead-phone-input" placeholder="Phone number">
                                    <p id="lead-invalid-phone">Please enter a valid phone number</p>
                                </div>

                                <input class="lead-details" type="text" name="lead-designation-input" id="lead-designation-input"
                                    placeholder="Designation">

                                <textarea class="lead-details notes" id="lead-notes-input" placeholder="Message"></textarea>

                            </div>
                            <div class="tos-text" id="tos-text">
                                ${tosDoc}
                            </div>
                            <div id="submit-container">
                                    <button id="submit-lead-btn" type="submit">
                                        <i id="lead-exchange-contact-icon" class="fas fa-exchange-alt"></i>
                                        <i id="loader" class="loader"></i>
                                        <span class="submitLead">
                                            Connect
                                        </span>
                                    </button>
                                </div>
                        </form>
                        <span class="button-seperator">or</span>
                        <button type="button" id="lead-add-contact-btn">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_434_678)">
                                <path d="M10.6667 14V12.6667C10.6667 11.9594 10.3857 11.2811 9.88564 10.781C9.38554 10.281 8.70726 10 8.00002 10H3.33335C2.62611 10 1.94783 10.281 1.44774 10.781C0.947639 11.2811 0.666687 11.9594 0.666687 12.6667V14M13.3334 5.33333V9.33333M15.3334 7.33333H11.3334M8.33335 4.66667C8.33335 6.13943 7.13945 7.33333 5.66669 7.33333C4.19393 7.33333 3.00002 6.13943 3.00002 4.66667C3.00002 3.19391 4.19393 2 5.66669 2C7.13945 2 8.33335 3.19391 8.33335 4.66667Z" stroke="#2595FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_434_678">
                                <rect width="16" height="16" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>

                            <a class="btn-text"  href="javascript:void(0)">
                                Add to Contacts
                            </a>
                        </button>
                        <div class="disclaimer">
                            Uniqode does not share or sell your data
                        </div>
                    </div>
                </div>
            <div class="" id="lead-overlay"></div>
        </div>

    <div>
 </div>

            <script>

                function setFormFields() {

                    if (!${phone}) {
                        document.getElementById('lead-phone-container').style.display = 'none';
                        document.getElementById('lead-phone-input').classList.add('hidden');
                    }
                    if (!${designation}) {
                        document.getElementById('lead-designation-input').classList.add('hidden');
                    }
                    if (!${notes}) {
                        document.getElementById('lead-notes-input').classList.add('hidden');
                    }

                    if (!${company}) {
                        document.getElementById('lead-company-input').classList.add('hidden');
                    }
                }

                setTimeout(()=> {
                    if (${showLeadForm}) {
                        document.getElementById('drawer').classList.add('drawer-modal--open');
                        document.getElementById('lead-overlay').classList.add('drawer-overlay');
                        setFormFields();
                    } else {
                        document.getElementById('drawer').classList.remove('drawer-modal--open');
                        document.getElementById('lead-overlay').classList.remove('drawer-overlay');
                    }
                }, 1000)


            </script>`
}

export function getStickyShareContactBtn(card) {
    let html = `<style>
        .fixed-btn-container {
            position: fixed;
            bottom: 0px;
            background: rgba(255, 255, 255, 0.80);
            box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
            backdrop-filter: blur(8px);
            width: -webkit-fill-available;
            padding: 12px 12px;
        }

        .btn, .btn-exchange {
            background-color: ${card.customizations.button_color};
            color: ${getAddToContactIconColor(card.customizations.typography)};
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(98, 62, 9, 0.16);
            width: 100%;
            padding: 16px 0px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-download {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            padding-left: 16px;
        }

        .exchange-button {
            padding-left: 20px;
        }

    </style>

    <div class="fixed-btn-container flex-center">`

    if (card.lead_collection) {
        html += getContactButton(CONTACT_BUTTON_TYPES.EXCHANGE_CONTACTS, card);
    } else {
        html += getContactButton(CONTACT_BUTTON_TYPES.ADD_TO_CONTACTS, card);
    }

    html += '</div>';

    return html;
}
