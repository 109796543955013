import { Component, Input, Output, EventEmitter, ElementRef, Renderer2 } from '@angular/core';

@Component({
    selector: 'beaconstac-mobile-drawer',
    templateUrl: './beaconstac-mobile-drawer.component.html',
    styleUrls: ['./beaconstac-mobile-drawer.component.scss'],
})
export class BeaconstacMobileDrawerComponent {
    @Input() isDrawerOpen: boolean;
    @Output() onOutsideClick: EventEmitter<any> = new EventEmitter();
    @Input() isFullScreen: boolean = false;

    private componentId: string;
    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.componentId = 'beaconstac-mobile-drawer-' + Math.random().toString(36).substr(2, 9);
    }

    ngOnChanges() {
        if (this.isDrawerOpen) {
            this.setOverflowHidden();
        } else {
            this.resetOverflow();
        }
    }

    private setOverflowHidden() {
        const appElement = this.el.nativeElement.closest('.app');
        if (appElement && !appElement.hasAttribute(`data-beaconstac-mobile-drawer-id="${this.componentId}"`)) {
            this.renderer.setStyle(appElement, 'overflow', 'hidden');
            appElement.setAttribute('data-beaconstac-mobile-drawer-id', this.componentId);
        }
    }

    private resetOverflow() {
        const appElement = this.el.nativeElement.closest('.app');
        if (appElement && appElement.hasAttribute(`data-beaconstac-mobile-drawer-id="${this.componentId}"`)) {
            this.renderer.removeStyle(appElement, 'overflow');
            appElement.removeAttribute('data-beaconstac-mobile-drawer-id');
        } else {
            this.forceResetOverflow();
        }
    }

    forceResetOverflow() {
        const appElement = this.el.nativeElement.closest('.app');
        this.renderer.removeStyle(appElement, 'overflow');
    }
}
