export class Attendance {

    capacity: number = 0;
    domain: string;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.capacity = json['capacity'];
        this.domain = json['domain'];
    }
}
