import {Injectable} from '@angular/core';

@Injectable()
export class LanguageService {

    allLanguagesNameCode: object = {
        'Abkhazian': 'ab',
        'Afar': 'aa',
        'Afrikaans': 'af',
        'Afrikaans (Namibia)': 'af-NA',
        'Afrikaans (South Africa)': 'af-ZA',
        'Akan': 'ak',
        'Akan (Ghana)': 'ak-GH',
        'Albanian': 'sq',
        'Albanian (Albania)': 'sq-AL',
        'Amharic': 'am',
        'Amharic (Ethiopia)': 'am-ET',
        'Arabic': 'ar',
        'Arabic (Algeria)': 'ar-DZ',
        'Arabic (Bahrain)': 'ar-BH',
        'Arabic (Egypt)': 'ar-EG',
        'Arabic (Iraq)': 'ar-IQ',
        'Arabic (Jordan)': 'ar-JO',
        'Arabic (Kuwait)': 'ar-KW',
        'Arabic (Lebanon)': 'ar-LB',
        'Arabic (Libya)': 'ar-LY',
        'Arabic (Morocco)': 'ar-MA',
        'Arabic (Oman)': 'ar-OM',
        'Arabic (Qatar)': 'ar-QA',
        'Arabic (Saudi Arabia)': 'ar-SA',
        'Arabic (Sudan)': 'ar-SD',
        'Arabic (Syria)': 'ar-SY',
        'Arabic (Tunisia)': 'ar-TN',
        'Arabic (United Arab Emirates)': 'ar-AE',
        'Arabic (Yemen)': 'ar-YE',
        'Armenian': 'hy',
        'Armenian (Armenia)': 'hy-AM',
        'Assamese': 'as',
        'Assamese (India)': 'as-IN',
        'Asu': 'asa',
        'Asu (Tanzania)': 'asa-TZ',
        'Aymara': 'ay',
        'Azerbaijani': 'az',
        'Azerbaijani (Cyrillic)': 'az-Cyrl',
        'Azerbaijani (Cyrillic, Azerbaijan)': 'az-Cyrl_AZ',
        'Azerbaijani (Latin)': 'az-Latn',
        'Azerbaijani (Latin, Azerbaijan)': 'az-Latn_AZ',
        'Bambara': 'bm',
        'Bambara (Mali)': 'bm-ML',
        'Bashkir': 'ba',
        'Basque': 'eu',
        'Basque (Spain)': 'eu-ES',
        'Belarusian': 'be',
        'Belarusian (Belarus)': 'be-BY',
        'Bemba': 'bem',
        'Bemba (Zambia)': 'bem-ZM',
        'Bena': 'bez',
        'Bena (Tanzania)': 'bez-TZ',
        'Bengali': 'bn',
        'Bengali (Bangladesh)': 'bn-BD',
        'Bengali (India)': 'bn-IN',
        'Bhutani': 'dz',
        'Bihari': 'bh',
        'Bislama': 'bi',
        'Bosnian': 'bs',
        'Bosnian (Bosnia and Herzegovina)': 'bs-BA',
        'Breton': 'br',
        'Bulgarian': 'bg',
        'Bulgarian (Bulgaria)': 'bg-BG',
        'Burmese': 'my',
        'Burmese (Myanmar [Burma])': 'my-MM',
        'Byelorussian': 'be',
        'Cambodian': 'km',
        'Cantonese (Traditional, Hong Kong SAR China)': 'yue-Hant_HK',
        'Catalan': 'ca',
        'Catalan (Spain)': 'ca-ES',
        'Central Morocco Tamazight': 'tzm',
        'Central Morocco Tamazight (Latin)': 'tzm-Latn',
        'Central Morocco Tamazight (Latin, Morocco)': 'tzm-Latn_MA',
        'Cherokee': 'chr',
        'Cherokee (United States)': 'chr-US',
        'Chiga': 'cgg',
        'Chiga (Uganda)': 'cgg-UG',
        'Chinese': 'zh',
        'Chinese (Simplified Han)': 'zh-Hans',
        'Chinese (Simplified Han, China)': 'zh-Hans_CN',
        'Chinese (Simplified Han, Hong Kong SAR China)': 'zh-Hans_HK',
        'Chinese (Simplified Han, Macau SAR China)': 'zh-Hans_MO',
        'Chinese (Simplified Han, Singapore)': 'zh-Hans_SG',
        'Chinese (Traditional Han)': 'zh-Hant',
        'Chinese (Traditional Han, Hong Kong SAR China)': 'zh-Hant_HK',
        'Chinese (Traditional Han, Macau SAR China)': 'zh-Hant_MO',
        'Chinese (Traditional Han, Taiwan)': 'zh-Hant_TW',
        'Cornish': 'kw',
        'Cornish (United Kingdom)': 'kw-GB',
        'Corsican': 'co',
        'Croatian': 'hr',
        'Croatian (Croatia)': 'hr-HR',
        'Czech': 'cs',
        'Czech (Czech Republic)': 'cs-CZ',
        'Danish': 'da',
        'Danish (Denmark)': 'da-DK',
        'Dutch': 'nl',
        'Dutch (Belgium)': 'nl-BE',
        'Dutch (Netherlands)': 'nl-NL',
        'Embu': 'ebu',
        'Embu (Kenya)': 'ebu-KE',
        'English': 'en',
        'English (American Samoa)': 'en-AS',
        'English (Australia)': 'en-AU',
        'English (Belgium)': 'en-BE',
        'English (Belize)': 'en-BZ',
        'English (Botswana)': 'en-BW',
        'English (Canada)': 'en-CA',
        'English (Guam)': 'en-GU',
        'English (Hong Kong SAR China)': 'en-HK',
        'English (India)': 'en-IN',
        'English (Ireland)': 'en-IE',
        'English (Israel)': 'en-IL',
        'English (Jamaica)': 'en-JM',
        'English (Malaysia)': 'en-MY',
        'English (Malta)': 'en-MT',
        'English (Marshall Islands)': 'en-MH',
        'English (Mauritius)': 'en-MU',
        'English (Namibia)': 'en-NA',
        'English (New Zealand)': 'en-NZ',
        'English (Northern Mariana Islands)': 'en-MP',
        'English (Pakistan)': 'en-PK',
        'English (Philippines)': 'en-PH',
        'English (Singapore)': 'en-SG',
        'English (South Africa)': 'en-ZA',
        'English (Trinidad and Tobago)': 'en-TT',
        'English (U.S. Minor Outlying Islands)': 'en-UM',
        'English (U.S. Virgin Islands)': 'en-VI',
        'English (United Kingdom)': 'en-GB',
        'English (United States)': 'en-US',
        'English (Zimbabwe)': 'en-ZW',
        'Esperanto': 'eo',
        'Estonian': 'et',
        'Estonian (Estonia)': 'et-EE',
        'Ewe': 'ee',
        'Ewe (Ghana)': 'ee-GH',
        'Ewe (Togo)': 'ee-TG',
        'Faeroese': 'fo',
        'Faroese': 'fo',
        'Faroese (Faroe Islands)': 'fo-FO',
        'Fiji': 'fj',
        'Filipino': 'fil',
        'Filipino (Philippines)': 'fil-PH',
        'Finnish': 'fi',
        'Finnish (Finland)': 'fi-FI',
        'French': 'fr',
        'French (Belgium)': 'fr-BE',
        'French (Benin)': 'fr-BJ',
        'French (Burkina Faso)': 'fr-BF',
        'French (Burundi)': 'fr-BI',
        'French (Cameroon)': 'fr-CM',
        'French (Canada)': 'fr-CA',
        'French (Central African Republic)': 'fr-CF',
        'French (Chad)': 'fr-TD',
        'French (Comoros)': 'fr-KM',
        'French (Congo - Brazzaville)': 'fr-CG',
        'French (Congo - Kinshasa)': 'fr-CD',
        'French (Côte d’Ivoire)': 'fr-CI',
        'French (Djibouti)': 'fr-DJ',
        'French (Equatorial Guinea)': 'fr-GQ',
        'French (France)': 'fr-FR',
        'French (Gabon)': 'fr-GA',
        'French (Guadeloupe)': 'fr-GP',
        'French (Guinea)': 'fr-GN',
        'French (Luxembourg)': 'fr-LU',
        'French (Madagascar)': 'fr-MG',
        'French (Mali)': 'fr-ML',
        'French (Martinique)': 'fr-MQ',
        'French (Monaco)': 'fr-MC',
        'French (Niger)': 'fr-NE',
        'French (Rwanda)': 'fr-RW',
        'French (Réunion)': 'fr-RE',
        'French (Saint Barthélemy)': 'fr-BL',
        'French (Saint Martin)': 'fr-MF',
        'French (Senegal)': 'fr-SN',
        'French (Switzerland)': 'fr-CH',
        'French (Togo)': 'fr-TG',
        'Frisian': 'fy',
        'Fulah': 'ff',
        'Fulah (Senegal)': 'ff-SN',
        'Galician': 'gl',
        'Galician (Spain)': 'gl-ES',
        'Ganda': 'lg',
        'Ganda (Uganda)': 'lg-UG',
        'Georgian': 'ka',
        'Georgian (Georgia)': 'ka-GE',
        'German': 'de',
        'German (Austria)': 'de-AT',
        'German (Belgium)': 'de-BE',
        'German (Germany)': 'de-DE',
        'German (Liechtenstein)': 'de-LI',
        'German (Luxembourg)': 'de-LU',
        'German (Switzerland)': 'de-CH',
        'Greek': 'el',
        'Greek (Cyprus)': 'el-CY',
        'Greek (Greece)': 'el-GR',
        'Greenlandic': 'kl',
        'Guarani': 'gn',
        'Gujarati': 'gu',
        'Gujarati (India)': 'gu-IN',
        'Gusii': 'guz',
        'Gusii (Kenya)': 'guz-KE',
        'Hausa': 'ha',
        'Hausa (Latin)': 'ha-Latn',
        'Hausa (Latin, Ghana)': 'ha-Latn_GH',
        'Hausa (Latin, Niger)': 'ha-Latn_NE',
        'Hausa (Latin, Nigeria)': 'ha-Latn_NG',
        'Hawaiian': 'haw',
        'Hawaiian (United States)': 'haw-US',
        'Hebrew': 'he',
        'Hebrew (Israel)': 'he-IL',
        'Hindi': 'hi',
        'Hindi (India)': 'hi-IN',
        'Hungarian': 'hu',
        'Hungarian (Hungary)': 'hu-HU',
        'Icelandic': 'is',
        'Icelandic (Iceland)': 'is-IS',
        'Igbo': 'ig',
        'Igbo (Nigeria)': 'ig-NG',
        'Indonesian': 'id',
        'Indonesian (Indonesia)': 'id-ID',
        'Interlingua': 'ia',
        'Interlingue': 'ie',
        'Inuktitut': 'iu',
        'Inupiak': 'ik',
        'Irish': 'ga',
        'Irish (Ireland)': 'ga-IE',
        'Italian': 'it',
        'Italian (Italy)': 'it-IT',
        'Italian (Switzerland)': 'it-CH',
        'Japanese': 'ja',
        'Japanese (Japan)': 'ja-JP',
        'Javanese': 'jw',
        'Kabuverdianu': 'kea',
        'Kabuverdianu (Cape Verde)': 'kea-CV',
        'Kabyle': 'kab',
        'Kabyle (Algeria)': 'kab-DZ',
        'Kalaallisut': 'kl',
        'Kalaallisut (Greenland)': 'kl-GL',
        'Kalenjin': 'kln',
        'Kalenjin (Kenya)': 'kln-KE',
        'Kamba': 'kam',
        'Kamba (Kenya)': 'kam-KE',
        'Kannada': 'kn',
        'Kannada (India)': 'kn-IN',
        'Kashmiri': 'ks',
        'Kazakh': 'kk',
        'Kazakh (Cyrillic)': 'kk-Cyrl',
        'Kazakh (Cyrillic, Kazakhstan)': 'kk-Cyrl_KZ',
        'Khmer': 'km',
        'Khmer (Cambodia)': 'km-KH',
        'Kikuyu': 'ki',
        'Kikuyu (Kenya)': 'ki-KE',
        'Kinyarwanda': 'rw',
        'Kinyarwanda (Rwanda)': 'rw-RW',
        'Kirghiz': 'ky',
        'Kirundi': 'rn',
        'Konkani': 'kok',
        'Konkani (India)': 'kok-IN',
        'Korean': 'ko',
        'Korean (South Korea)': 'ko-KR',
        'Koyra Chiini': 'khq',
        'Koyra Chiini (Mali)': 'khq-ML',
        'Koyraboro Senni': 'ses',
        'Koyraboro Senni (Mali)': 'ses-ML',
        'Kurdish': 'ku',
        'Langi': 'lag',
        'Langi (Tanzania)': 'lag-TZ',
        'Laothian': 'lo',
        'Latin': 'la',
        'Latvian': 'lv',
        'Latvian (Latvia)': 'lv-LV',
        'Lettish': 'lv',
        'Lingala': 'ln',
        'Lithuanian': 'lt',
        'Lithuanian (Lithuania)': 'lt-LT',
        'Luo': 'luo',
        'Luo (Kenya)': 'luo-KE',
        'Luyia': 'luy',
        'Luyia (Kenya)': 'luy-KE',
        'Macedonian': 'mk',
        'Macedonian (Macedonia)': 'mk-MK',
        'Machame': 'jmc',
        'Machame (Tanzania)': 'jmc-TZ',
        'Makonde': 'kde',
        'Makonde (Tanzania)': 'kde-TZ',
        'Malagasy': 'mg',
        'Malagasy (Madagascar)': 'mg-MG',
        'Malay': 'ms',
        'Malay (Brunei)': 'ms-BN',
        'Malay (Malaysia)': 'ms-MY',
        'Malayalam': 'ml',
        'Malayalam (India)': 'ml-IN',
        'Maltese': 'mt',
        'Maltese (Malta)': 'mt-MT',
        'Manx': 'gv',
        'Manx (United Kingdom)': 'gv-GB',
        'Maori': 'mi',
        'Marathi': 'mr',
        'Marathi (India)': 'mr-IN',
        'Masai': 'mas',
        'Masai (Kenya)': 'mas-KE',
        'Masai (Tanzania)': 'mas-TZ',
        'Meru': 'mer',
        'Meru (Kenya)': 'mer-KE',
        'Moldavian': 'mo',
        'Mongolian': 'mn',
        'Morisyen': 'mfe',
        'Morisyen (Mauritius)': 'mfe-MU',
        'Nama': 'naq',
        'Nama (Namibia)': 'naq-NA',
        'Nauru': 'na',
        'Nepali': 'ne',
        'Nepali (India)': 'ne-IN',
        'Nepali (Nepal)': 'ne-NP',
        'North Ndebele': 'nd',
        'North Ndebele (Zimbabwe)': 'nd-ZW',
        'Norwegian': 'no',
        'Norwegian Bokmål': 'nb',
        'Norwegian Bokmål (Norway)': 'nb-NO',
        'Norwegian Nynorsk': 'nn',
        'Norwegian Nynorsk (Norway)': 'nn-NO',
        'Nyankole': 'nyn',
        'Nyankole (Uganda)': 'nyn-UG',
        'Occitan': 'oc',
        'Oriya': 'or',
        'Oriya (India)': 'or-IN',
        'Oromo': 'om',
        'Oromo (Ethiopia)': 'om-ET',
        'Oromo (Kenya)': 'om-KE',
        'Pashto': 'ps',
        'Pashto (Afghanistan)': 'ps-AF',
        'Persian': 'fa',
        'Persian (Afghanistan)': 'fa-AF',
        'Persian (Iran)': 'fa-IR',
        'Polish': 'pl',
        'Polish (Poland)': 'pl-PL',
        'Portuguese': 'pt',
        'Portuguese (Brazil)': 'pt-BR',
        'Portuguese (Guinea-Bissau)': 'pt-GW',
        'Portuguese (Mozambique)': 'pt-MZ',
        'Portuguese (Portugal)': 'pt-PT',
        'Punjabi': 'pa',
        'Punjabi (Arabic)': 'pa-Arab',
        'Punjabi (Arabic, Pakistan)': 'pa-Arab_PK',
        'Punjabi (Gurmukhi)': 'pa-Guru',
        'Punjabi (Gurmukhi, India)': 'pa-Guru_IN',
        'Pushto': 'ps',
        'Quechua': 'qu',
        'Rhaeto': 'rm',
        'Romanian': 'ro',
        'Romanian (Moldova)': 'ro-MD',
        'Romanian (Romania)': 'ro-RO',
        'Romansh': 'rm',
        'Romansh (Switzerland)': 'rm-CH',
        'Rombo': 'rof',
        'Rombo (Tanzania)': 'rof-TZ',
        'Russian': 'ru',
        'Russian (Moldova)': 'ru-MD',
        'Russian (Russia)': 'ru-RU',
        'Russian (Ukraine)': 'ru-UA',
        'Rwa': 'rwk',
        'Rwa (Tanzania)': 'rwk-TZ',
        'Samburu': 'saq',
        'Samburu (Kenya)': 'saq-KE',
        'Samoan': 'sm',
        'Sango': 'sg',
        'Sango (Central African Republic)': 'sg-CF',
        'Sangro': 'sg',
        'Sanskrit': 'sa',
        'Scots': 'gd',
        'Sena': 'seh',
        'Sena (Mozambique)': 'seh-MZ',
        'Serbian': 'sr',
        'Serbian (Cyrillic)': 'sr-Cyrl',
        'Serbian (Cyrillic, Bosnia and Herzegovina)': 'sr-Cyrl_BA',
        'Serbian (Cyrillic, Montenegro)': 'sr-Cyrl_ME',
        'Serbian (Cyrillic, Serbia)': 'sr-Cyrl_RS',
        'Serbian (Latin)': 'sr-Latn',
        'Serbian (Latin, Bosnia and Herzegovina)': 'sr-Latn_BA',
        'Serbian (Latin, Montenegro)': 'sr-Latn_ME',
        'Serbian (Latin, Serbia)': 'sr-Latn_RS',
        'Serbo': 'sh',
        'Sesotho': 'st',
        'Setswana': 'tn',
        'Shona': 'sn',
        'Shona (Zimbabwe)': 'sn-ZW',
        'Sichuan Yi': 'ii',
        'Sichuan Yi (China)': 'ii-CN',
        'Sindhi': 'sd',
        'Singhalese': 'si',
        'Sinhala': 'si',
        'Sinhala (Sri Lanka)': 'si-LK',
        'Siswati': 'ss',
        'Slovak': 'sk',
        'Slovak (Slovakia)': 'sk-SK',
        'Slovenian': 'sl',
        'Slovenian (Slovenia)': 'sl-SI',
        'Soga': 'xog',
        'Soga (Uganda)': 'xog-UG',
        'Somali': 'so',
        'Somali (Djibouti)': 'so-DJ',
        'Somali (Ethiopia)': 'so-ET',
        'Somali (Kenya)': 'so-KE',
        'Somali (Somalia)': 'so-SO',
        'Spanish': 'es',
        'Spanish (Argentina)': 'es-AR',
        'Spanish (Bolivia)': 'es-BO',
        'Spanish (Chile)': 'es-CL',
        'Spanish (Colombia)': 'es-CO',
        'Spanish (Costa Rica)': 'es-CR',
        'Spanish (Dominican Republic)': 'es-DO',
        'Spanish (Ecuador)': 'es-EC',
        'Spanish (El Salvador)': 'es-SV',
        'Spanish (Equatorial Guinea)': 'es-GQ',
        'Spanish (Guatemala)': 'es-GT',
        'Spanish (Honduras)': 'es-HN',
        'Spanish (Latin America)': 'es-419',
        'Spanish (Mexico)': 'es-MX',
        'Spanish (Nicaragua)': 'es-NI',
        'Spanish (Panama)': 'es-PA',
        'Spanish (Paraguay)': 'es-PY',
        'Spanish (Peru)': 'es-PE',
        'Spanish (Puerto Rico)': 'es-PR',
        'Spanish (Spain)': 'es-ES',
        'Spanish (United States)': 'es-US',
        'Spanish (Uruguay)': 'es-UY',
        'Spanish (Venezuela)': 'es-VE',
        'Sudanese': 'su',
        'Swahili': 'sw',
        'Swahili (Kenya)': 'sw-KE',
        'Swahili (Tanzania)': 'sw-TZ',
        'Swedish': 'sv',
        'Swedish (Finland)': 'sv-FI',
        'Swedish (Sweden)': 'sv-SE',
        'Swiss German': 'gsw',
        'Swiss German (Switzerland)': 'gsw-CH',
        'Tachelhit': 'shi',
        'Tachelhit (Latin)': 'shi-Latn',
        'Tachelhit (Latin, Morocco)': 'shi-Latn_MA',
        'Tachelhit (Tifinagh)': 'shi-Tfng',
        'Tachelhit (Tifinagh, Morocco)': 'shi-Tfng_MA',
        'Tagalog': 'tl',
        'Taita': 'dav',
        'Taita (Kenya)': 'dav-KE',
        'Tajik': 'tg',
        'Tamil': 'ta',
        'Tamil (India)': 'ta-IN',
        'Tamil (Sri Lanka)': 'ta-LK',
        'Tatar': 'tt',
        'Telugu': 'te',
        'Telugu (India)': 'te-IN',
        'Teso': 'teo',
        'Teso (Kenya)': 'teo-KE',
        'Teso (Uganda)': 'teo-UG',
        'Thai': 'th',
        'Thai (Thailand)': 'th-TH',
        'Tibetan': 'bo',
        'Tibetan (China)': 'bo-CN',
        'Tibetan (India)': 'bo-IN',
        'Tigrinya': 'ti',
        'Tigrinya (Eritrea)': 'ti-ER',
        'Tigrinya (Ethiopia)': 'ti-ET',
        'Tonga': 'to',
        'Tonga (Tonga)': 'to-TO',
        'Tsonga': 'ts',
        'Turkish': 'tr',
        'Turkish (Turkey)': 'tr-TR',
        'Turkmen': 'tk',
        'Twi': 'tw',
        'Uigur': 'ug',
        'Ukrainian': 'uk',
        'Ukrainian (Ukraine)': 'uk-UA',
        'Urdu': 'ur',
        'Urdu (India)': 'ur-IN',
        'Urdu (Pakistan)': 'ur-PK',
        'Uzbek': 'uz',
        'Uzbek (Arabic)': 'uz-Arab',
        'Uzbek (Arabic, Afghanistan)': 'uz-Arab_AF',
        'Uzbek (Cyrillic)': 'uz-Cyrl',
        'Uzbek (Cyrillic, Uzbekistan)': 'uz-Cyrl_UZ',
        'Uzbek (Latin)': 'uz-Latn',
        'Uzbek (Latin, Uzbekistan)': 'uz-Latn_UZ',
        'Vietnamese': 'vi',
        'Vietnamese (Vietnam)': 'vi-VN',
        'Volapuk': 'vo',
        'Vunjo': 'vun',
        'Vunjo (Tanzania)': 'vun-TZ',
        'Welch': 'cy',
        'Welsh': 'cy',
        'Welsh (United Kingdom)': 'cy-GB',
        'Wolof': 'wo',
        'Xhosa': 'xh',
        'Yiddish': 'yi',
        'Yoruba': 'yo',
        'Yoruba (Nigeria)': 'yo-NG',
        'Zhuang': 'za',
        'Zulu': 'zu',
        'Zulu (South Africa)': 'zu-ZA'
    }


    constructor() {

    }

    getLanguageCode(language: string): string {
        return this.allLanguagesNameCode[language];
    }

    getAllLanguages(): Array<string> {
        const allLanguages = [];
        for (const language in this.allLanguagesNameCode) {
            allLanguages.push(language);
        }
        return allLanguages;
    }

    getLanguagesByCodes(codes: Array<string>): Array<string> {
        const languages = [];
        for (const langCode in this.allLanguagesNameCode) {
            for (const code of codes) {
                if (code === this.allLanguagesNameCode[langCode]) {
                    languages.push(langCode)
                }
            }
        }
        return languages;
    }

    getLanguageByCode(code: string): string {
        for (const langCode in this.allLanguagesNameCode) {
            if (code === this.allLanguagesNameCode[langCode]) {
                return langCode;
            }
        }
    }

}
