export class PhoneCall {

    phone: number;

    constructor(json?) {
        if (!json) {
            return;
        }
        this.phone = json['phone'];
    }
}
