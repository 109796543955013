export const BEACONSTAC_HOSTS: Array<any> = [
    'dashboard.beaconstac.com',
    'dashboard.uniqode.com',
    'bac-prod.firebaseapp.com',
    'bac-qa.firebaseapp.com',
    'dashboardqa.beaconstac.com',
    'localhost',
    'feature'
];

export const BEACONSTAC_ADMIN = 'admin.uniqode.com';
