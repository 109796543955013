import { Injectable } from '@angular/core';
import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DiscardChangesGuardV2, DiscardChangesV2 } from './discard-changes-v2.guard';

@Injectable()
export class ConditionalDiscardChangesGuardV2 implements CanDeactivate<DiscardChangesV2> {

    private excludedRoutes: string[] = [
        '/linkpage/templates',
        '/linkpage/add',
        '/forms/add'
    ];

    constructor(
        private discardChangesGuard: DiscardChangesGuardV2 // Inject the existing guard
    ) {}

    canDeactivate(
        component: DiscardChangesV2,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean> | boolean {

        const trialFirst = this.getTrialFirstParam(currentRoute);
        const isExcludedRoute = this.isExcludedRoute(nextState?.url);

        if (trialFirst && isExcludedRoute) {
            return true;
        }

        return this.discardChangesGuard.canDeactivate(component, currentRoute, currentState, nextState);
    }

    private getTrialFirstParam(routeSnapshot: ActivatedRouteSnapshot): boolean {
        let trialFirst = false;
        if (routeSnapshot.queryParams && routeSnapshot.queryParams['trial_first'] === 'true') {
            trialFirst = true;
        }
        return trialFirst;
    }
    private isExcludedRoute(url?: string): boolean {
        if (!url) {
            return false;
        }
        return this.excludedRoutes.some(excludedRoute => url.startsWith(excludedRoute));
    }
}
