import {
    getAddToContactIconColor,
    getHeaderCode,
    getLeadFormHtml,
    getStickyShareContactBtn,
    hexToRGBA,
    setTypography
} from './templateUtils';
import {getCustomColoredSocialIconSvg, getInvertedColor} from '../shared/utils';
import {socialIconsFontClasses, socialMediaNamesArray} from '../shared/social-media';
import {LanguageService} from '../global-components/notification-language/language.service';
import {CONTACT_DETAIL} from '../digital-business-card/digital-business-card-utils';


function populateDropdown(card, multiLangList, currentLanguage, color?: string) {
    let dropDownHTML = `<div class="language-dropdown" style="background-color: ${color}"><div class="beaconstac-dbc-language-dropdown-container" id="language-dropdown-container">
    <select class="beaconstac-dbc-language-dropdown"  name="language-dropdown" id="language-selector-1" autocomplete="off" disabled>`

    const languageService = new LanguageService();
    const languages = languageService.getLanguagesByCodes(multiLangList);
    const currentLanguageName = languageService.getLanguageByCode(currentLanguage);
    languages.forEach((language) => {
        const isSelected = language === currentLanguageName ? 'selected' : '';

        dropDownHTML += `<option value="${language}" ${isSelected}>${language}</option>`;
    });
    dropDownHTML += `let multiLangDropDownHTML;
    if (isMultiLanguageContent) {
        multiLangDropDownHTML = populateDropdown(card, multiLangList, currentLanguage, card.customizations.background_color);
    } else {
        multiLangDropDownHTML = '';
    }</select>
    <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M6.41843 8.87328L1.92793 4.42286C1.71136 4.20822 1.71136 3.86023 1.92793 3.64562L2.45168 3.12655C2.66788 2.91228 3.01828 2.91187 3.23499 3.12563L6.81056 6.65267L10.3861 3.12563C10.6028 2.91187 10.9532 2.91228 11.1694 3.12655L11.6932 3.64562C11.9097 3.86026 11.9097 4.20824 11.6932 4.42286L7.20269 8.87328C6.98612 9.0879 6.635 9.0879 6.41843 8.87328Z" fill="white"/>
    </svg>
</div></div>`;
    return dropDownHTML;
}

function buildSocialIcons(card) {
    const iconColor = getInvertedColor(card.customizations.icon_color);

    let html = '<div class="social-icons">';

    for (const name of socialMediaNamesArray) {
        if (!card.social_links[name]) {
            continue;
        }

        if (socialIconsFontClasses[name]) {
            html += `<div class="svg-icon-container" style="background-color: ${card.customizations.icon_color}"><i class="fab ${socialIconsFontClasses[name]}" style="color: ${iconColor}"></i></div>`;
        } else {
            const svgIconCode = getCustomColoredSocialIconSvg(name, card.customizations.icon_color, iconColor);
            html += `<div class="svg-icon-container" style="background-color: ${card.customizations.icon_color}">`;
            html += svgIconCode;
            html += '</div>';

        }
    }
    html += '</div>';

    return html;
}

function buildStyle(card) {
    return `
    <style>
        body {
                font-family: "Open Sans", sans-serif;
                margin: 0px;
            }

        @media only screen and (device-width: 500px) {
            .mobile-layout {
                    max-width: 100% !important;
                    width: 100%;
                }
            }


        .layout {
            max-width: 450px;
            padding: 1rem 0.5rem 1rem 0.5rem;
            zoom: 0.75;
            min-height: 100%;
        }


        .logo-container {
                display: flex;
                justify-content: center;
                margin-bottom: 16px;
            }


        .info-container {
                padding: 1rem;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                gap: 48px;
                margin-left: .5rem;
                margin-right: .5rem;
            }

        .info {
                display: flex;
                flex-direction: column;
            }

        .name {
                align-self: stretch;
                font-weight: 600;
                word-wrap: break-word;
            }

        .pronouns {
                font-weight: 300;
                word-wrap: break-word;
                margin-top: 4px;

                opacity: 0.8; /** This is required because in dashboard preview frame font-weights are not so effective visually **/
            }

        .company-info {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                gap: 4px;
                display: inline-flex;
                width: 100%;
                right: 1rem;
                bottom: 1rem;
            }

        .designation, .company {
                align-self: stretch;
                text-align: right;
                font-weight: 600;
                word-wrap: break-word;
            }

        .department {
                align-self: stretch;
                text-align: right;
                font-weight: 300;
                word-wrap: break-word;

                opacity: 0.8; /** This is required because in dashboard preview frame font-weights are not so effective visually **/
            }

        .summary-container {
                display: flex;
                justify-content: center;
                margin: 1rem .5rem;

            .summary {
                    text-align: center;
                    font-style: italic;
                    font-weight: 400;
                    word-wrap: break-word;
                    padding-top: 0px !important;
                }
            }

        .summary {
            span {
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
            }

        .contact-container {
            background: rgba(21.75, 112.5, 248.63, 0.1);
            display: flex;
            flex-direction: column;

            margin-left: -9px;
            margin-right: -9px;
            margin-top: 28px;
            margin-bottom: 56px;
        }

        .contact-box {
                display: flex;
                padding: 1.125rem;

            .contact-field-detail {
                max-width: 85%;
                overflow: hidden;

                .label {
                    opacity: 0.5;
                    font-style: normal;
                    line-height: normal;
                }

                .value {
                    opacity: 0.8;
                    line-height: normal;
                    margin-top: 0.25rem;
                    word-wrap: break-word;
                }

                }
            }

        .social-icons {
            display: grid;
            column-gap: 1rem;
            row-gap: 1rem;
            margin-top: 24px;
            margin-bottom: 24px;
            max-width: 100%;
            grid-template-columns: repeat(auto-fit, minmax(40px, max-content));
            justify-content: center;
            align-items: center;
        }

        .svg-icon-container {
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .social-icons:empty {
            display: none;
        }

        .svg-container {
            margin-right: 16px;
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .share-contact-btn {
            display: flex;
            height: 40px;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 100px;
            background: transparent;
            margin-top: 24px;
            cursor: pointer;
            margin-left: 8px;
            margin-right: 8px;
        }

        .floating-add-to-contact {
            position: fixed;
            bottom: 42px;
            right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            height: 42px;
            width: 42px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }

        .language-dropdown {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: row-reverse;
        }

        .beaconstac-dbc-language-dropdown-container {
            top: 12px;
            right: 14px;
            display: flex;
            align-items: center;
            padding-right: 2px;

            select {
                font-size: 10px;
                padding-right: 32px;
            }

            svg {
                position: relative;
                top: 0px;
            }
        }

        .fixed-btn-container {
            left: 0;
        };
    </style>`;
}

function buildLogoContainer(card) {
    if (!card.logo_url) {
        return '';
    }
    return `<div class="logo-container">
              <img src="${card.logo_url}" style= "height: auto; width: ${card.logo_size}%;" />
            </div>`
}

function buildInfoContainer(card) {
    let html = `
        <div class="info-container" style="background: ${card.customizations.background_color}">
            <div class="info">
                <span class="name personal_info">
                    ${ card.prefix ? card.prefix : '' } ${ card.first_name } ${ card.last_name ? card.last_name : ''} ${ card.suffix ? card.suffix : '' }
                </span>
                <span class="pronouns personal_info" >${card.pronouns_v2 ? `(${card.pronouns_v2})` : ''}</span>
            </div>`;

    html += '<div class="company-info">';

    if (card.designation) {
        html += `<div class="designation company_details" >
              ${card.designation}
            </div>`
    }

    if (card.company) {
        html += `<div class="company company_details" >
              ${card.company}
            </div>`
    }

    if (card.department) {
        html += `<div class="department company_details" >
              ${card.department}
            </div>`
    }

    html += `</div>
                </div>`;

    return html
}

function buildSummaryContainer(card) {

    if (!card.summary) {
        return '';
    }
    return `<div class="summary-container">
            <div class="summary bio" style="white-space: pre-line ; word-break: break-word; "">
                ${card.summary}
             </div>
        </div>`
}

function buildContactContainer(card) {
    // Convert the hex color to rgba and reduce the opacity
    const backgroundColor = card.customizations.background_color;
    const rgbaColorBackgroundColor = `rgba(${parseInt(backgroundColor.slice(-6, -4), 16)}, ${parseInt(backgroundColor.slice(-4, -2), 16)}, ${parseInt(backgroundColor.slice(-2), 16)}, 0.1)`;

    let htmlCode = `<div class="contact-container" style="background-color: ${rgbaColorBackgroundColor}">`

    card.__contact_info_list__.forEach((contact) => {
        if (contact.value.length > 0) {
            switch (contact.key) {
                case CONTACT_DETAIL.PHONE_V2:
                    contact.value.forEach(phone => {
                        if (phone.value.trim() !== '') {
                            htmlCode += `
                                        <div class="contact-box">
                                            <div style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; " class="svg-container d-flex flex-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                    <path d="M15.6007 11.8928L12.5382 10.5803C12.4074 10.5245 12.262 10.5128 12.1239 10.5468C11.9858 10.5808 11.8625 10.6588 11.7726 10.769L10.4163 12.426C8.28773 11.4224 6.57474 9.70945 5.57116 7.58091L7.22822 6.22463C7.3386 6.13485 7.41673 6.01156 7.45077 5.87341C7.48481 5.73526 7.47292 5.58978 7.41689 5.45899L6.10437 2.39644C6.04288 2.25546 5.93411 2.14035 5.79684 2.07096C5.65957 2.00158 5.50239 1.98226 5.3524 2.01636L2.5086 2.67262C2.364 2.70601 2.23498 2.78743 2.14261 2.90359C2.05024 3.01975 1.99997 3.16379 2 3.3122C2 10.326 7.68486 15.9999 14.6877 15.9999C14.8362 16 14.9803 15.9498 15.0965 15.8574C15.2127 15.765 15.2942 15.636 15.3276 15.4913L15.9838 12.6475C16.0177 12.4968 15.998 12.339 15.9281 12.2012C15.8581 12.0635 15.7424 11.9544 15.6007 11.8928Z" fill="${card.customizations.icon_color}"/>
                                                </svg>
                                              </div>
                                            <div class="contact-field-detail contact_details">
                                                <div class="label">
                                                    ${phone.label}
                                                </div>
                                                <div class="value" >
                                                    ${phone.value}
                                                </div>
                                            </div>
                                        </div>`
                        }
                    });
                    break;
                case CONTACT_DETAIL.EMAIL_V2:
                    contact.value.forEach(email => {
                        if (email.value.trim() !== '') {
                            htmlCode += `
                                          <div class="contact-box">
                                                <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                                     <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path xmlns="http://www.w3.org/2000/svg" d="M21.6211 9.45312C21.7734 9.33203
                                                                    22 9.44531 22 9.63672V17.625C22 18.6602 21.1602 19.5 20.125
                                                                    19.5H3.875C2.83984 19.5 2 18.6602 2 17.625V9.64062C2 9.44531
                                                                    2.22266 9.33594 2.37891 9.45703C3.25391 10.1367 4.41406 11 8.39844
                                                                    13.8945C9.22266 14.4961 10.6133 15.7617 12 15.7539C13.3945 15.7656
                                                                    14.8125 14.4727 15.6055 13.8945C19.5898 11 20.7461 10.1328 21.6211
                                                                    9.45312ZM12 14.5C12.9062 14.5156 14.2109 13.3594 14.8672 12.8828C20.0508
                                                                    9.12109 20.4453 8.79297 21.6406 7.85547C21.8672 7.67969 22 7.40625
                                                                    22 7.11719V6.375C22 5.33984 21.1602 4.5 20.125 4.5H3.875C2.83984
                                                                    4.5 2 5.33984 2 6.375V7.11719C2 7.40625 2.13281 7.67578 2.35938
                                                                    7.85547C3.55469 8.78906 3.94922 9.12109 9.13281 12.8828C9.78906
                                                                    13.3594 11.0938 14.5156 12 14.5Z" fill="${card.customizations.icon_color}"></path>
                                                    </svg>
                                                </div>
                                                <div class="contact-field-detail contact_details">
                                                    <div class="label">
                                                        ${email.label}
                                                    </div>
                                                    <div class="value" >
                                                        ${email.value}
                                                    </div>
                                                </div>
                                            </div>`
                        }
                    });
                    break;
                case CONTACT_DETAIL.WEBSITE_V2:
                    contact.value.forEach(website => {
                        if (website.value.trim() !== '') {
                            htmlCode += `
                                         <div class="contact-box">
                                            <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                                <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path xmlns="http://www.w3.org/2000/svg" d="M13.5706 6.12903C12.9859
                                                            2.52823 11.6069 0 10.0021 0C8.39724 0 7.0182 2.52823 6.43353
                                                            6.12903H13.5706ZM6.13111 10C6.13111 10.8952 6.17949 11.754 6.26417
                                                            12.5806H13.7359C13.8206 11.754 13.869 10.8952 13.869 10C13.869 9.10484
                                                            13.8206 8.24597 13.7359 7.41935H6.26417C6.17949 8.24597 6.13111 9.10484
                                                            6.13111 10ZM19.2239 6.12903C18.0706 3.39113 15.7359 1.27419 12.8529
                                                            0.419355C13.8368 1.78226 14.5142 3.83468 14.869 6.12903H19.2239ZM7.14724
                                                            0.419355C4.2682 1.27419 1.92949 3.39113 0.780301 6.12903H5.13514C5.48595
                                                            3.83468 6.16337 1.78226 7.14724 0.419355ZM19.6553 7.41935H15.0303C15.115
                                                            8.26613 15.1634 9.13306 15.1634 10C15.1634 10.8669 15.115 11.7339 15.0303
                                                            12.5806H19.6513C19.873 11.754 19.998 10.8952 19.998 10C19.998 9.10484 19.873
                                                            8.24597 19.6553 7.41935ZM4.84079 10C4.84079 9.13306 4.88917 8.26613 4.97385
                                                            7.41935H0.348849C0.131107 8.24597 0.0020752 9.10484 0.0020752 10C0.0020752
                                                            10.8952 0.131107 11.754 0.348849 12.5806H4.96982C4.88917 11.7339 4.84079 10.8669
                                                            4.84079 10ZM6.43353 13.871C7.0182 17.4718 8.39724 20 10.0021 20C11.6069 20 12.9859
                                                            17.4718 13.5706 13.871H6.43353ZM12.8569 19.5806C15.7359 18.7258 18.0747 16.6089
                                                            19.2279 13.871H14.873C14.5182 16.1653 13.8408 18.2177 12.8569 19.5806ZM0.780301
                                                            13.871C1.93353 16.6089 4.2682 18.7258 7.15127 19.5806C6.1674 18.2177 5.48998
                                                            16.1653 5.13514 13.871H0.780301Z" fill="${card.customizations.icon_color}"></path>
                                                            </svg>
                                              </div>
                                            <div class="contact-field-detail contact_details">
                                                <div class="label">
                                                    ${website.label}
                                                </div>
                                                <div class="value" >
                                                    ${website.value}
                                                </div>
                                            </div>
                                         </div>`
                        }
                    });
                    break;
                case CONTACT_DETAIL.CUSTOM_FIELDS:
                    contact.value.forEach(custom_field => {
                        if (custom_field.value.trim() !== '' || custom_field.label.trim() !== '') {
                            htmlCode += `
                                          <div class="contact-box">
                                             <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                                 <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                    <path d="M6.92188 13.5999H7.46875V9.6499H6.92188C6.61984 9.6499 6.375 9.40506 6.375 9.10302V7.79678C6.375 7.49474 6.61984 7.24991 6.92188 7.24991H9.98438C10.2864 7.24991 10.5313 7.49474 10.5313 7.79678V13.5999H11.0781C11.3802 13.5999 11.625 13.8448 11.625 14.1468V15.453C11.625 15.7551 11.3802 15.9999 11.0781 15.9999H6.92188C6.61984 15.9999 6.375 15.7551 6.375 15.453V14.1468C6.375 13.8448 6.61984 13.5999 6.92188 13.5999ZM9 1.99991C7.91268 1.99991 7.03125 2.88133 7.03125 3.96866C7.03125 5.05598 7.91268 5.93741 9 5.93741C10.0873 5.93741 10.9688 5.05598 10.9688 3.96866C10.9688 2.88133 10.0873 1.99991 9 1.99991Z" fill="${card.customizations.icon_color}"/>
                                                </svg>
                                            </div>
                                            <div class="contact-field-detail contact_details">
                                                <div class="label">
                                                    ${custom_field.label}
                                                </div>
                                                <div class="value" >
                                                    ${custom_field.value}
                                                </div>
                                            </div>
                                        </div>`
                        }
                    });
                    break;
            }
        }
    })

    if (card.address_v2) {
        const tempAddress = card.address_v2.replace(/ +\n/g, '\n').replace(/\n +/g, '\n').trim();
        htmlCode += `
              <div class="contact-box">
                 <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M8.46045 15.7174C4.48746 9.95779 3.75 9.36667 3.75 7.24991C3.75 4.3504 6.1005 1.99991 9 1.99991C11.8995 1.99991 14.25 4.3504 14.25 7.24991C14.25 9.36667 13.5125 9.95779 9.53955 15.7174C9.27882 16.0941 8.72115 16.0941 8.46045 15.7174ZM9 9.43741C10.2081 9.43741 11.1875 8.45804 11.1875 7.24991C11.1875 6.04178 10.2081 5.06241 9 5.06241C7.79187 5.06241 6.8125 6.04178 6.8125 7.24991C6.8125 8.45804 7.79187 9.43741 9 9.43741Z" fill="${card.customizations.icon_color}"/>
                    </svg>
                </div>
                <div class="contact-field-detail contact_details">
                    <div class="label">
                        Address
                    </div>
                    <div class="value" >
                        ${tempAddress}
                    </div>
                </div>
            </div>`
    }


    htmlCode += buildSocialIcons(card);
    htmlCode += '</div>'

    return htmlCode;
}

function buildShareYourContactBtn(card) {
    if (!card.lead_collection) {
        return '';
    }
    return `<div id="leadPrimarylBtn" class="share-contact-btn button" style="border: 1px solid ${card.customizations.button_color}; color: ${card.customizations.button_color};">
                        <span> Share your contact </span>
                    </div>`;
}

function buildTemplate(card, isMultiLanguageContent, multiLangList, currentLanguage) {
    let htmlCode = ''
    const addToContactIconColor = getAddToContactIconColor(card.customizations.typography);

    let multiLangDropDownHTML;
    if (isMultiLanguageContent) {
        multiLangDropDownHTML = populateDropdown(card, multiLangList, currentLanguage);
    } else {
        multiLangDropDownHTML = '';
    }

    htmlCode += '<html class="layout-firefox">';

    htmlCode += buildStyle(card);

    htmlCode += `<body>
                    <div id="content-container" class="container" style="background-attachment: fixed;">`;

    htmlCode += multiLangDropDownHTML;

    htmlCode += `<script>
                        function setCardBackground(maxRetries = 3) {
                            if (maxRetries === 0) {
                                return
                            }
                              const container = document.getElementById('content-container');
                              if (!container) {
                                  setCardBackground(maxRetries-1)
                                  return
                              }
                              const backgroundType = '${card.customizations?.background?.type}';
                              const backgroundValue = '${card.customizations?.background?.value}';

                              if (backgroundType === 'color') {
                                  container.style.backgroundColor = backgroundValue;
                              } else if (backgroundValue) {
                                  container.style.backgroundImage = "url('" + backgroundValue + "')";
                              }
                        }
                        setCardBackground()
                    </script>

                      <div class="layout mobile-layout">`

    htmlCode += buildLogoContainer(card);
    htmlCode += buildInfoContainer(card);
    htmlCode += buildSummaryContainer(card);

    htmlCode += buildContactContainer(card); // This builds contact and social icons

    if (!card.__show_new_sticky_btn__) {
        if (card.lead_collection) {
            htmlCode += buildShareYourContactBtn(card);
        }
    } else {
        htmlCode += getStickyShareContactBtn(card);
    }
    htmlCode += getLeadFormHtml(card);


    htmlCode += '    </div>';

    if (!card.__show_new_sticky_btn__) {
        htmlCode += `<div class="floating-add-to-contact" style="background-color: ${card.customizations.button_color}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M9.9 10.2656C9.13125 10.2656 8.76161 10.6875 7.5 10.6875C6.23839 10.6875 5.87143 10.2656 5.1 10.2656C3.1125 10.2656 1.5 11.8529 1.5 13.8094V14.4844C1.5 15.1831 2.07589 15.75 2.78571 15.75H12.2143C12.9241 15.75 13.5 15.1831 13.5 14.4844V13.8094C13.5 11.8529 11.8875 10.2656 9.9 10.2656ZM12.2143 14.4844H2.78571V13.8094C2.78571 12.5543 3.825 11.5312 5.1 11.5312C5.49107 11.5312 6.12589 11.9531 7.5 11.9531C8.88482 11.9531 9.50625 11.5312 9.9 11.5312C11.175 11.5312 12.2143 12.5543 12.2143 13.8094V14.4844ZM7.5 9.84375C9.62946 9.84375 11.3571 8.14307 11.3571 6.04688C11.3571 3.95068 9.62946 2.25 7.5 2.25C5.37054 2.25 3.64286 3.95068 3.64286 6.04688C3.64286 8.14307 5.37054 9.84375 7.5 9.84375ZM7.5 3.51562C8.91696 3.51562 10.0714 4.65205 10.0714 6.04688C10.0714 7.4417 8.91696 8.57812 7.5 8.57812C6.08304 8.57812 4.92857 7.4417 4.92857 6.04688C4.92857 4.65205 6.08304 3.51562 7.5 3.51562Z" fill="${addToContactIconColor}"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 7.7C15.75 7.58954 15.6605 7.5 15.55 7.5H14.825C14.7145 7.5 14.625 7.58954 14.625 7.7V8.6125C14.625 8.72296 14.5355 8.8125 14.425 8.8125H13.5125C13.402 8.8125 13.3125 8.90204 13.3125 9.0125V9.7375C13.3125 9.84796 13.402 9.9375 13.5125 9.9375H14.425C14.5355 9.9375 14.625 10.027 14.625 10.1375V11.05C14.625 11.1605 14.7145 11.25 14.825 11.25H15.55C15.6605 11.25 15.75 11.1605 15.75 11.05V10.1375C15.75 10.027 15.8395 9.9375 15.95 9.9375H16.8625C16.973 9.9375 17.0625 9.84796 17.0625 9.7375V9.0125C17.0625 8.90204 16.973 8.8125 16.8625 8.8125H15.95C15.8395 8.8125 15.75 8.72296 15.75 8.6125V7.7Z" fill="${addToContactIconColor}"/>
                        </svg>
                </div>`
    }
    htmlCode += ` </div>
        </body>
    </html>`;
    return htmlCode;
}



export function layout8TemplateHtml(card, isMobile, isMultiLanguageContent = false, multiLangList?, currentLanguage?) {
    let html = '<html>'
    html += getHeaderCode(card);
    html += setTypography(card);
    html += buildTemplate(card, isMultiLanguageContent, multiLangList, currentLanguage);
    html += '</html>'

    return html;
}
