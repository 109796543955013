import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {auth, firestore, initializeApp} from 'firebase';
import {UserNotification} from './user-notification.model';
import {environment} from '../../environments/environment';
const {version} = require('../../../package.json');
import {TrackScan} from './track-scan.model';
import {FirebaseEmailVerification} from './firebase-email-verification.model';
import {CookieService} from 'ngx-cookie-service';

enum SESSION_STATUS {
    active = 'active',
    expired = 'expired'
}

@Injectable()
export class FirebaseService {

    private cookieKeys = {
        session: 'BSSessionToken'
    };

    private isSignedInSubject = new BehaviorSubject<boolean>(false);
    public isSignedIn$ = this.isSignedInSubject.asObservable();
    private userNotificationSubject = new BehaviorSubject<Array<UserNotification>>(null);
    public userNotification$ = this.userNotificationSubject.asObservable();

    private dashboardVersionSubject = new BehaviorSubject<any>(null);
    public dashboardVersion$ = this.dashboardVersionSubject.asObservable();

    public trackScanLimitSubject = new BehaviorSubject<TrackScan>(null);
    public trackScanLimit$ = this.trackScanLimitSubject.asObservable();

    public emailVerificationSubject = new BehaviorSubject<FirebaseEmailVerification>(null);
    public emailVerification$ = this.emailVerificationSubject.asObservable();

    public sessionActiveStatusSubject = new BehaviorSubject<boolean>(true);
    public sessionActiveStatus$ = this.sessionActiveStatusSubject.asObservable();

    /* This subject stores unread popup notification types*/
    private userPopupNotificationSubject = new BehaviorSubject<Array<UserNotification>>(null);
    public userNotificationPopup$ = this.userPopupNotificationSubject.asObservable();

    userId: string;
    firestore;

    constructor(private cookieService: CookieService) {
        initializeApp({
            apiKey: environment.firebaseApiKey,
            projectId: environment.firebaseProject,
        });
        this.firestore = firestore();
    }

    login(token) {
        return auth().signInWithCustomToken(token).then(async res => {
            this.isSignedInSubject.next(true);
            await this.setSessionInfo(res.user.uid);
            return res;
        });
    }

    logout() {
        return auth().signOut()
    }

    getUserSessionId(): string {
        return this.cookieService.get(this.cookieKeys.session);
    }

    async setSessionInfo(userId) {
        return this.firestore.collection('users').doc(userId.toString()).collection('sessions').doc(this.getUserSessionId()).get()
            .then(res => {
                if (!res.exists) {
                    this.firestore.collection('users')
                        .doc(userId.toString()).collection('sessions').doc(this.getUserSessionId()).set(
                            {
                                status: SESSION_STATUS.active,
                                created: new Date().getTime()
                            }
                        );
                }
            }
            );
    }

    startObservingFirebase(userId) {
        this.userId = userId;
        this.startObservingScans(userId);
        this.startObservingDashboardVersion();
        this.startObservingEmailVerification(userId);
        this.startObservingSession(userId);
        this.startObservingNotifications(userId);
    }

    startObservingDashboardVersion() {
        if (environment.envName === 'dev' || this.isLocalHost() || this.isBacDev() || this.isFeatureBranch()) {
            return;
        }
        const dashboardVersion = this.firestore.collection('settings').doc('version'.toString());
        dashboardVersion.onSnapshot(res => {
            if (res.data().id !== version) {
                this.dashboardVersionSubject.next({
                    version: res.data().id
                })
            }
        }, err => {
        });
    }

    startObservingNotifications(userId) {
        const collection = this.firestore.collection('users').doc(userId.toString()).collection('notifications');
        collection.orderBy('updated', 'desc').limit(10).onSnapshot(res => {
            const notifications = [];
            for (const doc of res.docs) {
                const notification = new UserNotification(doc.data(), doc.id);
                notifications.push(notification);
                this.observeNotification(notification);
            }
            this.userNotificationSubject.next(notifications);
            this.userPopupNotificationSubject.next(notifications.filter(notification => (notification.is_popup && !notification.read)));
        }, err => {
        });
    }

    startObservingScans(userId) {
        const scanCollection = this.firestore.collection('users').doc(userId.toString());
        scanCollection.onSnapshot((res) => {
            const trackscan = new TrackScan(res.data());
            this.trackScanLimitSubject.next(trackscan)
        }, err => {
        });
    }

    startObservingSession(userId) {
        const sessionInfo = this.firestore.collection('users').doc(userId.toString()).collection('sessions').doc(this.getUserSessionId());
        sessionInfo.onSnapshot((res) => {
            if (res.data().status === SESSION_STATUS.expired) {
                this.sessionActiveStatusSubject.next(false);
            }
        }, err => {
        });
    }

    startObservingEmailVerification(userId) {
        const scanCollection = this.firestore.collection('users').doc(userId.toString());
        scanCollection.onSnapshot((res) => {
            const firebaseEmailVerification = new FirebaseEmailVerification(res.data());
            this.emailVerificationSubject.next(firebaseEmailVerification)
        }, err => {
        });
    }

    observeNotification(notification) {
        notification.readAsObservable.subscribe(res => {
            if (!res) {
                return;
            }
            this.firestore.collection('users')
                .doc(this.userId.toString()).collection('notifications').doc(notification.id)
                .update(notification.toObject());
        })
    }

    isLocalHost() {
        const host = window.location.hostname.toLowerCase();
        return host === 'localhost';
    }

    isBacDev() {
        const host = window.location.hostname.toLowerCase();
        return host === 'bac-dev-2bb6f.firebaseapp.com';
    }

    isFeatureBranch() {
        const host = window.location.hostname.toLowerCase();
        return host.includes('amplifyapp');
    }
}
