import { BaseModel } from '../global-services/base.model';
import { PRODUCT_STATE, DEFAULT_MAP_LOCATION } from '../shared/utils';
import { BACKGROUND_TYPE, LINKPAGE_BUTTON_TYPES, LINKPAGE_URL_TYPES } from './linkpage-utils';
import {SocialLinkObject} from '../global-components/social-links/socialLinkObject.model';
import {SOCIAL_LINKS_VIEW_TYPE} from '../global-components/social-links/social-links-utils';
import {environment} from '../../environments/environment';
import {DAYS} from '../global-services/business-card.model';

export enum LINKPAGE_HEADER_LAYOUTS {
    PROFILE = 1,
    BANNER,
    COVER,
    MINIMAL
}

export class Linkpage extends BaseModel {
    id: number
    name: string = ''
    meta: object = {}
    attributes: Attributes = new Attributes()
    url: string
    links: Array<any> = []
    deleted: boolean
    title: string = 'Stark Industries'
    description: string = 'Changing the world for a better future'
    thumbnail: string
    threat_active: boolean
    organization: number
    state: PRODUCT_STATE
    created: string
    updated: string
    start_date: string
    end_date: string
    timezone: string
    password: string
    slug: string;
    location_enabled: boolean
    fb_pixel_id: string;
    google_conversion_id: string;
    pass_parameters: boolean = true
    header_layout: LINKPAGE_HEADER_LAYOUTS = LINKPAGE_HEADER_LAYOUTS.PROFILE;
    cover_image: string;
    full_width_cover: boolean = false

    // dashboard data
    __views__: number = 0;
    __coverImageUploading__: boolean = false;

    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.name = json['name'] || '';
        this.meta = json['meta'];
        this.attributes = new Attributes(json['attributes']);

        this.url = json['url'];
        for (const link of json['links'] || []) {
            switch (link['url_type']) {
                case LINKPAGE_URL_TYPES.LINKS:
                    this.links.push(new BasicLink(link));
                    break;
                case LINKPAGE_URL_TYPES.SEPARATOR:
                    this.links.push(new Separator(link));
                    break;
                case LINKPAGE_URL_TYPES.SOCIAL_LINKS:
                    this.links.push(new SocialLink(link));
                    break;
                case LINKPAGE_URL_TYPES.LINK_PAGE:
                    this.links.push(new LinkpageLink(link));
                    break;
                case LINKPAGE_URL_TYPES.LANDING_PAGE:
                    this.links.push(new LandingPageLink(link));
                    break;
                case LINKPAGE_URL_TYPES.FORM:
                    this.links.push(new FormLink(link));
                    break;
                case LINKPAGE_URL_TYPES.TEXT:
                    this.links.push(new TextBlock(link));
                    break;
                case LINKPAGE_URL_TYPES.VIDEO:
                    this.links.push(new Video(link));
                    break;
                case LINKPAGE_URL_TYPES.GALLERY:
                    this.links.push(new ImageGallry(link));
                    break;
                case LINKPAGE_URL_TYPES.SMS:
                    this.links.push(new SMSLink(link));
                    break;
                case LINKPAGE_URL_TYPES.EMAIL:
                    this.links.push(new EmailLink(link));
                    break;
                case LINKPAGE_URL_TYPES.CALL:
                    this.links.push(new CallLink(link));
                    break;
                case LINKPAGE_URL_TYPES.PDF:
                    this.links.push(new UploadPDF(link));
                    break;
                case LINKPAGE_URL_TYPES.LOCATION:
                    this.links.push(new LocationLink(link));
                    break;
                case LINKPAGE_URL_TYPES.BUSINESS_HOURS:
                    this.links.push(new BusinessHours(link));
                    break;
            }
        }
        this.deleted = json['deleted'] || false;
        this.title = json['title'] || '';
        this.description = json['description'] || '';
        this.thumbnail = json['thumbnail'] || '';
        this.threat_active = json['threat_active'] || false;
        this.organization = json['organization'];
        this.state = json['state'] || PRODUCT_STATE.ACTIVE;
        this.created = json['created'] || '';
        this.updated = json['updated'] || '';
        this.start_date = json['start_date'];
        this.end_date = json['end_date'];
        this.timezone = json['timezone'] || 'UTC';
        this.password = json['password'] || '';
        this.slug = json['slug'] || '';
        this.__views__ = json['__views__'] || 0;
        this.location_enabled = json['location_enabled'];
        this.fb_pixel_id = json['fb_pixel_id'];
        this.google_conversion_id = json['google_conversion_id'];
        this.pass_parameters = json['pass_parameters'];
        this.header_layout = json['header_layout'] || this.header_layout;
        this.cover_image = json['cover_image'] ? json['cover_image'] : this.cover_image;
        this.full_width_cover = json.hasOwnProperty('full_width_cover') ? json['full_width_cover'] : this.full_width_cover;
    }

    clearModelForPost() {
        delete this.id
        delete this.url
        delete this.__views__
        delete this.__coverImageUploading__
        for (const link of this.links) {
            link.clearModelForPost()
        }
    }

    clearModelForPut() {
        delete this.__views__
        delete this.__coverImageUploading__
        for (const link of this.links) {
            link.clearModelForPut()
        }
    }

}

export class TitleCustomization extends BaseModel {
    bold: boolean = false;
    alignment: 'left' | 'right' | 'center' = 'center';
    italic: boolean = false;
    strikethrough: boolean = false;
    underline: boolean = false;
    font_size = 24;
    font_color = '#000000';

    constructor(json?) {
        super();

        if (!json) {
            return;
        }

        this.bold = json.hasOwnProperty('bold') ? json['bold'] : this.bold;
        this.italic = json.hasOwnProperty('italic') ? json['italic'] : this.italic;
        this.strikethrough = json.hasOwnProperty('strikethrough') ? json['strikethrough'] : this.strikethrough;
        this.underline = json.hasOwnProperty('underline') ? json['underline'] : this.underline;
        this.alignment = json['alignment'] ? json['alignment'] : this.alignment;
        this.font_size = json['font_size'] ? json['font_size'] : this.font_size;
        this.font_color = json['font_color'] ? json['font_color'] : this.font_color;
    }
}

export class DescriptionCustomization extends TitleCustomization {
    font_size = 13;
    constructor(json?) {
        super(json);

        if (!json) {
            return;
        }
        this.font_size = json['font_size'] ? json['font_size'] : 13;
    }

}

export class Attributes extends BaseModel {
    background: number = BACKGROUND_TYPE.SOLID_COLOR
    background_color: string = '#ffffff'
    font: string = 'Open Sans'
    title_text_color: string = '#000000'

    image_url: string;
    video_url: string;

    button_color: string = '#2F2F3F';
    button_text_color: string = '#ffffff';
    button_type: number = LINKPAGE_BUTTON_TYPES.ROUNDED;
    fill_button: boolean = true;

    button_style_bold: boolean = false;
    button_style_underline: boolean = false;
    button_style_italic: boolean = false;
    button_style_strikethrough: boolean = false;

    title_customization: TitleCustomization = new TitleCustomization();

    description_customization: DescriptionCustomization = new DescriptionCustomization();

    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.background = json['background'] || BACKGROUND_TYPE.SOLID_COLOR;
        this.background_color = json['background_color'] || '#ffffff';
        this.font = json['font'] || 'Open Sans';
        this.title_text_color = json['title_text_color'] || '#000000';
        this.fill_button = json['fill_button'] === null ? true : json['fill_button'];

        this.image_url = json['background'] === BACKGROUND_TYPE.IMAGE_URL ? json['image_url'] : '';
        this.video_url = json['background'] === BACKGROUND_TYPE.VIDEO ? json['video_url'] : '';

        this.button_type = json['button_type'] || LINKPAGE_BUTTON_TYPES.ROUNDED;
        this.button_text_color = json['button_text_color'] || '#ffffff';
        this.button_color = json['button_color'] || '#2F2F3F';
        this.button_style_bold = json['button_style_bold'] || false;
        this.button_style_underline = json['button_style_underline'] || false;
        this.button_style_italic = json['button_style_italic'] || false;
        this.button_style_strikethrough = json['button_style_strikethrough'] || false;

        this.title_customization = json['title_customization'] ? json['title_customization'] : this.title_customization;
        this.description_customization = json['description_customization'] ? json['description_customization'] : this.description_customization;
    }

}

export class Link extends BaseModel {
    id: number
    deleted: boolean
    url: string
    image_type: number
    image_url: string
    title: string
    url_type: number = LINKPAGE_URL_TYPES.LINKS
    text: string
    markdown_card: number
    form: number

    // dashboard data
    __collapsed__: boolean
    __isValidTitle__: boolean = true
    __isValidURL__: boolean = true
    __isImageUploading__: boolean = false


    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.deleted = json['deleted'] || false;
        this.url = json['url'] || '';
        this.title = json['title'] || '';
        this.image_type = json['image_type'] || 1;
        this.image_url = json['image_url'] || '';
        this.text = json['text'];
        this.markdown_card = json['markdown_card'];
        this.form = json['form'];
    }

    clearModelForPost() {
        delete this.id
        this.clearAdditionalProperties()
    }

    clearModelForPut() {
        if (this.id < 0) {
            delete this.id
        }
        this.clearAdditionalProperties()
    }

    clearAdditionalProperties() {
        delete this.__collapsed__
        delete this.__isValidTitle__
        delete this.__isValidURL__
        delete this.__isImageUploading__
    }
}

export class BasicLink extends Link {
    constructor(json) {
        super(json);
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.LINKS;
    }

    clearModelForPost() {
        super.clearModelForPost()
    }

    clearModelForPut() {
        super.clearModelForPut();
    }
}

export class LinkpageLink extends Link {
    __attachedLinkpageData__: object
    __linkpageNotFound__: boolean
    constructor(json) {
        super(json);
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.LINK_PAGE;
    }

    clearModelForPost() {
        delete this.__attachedLinkpageData__
        delete this.__linkpageNotFound__
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__attachedLinkpageData__
        delete this.__linkpageNotFound__
        super.clearModelForPut();
    }
}

export class LandingPageLink extends Link {
    __attachedLandingPageData__: object
    __landingPageNotFound__: boolean
    constructor(json) {
        super(json);
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.LANDING_PAGE;
    }

    clearModelForPost() {
        delete this.__attachedLandingPageData__
        delete this.__landingPageNotFound__
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__attachedLandingPageData__
        delete this.__landingPageNotFound__
        super.clearModelForPut();
    }
}

export class FormLink extends Link {
    __attachedFormData__: object
    __formNotFound__: boolean
    constructor(json) {
        super(json);
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.FORM;
    }

    clearModelForPost() {
        delete this.__attachedFormData__
        delete this.__formNotFound__
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__attachedFormData__
        delete this.__formNotFound__
        super.clearModelForPut();
    }
}

export class Separator extends Link {
    field_data = {
        color: '#16212F',
        thickness: 1,
        width: 100
    }
    __thicknessOutOfBounds__: boolean

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.SEPARATOR;
        this.field_data = json['field_data'] || {
            color: '#16212F',
            thickness: 1,
            width: 100
        };
    }

    clearModelForPost() {
        delete this.__thicknessOutOfBounds__
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__thicknessOutOfBounds__
        super.clearModelForPut();
    }
}

export class Video extends Link {
    field_data: any = {
        autoplay: false,
    }

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.VIDEO;
        this.url = json['url'] || '';
        this.field_data = json['field_data'] || { autoplay: false };
    }

    clearModelForPost() {
        super.clearModelForPost()
    }

    clearModelForPut() {
        super.clearModelForPut();
    }
}

export class TextBlock extends Link {
    defaultTextBlockData = {
        content: '',
        customizations: {
            bold: false,
            alignment: 'left',
            italic: false,
            strikethrough: false,
            font_size: 13,
            font_color: '#000000'
        }
    }
    field_data: any = this.defaultTextBlockData;
    __isValidContent__: boolean = true;

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }

        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.TEXT;
        this.field_data = json['field_data'] || this.defaultTextBlockData;
    }

    clearModelForPost() {
        delete this.defaultTextBlockData;
        delete this.__isValidContent__;
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.defaultTextBlockData;
        delete this.__isValidContent__;
        super.clearModelForPut();
    }
}

export class ImageGallry extends Link {
    field_data: any
    __isValidImage__: boolean = true;

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }

        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.GALLERY;
        this.field_data = json['field_data'] || { image_gallery: [], carousel: true };
        this.__isValidImage__ === true;
    }

    clearModelForPost() {
        delete this.__isValidImage__;
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__isValidImage__;
        super.clearModelForPut();
    }
}
export class SocialLink extends Link {
    social_links: {}
    social_links_v2: {
        links: Array<SocialLinkObject>,
        view_type: number
    } = {links: [], view_type: SOCIAL_LINKS_VIEW_TYPE.ICON}

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }
        this.social_links = json['social_links'] || {}
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.SOCIAL_LINKS;
        this.social_links_v2.view_type = json['social_links_v2']?.view_type || SOCIAL_LINKS_VIEW_TYPE.ICON;
        if (json['social_links_v2']?.links) {
            this.social_links_v2.links = json['social_links_v2'].links.map(link => new SocialLinkObject(link))
        }
    }

    migrateSocialLinkV1ToV2(social_links) {
        const socialLinkList: Array<SocialLinkObject> = [];
        const social_links_v2 = this.social_links_v2;

        for (const [key, value] of Object.entries(social_links)) {
            if (value) {
                const socialLinkItem = new SocialLinkObject({
                    button_id: key,
                    button_name: key,
                    icon_url: `${environment.imagesEndpoint}linkpage/social_icons/${key.toLowerCase()}-icon.png`,
                    redirect_url: value.toString()
                });
                socialLinkList.push(socialLinkItem);
            }
        }
        social_links_v2.view_type = SOCIAL_LINKS_VIEW_TYPE.ICON;
        social_links_v2.links = socialLinkList;
        return social_links_v2;
    }


    clearModelForPost() {
        super.clearModelForPost();
        this.social_links_v2.links.forEach((element) => {
            element.clearModelForPost();
        })
    }

    clearModelForPut() {
        super.clearModelForPut();
        this.social_links_v2.links.forEach((element) => {
            element.clearModelForPut();
        })
    }
}

export class UploadPDF extends Link {
    __isPDFUploading__: boolean = false;
    field_data: {
        pdf_url: string;
        pdf_name: string;
    }

    constructor(json) {
        super(json);
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.PDF;
        this.field_data = json['field_data'] || { pdf_url: '', pdf_name: '' }
    }

    clearModelForPost() {
        delete this.__isPDFUploading__;
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__isPDFUploading__;
        super.clearModelForPut();
    }
}

export class CustomSlugValidation {
    url: string;
}

export class CallLink extends Link {
    field_data = {
        phone: ''
    }

    __isValidPhone__: boolean = true;

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.CALL;
        this.field_data = json['field_data'] ? json['field_data'] : this.field_data;
    }

    clearModelForPost() {
        delete this.__isValidPhone__;
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__isValidPhone__;
        super.clearModelForPut();
    }
}
export class EmailLink extends Link {
    field_data = {
        recipient: '',
        subject: '',
        body: '',
    }

    __isValidEmail__: boolean = true;

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.EMAIL;
        this.field_data = json['field_data'] ? json['field_data'] : this.field_data;
    }

    clearModelForPost() {
        delete this.__isValidEmail__;
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__isValidEmail__;
        super.clearModelForPut();
    }
}
export class SMSLink extends Link {
    field_data = {
        phone: '',
        message: ''
    }

    __isValidPhone__: boolean = true;

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.SMS;
        this.field_data = json['field_data'] ? json['field_data'] : this.field_data;
    }

    clearModelForPost() {
        delete this.__isValidPhone__;
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__isValidPhone__;
        super.clearModelForPut();
    }
}

export class LocationLink extends Link {
    __longitude__: number = 0;
    __latitude__: number = 0;
    __searchTerm__: string = '';
    __center__: {lat: DEFAULT_MAP_LOCATION.LATITUDE, lng: DEFAULT_MAP_LOCATION.LONGITUDE } = { lat: DEFAULT_MAP_LOCATION.LATITUDE, lng: DEFAULT_MAP_LOCATION.LONGITUDE };
    __markerOptions__ = {draggable: true};
    __placeId__: string = '';
    field_data: any = {
        center: {lat: 0, lng: 0},
        zoom: 0,
        show_map: false,
    }

    constructor(json?) {
        super(json);
        if (!json) {
            return;
        }
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.LOCATION;
        this.url = json['url'] || '';
        this.field_data = json['field_data'] ? json['field_data'] : this.field_data;
    }

    clearModelForPost() {
        delete this.__longitude__;
        delete this.__latitude__;
        delete this.__searchTerm__;
        delete this.__center__;
        delete this.__markerOptions__;
        delete this.__placeId__;
        super.clearModelForPost()
    }

    clearModelForPut() {
        delete this.__latitude__;
        delete this.__longitude__;
        delete this.__latitude__;
        delete this.__searchTerm__;
        delete this.__center__;
        delete this.__markerOptions__;
        delete this.__placeId__;
        super.clearModelForPut();
    }

}

export enum BUSINESS_HOUR_TYPE {
    OPEN_CLOSE,
    ONLINE_DELIVERY,
    CHECK_IN_OUT
}

class BusinessHoursField {
    widget_type: BUSINESS_HOUR_TYPE = BUSINESS_HOUR_TYPE.OPEN_CLOSE
    timezone: string;
    same_all_days: boolean = false;
    days: Array<any>;
    all_day_data = {start_time: '9:00 AM', end_time: '6:00 PM'};

    constructor(json) {
        if (!json) {
            return;
        }
        if (!json['days']) {
            this.days = DAYS.map((res, i) => {
                return {day_name: DAYS[i], state: true, start_time: '9:00 AM', end_time: '6:00 PM'}
            })
        } else {
            this.days = json['days'];
            this.days = this.days.map((res, i) => {
                return {day_name: DAYS[i], state: this.days[i].state, start_time: this.days[i].start_time, end_time: this.days[i].end_time}
            })
        }

        this.widget_type = json['widget_type'] ? json['widget_type'] : this.widget_type;
        this.timezone = json['timezone'] ? json['timezone'] : this.timezone;
        this.same_all_days = json.hasOwnProperty('same_all_days') ? json['same_all_days'] : this.same_all_days;
        this.all_day_data = json['all_day_data'] ? json['all_day_data'] : this.all_day_data;

    }
}

export class BusinessHours extends Link {
    field_data: BusinessHoursField;

    constructor(json) {
        super(json);
        if (!json) {
            return;
        }
        this.url_type = json['url_type'] || LINKPAGE_URL_TYPES.BUSINESS_HOURS;
        this.field_data = json['field_data'] ? new BusinessHoursField(json['field_data']) : new BusinessHoursField({});
    }

    clearModelForPost() {
        super.clearModelForPost()
    }

    clearModelForPut() {
        super.clearModelForPut();
    }
}

export class LinkedQRCodes extends BaseModel {
    id: number;
    name: string = ''
    attributes: Attributes = new Attributes();
    url: string = '';
    updated: string = '';
    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.name = json['name'] || '';
        this.attributes = new Attributes(json['attributes']) || null;
        this.url = json['url'] || '';
        this.updated = json['updated'] || '';
    }
}
