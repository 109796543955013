import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LowerCaseTextInputDirective} from './lower-case-text-input.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LowerCaseTextInputDirective,
    ],
    exports: [
        LowerCaseTextInputDirective,
    ]
})
export class LowerCaseTextInputModule {
}
