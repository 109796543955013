import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppleWalletPassPreviewComponent } from './apple-wallet-pass-preview.component';

@NgModule({
    imports: [CommonModule],
    declarations: [AppleWalletPassPreviewComponent],
    exports: [AppleWalletPassPreviewComponent]
})
export class AppleWalletPassPreviewModule { }

