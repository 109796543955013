import { Injectable } from '@angular/core';
import { BaseBackendService } from './base-backend.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { MessageModalService } from '../shared/message-modal/message-modal.service';

@Injectable({
    providedIn: 'root'
})

class TrialSwitch{
    plan: string = '';
}
@Injectable()
export class TrialSwitchService extends BaseBackendService<TrialSwitch> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, TrialSwitch, 'switch_trial', messageModal);
    }
}
