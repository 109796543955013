import { animate, state, style, transition, trigger } from '@angular/animations';

export class Animations {
    static collapse = trigger('collapse', [
        transition(':enter', [
            style({ height: 0, opacity: 0, 'padding-top': 0, 'padding-bottom': 0 }),
            animate('150ms', style({ height: '*', 'padding-top': '*', 'padding-bottom': '*' })),
            animate('150ms', style({ opacity: '*' })),
        ]),
        transition(':leave', [
            style({ height: '*', opacity: '*', 'padding-top': '*', 'padding-bottom': '*' }),
            animate('150ms', style({ opacity: 0 })),
            animate('150ms', style({ height: 0, 'padding-top': 0, 'padding-bottom': 0 })),
        ]),
    ]);

    static insertRemoveTrigger = trigger('insertRemoveTrigger', [
        transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
        transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]);

    static flyInOut = trigger('flyInOut', [
        state('in', style({ transform: 'translateX(0)' })),
        transition('void => *', [style({ transform: 'translateX(100%)' }), animate(300)]),
        transition('* => void', [animate(300, style({ transform: 'translateX(100%)' }))]),
    ]);

    static toggleScale = trigger('toggleScale', [
        state(
            'small',
            style({
                transform: 'scale(0.7)',
                width: '-webkit-fill-available',
                height: '365px',
                marginTop: '-3px',
            }),
        ),
        state(
            'big',
            style({
                transform: 'scale(0.9)',
                width: '90%',
                height: '460px',
            }),
        ),
        transition('small => big', [animate('600ms')]),
        transition('big => small', [animate('600ms')]),
    ]);

    static rotate = trigger('rotate', [
        state('open', style({ transform: 'rotate(180deg)' })),
        state('closed', style({ transform: 'rotate(0deg)' })),
        transition('open => closed', [animate('0.2s')]),
        transition('closed => open', [animate('0.2s')]),
    ]);

    static rotateToBottom = trigger('rotateToBottom', [
        state('open', style({ transform: 'rotate(90deg)' })),
        state('closed', style({ transform: 'rotate(0deg)' })),
        transition('open => closed', [animate('150ms')]),
        transition('closed => open', [animate('150ms')]),
    ]);

    static fadeInOut = trigger('fadeInOut', [
        state('void', style({ opacity: 0 })),
        transition('void <=> *', animate(200)),
    ]);

    static slideAnimation = trigger('slideAnimation', [
        transition(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('300ms ease-out', style({ transform: 'translateX(0%)' })),
        ]),
        transition(':leave', [animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))]),
    ]);
}
