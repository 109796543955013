import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {BEACONSTAC_ADMIN} from '../app.constants';
import {INTERCOM_WIDGET_HIDDEN_ON_ROUTES} from '../shared/utils';

export enum INTERCOM_EVENTS {
    CREATE_DBC = 'create DBC',
    CREATE_DBC_FAILED = 'create DBC failed',
    CREATE_DBC_TEMPLATE = 'create DBC template',
    CREATE_DBC_TEMPLATE_FAILED = 'create DBC template failed',
}

@Injectable()
export class IntercomService {

    constructor(private authService: AuthService, private router: Router) {

        this.authService.isLoggedIn$.subscribe(loggedIn => {
            if (loggedIn) {
                const user = this.authService.getUser();
                if (!(user.isOnEnterprisePlan() || user.isOwner()) || this.isAdminDashboard()) {
                    return;
                }
                const intercomUser = {
                    app_id: environment.intercomAppId,
                    email: user.username,
                    user_id: user.id,
                    group: user.getReadableGroup(),
                    name: user.first_name + ' ' + user.last_name
                };
                if (user.isOnEnterprisePlan() || user.isOnDBCEnterprisePlan()) {
                    intercomUser['company'] = {
                        company_id: user.mainOrganization
                    };
                }
                (<any>window).Intercom('boot', intercomUser);
            } else {
                (<any>window).Intercom('shutdown')
            }


        });
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                const hideIntercomLauncher = INTERCOM_WIDGET_HIDDEN_ON_ROUTES.some(route => event instanceof NavigationEnd && event.urlAfterRedirects.includes(route));
                (<any>window).Intercom('update', { 'hide_default_launcher': hideIntercomLauncher });
            });
    }

    public update(data?: object) {
        if (!data) {
            (<any>window).Intercom('update');
            return;
        }

        const user = this.authService.getUser();
        if (!user) {
            (<any>window).Intercom('shutdown');
            console.error('Attempted Intercom update while logged out');
            return;
        }

        const mandatoryFields = {
            email: user.username,
            user_id: user.id
        };
        const dataWithMandatoryFields = Object.assign(mandatoryFields, data);
        (<any>window).Intercom('update', dataWithMandatoryFields);
    }

    public trackEvent(event: string) {
        if (!event) {
            return;
        }

        const user = this.authService.getUser();
        if (!user) {
            (<any>window).Intercom('shutdown');
            console.error('Attempted Intercom update while logged out');
            return;
        }

        (<any>window).Intercom('trackEvent', event);
    }

    public bootAnonymousUser(start: boolean) {
        if (start) {
            (<any>window).Intercom('boot', {
                app_id: environment.intercomAppId,
            });
        } else {
            (<any>window).Intercom('shutdown');
        }
    }

    isAdminDashboard() {
        const host = window.location.hostname.toLowerCase();
        return host === BEACONSTAC_ADMIN;
    }

    startNewConversation() {
        (<any>window).Intercom('showNewMessage');
    }

}
