import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeaconstacMobileDrawerComponent } from './beaconstac-mobile-drawer.component';

@NgModule({
    declarations: [BeaconstacMobileDrawerComponent],
    imports: [CommonModule],
    exports: [BeaconstacMobileDrawerComponent],
})
export class BeaconstacMobileDrawerModule {}
