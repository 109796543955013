<div class="popup-container" [id]=popupId [ngClass]="{'modal-open': showModal, 'modal-hidden': !showModal}">
    <div class="popup-body">
        <div class="close-button-container" (click)="closeModal()" (keyup)="closeModal()" (keydown)="closeModal()">
            <i class="close-button fa fa-times"></i>
        </div>
        <div class="template-content">
            <ng-content></ng-content>
        </div>

        <div class="mediaUrl-content">
            <img [src]=mediaUrl class="mediaUrl-main" alt="PWA functionality demonstration">
        </div>
    </div>
</div>
