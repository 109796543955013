import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModalModule} from 'ngx-bootstrap/modal';
import {HelpMenuDisplayComponent} from './help-menu-display.component';

@NgModule({
    imports: [
        CommonModule,
        ModalModule
    ],
    declarations: [
        HelpMenuDisplayComponent,
    ],
    exports: [
        HelpMenuDisplayComponent,
    ]
})

export class HelpMenuDisplayModule {

}
