<div class="p-3 awp-preview" [ngStyle]="{'background-color': backgroundColor, 'color': textColor}">
    <div class="d-flex align-items-start awp-company-details">
        <div class="awp-company-logo" [ngClass]="logo ? '' : 'd-none'">
            <img class="w-100 h-100" style="object-fit: contain" [src]="logo">
        </div>
        <div *ngIf="!logo" class="awp-company-name" [ngClass]="logo ? 'ml-3' : ''"> {{ companyName }} </div>
    </div>
    <div class="d-flex justify-content-between mt-4 awp-profile-detail">
        <div>
            <div class="awp-profile-designation font-fs-12" *ngIf="designation">{{ designation | uppercase }}</div>
            <div class="d-flex mt-1"><span class="awp-profile-name">{{firstName + ' ' + lastName}}</span></div>
        </div>
        <div *ngIf="profilePhoto" class="awp-profile-picture">
            <img class="w-100 h-100" [src]="profilePhoto">
        </div>
    </div>


    <div class="mt-4" *ngIf="email">
        <div class="label-email font-fs-12">Email</div>
        <div class="awp-profile-email text-break font-fs-16">{{ email }}</div>
    </div>
    <div class="awp-vcard-qrcode-cont">
        <div class="m-auto awp-vcard-qrcode">
            <img class="awp-vcard-qrcode-img" src="../../../assets/img/dummy-qrcode.png" />
        </div>
    </div>
</div>