import { environment } from '../../environments/environment';
import { socialMediaNamesArray } from 'app/shared/social-media';
import { LanguageService } from '../global-components/notification-language/language.service';
import {
    getInitials,
    setTypography,
    getDesignationFontSize,
    getAddToContactIconColor, getLeadFormHtml, getStickyShareContactBtn
} from './templateUtils';
import {CONTACT_DETAIL} from '../digital-business-card/digital-business-card-utils';

function isHexColorCode(hex) {
    return /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);
}

function hexToRGBA(hex, opacity) {
    hex = hex.trim();
    if (!isHexColorCode(hex)) {
        return hex;
    }
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16)
    }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
}

function populateDropdown(card, multiLangList, currentLanguage) {
    let dropDownHTML = `<div class="language-dropdown"><div class="beaconstac-dbc-language-dropdown-container" id="language-dropdown-container">
    <select class="beaconstac-dbc-language-dropdown"  name="language-dropdown" id="language-selector-1" autocomplete="off" disabled>`

    const languageService = new LanguageService();
    const languages = languageService.getLanguagesByCodes(multiLangList);
    const currentLanguageName = languageService.getLanguageByCode(currentLanguage);
    languages.forEach((language) => {
        const isSelected = language === currentLanguageName ? 'selected' : '';

        dropDownHTML += `<option value="${language}" ${isSelected}>${language}</option>`;
    });
    dropDownHTML += `</select>
    <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M6.41843 8.87328L1.92793 4.42286C1.71136 4.20822 1.71136 3.86023 1.92793 3.64562L2.45168 3.12655C2.66788 2.91228 3.01828 2.91187 3.23499 3.12563L6.81056 6.65267L10.3861 3.12563C10.6028 2.91187 10.9532 2.91228 11.1694 3.12655L11.6932 3.64562C11.9097 3.86026 11.9097 4.20824 11.6932 4.42286L7.20269 8.87328C6.98612 9.0879 6.635 9.0879 6.41843 8.87328Z" fill="white"/>
    </svg>
</div></div>`;
    return dropDownHTML;
}

export function layout1TemplateHtml(card, isMobile, isMultiLanguageContent = false, multiLangList?, currentLanguage?, languageData = null) {
    let name = card.first_name;
    if (card.last_name) {
        name += ' '
        name += card.last_name;
    }
    if (card.prefix) {
        name = card.prefix + ' ' + name
    }
    if (card.suffix) {
        name += ', ' + card.suffix
    }
    const multiLangShift = 30; // pixel count
    const user_info_margin = 30; // pixel count
    let userInfoMargin = '30px';

    // @ts-ignore
    const isSafari = window.safari !== undefined;
    if (isSafari) {
        if (isMultiLanguageContent) {
            userInfoMargin = `${user_info_margin}px` // 0 to 30px
        }
        userInfoMargin = '0px' // 0px
    } else if (isMultiLanguageContent) {
        userInfoMargin = `${multiLangShift + user_info_margin}px` // 30 to 60px
    }
    let hasSocialLinks = false;

    let multiLangDropDownHTML;
    if (isMultiLanguageContent) {
        multiLangDropDownHTML = populateDropdown(card, multiLangList, currentLanguage);
    } else {
        multiLangDropDownHTML = '';
    }

    const addToContactIconColor = getAddToContactIconColor(card.customizations.typography);
    const designationFontSize = getDesignationFontSize(card.customizations.typography);

    let headHtmlCode =
        `<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>${name}</title>
  <link rel="stylesheet" href="${environment.vCardPlusCssEndpoint}/vcard_plus_layout-1.css?v=6.1" />
  <link rel="stylesheet" href="${environment.multiLanguageDropdownCssEndpoint}styles.css?v=3.0" />
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Work+Sans">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />

  <script src="https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js"></script>`


    if (card.logo_url) {
        headHtmlCode +=
            `<link rel="icon" type="image/x-icon" href="${card.logo_url}" id="fav_icon" />
    <link rel="shortcut icon" type="image/x-icon" href="${card.logo_url}" />`
    } else {
        headHtmlCode +=
            `<link rel="icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" id="fav_icon" />
    <link rel="shortcut icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" />`
    }

    let styleCode = '';

    styleCode += setTypography(card);

    headHtmlCode += `<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    <style>
    html{
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .main-container {
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    html::-webkit-scrollbar {
      width: 0;
      height: 0;
  }
  .main-container::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .secondary_data_label {
        opacity: 0.5;
    }

    #language-selector-1: disabled{
        border-radius: 4px;
        background-color: rgba(0,0,0,0.4);
        border: 0px;
        color: #FFF;
        text-align: center;
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px;
        padding-right: 16px;
        position: relative;
        top: 16px;
    }
    .multilang-shift {
        margin-top: ${multiLangShift}px;
    }
    .initials_custom_styles {
        background-color: ${hexToRGBA(card.customizations.background_color, 0.2)};
        color: ${card.customizations.background_color};
        font-family: ${card.customizations.font_style};
    }
    #id {
        background-size: cover;
    }
    </style>
  </head>`

    headHtmlCode += styleCode;


    let html = `<!DOCTYPE html>
  <html lang="en">
  ${headHtmlCode}

  <body style="margin: 0;" id="body">

    <div class="content overflow-hidden">
      <div id="content-container" class="main-container container position-absolute overflow-x-hidden" style="${isMobile ? '' : 'zoom: 0.7'} ; height : calc(160vh - 42px);">
        ${multiLangDropDownHTML}
        <div class="ellipse position-absolute ${isMultiLanguageContent ? 'multilang-shift' : ''}" style="background-color: ${card.customizations.background_color}; width : 135%;">`
    if (card.logo_url) {
        html += `<img style="z-index:10 ; height : auto ;  ; ${card.logo_size >= 0 ? `max-width: calc(${card.logo_size}%/3)` : 'max-width : 200px; max-height : 130px'};" class="logo" src="${card.logo_url}" />`
    }
    html += '</div>'

    html += `<div class="flex-center">
          <div style="top : 3rem;background-color: ${!card.user_image_url ? 'white' : ''}" class="profile flex-center position-absolute overflow-hidden ${isMultiLanguageContent ? 'multilang-shift' : ''}">`
    if (card.user_image_url) {
        html += `<img src="${card.user_image_url}" class="user-image"/>`;
    } else if (card.first_name) {
        html += `<div class="initials initials_custom_styles">${getInitials(card)}</div>`;
    } else {
        html += `<img src="${environment.imagesEndpoint}/vcard_plus/default_vcard_image.png" class="user-image"/>`
    }
    html += `</div></div>

        <div style="margin-top : ${userInfoMargin}; padding-bottom:7rem;" class="flex-center user-name-container flex-column position-absolute">
          <div class="flex-center -mt-10 ">
            <p class="user-name personal_info mb-0 " style=" text-align : center ;">${name}`
    if (card.pronouns_v2) {
        html += `<span class="user-name personal_info pronunciation">&nbsp;(${card.pronouns_v2})</span>`
    }
    html += '</p></div>'

    if (card.designation) {
        html += `<p class="user-designation company_details mt-6 mb-0" style="font-size:${designationFontSize} !important;">${card.designation}</p>`
    }
    if (card.company) {
        if (card.department) {
            html += `<p class="user-designation company_details mt-5 opacity-1">${card.department}<span> &#x2022; </span>${card.company}</p>`
        } else {
            html += `<p class="user-designation company_details mt-5 opacity-1">${card.company}</p>`
        }
    } else if (card.department) {
        html += `<p class="user-designation company_details mt-5 opacity-1">${card.department}</p>`
    }

    if (card.summary) {
        html +=
            `<div class="flex-center summary-container">
            <p style="white-space: pre-line ; word-break: break-word;" class="summary bio secondary_data">${card.summary}</p>
          </div>`
    }

    html += '<div  style="margin-top : 1.5rem; margin-bottom:0px;" class="contacts-container position-relative">'

    card.__contact_info_list__.forEach((contact) => {
        if (contact.value.length > 0) {
            switch (contact.key) {
                case CONTACT_DETAIL.PHONE_V2:
                    contact.value.forEach(phone => {
                        if (phone.value.trim() !== '') {
                            html += `<div class="d-flex mb-20 word-break">
                                      <div class="flex-center">
                                          <div style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; " class="svg-container d-flex flex-center">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M12.1492 4.44035C12.9909 4.60425 13.7644 5.01511 14.3708 5.62033C14.9772 6.22556 15.3888 6.99761 15.5531 7.83769M12.1492 1C13.8979 1.19389 15.5286 1.97549 16.7735 3.21645C18.0184 4.45741 18.8035 6.08398 19 7.82909M18.1383 14.6926V17.2728C18.1392 17.5124 18.0901 17.7495 17.9939 17.969C17.8978 18.1884 17.7568 18.3854 17.5799 18.5474C17.4031 18.7093 17.1943 18.8326 16.967 18.9093C16.7396 18.9861 16.4987 19.0146 16.2597 18.993C13.608 18.7054 11.0608 17.8011 8.82292 16.3526C6.74082 15.032 4.97557 13.2701 3.65252 11.192C2.19617 8.94824 1.28985 6.3936 1.00699 3.73508C0.98546 3.49723 1.01378 3.25752 1.09015 3.0312C1.16652 2.80488 1.28927 2.59691 1.45059 2.42054C1.6119 2.24416 1.80824 2.10324 2.02711 2.00675C2.24598 1.91026 2.48258 1.86031 2.72185 1.86009H5.30705C5.72525 1.85598 6.13069 2.00379 6.44778 2.27597C6.76487 2.54815 6.97198 2.92612 7.03051 3.33944C7.13963 4.16518 7.34199 4.97595 7.63373 5.75628C7.74967 6.06413 7.77476 6.39869 7.70603 6.72034C7.63731 7.04198 7.47764 7.33722 7.24595 7.57106L6.15155 8.66337C7.37827 10.8166 9.16456 12.5995 11.322 13.8239L12.4164 12.7316C12.6506 12.5003 12.9465 12.341 13.2687 12.2724C13.591 12.2038 13.9262 12.2288 14.2346 12.3445C15.0164 12.6357 15.8288 12.8377 16.6561 12.9466C17.0747 13.0055 17.457 13.216 17.7303 13.5379C18.0035 13.8598 18.1488 14.2708 18.1383 14.6926Z"
                                                stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                              <path
                                                d="M12.1492 4.44035C12.9909 4.60425 13.7644 5.01511 14.3708 5.62033C14.9772 6.22556 15.3888 6.99761 15.5531 7.83769M12.1492 1C13.8979 1.19389 15.5286 1.97549 16.7735 3.21645C18.0184 4.45741 18.8035 6.08398 19 7.82909M18.1383 14.6926V17.2728C18.1392 17.5124 18.0901 17.7495 17.9939 17.969C17.8978 18.1884 17.7568 18.3854 17.5799 18.5474C17.4031 18.7093 17.1943 18.8326 16.967 18.9093C16.7396 18.9861 16.4987 19.0146 16.2597 18.993C13.608 18.7054 11.0608 17.8011 8.82292 16.3526C6.74082 15.032 4.97557 13.2701 3.65252 11.192C2.19617 8.94824 1.28985 6.3936 1.00699 3.73508C0.98546 3.49723 1.01378 3.25752 1.09015 3.0312C1.16652 2.80488 1.28927 2.59691 1.45059 2.42054C1.6119 2.24416 1.80824 2.10324 2.02711 2.00675C2.24598 1.91026 2.48258 1.86031 2.72185 1.86009H5.30705C5.72525 1.85598 6.13069 2.00379 6.44778 2.27597C6.76487 2.54815 6.97198 2.92612 7.03051 3.33944C7.13963 4.16518 7.34199 4.97595 7.63373 5.75628C7.74967 6.06413 7.77476 6.39869 7.70603 6.72034C7.63731 7.04198 7.47764 7.33722 7.24595 7.57106L6.15155 8.66337C7.37827 10.8166 9.16456 12.5995 11.322 13.8239L12.4164 12.7316C12.6506 12.5003 12.9465 12.341 13.2687 12.2724C13.591 12.2038 13.9262 12.2288 14.2346 12.3445C15.0164 12.6357 15.8288 12.8377 16.6561 12.9466C17.0747 13.0055 17.457 13.216 17.7303 13.5379C18.0035 13.8598 18.1488 14.2708 18.1383 14.6926Z"
                                                stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                          </div>
                                      </div>
                                      <div class="pl-12 d-flex align-items-center">
                                      <div class="d-flex flex-column">
                                        <a class="secondary_data contact_details">
                                          ${phone.value}
                                        </a>`
                            if (phone.label) {
                                html += `<p class="sub-title mt-5 secondary_data_label contact_details">${phone.label.charAt(0).toUpperCase() + phone.label.slice(1)}</p>`
                            }
                            html += '</div></div></div>'
                        }
                    })

                    break;
                case CONTACT_DETAIL.EMAIL_V2:
                    contact.value.forEach(email => {
                        if (email.value.trim() !== '') {
                            html += `
                                      <div class="d-flex mb-20 word-break">
                                          <div class="flex-center">
                                              <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                    d="M2.8 1H17.2C18.19 1 19 1.7875 19 2.75V13.25C19 14.2125 18.19 15 17.2 15H2.8C1.81 15 1 14.2125 1 13.25V2.75C1 1.7875 1.81 1 2.8 1Z"
                                                    stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M19 3L10 9L1 3" stroke="${card.customizations.icon_color}" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                </svg>
                                              </div>
                                          </div>
                                      <div class="pl-12 d-flex align-items-center">
                                      <div class="d-flex flex-column">
                                        <a class="secondary_data contact_details">
                                          ${email.value}
                                        </a>`
                            if (email.label && email.label.length) {
                                html += `<p class="sub-title mt-5 secondary_data_label contact_details">${email.label.charAt(0).toUpperCase() + email.label.slice(1)}</p>`
                            }
                            html += '</div></div></div>'
                        }
                    });
                    break;
                case CONTACT_DETAIL.WEBSITE_V2:
                    contact.value.forEach(website => {
                        if (website.value.trim() !== '') {
                            html += `<div class="d-flex mb-20 word-break">
                                          <div class="flex-center">
                                              <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path
                                                        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                                                        stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                                      <path d="M1 10H19" stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                                      <path
                                                        d="M10.0004 1C12.2515 3.46452 13.5309 6.66283 13.6004 10C13.5309 13.3372 12.2515 16.5355 10.0004 19C7.74924 16.5355 6.46991 13.3372 6.40039 10C6.46991 6.66283 7.74924 3.46452 10.0004 1V1Z"
                                                        stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>

                                              </div>
                                          </div>
                                          <div class="pl-12 d-flex align-items-center">
                                          <div class="d-flex flex-column">
                                            <a class="secondary_data contact_details">
                                              ${website.value}
                                            </a>`
                            if (website.label) {
                                html += `<p class="sub-title mt-5 secondary_data_label contact_details">${website.label.charAt(0).toUpperCase() + website.label.slice(1)}</p>`
                            }
                            html += '</div></div></div>'
                        }
                    });
                    break;
                case CONTACT_DETAIL.CUSTOM_FIELDS:
                    contact.value.forEach(custom_field => {
                        if (custom_field.value.trim() !== '') {
                            html += `<div class="d-flex mb-20 word-break">
                                        <div class="flex-center">
                                            <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                              <svg style="padding-left: 2px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                      <path d="M9.03125 18.5714H9.8125V12.9286H9.03125C8.59977 12.9286 8.25 12.5788 8.25 12.1473V10.2812C8.25 9.84977 8.59977 9.5 9.03125 9.5H13.4062C13.8377 9.5 14.1875 9.84977 14.1875 10.2812V18.5714H14.9688C15.4002 18.5714 15.75 18.9212 15.75 19.3527V21.2188C15.75 21.6502 15.4002 22 14.9688 22H9.03125C8.59977 22 8.25 21.6502 8.25 21.2188V19.3527C8.25 18.9212 8.59977 18.5714 9.03125 18.5714ZM12 2C10.4467 2 9.1875 3.25918 9.1875 4.8125C9.1875 6.36582 10.4467 7.625 12 7.625C13.5533 7.625 14.8125 6.36582 14.8125 4.8125C14.8125 3.25918 13.5533 2 12 2Z" stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round"/>
                                              </svg>
                                            </div>
                                        </div>
                                        <div class="pl-12 d-flex align-items-center">
                                        <div class="d-flex flex-column">
                                          <a class="secondary_data contact_details">
                                            ${custom_field.value}
                                          </a>`
                            if (custom_field.label) {
                                html += `<p class="sub-title mt-5 secondary_data_label contact_details">${custom_field.label}</p>`
                            }
                            html += '</div></div></div>'
                        }
                    });
                    break;
            }
        }
    })

    /* Moved this code inside the switch case */
    /* Spend time if you can clean the above code */


    if (card.address_v2) {
        const tempAddress = card.address_v2.replace(/ +\n/g, '\n').replace(/\n +/g, '\n').trim();
        html += `<div class="d-flex mb-20 word-break">
            <div class="flex-center">
            <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
              <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.1818 9.59091C18.1818 16.2727 9.59091 22 9.59091 22C9.59091 22 1 16.2727 1 9.59091C1 7.31246 1.90511 5.12733 3.51622 3.51622C5.12733 1.90511 7.31246 1 9.59091 1C11.8694 1 14.0545 1.90511 15.6656 3.51622C17.2767 5.12733 18.1818 7.31246 18.1818 9.59091Z"
                  stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M9.59118 12.4548C11.1727 12.4548 12.4548 11.1727 12.4548 9.59118C12.4548 8.00963 11.1727 6.72754 9.59118 6.72754C8.00963 6.72754 6.72754 8.00963 6.72754 9.59118C6.72754 11.1727 8.00963 12.4548 9.59118 12.4548Z"
                  stroke="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            </div>
            <div class="pl-12">
              <span style="word-break: break-word;" class="secondary_data contact_details">
                ${tempAddress}
              </span>
              <p class="sub-title secondary_data_label contact_details">Address</p>
            </div>
          </div>`
    }
    html += '</div>'

    let socialMediaIcons = ''
    socialMediaNamesArray.forEach(item => {
        let imgLink = item;
        if (card.social_links[item]) {
            if (item === 'dribbble') {
                imgLink = 'dribble';
            } else if (item === 'custom_url') {
                imgLink = 'custom'
            }
            hasSocialLinks = true;
            socialMediaIcons +=
                `<a class="grid-item">
                  <img src="${environment.imagesEndpoint}/vcard_plus/social_link_icons/${imgLink}_icon.png" class="social-logo">
              </a>`
        }
    })

    if (hasSocialLinks) {
        html +=
            `<div class="grid-layout" style="margin-bottom: 2rem; margin-top:1rem;">
                ${socialMediaIcons}
              </div>`
    }

    if (!card.__show_new_sticky_btn__) {
        if (card.lead_collection) {
            html += `<div id="leadModalBtn" class="flex-center" style=" border: 2px solid ${card.customizations.button_color};">
                      <div class="flex-center button" style="cursor: pointer; color: ${card.customizations.button_color};">
                          Share your contact
                      </div>
                  </div>
          `
        }
    } else {
        html += getStickyShareContactBtn(card);
    }
    html += '</div>'



    if (!card.__show_new_sticky_btn__) {
        html += `<div class="btn-container flex-center" style="background-color: ${card.customizations.button_color}; width : 100%;">
              <div class="flex-center button" style="padding:16px 0px">
                 <svg width="24" height="40" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.5 19C15.5 19.2761 15.7239 19.5 16 19.5C16.2761 19.5 16.5 19.2761 16.5 19H15.5ZM16 17H16.5H16ZM12 13V12.5V13ZM5 13L5 12.5L5 13ZM1 17H0.5H1ZM0.5 19C0.5 19.2761 0.723858 19.5 1 19.5C1.27614 19.5 1.5 19.2761 1.5 19H0.5ZM20.5 6C20.5 5.72386 20.2761 5.5 20 5.5C19.7239 5.5 19.5 5.72386 19.5 6H20.5ZM19.5 12C19.5 12.2761 19.7239 12.5 20 12.5C20.2761 12.5 20.5 12.2761 20.5 12H19.5ZM23 9.5C23.2761 9.5 23.5 9.27614 23.5 9C23.5 8.72386 23.2761 8.5 23 8.5V9.5ZM17 8.5C16.7239 8.5 16.5 8.72386 16.5 9C16.5 9.27614 16.7239 9.5 17 9.5V8.5ZM16.5 19V17H15.5V19H16.5ZM16.5 17C16.5 15.8065 16.0259 14.6619 15.182 13.818L14.4749 14.5251C15.1313 15.1815 15.5 16.0717 15.5 17H16.5ZM15.182 13.818C14.3381 12.9741 13.1935 12.5 12 12.5L12 13.5C12.9283 13.5 13.8185 13.8687 14.4749 14.5251L15.182 13.818ZM12 12.5H5V13.5H12V12.5ZM5 12.5C3.80653 12.5 2.66193 12.9741 1.81802 13.818L2.52513 14.5251C3.1815 13.8687 4.07174 13.5 5 13.5L5 12.5ZM1.81802 13.818C0.974106 14.6619 0.5 15.8065 0.5 17H1.5C1.5 16.0717 1.86875 15.1815 2.52513 14.5251L1.81802 13.818ZM0.5 17V19H1.5V17H0.5ZM12 5C12 6.933 10.433 8.5 8.5 8.5V9.5C10.9853 9.5 13 7.48528 13 5H12ZM8.5 8.5C6.567 8.5 5 6.933 5 5H4C4 7.48528 6.01472 9.5 8.5 9.5V8.5ZM5 5C5 3.067 6.567 1.5 8.5 1.5V0.5C6.01472 0.5 4 2.51472 4 5H5ZM8.5 1.5C10.433 1.5 12 3.067 12 5H13C13 2.51472 10.9853 0.5 8.5 0.5V1.5ZM19.5 6V12H20.5V6H19.5ZM23 8.5H17V9.5H23V8.5Z"
                    fill="${addToContactIconColor}" />
                </svg>
                <a class="pl-4 btn-contact button"
                  target="_blank">
                  Add to Contacts
                </a>
              </div>
            </div>`
    }
    html += getLeadFormHtml(card);
    html += `</div>
            </div>
        </div>`
    html += `<script>
        WebFont.load({
                    google: {
                           families: ['${card.customizations.font_style}']
                     }
         });
        (function () {
            const container = document.getElementById('content-container');
            const backgroundType = '${card.customizations.background.type}';
            const backgroundValue = '${card.customizations.background.value}';

            if (backgroundType === 'color') {
              container.style.backgroundColor = backgroundValue;
            } else {
              container.style.backgroundImage = "url('" + backgroundValue + "')";
            }
        })();
   </script>
  </body>
  </html>`

    return html;
}
