import {
    DigitalBusinessCard,
    DigitalBusinessCardBulkModel,
    DigitalBusinessCardTemplateModel
} from './digital-business-card.model';
import {DigitalBusinessCardSetting} from './digital-business-card-setting/digital-business-card-setting.model';
import {DBCCardTypes} from '../shared/utils';

export enum TYPOGRAPHY_CUSTOMIZATIONS {
    PERSONAL_INFO = 'personal_info',
    COMPANY_DETAILS = 'company_details',
    BIO = 'bio',
    CONTACT_DETAILS = 'contact_details',
    BUTTON = 'button',
}

export enum CONTACT_DETAIL {
    PHONE_V2 = 'phone_v2',
    EMAIL_V2 = 'email_v2',
    WEBSITE_V2 = 'website_v2',
    CUSTOM_FIELDS = 'custom_fields',
}

export enum ROUTES {
    EDIT_CARDS = '/digital-business-cards/my-cards/edit/',
    EDIT_TEAM_CARDS = '/digital-business-cards/team-cards/edit/'
}

export enum DBC_LAYOUTS {
    LAYOUT_1 = '1',
    LAYOUT_2 = '2',
    LAYOUT_3 = '3',
    LAYOUT_4 = '4',
    LAYOUT_5 = '5',
    LAYOUT_6 = '6',
    LAYOUT_7 = '7',
    LAYOUT_8 = '8',
    LAYOUT_9 = '9',
    LAYOUT_10 = '10',
    LAYOUT_11 = '11',
    LAYOUT_12 = '12',
}

export function getUserConsentAmplitudeValue(product: DigitalBusinessCard | DigitalBusinessCardBulkModel | DigitalBusinessCardTemplateModel) {
    if (product.lead_user_agreement) {
        product.lead_user_agreement_attribute.user_agreement_urls = product.lead_user_agreement_attribute.user_agreement_urls.filter(res => {
            return (res.url && res.label) ? res : null;
        });
        return product.lead_user_agreement_attribute.user_agreement_urls.length ? 'added' : 'not added';
    } else {
        return 'no';
    }
}

export function setLeadCollectionFieldChecks(leadSettings: any[], hasWriteAccess: boolean, cardSetting: DigitalBusinessCardSetting, cardType: DBCCardTypes, isSalesforceConnected: boolean) {
    leadSettings.forEach((setting: any) => {
        if (setting?.name === 'company') {
            setting['disable'] = !hasWriteAccess || (cardSetting.lead_collection && cardType !== DBCCardTypes.TEMPLATE_CARDS) || isSalesforceConnected;

            // If Sales-force is on set default value to true
            setting['default'] = isSalesforceConnected;
        }
    });

    return leadSettings;
}

