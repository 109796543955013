export enum SOCIAL_MEDIA {
    Facebook = 1,
    Instagram = 2,
    Twitter = 3,
    LinkedIn = 4,
    TikTok = 5,
    SnapChat = 6,
    Whatsapp = 7,
    Telegram = 8,
    Vimeo = 9,
    Youtube = 10,
    Wistia = 11,
    Twitch = 12,
    Discord = 13,
    Pinterest = 14,
    Yelp = 15,
    Paypal = 16,
    Venmo = 17,
    CashApp = 18,
    Calendly = 19,
    Shopify = 20,
    Github = 21,
    Dribbble = 22,
    Behance = 23,
    CustomUrl = 24
}


export const socialMediaNamesArray = [ 'facebook', 'instagram', 'twitter', 'linkedin', 'tiktok', 'snapchat', 'whatsapp', 'telegram', 'vimeo', 'youtube', 'wistia', 'twitch', 'discord', 'pinterest', 'yelp', 'paypal', 'venmo', 'cashapp', 'calendly', 'shopify', 'github', 'dribbble', 'behance', 'custom_url']

export const socialIconsFontClasses = {
    'yelp': 'fab fa-yelp',
    'vimeo': 'fab fa-vimeo',
    'github': 'fab fa-github',
    'paypal': 'fab fa-paypal',
    'tiktok': 'fab fa-tiktok',
    'twitch': 'fab fa-twitch',
    'behance': 'fab fa-behance',
    'discord': 'fab fa-discord',
    'shopify': 'fab fa-shopify',
    'youtube': 'fab fa-youtube',
    'dribbble': 'fab fa-dribbble',
    'facebook': 'fab fa-facebook',
    'linkedin': 'fab fa-linkedin',
    'snapchat': 'fab fa-snapchat',
    'telegram': 'fab fa-telegram',
    'whatsapp': 'fab fa-whatsapp',
    'instagram': 'fab fa-instagram',
    'pinterest': 'fab fa-pinterest',
};

export const socialMediaMetaData = [
    {
        id: 1,
        logo: '../../../assets/img/vcard-plus-social-icons/facebook_icon.png',
        title: 'Facebook',
        font_aweomse_class: socialIconsFontClasses['facebook']
    },
    {
        id: 2,
        logo: '../../../assets/img/vcard-plus-social-icons/instagram_icon.png',
        title: 'Instagram',
        font_aweomse_class: socialIconsFontClasses['instagram']
    },
    {
        id: 3,
        logo: '../../../assets/img/vcard-plus-social-icons/twitter_icon.png',
        title: 'Twitter'
    },
    {
        id: 4,
        logo: '../../../assets/img/vcard-plus-social-icons/linkedin_icon.png',
        title: 'LinkedIn',
        font_aweomse_class: socialIconsFontClasses['linkedin']
    },
    {
        id: 5,
        logo: '../../../assets/img/vcard-plus-social-icons/tiktok_icon.png',
        title: 'TikTok',
        font_aweomse_class: socialIconsFontClasses['tiktok']
    },
    {
        id: 6,
        logo: '../../../assets/img/vcard-plus-social-icons/snapchat_icon.png',
        title: 'Snapchat',
        font_aweomse_class: socialIconsFontClasses['snapchat']
    },
    {
        id: 7,
        logo: '../../../assets/img/vcard-plus-social-icons/whatsapp_icon.png',
        title: 'Whatsapp',
        font_aweomse_class: socialIconsFontClasses['whatsapp']
    },
    {
        id: 8,
        logo: '../../../assets/img/vcard-plus-social-icons/telegram.png',
        title: 'Telegram',
        font_aweomse_class: socialIconsFontClasses['telegram']
    },
    {
        id: 9,
        logo: '../../../assets/img/vcard-plus-social-icons/Vimeo.png',
        title: 'Vimeo',
        font_aweomse_class: socialIconsFontClasses['vimeo']
    },
    {
        id: 10,
        logo: '../../../assets/img/vcard-plus-social-icons/youtube_icon.png',
        title: 'Youtube',
        font_aweomse_class: socialIconsFontClasses['youtube']
    },
    {
        id: 11,
        logo: '../../../assets/img/vcard-plus-social-icons/Vistea.png',
        title: 'Wistia'
    },
    {
        id: 12,
        logo: '../../../assets/img/vcard-plus-social-icons/Twitch.png',
        title: 'Twitch',
        font_aweomse_class: socialIconsFontClasses['twitch']
    },
    {
        id: 13,
        logo: '../../../assets/img/vcard-plus-social-icons/Discord.png',
        title: 'Discord',
        font_aweomse_class: socialIconsFontClasses['discord']
    },
    {
        id: 14,
        logo: '../../../assets/img/vcard-plus-social-icons/pinterest_icon.png',
        title: 'Pinterest',
        font_aweomse_class: socialIconsFontClasses['pinterest']
    },
    {
        id: 15,
        logo: '../../../assets/img/vcard-plus-social-icons/Yelp.png',
        title: 'Yelp',
        font_aweomse_class: socialIconsFontClasses['yelp']
    },
    {
        id: 16,
        logo: '../../../assets/img/vcard-plus-social-icons/Paypal.png',
        title: 'Paypal',
        font_aweomse_class: socialIconsFontClasses['paypal']
    },
    {
        id: 17,
        logo: '../../../assets/img/vcard-plus-social-icons/Venmo.png',
        title: 'Venmo',
        font_aweomse_class: socialIconsFontClasses['venmo']
    },
    {
        id: 18,
        logo: '../../../assets/img/vcard-plus-social-icons/Cashapp.png',
        title: 'CashApp'
    },
    {
        id: 19,
        logo: '../../../assets/img/vcard-plus-social-icons/Calendly.png',
        title: 'Calendly'
    },
    {
        id: 20,
        logo: '../../../assets/img/vcard-plus-social-icons/shopify.png',
        title: 'Shopify',
        font_aweomse_class: socialIconsFontClasses['shopify']
    },
    {
        id: 21,
        logo: '../../../assets/img/vcard-plus-social-icons/Github.png',
        title: 'Github',
        font_aweomse_class: socialIconsFontClasses['github']
    },
    {
        id: 22,
        logo: '../../../assets/img/vcard-plus-social-icons/Dribbble.png',
        title: 'Dribbble',
        font_aweomse_class: socialIconsFontClasses['dribbble']
    },
    {
        id: 23,
        logo: '../../../assets/img/vcard-plus-social-icons/Behance.png',
        title: 'Behance',
        font_aweomse_class: socialIconsFontClasses['behance']
    },
    {
        id: 24,
        logo: '../../../assets/img/vcard-plus-social-icons/URL.png',
        title: 'Custom URL'
    },
]
