import { Component, Input } from '@angular/core';
@Component({
    selector: 'beaconstac-google-wallet-pass-preview',
    templateUrl: './google-wallet-pass-preview.component.html',
    styleUrls: ['./google-wallet-pass-preview.component.scss']
})
export class GoogleWalletPassPreviewComponent {

    @Input() backgroundColor: string;
    @Input() textColor: string;
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() company: string;
    @Input() email: string;
    @Input() phone: string;
    @Input() logo: string;
    @Input() website: string;
    @Input() designation: string;
    @Input() heroImage: string;
    constructor() { }
}
