import { share, tap } from 'rxjs/operators';
import { BaseBackendService } from '../global-services/base-backend.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../global-services/auth.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageModalService } from '../shared/message-modal/message-modal.service';
import { Organization } from 'app/user-account/user-organization.model';

@Injectable()
export class WalletpassTemplateService extends BaseBackendService<Organization> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, Organization, 'walletpass-templates', messageModal);
    }

    put(body: object, id: number | string, custom?: string): Observable<any> {
        const sub = super.put(body, id, custom).pipe(
            tap((res) => {
                this.authService.setOrganization(res);
                this.authService.updateOrgInMyOrgList(res);
                this.authService.setCurrentOrgObjInLocalStorage(res);
            }),
            share(),
        );
        sub.subscribe(
            (_) => {},
            (_) => {},
        );
        return sub;
    }
}
