import {finalize, takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {AuthService} from '../../global-services/auth.service';
import {environment} from '../../../environments/environment';
import {OverlayService} from '../../global-services/overlay.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';

@Component({
    selector: 'beaconstac-stripe-card',
    templateUrl: './stripe-card.component.html',
    styleUrls: ['stripe-card.component.scss']
})
export class StripeCardComponent implements AfterViewInit, OnDestroy {

    private static stripeCardBaseURL = environment.baseURL + environment.apiEndpoint + environment.apiVersion + '/users/';

    @Output() cardUpdated: EventEmitter<void> = new EventEmitter();
    @Output() onPlanTypeSelected: EventEmitter<boolean> = new EventEmitter();
    @Output() accountPassword: EventEmitter<string> = new EventEmitter();

    @Input() primaryButtonText: string = 'Confirm Card';
    @Input() staterUpgrade: boolean = false;
    @Input() headerText: string = 'Change Credit Card';
    @Input() disabled: boolean = false;

    @ViewChild('creditCardModal', {static: true}) creditCardModal: ModalDirective;

    // Starter plan variables
    staterPlanYearly: boolean = false;
    accountPasswordText: string;
    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private http: HttpClient, private authService: AuthService, private overlay: OverlayService,
        private messageModal: MessageModalService) {
    }

    private buildHeaders(argsObject: object): object {
        if (!argsObject) {
            // throw new Error('RequestOptionsArgs not passed to buildHeaders');
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });
        argsObject['headers'] = headers;
        return argsObject;
    }

    ngAfterViewInit(): void {
        this.getToken();
    }

    show() {
        this.creditCardModal.show();
    }

    hide() {
        this.creditCardModal.hide();
    }

    getToken() {

        const _this = this;

        const stripe = (<any>window).Stripe(environment.stripeKey);
        const elements = stripe.elements();
        const card = elements.create('card');

        card.mount('#card-element');

        card.addEventListener('change', function (event) {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        const form = document.getElementById('payment-form');
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            _this.overlay.isLoading(true);
            _this.creditCardModal.hide();
            stripe.createToken(card).then(function (result) {
                if (result.error) {
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    _this.messageModal.show('Failed to update your card', 'danger');
                    _this.overlay.isLoading(false);
                } else {
                    _this.updateCard(result.token.id);
                }
            });
        });
    }

    updateCard(token: string): void {
        const body = {
            stripe_card_token: token,
            default: true
        };
        const url = StripeCardComponent.stripeCardBaseURL + this.authService.getUser().id + '/cards/';
        this.http.post(url, body, this.buildHeaders({})).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.overlay.isLoading(false);
            })).subscribe(res => {
            this.messageModal.show('Card updated successfully', 'success');
            this.cardUpdated.emit();
        }, error => {
            this.messageModal.show('There was an error while updating your card', 'danger');
        })
    }

    onPlanTypeClicked(yearly: boolean) {
        this.staterPlanYearly = yearly;
        this.onPlanTypeSelected.emit(this.staterPlanYearly);
    }

    onAccountPasswordEntered() {
        this.accountPassword.emit(this.accountPasswordText)
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    confirmCard() {
        document.getElementById('upgrade-submit-button').click();
    }
}
