import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
    selector: 'beaconstac-stroke-radio-button',
    templateUrl: './stroke-radio-button.component.html',
    styleUrls: ['./stroke-radio-button.component.scss']
})
export class StrokeRadioButtonComponent implements OnInit {

    @Output() onClick = new EventEmitter<any>();
    @Input() button = {
        name: '',
        selected: false,
        disabled: false,
        value: null
    };

    constructor() {}

    ngOnInit() { }

    handleButtonClick() {
        this.onClick.emit(this.button);
    }
}
