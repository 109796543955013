import * as moment from 'moment'
import {SearchParams} from '../../global-services/base-backend.service';

export interface FilterOperator {
    name: string,
    value: string,
}

export const IS_WATCHLISTED = 'is_watchlisted';

export enum FILTER_OPERATOR_TYPE {
    String, Number, Date, Dropdown, NUMBER_OF_PRODUCTS_SELECTED
}

export class Filter {
    id?: string;
    name: string;
    value: string;
    icon: string;
    input: string;
    dropdownOptions?: Array<{
        text: string,
        value: string | number
    }>;
    dropDownChoice?: {
        text: string,
        value: string | number
    } |  any;
    radioOptions?: Array<{
        value: any;
        name: string,
        state: boolean,
        disabled: boolean
    }>;
    date?: {__startDate__: string, __endDate__: string};
    operators?: Array<FilterOperator>;
    selectedOperator?: FilterOperator;
    type: FILTER_OPERATOR_TYPE;
    selectedRadioOption: { name: string; state: boolean; disabled: boolean; value?: string };
    queryParam?: string;
    useOnce?: boolean;
    show?: boolean;
    placeholder?: string;
    isPremium?: boolean;

    constructor(json?) {
        this.id = json['id'];
        this.name = json['name'];
        this.value = json['value'];
        this.icon = json['icon'];
        this.input = json['input'];
        this.placeholder = json['placeholder'];
        this.dropdownOptions = json['dropdownOptions'];
        this.dropDownChoice = json['dropDownChoice'];
        this.radioOptions = json['radioOptions'];
        this.date = json['date'] || {__startDate__: '', __endDate__: ''};
        this.operators = json['operators'];
        this.selectedOperator = json['selectedOperator'];
        this.type = json['type'];
        this.selectedRadioOption = json['selectedRadioOption'];
        this.queryParam = json['queryParam'];
        this.useOnce = json['useOnce'];
        this.show = json['show'];
        this.isPremium = json['isPremium'];
    }


    dateRadioChoiceToValue(option: string): string {
        if (option === 'Last 24 hours') {
            return '1';
        } else if (option === 'Last week') {
            return '7';
        } else if (option === 'Last month') {
            return '30';
        } else if (option === 'Last 3 months') {
            return '90';
        } else if (option.includes('days')){
            return moment(new Date()).subtract(parseInt(option.split(' ')[0]), 'days').format('YYYY-MM-DD');
        }
        return '';
    }

    toKeyValue(item?): SearchParams {
        const params: SearchParams = {}
        switch (this.type) {
            case FILTER_OPERATOR_TYPE.String:
            case FILTER_OPERATOR_TYPE.Number:
            case FILTER_OPERATOR_TYPE.NUMBER_OF_PRODUCTS_SELECTED:
                params[this.value + this.selectedOperator.value] = this.input;
                break;
            case FILTER_OPERATOR_TYPE.Dropdown:
                const key = this.value + this.selectedOperator.value;
                if (this.name === 'Owner') {
                    if (this.selectedOperator.name === 'is'){
                        params[key] = (item.dropDownChoice?.value || item.input);
                        if (!this.dropDownChoice){
                            this.dropDownChoice = (item.dropDownChoice || { name : item.input , value : item.input });
                        }
                    } else {
                        params[key] = this.input;
                    }
                } else {
                    params[key] = this.dropDownChoice ? this.dropDownChoice.value : null;
                }
                if (this.value === 'tags') {
                    params[this.value] = this.dropDownChoice.text;
                }
                break;
            case FILTER_OPERATOR_TYPE.Date:
                if (this.input === 'Custom') {
                    if (this.date.__startDate__) {
                        params[this.value + '__gte'] = this.date.__startDate__;
                    }
                    if (this.date.__endDate__) {
                        params[this.value + '__lte'] = this.date.__endDate__;
                    }
                } else {
                    params[this.value + '__days'] = this.dateRadioChoiceToValue(this.input);
                    params[this.value + '__gte'] = moment(new Date()).subtract(parseInt(this.dateRadioChoiceToValue(this.input)), 'days').format('YYYY-MM-DD');
                }
                break;
        }
        return params;
    }

    keyFromKeyValue(): string{
        return Object.keys(this.toKeyValue())[0];
    }

    valueFromKeyValue(): string | number{
        return Object.values(this.toKeyValue())[0];
    }
}
