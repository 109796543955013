import {Directive, HostListener, Input} from '@angular/core';
import {Router} from '@angular/router';
import {debounceTime} from 'rxjs/operators';

/**
 * Miniside bar highlighting.
 */
@Directive({
    selector: '[appSidebarDropdown]'
})
export class SidebarNestedLinkDirective {
    constructor(router: Router) {
        router.events.pipe(debounceTime(1000), ).subscribe((event) => {
            this.setUpDropDownLinkClassOnLoad();
        });
        this.setUpDropDownLinkClassOnLoad();
    }

    setUpDropDownLinkClassOnLoad() {
        const activeDropdowns = document.getElementsByClassName('dropdown-nested-link-active');
        for (let i = 0; i < activeDropdowns.length; i++) {
            activeDropdowns.item(i).classList.remove('dropdown-nested-link-active');
        }
        const currentActiveLink = document.getElementsByClassName('nav-link active');

        // @ts-ignore
        if (currentActiveLink.length === 0) {
            setTimeout(this.setUpDropDownLinkClassOnLoad, 500);
        } else {
            if (currentActiveLink.item(0).parentElement &&
        currentActiveLink.item(0).parentElement.parentElement &&
        currentActiveLink.item(0).parentElement.parentElement &&
        currentActiveLink.item(0).parentElement.parentElement.parentElement &&
        currentActiveLink.item(0).parentElement.parentElement.parentElement.classList.contains('nav-dropdown')
            ) {
                currentActiveLink.item(0).parentElement.parentElement.parentElement.classList.add('dropdown-nested-link-active');
            }
        }
    }
}


@Directive({
    selector: '[appSidebarMinimizer]'
})
export class SidebarMinimizeDirective {
    @Input() pinSidebar: boolean;
    constructor() {
    }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {
        $event.preventDefault();
        document.querySelector('body').classList.toggle('sidebar-minimized');
    }
}

/**
 * Allows to open the sidebar when you click anywhere in the sidebar
 */
@Directive({
    selector: '[appSidebarOpener]'
})
export class SidebarOpenDirective {
    @Input() pinSidebar: boolean;
    constructor() {
    }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {
        $event.preventDefault();
        if (this.pinSidebar) {
            document.querySelector('body').classList.remove('sidebar-minimized');
        }
    }
}

@Directive({
    selector: '[appMobileSidebarToggler]'
})
export class MobileSidebarToggleDirective {
    @Input() pinSidebar: boolean;
    constructor() {
    }

    // Check if element has class
    hasClass(target: any, elementClassName: string) {
        return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
    }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {
        $event.preventDefault();
        if (!this.pinSidebar) {
            document.querySelector('body').classList.toggle('sidebar-mobile-show');
        }
    }
}

export const SIDEBAR_TOGGLE_DIRECTIVES = [
    SidebarMinimizeDirective,
    SidebarOpenDirective,
    MobileSidebarToggleDirective,
    SidebarNestedLinkDirective,
];
