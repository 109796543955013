import { environment } from 'environments/environment';

export class CustomFont {

    id: number;
    url: string;
    status: string;
    name: string;
    source: string;
    md5_hash: string;
    content_type: string;
    created: string;
    modified: string;
    organization: number;
    style: string;
    public: boolean;
    family: string;
    __preview_url: string;
    maintainer: number;
    s3_object_key: string;
    meta: object;

    constructor(json: any) {
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.url = json['url'];
        this.status = json['status'];
        this.name = json['name'];
        this.source = json['source'];
        this.md5_hash = json['md5_hash'];
        this.content_type = json['content_type'];
        this.created = json['created'];
        this.modified = json['modified'];
        this.organization = json['organization'];
        this.style = json['style'];
        this.public = json['public'];
        this.family = json['family'];
        this.maintainer = json['maintainer'];
        this.s3_object_key = json['s3_object_key'];
        this.meta = json['meta'];
        if (this.content_type === 'application/pdf') {
            this.__preview_url = environment.eddyProPDFUrl + this.id;
        }
    }

    toJSON(): object {
        return {
            id: this.id,
            url: this.url,
            status: this.status,
            name: this.name,
            source: this.source,
            md5_hash: this.md5_hash,
            content_type: this.content_type,
            created: this.created,
            modified: this.modified,
            organization: this.organization,
            style : this.style,
            public: this.public,
            family: this.family,
            maintainer: this.maintainer,
            s3_object_key: this.s3_object_key,
            meta: this.meta,
        };
    }

    toString(): string {
        return JSON.stringify(this.toJSON());
    }
}

export interface CustomFontsData {
    header: { text: string; mobile: boolean }[];
    data: {
        dataObject: string;
        dataItems: {
            title: { type: string; class: string; mobile: boolean; value: { text: string } };
            labels: { type: string; value: { text: string } };
        };
        toolbarIcons: {
            desktop: { class: string; color: string; tooltip: string; event: { type: string; operation: string }; hidden: boolean; }[];
            mobile: { class: string; color: string; tooltip: string; event: { type: string; operation: string; output?: string } }[];
        };
    }[];
}

export interface Typography {
    font_type: string;
    font_family: string;
    personal_info: {
        google_font_style?: string;
        google_font_size?: number;
        google_font_colour?: string;

        custom_font_style?: string;
        custom_font_url?: string;
        custom_font_size?: number;
        custom_font_colour?: string;
    };
    company_details: {
        google_font_style?: string;
        google_font_size?: number;
        google_font_colour?: string;

        custom_font_style?: string;
        custom_font_url?: string;
        custom_font_size?: number;
        custom_font_colour?: string;
    };
    contact_details: {
        google_font_style?: string;
        google_font_size?: number;
        google_font_colour?: string;

        custom_font_style?: string;
        custom_font_url?: string;
        custom_font_size?: number;
        custom_font_colour?: string;
    };
    button: {
        google_font_style?: string;
        google_font_size?: number;
        google_font_colour?: string;

        custom_font_style?: string;
        custom_font_url?: string;
        custom_font_size?: number;
        custom_font_colour?: string;
    };
    bio: {
        google_font_style?: string;
        google_font_size?: number;
        google_font_colour?: string;

        custom_font_style?: string;
        custom_font_url?: string;
        custom_font_size?: number;
        custom_font_colour?: string;
    };
}

export class CustomFontObject {
    name: string;
    value: string;
    isHeader?: boolean;
    isClickable?: boolean;
}
