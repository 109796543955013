export enum CARD_PROPERTY_TYPES {
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    PHONE = 'phone',
    EMAIL = 'email',
    WEBSITE = 'website',
    ADDRESS = 'address',
    SUMMARY = 'summary',
    LOGO_URL = 'logo_url',
    DESIGNATION = 'designation',
    COMPANY = 'company',
    SOCIAL_LINKS = 'social_links',
    CUSTOMIZATIONS = 'customizations',
    USER_IMAGE_URL = 'user_image_url',
    PRONOUNS = 'pronouns',
    AUTODOWNLOAD = 'autodownload',
    PREFIX = 'prefix',
    SUFFIX = 'suffix',
    DEPARTMENT = 'department',
    CUSTOM_FIELDS = 'custom_fields',
    LAYOUT = 'layout',
    LOCATION_ENABLED = 'location_enabled',
    LEAD_COLLECTION = 'lead_collection',

    LEAD_COLLECTION_V2 = 'lead_collection_v2',
    LOCATION_ENABLED_V2 = 'location_enabled_v2',
    AUTODOWNLOAD_V2 = 'autodownload_v2',
    EMAIL_WALLET_PASS = 'email_wallet_pass',
    IP_LOCATION_ENABLED = 'ip_location_enabled',
    TEMPLATE = 'template',
    LEAD_USER_AGREEMENT = 'lead_user_agreement',
}

export class DigitalBusinessCardSetting {
    first_name: boolean = undefined;
    last_name: boolean = false;
    phone: boolean = false
    email: boolean = false;
    website: boolean = false;
    address: boolean = false;
    summary: boolean = false;
    logo_url: boolean = false;
    designation: boolean = false;
    company: boolean = false;
    social_links: boolean = false;
    customizations: boolean = false;
    user_image_url: boolean = false;
    pronouns: boolean = false;
    autodownload: boolean = false;
    prefix: boolean = false;
    suffix: boolean = false;
    department: boolean = false;
    custom_fields: boolean = false;
    layout: boolean = false;
    location_enabled: boolean = false;
    lead_collection: boolean = false;
    custom_labels: Array<String> = [];
    organization: number = null;
    template: boolean = false;

    // New fields
    id: number = null;
    enforce_card_template: boolean = false;
    email_wallet_pass: boolean = false
    ip_location_enabled: boolean = false
    lead_user_agreement: boolean = false

    constructor(json?) {
        if (!json) {
            return;
        }
        this.first_name = json['first_name'] || false;
        this.last_name = json['last_name'] || false;
        this.phone = json['phone'] || false;
        this.email = json['email'] || false;
        this.website = json['website'] || false;
        this.address = json['address'] || false;
        this.summary = json['summary'] || false;
        this.logo_url = json['logo_url'] || false;
        this.designation = json['designation'] || false;
        this.company = json['company'] || false;
        this.customizations = json['customizations'] || false;
        this.user_image_url = json['user_image_url'] || false;
        this.social_links = json['social_links'] || false;
        this.pronouns = json['pronouns'] || false;
        this.custom_fields = json['custom_fields'] || false;
        this.prefix = json['prefix'] || false;
        this.suffix = json['suffix'] || false;
        this.department = json['department'] || false;
        this.layout = json['layout'] || false;
        this.autodownload = json['autodownload'] || false;
        this.location_enabled = json['location_enabled'] || false;
        this.lead_collection = json['lead_collection'] || false;
        this.custom_labels = json['custom_labels'] || [];
        this.organization = json['organization'] || null;
        this.template = json['template'] || false;

        this.id = json['id'];
        this.enforce_card_template = json['enforce_card_template'] || false;
        this.email_wallet_pass = json['email_wallet_pass'] || false;
        this.ip_location_enabled = json['ip_location_enabled'] || false;
        this.lead_user_agreement = json.hasOwnProperty('lead_user_agreement') ? json['lead_user_agreement'] : false;
    }
}
