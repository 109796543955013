import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoActionPopupComponent } from './info-action-popup.component';

@NgModule({
    declarations: [InfoActionPopupComponent],
    imports: [CommonModule],
    exports: [InfoActionPopupComponent],
})
export class InfoActionPopupModule {}
