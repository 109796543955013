import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

export interface DiscardChanges {
    hasWriteAccess: boolean;
    hasUnsavedChanges: () => Observable<boolean>;
}

@Injectable()
export class DiscardChangesGuard implements CanDeactivate<DiscardChanges> {

    canDeactivate(component: DiscardChanges, _, __, nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!component?.hasWriteAccess || !nextState || nextState.url === '/login') {
            return true;
        }

        return component.hasUnsavedChanges().pipe(map((hasUnsavedChanges): boolean => {
            if (hasUnsavedChanges) {
                return window.confirm('You have unsaved changes on this page. Are you sure you want to discard them?');
            } else {
                return true;
            }
        }));
    }
}
