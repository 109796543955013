import {environment} from '../../environments/environment';

export class SocialMedia {
    title: string;
    message: string;
    brand_image: string;
    sub_text: string;
    customizations: {
        title_color: string,
        message_background_color: string
    } = {
            title_color: '#ad63ff',
            message_background_color: '#f0efff'
        };
    logo_url: string;
    urls: {
        facebook: string;
        instagram: string;
        whatsapp: string;
        linkedin: string;
        twitter: string;
        pinterest: string;
        snapchat: string;
        youtube: string;
        tiktok: string;
        website: string;
    } = {
            facebook: '',
            instagram: '',
            whatsapp: '',
            linkedin: '',
            twitter: '',
            pinterest: '',
            snapchat: '',
            youtube: '',
            tiktok: '',
            website: '',
        };
    constructor(json?) {
        if (!json) {
            return;
        }

        this.title = json['title'] ? json['title'] : this.title;
        this.message = json['message'] ? json['message'] : this.message;
        this.sub_text = json['sub_text'] ? json['sub_text'] : this.sub_text;
        this.logo_url = json['logo_url'] ? json['logo_url'] : this.logo_url;
        this.brand_image = json['brand_image'] ? json['brand_image'] : this.brand_image;
        this.urls = json['urls'] ? json['urls'] : this.urls;
        this.customizations = json['customizations'] ? json['customizations'] : this.customizations;
    }

    // tslint:disable-next-line:member-ordering
    static getSocialMediaPreviewHTML(social_media, isMobile: boolean) {
        let html = `<html lang="en"><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <link rel="stylesheet" href="${environment.socialMediaCssEndpoint}social_media.css">
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
    <style>
        .content {
            max-width: 500px;
            margin: auto;
            background-color: #ffffff;
        }
        .social_logo{
            max-height: 80px !important;
        }
        .no-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .no-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
    </style>
    </head>

    <body class="no-scrollbar" style="margin: 0; padding-bottom: 10px; ${isMobile ? '' : 'zoom: 0.70'}">
        <div class="content">
            <div style="background-color: #ffffff">`;

        if (social_media.logo_url) {
            html += `<img class="logo_image" src="${social_media.logo_url}">`;
        }
        html += `<h1 style="color: ${social_media.customizations.title_color}" class="heading">
                    ${social_media.title ? social_media.title : 'Follow Us !!!'}
                    </h1>`;
        if (social_media.brand_image) {
            html += `<img class="image" src="${social_media.brand_image}">`;
        } else {
            html += `<img class="image" src="${environment.imagesEndpoint}social_media/social_media_back.png">`;
        }
        html += `<div class="sub_section" style="background-color: ${social_media.customizations.message_background_color}">`;
        if (social_media.message) {
            html += `<p class="message">${social_media.message}</p>`;
            if (social_media.sub_text) {
                html += '<div style="padding-bottom: 0px"></div>';
            } else {
                html += '<div style="padding-bottom: 20px"></div>';
            }
        }
        if (social_media.sub_text) {
            html += `<p class="sub_text">${social_media.sub_text}</p>`;
        }
        html += `</div>
               <div class="button_icon_container">`;
        if (social_media.urls.website) {
            html += `<img src="${environment.imagesEndpoint}social_media/website_icon.png" class="social_logo grid-item">`;
        }
        if (social_media.urls.whatsapp) {
            html += `<img src="${environment.imagesEndpoint}social_media/whatsapp_icon.png" class="social_logo grid-item">`;
        }
        if (social_media.urls.facebook) {
            html += `<img src="${environment.imagesEndpoint}social_media/facebook_icon.png" class="social_logo grid-item">`;
        }
        if (social_media.urls.instagram) {
            html += `<img src="${environment.imagesEndpoint}social_media/instagram_icon.png" class="social_logo grid-item">`;
        }
        if (social_media.urls.linkedin) {
            html += `<img src="${environment.imagesEndpoint}social_media/linkedin_icon.png" class="social_logo grid-item">`;
        }
        if (social_media.urls.twitter) {
            html += `<img src="${environment.imagesEndpoint}social_media/twitter_icon.png" class="social_logo grid-item" [src]="">`;
        }
        if (social_media.urls.pinterest) {
            html += `<img src="${environment.imagesEndpoint}social_media/pinterest_icon.png" class="social_logo grid-item">`;
        }
        if (social_media.urls.youtube) {
            html += `<img src="${environment.imagesEndpoint}social_media/youtube_icon.png" class="social_logo grid-item">`;
        }
        if (social_media.urls.snapchat) {
            html += `<img src="${environment.imagesEndpoint}social_media/snapchat_icon.png" class="social_logo grid-item">`;
        }
        if (social_media.urls.tiktok) {
            html += `<img src="${environment.imagesEndpoint}social_media/tiktok_icon.png" class="social_logo grid-item">`;
        }

        html += `</div></div>

                <script>
                var buttonContainer = document.getElementsByClassName('button_icon_container')[0];
                var col = '--columns:' + (buttonContainer.childElementCount >=3 ? 3 : buttonContainer.childElementCount);
                buttonContainer.setAttribute('style', col );
                </script>
                </body></html>`;
        return html;
    }
}
